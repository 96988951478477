import SvgIcon from 'components/common/SvgIcon'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperFormDropdown,
  KooperFormField,
  KooperFormGroup,
  KooperTooltip,
} from 'kooper-library'
import {Controller} from 'react-hook-form'
import {useState} from 'react'
import {Select} from 'components/common/form'
import ParentGoalModal from './ParentGoalModal'

const AlignmentCreatCard = ({control, errors}) => {
  const [isOpenParentGoalModal, setIsOpenParentGoalModal] = useState(false)
  return (
    <KooperCard fluid>
      <KooperCardContent>
        <KooperCardHeader>Alignment</KooperCardHeader>
        <KooperFormField>
          <label>Parent goal</label>
        </KooperFormField>
        <KooperFormField>
          <KooperButton onClick={() => setIsOpenParentGoalModal(true)} basic content="+ Select a parent goal" />
        </KooperFormField>

        <KooperFormGroup>
          <Select
            width={8}
            control={control}
            errors={errors}
            name="subTeamIds"
            label="Select Metric"
            tooltipProps={{
              content: 'Select Metric',
            }}
            placeholder="Select goal owner"
            options={[
              {key: 'Numeric', text: 'Numeric', value: 'numeric'},
              {key: 'Percentage', text: 'Percentage', value: 'percentage'},
              {key: 'Yes/No', text: 'Yes/No', value: 'boolean'},
            ]}
          />
          <KooperFormField width={8}>
            <div className="info-header">
              <label>Sub-teams</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Which teams do you expect to directly align with this goal? We will notify their managers and invite them to create sub-goals."
                size="mini"
                position="top center"
              />
            </div>
            <Controller
              control={control}
              name="subTeamIds"
              render={({onChange, value}) => (
                <KooperFormDropdown
                  placeholder="e.g. Marketing team"
                  fluid
                  selection
                  defaultValue="marketting"
                  options={[
                    {key: 'marketting', text: 'marketting', value: 'Marketting'},
                    {key: 'alex', text: 'Alex', value: 'alex'},
                    {key: 'sam', text: 'Sam', value: 'sam'},
                  ]}
                />
              )}
            />
          </KooperFormField>
        </KooperFormGroup>
      </KooperCardContent>

      {isOpenParentGoalModal && (
        <ParentGoalModal isModalOpen={isOpenParentGoalModal} setIsModalOpen={setIsOpenParentGoalModal} />
      )}
    </KooperCard>
  )
}

export default AlignmentCreatCard
