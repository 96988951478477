import KooperCustomPagination from 'components/common/KooperCustomPagination'
import SvgIcon from 'components/common/SvgIcon'
import {
  KooperContainer,
  KooperGrid,
  KooperGridColumn,
  KooperNoData,
  KooperIcon,
  KooperSearch,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'

const OneOnOnes = () => {
  const currentPage = 1
  const totalPages = 1
  const isLoading = false
  const goalList = []
  const renderUserBody = () => {
    if (isLoading) {
      return <KooperTablePlaceholder columns={2} line={1} row={3} />
    }
    if (!isLoading && goalList?.length === 0) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="2" className="emptyValue">
            <KooperNoData type="table" noDataText="No Data Available" />
          </KooperTableCell>
        </KooperTableRow>
      )
    }
    // return get(['data'], userActivitiesSLAData, []).map(
    //   ({user, unresolved, resolved, firstResponseTime, resolvedTime, feedback}, index) => (
    //     <KooperTableRow key={index}>
    //       <KooperTableCell>{startCase(user)}</KooperTableCell>
    //       <KooperTableCell>{unresolved}</KooperTableCell>
    //       <KooperTableCell>{resolved}</KooperTableCell>
    //       <KooperTableCell>{firstResponseTime}</KooperTableCell>
    //       <KooperTableCell>{resolvedTime}</KooperTableCell>
    //       <KooperTableCell>{feedback}</KooperTableCell>
    //     </KooperTableRow>
    //   )
    // )
  }
  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%'}}>
      <KooperContainer>
        <div className="page-header">
          <div className="page-title">
            <div className="page-titleIcon">
              <SvgIcon path="analytics/reports/email-tracking" />
              <h5>1-on-1s report</h5>
            </div>
          </div>
          <div className="page-action">
            <KooperSearch placeholder="Search for a team or manager" />
          </div>
        </div>

        <KooperGrid>
          <KooperGridColumn width={16}>
            <KooperTable sortable basic>
              <KooperTableHeader>
                <KooperTableRow>
                  <KooperTableHeaderCell>Team</KooperTableHeaderCell>
                  <KooperTableHeaderCell textAlign="center">Members</KooperTableHeaderCell>
                  <KooperTableHeaderCell textAlign="right">Participation</KooperTableHeaderCell>
                </KooperTableRow>
              </KooperTableHeader>
              <KooperTableBody>
                <KooperTableRow>
                  <KooperTableCell>
                    Jalene's Direct Report
                    <KooperTooltip
                      trigger={<KooperIcon name="user" />}
                      content={
                        <div>
                          <p>
                            <strong>Managers:</strong>
                          </p>
                          <p>• Alexandre Yildi</p>
                          <p>• Douglas Wood</p>
                          <p>• Simon Coutu</p>
                        </div>
                      }
                      position="top center"
                      hoverable
                    />
                  </KooperTableCell>
                  <KooperTableCell textAlign="center">33 members</KooperTableCell>
                  <KooperTableCell textAlign="right">3% (1/33)</KooperTableCell>
                </KooperTableRow>
                <KooperTableRow>
                  <KooperTableCell>
                    Comité de Direction
                    <KooperTooltip
                      trigger={<KooperIcon name="user" />}
                      content={
                        <div>
                          <p>
                            <strong>Managers:</strong>
                          </p>
                          <p>• Manager 1</p>
                          <p>• Manager 2</p>
                        </div>
                      }
                      position="top center"
                      hoverable
                    />
                  </KooperTableCell>
                  <KooperTableCell textAlign="center">178 members</KooperTableCell>
                  <KooperTableCell textAlign="right">2% (4/178)</KooperTableCell>
                </KooperTableRow>
                <KooperTableRow>
                  <KooperTableCell>
                    Department RH (Barbara)
                    <KooperTooltip
                      trigger={<KooperIcon name="user" />}
                      content={
                        <div>
                          <p>
                            <strong>Managers:</strong>
                          </p>
                          <p>• Alexandre Yildi</p>
                          <p>• Douglas Wood</p>
                          <p>• Simon Coutu</p>
                        </div>
                      }
                      position="top center"
                      hoverable
                    />
                  </KooperTableCell>
                  <KooperTableCell textAlign="center">42 members</KooperTableCell>
                  <KooperTableCell textAlign="right">5% (2/42)</KooperTableCell>
                </KooperTableRow>
                <KooperTableRow>
                  <KooperTableCell>
                    Commercial (Romain)
                    <KooperTooltip
                      trigger={<KooperIcon name="user" />}
                      content={
                        <div>
                          <p>
                            <strong>Managers:</strong>
                          </p>
                          <p>• Manager A</p>
                        </div>
                      }
                      position="top center"
                      hoverable
                    />
                  </KooperTableCell>
                  <KooperTableCell textAlign="center">19 members</KooperTableCell>
                  <KooperTableCell textAlign="right">4% (1/19)</KooperTableCell>
                </KooperTableRow>
              </KooperTableBody>
            </KooperTable>

            <KooperCustomPagination
              activePage={currentPage}
              totalPages={totalPages}
              // onPageChange={page =>
              // //   setCurrentListParams(currentListParams => ({
              // //     ...currentListParams,
              // //     page,
              // //   }))
              // }
            />
          </KooperGridColumn>
        </KooperGrid>
      </KooperContainer>
    </div>
  )
}

export default OneOnOnes
