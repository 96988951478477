import {ResponsiveFunnel} from '@nivo/funnel'
import KooperCustomPagination from 'components/common/KooperCustomPagination'
import KooperNoDataTable from 'components/common/KooperNoDataTable'
import SvgIcon from 'components/common/SvgIcon'
import {
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperDropdown,
  KooperGrid,
  KooperGridColumn,
  KooperGridRow,
  KooperNoData,
  KooperLoader,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import React from 'react'

const Goals = () => {
  const currentPage = 1
  const totalPages = 1
  const isLoading = false

  const teamsData = [
    {
      team: ' Diversity & Inclusion',
      activeGoals: 8,
      membersWithGoals: '8% (6/68)',
      alignment: '12% (1/8)',
      progressUpdated: '2024-11-30',
    },
    {
      team: ' Employee Engagement',
      activeGoals: 15,
      membersWithGoals: '25% (20/80)',
      alignment: '30% (5/15)',
      progressUpdated: '2024-11-29',
    },
    {
      team: ' Operational Excellence',
      activeGoals: 'No active goals',
      membersWithGoals: '',
      alignment: '',
      progressUpdated: '',
    },
  ]

  const renderUserBody = () => {
    if (isLoading) {
      return <KooperTablePlaceholder columns={6} line={1} row={3} />
    }
    if (!isLoading && teamsData?.length === 0) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="6" className="emptyValue">
            <KooperNoData type="table" noDataText="No Data Available" />
          </KooperTableCell>
        </KooperTableRow>
      )
    }
    return teamsData.map((team, index) => (
      <KooperTableRow key={index}>
        <KooperTableCell>{team.team}</KooperTableCell>
        <KooperTableCell>{team.activeGoals}</KooperTableCell>
        <KooperTableCell>{team.membersWithGoals}</KooperTableCell>
        <KooperTableCell>{team.alignment}</KooperTableCell>
        <KooperTableCell>{team.progressUpdated}</KooperTableCell>
      </KooperTableRow>
    ))
  }
  const data = {
    membersWithGoals: 43,
    totalMembersWithGoals: 373,
    organizationAlignment: 43,
    totalOrganizationAlignment: 135,
    updatedInLast: 9,
    totalUpdatedInLast: 135,
    goalsStatus: {
      complete: 5,
      onTrack: 10,
      delayed: 3,
      atRisk: 1,
    },
  }
  const funnelData = [
    {
      id: 'step_sent',
      value: 80455,
      label: 'Complete',
    },
    {
      id: 'step_viewed',
      value: 66341,
      label: 'On track',
    },
    {
      id: 'step_clicked',
      value: 44477,
      label: 'Delayed',
    },
    {
      id: 'step_add_to_card',
      value: 37094,
      label: 'At risk',
    },
  ]
  const dateRange = [
    {
      key: 1,
      value: 'all_time',
      text: 'All time',
    },
    {
      key: 2,
      value: 'past_3_months',
      text: 'Past 3 months',
    },
    {
      key: 3,
      value: 'past_6_months',
      text: 'Past 6 months',
    },
    {
      key: 4,
      value: 'past_12_months',
      text: 'Past 12 months',
    },
  ]
  const goalTypes = [
    {
      key: 1,
      value: 'all_goal_types',
      text: 'All Goal types',
    },
    {
      key: 2,
      value: 'organizational',
      text: 'Organizational',
    },
    {
      key: 3,
      value: 'team',
      text: 'Team',
    },
    {
      key: 4,
      value: 'individual',
      text: 'Individual (Team contribution only)',
    },
  ]
  const ownersData = [
    {
      name: 'Jordan',
      goalsOwned: 10,
      alignment: '50%',
      overdue: 2,
      progressUpdated: '2024-11-25',
    },
    {
      name: 'Alex',
      goalsOwned: 8,
      alignment: '60%',
      overdue: 1,
      progressUpdated: '2024-11-24',
    },
    {
      name: 'Taylor',
      goalsOwned: 15,
      alignment: '70%',
      overdue: 3,
      progressUpdated: '2024-11-23',
    },
  ]

  const isItemLoading = false
  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/email-tracking" />
            <h5>Goals Report</h5>
          </div>
        </div>
      </div>
      <KooperGrid columns="equal">
        <KooperGridColumn width={16}>
          <KooperCard fluid>
            <KooperCardContent>
              <KooperGrid columns={3}>
                <KooperGridRow>
                  <KooperGridColumn>
                    <div className="valueBox">
                      <KooperCardHeader className="headerBox">
                        Members With Goals
                        <KooperTooltip
                          content="Number of Members who own a Goal within the organization."
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="right center"
                        />
                      </KooperCardHeader>
                      {data?.membersWithGoals ? (
                        <div className="d-flex jcfs mt-3">
                          <h3>{Math.floor((data?.membersWithGoals * 100) / data?.totalMembersWithGoals)}%</h3>
                          <span className="ml-2">
                            ({data?.membersWithGoals} / {data?.totalMembersWithGoals})
                          </span>
                        </div>
                      ) : (
                        <h3>--</h3>
                      )}
                    </div>
                  </KooperGridColumn>
                  <KooperGridColumn>
                    <div className="valueBox">
                      <KooperCardHeader className="headerBox">
                        Organization Alignment
                        <KooperTooltip
                          content="This refers to the number of active goals linked to organizational goals."
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="right center"
                        />
                      </KooperCardHeader>
                      {data?.organizationAlignment ? (
                        <div className="d-flex jcfs mt-3">
                          <h3>{Math.floor((data?.organizationAlignment * 100) / data?.totalOrganizationAlignment)}%</h3>
                          <span className="ml-2">
                            ({data?.organizationAlignment} / {data?.totalOrganizationAlignment})
                          </span>
                        </div>
                      ) : (
                        <h3>--</h3>
                      )}
                    </div>
                  </KooperGridColumn>
                  <KooperGridColumn>
                    <div className="valueBox">
                      <KooperCardHeader className="headerBox">
                        Updated in last 30 days
                        <KooperTooltip
                          content="Number of Goals updated in the last 30 days."
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="right center"
                        />
                      </KooperCardHeader>
                      {data?.updatedInLast ? (
                        <div className="d-flex jcfs mt-3">
                          <h3>{Math.floor((data?.updatedInLast * 100) / data?.totalUpdatedInLast)}%</h3>
                          <span className="ml-2">
                            ({data?.updatedInLast} / {data?.totalUpdatedInLast})
                          </span>
                        </div>
                      ) : (
                        <h3>--</h3>
                      )}
                    </div>
                  </KooperGridColumn>
                </KooperGridRow>
              </KooperGrid>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
      <KooperGrid columns="equal">
        <KooperGridColumn width={16}>
          <KooperCard fluid>
            <KooperCardContent>
              <KooperGrid>
                <KooperGridRow>
                  <KooperGridColumn>
                    <div className="valueBox">
                      <KooperCardHeader className="headerBox">
                        Goals
                        <KooperTooltip
                          content="The different statuses of your organization or team goals."
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="right center"
                        />
                      </KooperCardHeader>
                    </div>
                    <KooperCardContent className="pt-0" style={{height: 250}}>
                      {funnelData?.length > 0 && !isItemLoading ? (
                        <ResponsiveFunnel
                          data={funnelData}
                          margin={{top: 0, right: 20, bottom: 0, left: 20}}
                          direction="horizontal"
                          interpolation="linear"
                          shapeBlending={0}
                          enableLabel
                          // valueFormat=">-.4s"
                          colors={{scheme: 'spectral'}}
                          borderWidth={0}
                          borderOpacity={0}
                          beforeSeparatorLength={40}
                          beforeSeparatorOffset={0}
                          afterSeparatorLength={40}
                          afterSeparatorOffset={0}
                          borderColor={{from: 'color', modifiers: []}}
                          labelColor={{
                            from: 'color',
                            modifiers: [['darker', '3']],
                          }}
                          currentPartSizeExtension={23}
                          currentBorderWidth={0}
                          motionConfig="wobbly"
                        />
                      ) : (
                        <>
                          {isItemLoading && <KooperLoader active />}
                          {!isItemLoading && <KooperNoDataTable type="chart" noDataText="No Data Available" />}
                        </>
                      )}
                    </KooperCardContent>
                  </KooperGridColumn>
                </KooperGridRow>
              </KooperGrid>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
      <KooperGrid>
        <KooperGridColumn width={8}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Teams</KooperCardHeader>
              <KooperTooltip
                content="Number of Teams associated with Goals in the organization. Use the Teams dropdown to filter by specific teams or view all."
                trigger={<SvgIcon path="common/question" />}
                size="mini"
                position="top center"
              />

              <div className="graph-dropdown" style={{display: 'flex', gap: '10px'}}>
                <KooperTooltip
                  content="Select Teams"
                  size="mini"
                  position="top center"
                  trigger={
                    <KooperDropdown
                      search
                      placeholder="Teams(2)"
                      selection
                      style={{fontSize: '12px', marginLeft: 'auto'}}
                      options={[
                        {
                          key: 1,
                          value: 1,
                          text: 'Team 1',
                        },
                        {
                          key: 2,
                          value: 2,
                          text: 'Team 2',
                        },
                      ]}
                      // onChange={(e, {value}) =>}
                      defaultValue={1}
                    />
                  }
                />
                <KooperTooltip
                  content="Select Date range"
                  size="mini"
                  position="top center"
                  trigger={
                    <KooperDropdown
                      search
                      placeholder="All time"
                      selection
                      style={{fontSize: '12px', marginLeft: 'auto'}}
                      options={dateRange}
                      // onChange={(e, {value}) =>}
                      defaultValue="all_time"
                    />
                  }
                />
                <KooperTooltip
                  content="Select Goal Types"
                  size="mini"
                  position="top center"
                  trigger={
                    <KooperDropdown
                      search
                      placeholder="Teams(2)"
                      selection
                      style={{fontSize: '12px', marginLeft: 'auto'}}
                      options={goalTypes}
                      // onChange={(e, {value}) =>}
                      defaultValue="all_goal_types"
                    />
                  }
                />
              </div>
            </KooperCardContent>
            <KooperCardContent className="pt-0">
              <KooperTable sortable basic>
                <KooperTableHeader>
                  <KooperTableRow>
                    <KooperTableHeaderCell
                    //   sorted={currentListParams.sortBy === 'user' ? getSortType(currentListParams.sortType) : null}
                    //   onClick={handleSort('user')}
                    >
                      Team
                    </KooperTableHeaderCell>
                    <KooperTableHeaderCell
                    //   sorted={
                    //     currentListParams.sortBy === 'unresolved' ? getSortType(currentListParams.sortType) : null
                    //   }
                    //   onClick={handleSort('unresolved')}
                    >
                      Active
                    </KooperTableHeaderCell>
                    <KooperTableHeaderCell
                    //   sorted={currentListParams.sortBy === 'resolved' ? getSortType(currentListParams.sortType) : null}
                    //   onClick={handleSort('resolved')}
                    >
                      Members with goals
                    </KooperTableHeaderCell>
                    <KooperTableHeaderCell
                    //   sorted={
                    //     currentListParams.sortBy === 'firstResponseTime'
                    //       ? getSortType(currentListParams.sortType)
                    //       : null
                    //   }
                    //   onClick={handleSort('firstResponseTime')}
                    >
                      Alignment
                    </KooperTableHeaderCell>
                    <KooperTableHeaderCell
                    //   sorted={
                    //     currentListParams.sortBy === 'resolvedTime' ? getSortType(currentListParams.sortType) : null
                    //   }
                    //   onClick={handleSort('resolvedTime')}
                    >
                      Progress updated
                    </KooperTableHeaderCell>
                  </KooperTableRow>
                </KooperTableHeader>
                <KooperTableBody>{renderUserBody()}</KooperTableBody>
              </KooperTable>
              <KooperCustomPagination
                activePage={currentPage}
                totalPages={totalPages}
                // onPageChange={page =>
                // //   setCurrentListParams(currentListParams => ({
                // //     ...currentListParams,
                // //     page,
                // //   }))
                // }
              />
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
        <KooperGridColumn width={8}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Owners</KooperCardHeader>
              <KooperTooltip
                content="Number of Owners who have created Goals within the organization. Use the Owners dropdown to filter by specific individuals or view all."
                trigger={<SvgIcon path="common/question" />}
                size="mini"
                position="top center"
              />
              <div className="graph-dropdown" style={{display: 'flex', gap: '10px'}}>
                <KooperTooltip
                  content="Select Owners"
                  size="mini"
                  position="top center"
                  trigger={
                    <KooperDropdown
                      search
                      placeholder="All owners"
                      selection
                      style={{fontSize: '12px', marginLeft: 'auto'}}
                      options={[
                        {key: 1, value: 1, text: 'Jordan'},
                        {key: 2, value: 2, text: 'Test user'},
                      ]}
                      defaultValue={1}
                    />
                  }
                />
                <KooperTooltip
                  content="Select Date range"
                  size="mini"
                  position="top center"
                  trigger={
                    <KooperDropdown
                      search
                      placeholder="All time"
                      selection
                      style={{fontSize: '12px', marginLeft: 'auto'}}
                      options={dateRange}
                      defaultValue="all_time"
                    />
                  }
                />
                <KooperTooltip
                  content="Select Goal Types"
                  size="mini"
                  position="top center"
                  trigger={
                    <KooperDropdown
                      search
                      placeholder="Teams(2)"
                      selection
                      style={{fontSize: '12px', marginLeft: 'auto'}}
                      options={goalTypes}
                      defaultValue="all_goal_types"
                    />
                  }
                />
              </div>
            </KooperCardContent>
            <KooperCardContent className="pt-0">
              <KooperTable sortable basic>
                <KooperTableHeader>
                  <KooperTableRow>
                    <KooperTableHeaderCell>Name</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Goals Owned</KooperTableHeaderCell>
                    <KooperTableHeaderCell>
                      <div className="headerBox">
                        Alignment
                        <KooperTooltip
                          content="This refers to the number of active goals linked to organizational goals"
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="top center"
                        />
                      </div>
                    </KooperTableHeaderCell>
                    <KooperTableHeaderCell>Overdue</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Progress Updated</KooperTableHeaderCell>
                  </KooperTableRow>
                </KooperTableHeader>
                <KooperTableBody>
                  {ownersData.map((owner, index) => (
                    <KooperTableRow key={index}>
                      <KooperTableCell>{owner.name}</KooperTableCell>
                      <KooperTableCell>{owner.goalsOwned}</KooperTableCell>
                      <KooperTableCell>{owner.alignment}</KooperTableCell>
                      <KooperTableCell>{owner.overdue}</KooperTableCell>
                      <KooperTableCell>{owner.progressUpdated}</KooperTableCell>
                    </KooperTableRow>
                  ))}
                </KooperTableBody>
              </KooperTable>
              {/* <KooperCustomPagination
                activePage={1}
                totalPages={3}
                onPageChange={page => console.log(`Change to page ${page}`)}
              /> */}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
    </div>
  )
}

export default Goals
