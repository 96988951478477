import React from 'react'
import {Redirect, Route, Switch} from 'react-router'
import DocumentTitle from 'components/common/DocumentTitle'
import Overview from 'components/pages/Analytics/Reports/Conversations/Overview'
import Inbox from 'components/pages/Analytics/Reports/Conversations/Inbox'
import Channels from 'components/pages/Analytics/Reports/Conversations/Channels'
import Tags from 'components/pages/Analytics/Reports/Conversations/Tags'
import EmailAnalytics from 'components/pages/Analytics/Reports/Conversations/Email_Tracking'
import Chat from 'components/pages/Analytics/Reports/Campaigns/Chat'
import Email from 'components/pages/Analytics/Reports/Campaigns/Email'
import SMS from 'components/pages/Analytics/Reports/Campaigns/SMS'
import Metrics from 'components/pages/Analytics/Reports/Performance/Metrics'
import Users from 'components/pages/Analytics/Reports/Performance/Users'
import Sla from 'components/pages/Analytics/Reports/Performance/Sla'
import Contacts from 'components/pages/Analytics/Reports/Customers/Contacts'
import CompanyAnalytics from 'components/pages/Analytics/Reports/Customers/Companies'
import Visitors from 'components/pages/Analytics/Reports/Customers/Visitors'
import ConversionAnalytics from 'components/pages/Analytics/Reports/Deals/Conversion'
import DealsAnalytics from 'components/pages/Analytics/Reports/Deals/Deals'
import ProductsAnalytics from 'components/pages/Analytics/Reports/Revenue/Products'
import QuotesAnalytics from 'components/pages/Analytics/Reports/Revenue/Quotes'
import TaskAnalytics from 'components/pages/Analytics/Reports/Performance/Task'
import ForecastAnalytics from 'components/pages/Analytics/Reports/Revenue/Forecast'
import TemplateAnalytics from 'components/pages/Analytics/Reports/Productivity/Template'
import Vendors from 'components/pages/Analytics/Reports/Finance/Vendors'
import Purchases from 'components/pages/Analytics/Reports/Finance/Purchases'
import Projects from 'components/pages/Analytics/Reports/Reports/Projects'
import Resources from 'components/pages/Analytics/Reports/Reports/resource'
import Employees from 'components/pages/Analytics/Reports/Hr/Employees'
import Expenses from 'components/pages/Analytics/Reports/Hr/Expenses'
import TimeOff from 'components/pages/Analytics/Reports/Hr/TimeOff'
import PurchaseOrders from 'components/pages/Analytics/Reports/Finance/PurchaseOrders'
import SalesOrders from 'components/pages/Analytics/Reports/Finance/SalesOrders'
import Invoices from 'components/pages/Analytics/Reports/Finance/Invoices'
import ProjectHealth from 'components/pages/Analytics/Reports/Reports/ProjectHealth/ProjectHealth'
import TimesheetByProjects from 'components/pages/Analytics/Reports/Reports/TimesheetByProjects'
import TimesheetByUsers from 'components/pages/Analytics/Reports/Reports/TimesheetByUsers'
import TaskByUsers from 'components/pages/Analytics/Reports/Reports/TaskByUsers'
import ResourceUtilization from 'components/pages/Analytics/Reports/Reports/ResourceUtilization'
import Goals from 'components/pages/Analytics/Reports/Teams/Goals/Goals'
import {REPORTS_PATH} from 'constants/path'
import OneOnOnes from 'components/pages/Analytics/Reports/Teams/OneOnOnes'

const routes = [
  // Conversations
  {path: REPORTS_PATH.OVERVIEW, component: Overview, title: 'Overview', exact: true},
  {path: REPORTS_PATH.INBOX, component: Inbox, title: 'Inbox', exact: true},
  {path: REPORTS_PATH.CHANNELS, component: Channels, title: 'Channels', exact: true},
  {path: REPORTS_PATH.TAGS, component: Tags, title: 'Tags', exact: true},
  {path: REPORTS_PATH.EMAIL_TRACKING, component: EmailAnalytics, title: 'Email Tracking', exact: true},
  // Clients
  {path: REPORTS_PATH.CONTACTS, component: Contacts, title: 'Contacts', exact: true},
  {path: REPORTS_PATH.COMPANIES, component: CompanyAnalytics, title: 'Company', exact: true},
  {path: REPORTS_PATH.VISITORS, component: Visitors, title: 'Visitors', exact: true},
  // Sales
  {path: REPORTS_PATH.DEALS, component: DealsAnalytics, title: 'Deals', exact: true},
  {path: REPORTS_PATH.CONVERSIONS, component: ConversionAnalytics, title: 'Conversions', exact: true},
  {path: REPORTS_PATH.FORECASTS, component: ForecastAnalytics, title: 'Forecasts', exact: true},
  {path: REPORTS_PATH.PRODUCTS, component: ProductsAnalytics, title: 'Products', exact: true},
  {path: REPORTS_PATH.QUOTES, component: QuotesAnalytics, title: 'Quotes', exact: true},
  // Projects
  {path: REPORTS_PATH.PROJECT_HEALTH, component: ProjectHealth, title: 'Project Health', exact: true},
  {path: REPORTS_PATH.PROJECT_TIMESHEET, component: TimesheetByProjects, title: 'Timesheet by Projects', exact: true},
  {path: REPORTS_PATH.PROJECT_TIMESHEET_USERS, component: TimesheetByUsers, title: 'Timesheet by Users', exact: true},
  {path: REPORTS_PATH.PROJECT_TASK_USERS, component: TaskByUsers, title: 'Task by Users', exact: true},
  {path: REPORTS_PATH.RESOURCE_UTILIZATION, component: ResourceUtilization, title: 'Resource Utilization', exact: true},
  {path: REPORTS_PATH.PROJECTS, component: Projects, title: 'Projects', exact: true},
  {path: REPORTS_PATH.RESOURCES, component: Resources, title: 'Resources', exact: true},
  // Finance
  {path: REPORTS_PATH.VENDORS, component: Vendors, title: 'Vendors', exact: true},
  {path: REPORTS_PATH.PURCHASES, component: Purchases, title: 'Purchases', exact: true},
  {path: REPORTS_PATH.PURCHASE_ORDERS, component: PurchaseOrders, title: 'Purchase Orders', exact: true},
  {path: REPORTS_PATH.SALES_ORDERS, component: SalesOrders, title: 'Sales Orders', exact: true},
  {path: REPORTS_PATH.INVOICES, component: Invoices, title: 'Invoices', exact: true},
  // {path: REPORTS_PATH.BUDGETS_PLANNER, component: BudgetsPlanner, title: 'Budgets Planner', exact: true},
  // Teams
  {path: REPORTS_PATH.GOALS, component: Goals, title: 'Goals', exact: true},
  {path: REPORTS_PATH.ONE_ON_ONE, component: OneOnOnes, title: '1-on-1s', exact: true},
  // Performance
  {path: REPORTS_PATH.METRICS, component: Metrics, title: 'Metrics', exact: true},
  {path: REPORTS_PATH.USERS, component: Users, title: 'Users', exact: true},
  {path: REPORTS_PATH.SLA, component: Sla, title: 'SLA', exact: true},
  {path: REPORTS_PATH.TASKS, component: TaskAnalytics, title: 'Tasks', exact: true},
  {path: REPORTS_PATH.EMAIL_TEMPLATES, component: TemplateAnalytics, title: 'Email Template', exact: true},
  // Campaigns
  {path: REPORTS_PATH.CHAT, component: Chat, title: 'Chat', exact: true},
  {path: REPORTS_PATH.EMAIL, component: Email, title: 'Email', exact: true},
  {path: REPORTS_PATH.SMS, component: SMS, title: 'SMS', exact: true},
  // HR
  {path: REPORTS_PATH.EMPLOYEES, component: Employees, title: 'Employees', exact: true},
  {path: REPORTS_PATH.EXPENSES, component: Expenses, title: 'Expenses', exact: true},
  {path: REPORTS_PATH.TIME_OFF, component: TimeOff, title: 'Time Off', exact: true},
]

const renderRoute = ({path, component: Component, title, exact = false}) => (
  <Route
    key={path}
    path={`/${REPORTS_PATH.PREFIX}/${path}`}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} reports />
        <Component {...props} />
      </>
    )}
  />
)

const ReportsRoutes = () => (
  <Switch>
    {routes.map(route => renderRoute(route))}
    <Route path={`/${REPORTS_PATH.PREFIX}/*`}>
      <Redirect to={`/${REPORTS_PATH.PREFIX}/${REPORTS_PATH.OVERVIEW}`} />
    </Route>
  </Switch>
)

export default ReportsRoutes
