/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import SvgIcon from 'components/common/SvgIcon'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import {KooperColorPicker, KooperFormField, KooperFormGroup, KooperFormInput, KooperTooltip} from 'kooper-library'

function Design({control}) {
  const [isBackGroundColorPickerOpen, setIsBackGroundColorPickerOpen] = useState(false)
  const [isSubmitButtonColorPickerOpen, setIsSubmitButtonColorPickerOpen] = useState(false)

  return (
    <div className="designdesign-main">
      <div className="designdesign-content">
        <KooperFormField>
          <div className="info-header">
            <label>Submit Button</label>
            <KooperTooltip
              trigger={<SvgIcon path="common/question" />}
              content="Specify a text for submit button field"
              size="mini"
              position="top left"
            />
          </div>
          <Controller
            name="design.submitButtonText"
            render={({value, onChange}) => (
              <KooperFormInput
                type="text"
                maxLength={20}
                placeholder="Submit Form.."
                value={value}
                onChange={e => {
                  onChange(e.target.value)
                }}
              />
            )}
            control={control}
          />
        </KooperFormField>
        <KooperFormField>
          <div className="info-header">
            <label>Submit Button Color</label>
            <KooperTooltip
              content="Set a submit button color to match with your brand"
              size="mini"
              position="top left"
              trigger={<SvgIcon path="common/question" />}
            />
          </div>
          <Controller
            name="design.submitButtonColor"
            control={control}
            render={({value, onChange}) => (
              <>
                <div
                  style={{
                    padding: 5,
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                  }}
                  onClick={() => setIsSubmitButtonColorPickerOpen(!isSubmitButtonColorPickerOpen)}
                >
                  <div
                    style={{
                      width: '80px',
                      height: '15px',
                      borderRadius: '2px',
                      background: value,
                    }}
                  />
                </div>
                {isSubmitButtonColorPickerOpen && (
                  <div
                    style={{
                      position: 'absolute',
                      zIndex: '2',
                    }}
                  >
                    <div
                      style={{
                        position: 'fixed',
                        top: '0px',
                        right: '0px',
                        bottom: '0px',
                        left: '0px',
                      }}
                      onClick={() => setIsSubmitButtonColorPickerOpen(!isSubmitButtonColorPickerOpen)}
                    />
                    <KooperColorPicker color={value} onChange={value => onChange(value.hex)} />
                  </div>
                )}
              </>
            )}
          />
        </KooperFormField>
        <KooperFormField>
          <div className="info-header">
            <label>Background Color</label>
            <KooperTooltip
              content="Set background color of form to match with your brand"
              size="mini"
              position="top left"
              trigger={<SvgIcon path="common/question" />}
            />
          </div>
          <Controller
            name="design.backgroundColor"
            control={control}
            render={({value, onChange}) => (
              <>
                <div
                  style={{
                    padding: 5,
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                  }}
                  onClick={() => setIsBackGroundColorPickerOpen(!isBackGroundColorPickerOpen)}
                >
                  <div
                    style={{
                      width: '80px',
                      height: '15px',
                      borderRadius: '2px',
                      background: value,
                    }}
                  />
                </div>
                {isBackGroundColorPickerOpen && (
                  <div
                    style={{
                      position: 'absolute',
                      zIndex: '2',
                    }}
                  >
                    <div
                      style={{
                        position: 'fixed',
                        top: '0px',
                        right: '0px',
                        bottom: '0px',
                        left: '0px',
                      }}
                      onClick={() => setIsBackGroundColorPickerOpen(!isBackGroundColorPickerOpen)}
                    />
                    <KooperColorPicker color={value} onChange={value => onChange(value.hex)} />
                  </div>
                )}
              </>
            )}
          />
        </KooperFormField>
      </div>
    </div>
  )
}

export default Design
