import SvgIcon from 'components/common/SvgIcon'
import {SURVEYS_PATH} from 'constants/path'
import {KooperAccordion, KooperAccordionContent, KooperAccordionTitle, KooperMenu, KooperMenuItem} from 'kooper-library'
import {useState} from 'react'
import {FiChevronRight, FiLayers} from 'react-icons/fi'
import {useHistory} from 'react-router-dom'
import {isActiveNavlink} from 'utils/helper'

const surveysMenu = [
  {path: `/${SURVEYS_PATH.PREFIX}/${SURVEYS_PATH.ALL}`, title: 'All Surveys', exact: true},
  {path: `/${SURVEYS_PATH.PREFIX}/${SURVEYS_PATH.PULSE_SURVEY}`, title: 'Pulse survey', exact: false},
]
const SurveysMenu = () => {
  const history = useHistory()
  const [expand, setExpand] = useState([])
  const handleMenuExpand = key => {
    const menus = [...expand]
    if (menus.includes(key)) {
      menus.splice(menus.indexOf(key), 1)
      setExpand(menus)
    } else {
      setExpand([...menus, key])
    }
  }
  const isActive = key => !expand.includes(key)
  return (
    <KooperMenu vertical secondary className="kooper-menu">
      {surveysMenu.map(({path, title}) => (
        <KooperMenuItem key={path} active={isActiveNavlink(path)} onClick={() => history.push(path)}>
          <FiLayers /> {title}
        </KooperMenuItem>
      ))}

      <KooperAccordion>
        <KooperAccordionTitle onClick={() => handleMenuExpand(SURVEYS_PATH.SETTINGS)}>
          Settings
          {isActive(SURVEYS_PATH.SETTINGS) ? <SvgIcon path="common/down" /> : <FiChevronRight />}
        </KooperAccordionTitle>
        <KooperAccordionContent active={isActive(SURVEYS_PATH.SETTINGS)}>
          <KooperMenuItem
            active={isActiveNavlink(`/${SURVEYS_PATH.PREFIX}/${SURVEYS_PATH.SETTINGS}`)}
            onClick={() => history.push(`/${SURVEYS_PATH.PREFIX}/${SURVEYS_PATH.SETTINGS}`)}
          >
            <SvgIcon path="finance/prefix" /> Settings
          </KooperMenuItem>
        </KooperAccordionContent>
      </KooperAccordion>
    </KooperMenu>
  )
}

export default SurveysMenu
