import DeleteModal from 'components/common/DeleteModal'
import SvgIcon from 'components/common/SvgIcon'
import {ONE_ON_ONE_S_PATH} from 'constants/path'
import {
  KooperCard,
  KooperCardContent,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperIcon,
  KooperTooltip,
} from 'kooper-library'
import {useState} from 'react'
import {PiUserCirclePlus} from 'react-icons/pi'
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min'
import InviteMemberModal from './InviteMemberModal'

const OneOnOneSCardItem = ({item}) => {
  const history = useHistory()

  const getStatus = status => {
    if (status === 'active') return 'Active'
    if (status === 'inactive') return 'In-Active'
    if (status === 'archived') return 'Archived'
    return null
  }
  const [open, setOpen] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  return (
    <KooperCard fluid className="one-on-one-card">
      <KooperCardContent
        onClick={() =>
          item?.selected
            ? history.push(`/${ONE_ON_ONE_S_PATH.PREFIX}/details`)
            : history.push(`/${ONE_ON_ONE_S_PATH.PREFIX}/create/${item.type}`)
        }
      >
        <div className="one-on-one-card-left">
          <div className="one-on-one-card-header">
            <span className="one-on-one-card-title">{item?.title}</span>
            <KooperTooltip
              content={
                <span>
                  <b>{item.taklingPoint} talking points</b> in this agenda
                </span>
              }
              size="mini"
              position="top center"
              trigger={<span className="one-on-one-point">{item.taklingPoint}</span>}
            />
          </div>
          <div className="one-on-one-card-status">Unscheduled</div>
        </div>
        <div className="one-on-one-card-right">
          {item?.selected ? (
            <div className="select-user-details">
              <KooperIcon name="user" size="large" />
              <span className="select-user-name">test-user</span>
            </div>
          ) : (
            <button
              type="button"
              className="invite-button"
              onClick={e => {
                e.stopPropagation()
                setOpen(true)
              }}
            >
              <PiUserCirclePlus />
              <strong>Invite a member</strong>
            </button>
          )}

          <KooperTooltip
            content="More"
            size="mini"
            position="top center"
            trigger={
              <KooperDropdown button icon={<SvgIcon path="common/more-verticle" />} className="icon mr-0 button">
                <KooperDropdownMenu direction="left">
                  <KooperDropdownItem>
                    <KooperIcon name="note" /> View past 1-on-1s
                  </KooperDropdownItem>
                  <KooperDropdownItem onClick={() => setDeleteModal(true)}>
                    <SvgIcon path="common/delete" /> Delete this 1-on-1
                  </KooperDropdownItem>
                </KooperDropdownMenu>
              </KooperDropdown>
            }
          />
        </div>
      </KooperCardContent>
      {deleteModal && (
        <DeleteModal
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete ?"
          //   deleteAction={deleteSalesOrders}
          //   idTobeDeleted={editId}
          //   type={DELETE_SALES_ORDERS}
        />
      )}
      {open && (
        <InviteMemberModal
          type={item?.type}
          open={open}
          onClose={e => {
            e.stopPropagation()
            setOpen(false)
          }}
        />
      )}
    </KooperCard>
  )
}

export default OneOnOneSCardItem
