import React from 'react'
import {
  KooperContainer,
  KooperCard,
  KooperCardContent,
  KooperForm,
  KooperFormSelect,
  KooperFormGroup,
  KooperFormField,
  KooperTooltip,
  KooperFormInput,
  KooperDatePicker,
  KooperGridColumn,
  KooperFormTextArea,
  KooperGrid,
  KooperCheckbox,
  KooperRadio,
} from 'kooper-library'
import SvgIcon from 'components/common/SvgIcon'
import {Controller, useForm} from 'react-hook-form'
import {removeDoubleQuotes} from 'utils/helper'

const GenerelSettings = () => {
  const {handleSubmit, errors, clearErrors, control, watch, setValue, reset} = useForm({
    mode: 'onTouched',
    shouldUnregister: false,
    shouldFocusError: true,
    reValidateMode: 'onChange',
    defaultValues: {
      audience: 'team',
    },
    // resolver: joiResolver(),
  })

  const renderKooperRadio = (name, label, value, checkedValue, onChange) => (
    <KooperRadio
      label={label}
      value={value}
      checked={checkedValue === value}
      onChange={(_, {value}) => {
        onChange(value)
        // disableSaveBtn()
      }}
    />
  )
  return (
    <KooperContainer>
      <KooperCard fluid>
        <KooperCardContent>
          <h3 className="mb-0">Introduce the Custom Survey to your team</h3>
          <p className="mt-0 mb-4 card-description">Introduce the Custom Survey to your team</p>
          <KooperForm className="errorLabel">
            <KooperFormGroup>
              <KooperFormField required width={8}>
                <div className="info-header">
                  <label>Title</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Set title of the survey"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="title"
                  control={control}
                  render={({onChange, value}) => (
                    <KooperFormInput
                      placeholder="Survey title"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      maxLength={20}
                      error={
                        errors?.title && {
                          content: removeDoubleQuotes(errors.title.message),
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>
            </KooperFormGroup>
            <KooperFormGroup>
              <KooperFormField width={8}>
                <div className="info-header">
                  <label>Description</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Brief description about survye"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="description"
                  render={({onChange, value}) => (
                    <KooperFormTextArea
                      maxLength={160}
                      type="text"
                      value={value}
                      placeholder="Description"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors.description && {
                          content: removeDoubleQuotes(errors.description.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>
            </KooperFormGroup>
          </KooperForm>
        </KooperCardContent>
      </KooperCard>
      <KooperCard fluid>
        <KooperCardContent>
          <h3 className="mb-0">Audience and permissions</h3>
          <p className="mt-0 mb-4 card-description">Audience and permissions</p>
          <KooperForm className="errorLabel">
            <KooperFormGroup>
              <KooperFormField width={8}>
                <div className="info-header">
                  <label>Who is this Custom Survey from?</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select who is this Custom Survey from?"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="customsurveyfrom"
                  control={control}
                  render={({onChange, value, onBlur}) => (
                    <KooperFormSelect
                      fluid
                      options={[
                        {
                          key: 'jordan',
                          value: 'jordan',
                          text: 'Jordan',
                        },
                        {
                          key: 'My organization',
                          value: 'My organization',
                          text: 'My organization',
                        },
                      ]}
                      selection
                      placeholder="Custom survey from"
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors.purchaseOrderNumber && {
                          content: removeDoubleQuotes(errors.purchaseOrderNumber.message),
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>
            </KooperFormGroup>
            <KooperFormField>
              <div className="info-header">
                <label>Audience</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Audience"
                  size="mini"
                  psoition="top center"
                />
              </div>
              <KooperFormField className="pr-4">
                <Controller
                  control={control}
                  name="audience"
                  render={({onChange, value}) => (
                    <>
                      {renderKooperRadio('audience', 'Teams', 'team', value, onChange)}
                      <p className="Kooper-lead ml-4 pl-1">Teams</p>
                      {watch('audience') === 'team' && (
                        <Controller
                          name="select team"
                          control={control}
                          render={({onChange, value, onBlur}) => (
                            <KooperFormSelect
                              width={8}
                              fluid
                              search
                              options={[
                                {
                                  key: 'jordan',
                                  value: 'jordan',
                                  text: 'Marketing',
                                },
                                {
                                  key: 'My organization',
                                  value: 'My organization',
                                  text: 'Teams 1',
                                },
                              ]}
                              selection
                              placeholder="e.g. Marketing"
                              onBlur={onBlur}
                              value={value}
                              onChange={(e, {value}) => {
                                onChange(value)
                              }}
                              error={
                                errors.purchaseOrderNumber && {
                                  content: removeDoubleQuotes(errors.purchaseOrderNumber.message),
                                }
                              }
                            />
                          )}
                        />
                      )}

                      {renderKooperRadio('audience', 'Users', 'user', value, onChange)}
                      <p className="Kooper-lead ml-4 pl-1">Users</p>
                      {watch('audience') === 'user' && (
                        <Controller
                          name="select user"
                          control={control}
                          render={({onChange, value, onBlur}) => (
                            <KooperFormSelect
                              width={8}
                              fluid
                              search
                              options={[
                                {
                                  key: 'jordan',
                                  value: 'jordan',
                                  text: 'Marketing',
                                },
                                {
                                  key: 'jordan 2',
                                  value: 'j',
                                  text: 'Teams 1',
                                },
                              ]}
                              selection
                              placeholder="e.g. Test user"
                              onBlur={onBlur}
                              value={value}
                              onChange={(e, {value}) => {
                                onChange(value)
                              }}
                              error={
                                errors.purchaseOrderNumber && {
                                  content: removeDoubleQuotes(errors.purchaseOrderNumber.message),
                                }
                              }
                            />
                          )}
                        />
                      )}

                      {renderKooperRadio('audience', 'Entire organization', 'organization', value, onChange)}
                      <p className="Kooper-lead ml-4 pl-1">Entire organization</p>
                    </>
                  )}
                />
              </KooperFormField>
            </KooperFormField>
            {/* <KooperFormField>
              <div className="info-header">
                <label>Permissions</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Permissions"
                  size="mini"
                  psoition="top center"
                />
              </div>
              <Controller
                control={control}
                name="permissions"
                render={({onChange, value}) => (
                  <>
                    {renderKooperRadio('permissions', 'Executives only', 'top', value, onChange)}
                    <p className="Kooper-lead ml-4 pl-1">
                      Block access to Team Managers. Only Executives can view, edit, and close the Custom Survey.
                    </p>
                    {renderKooperRadio('permissions', 'Open', 'bottom', value, onChange)}
                    <p className="Kooper-lead ml-4 pl-1">
                      Any Team Manager whose team is targeted by this Custom Survey can view the results for their team
                      (and sub-teams). Those who have access to all teams within this audience can edit and close the
                      Custom Survey.
                    </p>
                  </>
                )}
              />
            </KooperFormField> */}
          </KooperForm>
        </KooperCardContent>
      </KooperCard>
    </KooperContainer>
  )
}

export default GenerelSettings
