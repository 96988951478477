import {FaUser, FaUsers} from 'react-icons/fa'

export const createOneOnOneDropdownData = [
  {
    Icon: FaUsers,
    header: 'Team',
    subHeader: 'Team',
    value: 'team',
  },
  {
    Icon: FaUser,
    header: 'Personal',
    subHeader: 'Personal',
    value: 'personal',
  },
]

export const ONE_ON_ONE_INTERVALS = [
  {key: 0, value: 0, text: "Don't repeat"},
  {key: 1, value: 1, text: 'Every week'},
  {key: 2, value: 2, text: 'Every 2 weeks'},
  {key: 3, value: 3, text: 'Every 3 weeks'},
  {key: 4, value: 4, text: 'Every 4 weeks'},
  {key: 5, value: 5, text: 'Every 5 weeks'},
  {key: 6, value: 6, text: 'Every 6 weeks'},
  {key: 7, value: 7, text: 'Every 7 weeks'},
  {key: 8, value: 8, text: 'Every 8 weeks'},
  {key: 9, value: 9, text: 'Every 9 weeks'},
  {key: 10, value: 10, text: 'Every 10 weeks'},
  {key: 11, value: 11, text: 'Every 11 weeks'},
  {key: 12, value: 12, text: 'Every 12 weeks'},
]
