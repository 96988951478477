import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ResponsiveBar} from '@nivo/bar'
import {
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperGrid,
  KooperGridColumn,
  KooperNoData,
  KooperTooltip,
} from 'kooper-library'

import {ADDED_BY_SOURCE_OVERTIME, ADDED_VS_DELETED} from 'actions/types'
import {addedBySourceOvertimeContacts, addedVsDeletedContacts, statsContacts} from 'actions/analytics_contacts'
import CustomLoader from 'components/common/CustomLoader'
import {get} from 'utils/helper'
import {getGridYValuesMultipleBar, maxOfGraphMultipleBar} from 'utils/graph'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import {MailboxDropdown} from 'components/pages/Analytics/AnalyticsUtils'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'

const TotalLabels = ({bars, yScale}) => {
  const labelMargin = 15

  return bars.map(({data: {data, indexValue}, x, width}, i) => {
    const total = Object.keys(data)
      .filter(
        key =>
          key !== 'EmailColor' &&
          key !== 'FacebookColor' &&
          key !== 'FormColor' &&
          key !== 'InternalColor' &&
          key !== 'SmsColor' &&
          key !== 'TwitterColor' &&
          key !== 'WebColor' &&
          key !== 'WidgetColor' &&
          key !== 'Contact FormColor' &&
          key !== 'time'
      )
      .reduce((a, key) => a + data[key], 0)

    if (!total) {
      return null
    }

    return (
      <g transform={`translate(${x}, ${yScale(total) - labelMargin})`} key={`${indexValue}-${i}`}>
        <text x={width / 2} y={labelMargin / 2} textAnchor="middle" alignmentBaseline="central" fontSize={10}>
          {total}
        </text>
      </g>
    )
  })
}

const Contacts = () => {
  const dispatch = useDispatch()

  const {type = []} = useSelector(state => state.apiReducer)
  const {analyticsSelectedInbox} = useSelector(state => state.analyticsInbox)
  const {
    contactStats = {},
    addedVsDeleted = [],
    addedBySourceOvertime = [],
  } = useSelector(state => state.analyticsContacts)
  const {reportsPermissions} = useUserPermissions()
  const customersReportsPermissions = reportsPermissions?.customers_reports

  const actionsToDispatch = useCallback(
    data => {
      dispatch(statsContacts(data))
      dispatch(addedVsDeletedContacts(data))
      dispatch(addedBySourceOvertimeContacts(data))
    },
    [dispatch]
  )

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/contacts" />
            <h5>Contacts</h5>
          </div>
        </div>
        {customersReportsPermissions && (
          <div className="page-action">
            <MailboxDropdown />
            <DateRangeGroup
              actionsToDispatch={actionsToDispatch}
              inboxId={
                analyticsSelectedInbox !== 'all' && analyticsSelectedInbox ? Number(analyticsSelectedInbox) : null
              }
            />
          </div>
        )}
      </div>

      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Contacts</KooperCardHeader>
              <KooperTooltip
                content="Graphical representation of total number of contacts added and deleted over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0">
              <KooperGrid>
                <KooperGridColumn width={4}>
                  <KooperCard fluid>
                    <KooperCardContent className="headerBox pb-0">
                      <KooperCardHeader>Contacts Added</KooperCardHeader>
                      <KooperTooltip
                        content="Number of new contacts added compared to previous duration"
                        size="mini"
                        position="top center"
                        trigger={<SvgIcon path="common/question" />}
                      />
                    </KooperCardContent>
                    <KooperCardContent className="valueBox pt-2">
                      <h3>
                        {get(['created', 'value'], contactStats, 0)}
                        <span
                          className={
                            get(['created', 'growth'], contactStats, 'positive') === 'positive' ? '' : 'text-danger'
                          }
                        >
                          <KooperTooltip
                            position="top center"
                            size="mini"
                            content="Compared to Previous Duration"
                            trigger={
                              <div>
                                {get(['created', 'growth'], contactStats, 'positive') === 'positive' ? (
                                  <SvgIcon path="common/arrow-up-right" />
                                ) : (
                                  <SvgIcon path="common/arrow-down-left" className="down-left" />
                                )}
                                {get(['created', 'percentage'], contactStats, 0)}%
                              </div>
                            }
                          />
                        </span>
                      </h3>
                    </KooperCardContent>
                  </KooperCard>
                </KooperGridColumn>
                <KooperGridColumn width={4}>
                  <KooperCard fluid>
                    <KooperCardContent className="headerBox pb-0">
                      <KooperCardHeader>Contacts Deleted</KooperCardHeader>
                      <KooperTooltip
                        content="Number of contacts deleted compared to previous duration"
                        size="mini"
                        position="top center"
                        trigger={<SvgIcon path="common/question" />}
                      />
                    </KooperCardContent>
                    <KooperCardContent className="valueBox pt-2">
                      <h3>
                        {get(['deleted', 'value'], contactStats, 0)}
                        <span
                          className={
                            get(['deleted', 'growth'], contactStats, 'positive') === 'positive' ? '' : 'text-danger'
                          }
                        >
                          <KooperTooltip
                            position="top center"
                            size="mini"
                            content="Compared to Previous Duration"
                            trigger={
                              <div>
                                {get(['deleted', 'growth'], contactStats, 'positive') === 'positive' ? (
                                  <SvgIcon path="common/arrow-up-right" />
                                ) : (
                                  <SvgIcon path="common/arrow-down-left" className="down-left" />
                                )}
                                {get(['deleted', 'percentage'], contactStats, 0)}%
                              </div>
                            }
                          />
                        </span>
                      </h3>
                    </KooperCardContent>
                  </KooperCard>
                </KooperGridColumn>
              </KooperGrid>
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 300}}>
              {type.includes(ADDED_VS_DELETED) && <CustomLoader />}
              {!type.includes(ADDED_VS_DELETED) &&
              addedVsDeleted.every(({added, deleted}) => added === 0 && deleted === 0) ? (
                <KooperNoData type="chart" noDataText="No Data Available" />
              ) : (
                !type.includes(ADDED_VS_DELETED) && (
                  <ResponsiveBar
                    data={addedVsDeleted}
                    keys={['added', 'deleted']}
                    indexBy="time"
                    margin={{top: 27, right: 27, bottom: 70, left: 60}}
                    padding={0.3}
                    minValue={0}
                    maxValue={maxOfGraphMultipleBar(addedVsDeleted, ['added', 'deleted'])}
                    gridYValues={getGridYValuesMultipleBar(addedVsDeleted, ['added', 'deleted'])}
                    groupMode="grouped"
                    valueScale={{type: 'linear'}}
                    indexScale={{type: 'band', round: true}}
                    colors={d => d.data[`${d.id}Color`]}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 1.6]],
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: -45,
                      legend: 'Time',
                      legendPosition: 'middle',
                      legendOffset: 60,
                    }}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      tickValues: getGridYValuesMultipleBar(addedVsDeleted, ['added', 'deleted']),
                      legend: 'Count',
                      legendPosition: 'middle',
                      legendOffset: -40,
                    }}
                    labelSkipHeight={1}
                    label={d => <tspan y={-8}>{d.value}</tspan>}
                    labelTextColor={{
                      from: 'color',
                      modifiers: [['darker', 1.6]],
                    }}
                    legends={[
                      {
                        dataFrom: 'keys',
                        anchor: 'top',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: -30,
                        itemsSpacing: 0,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 10,
                        symbolShape: 'circle',
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                  />
                )
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>

      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Contacts Added By Source</KooperCardHeader>
              <KooperTooltip
                content="Shows all the contacts added from different source over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 350}}>
              {type.includes(ADDED_BY_SOURCE_OVERTIME) && <CustomLoader />}
              {!type.includes(ADDED_BY_SOURCE_OVERTIME) &&
              addedBySourceOvertime.every(
                v =>
                  v.Web === 0 &&
                  v.Email === 0 &&
                  v.Facebook === 0 &&
                  v.Twitter === 0 &&
                  v.Internal === 0 &&
                  v['Contact Form'] === 0 &&
                  v.Form === 0 &&
                  v.Sms === 0 &&
                  v.Widget === 0
              ) ? (
                <KooperNoData type="chart" noDataText="No Data Available" />
              ) : (
                !type.includes(ADDED_BY_SOURCE_OVERTIME) && (
                  <ResponsiveBar
                    data={addedBySourceOvertime}
                    keys={['Web', 'Email', 'Facebook', 'Twitter', 'Internal', 'Contact Form', 'Form', 'Sms', 'Widget']}
                    indexBy="time"
                    margin={{top: 27, right: 27, bottom: 70, left: 60}}
                    padding={0.3}
                    minValue={0}
                    maxValue={maxOfGraphMultipleBar(addedBySourceOvertime, [
                      'Web',
                      'Email',
                      'Facebook',
                      'Twitter',
                      'Internal',
                      'Contact Form',
                      'Form',
                      'Sms',
                      'Widget',
                    ])}
                    gridYValues={getGridYValuesMultipleBar(addedBySourceOvertime, [
                      'Web',
                      'Email',
                      'Facebook',
                      'Twitter',
                      'Internal',
                      'Contact Form',
                      'Form',
                      'Sms',
                      'Widget',
                    ])}
                    valueScale={{type: 'linear'}}
                    indexScale={{type: 'band', round: true}}
                    colors={d => d.data[`${d.id}Color`]}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 1.6]],
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: -45,
                      legend: 'Time',
                      legendPosition: 'middle',
                      legendOffset: 60,
                    }}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      tickValues: getGridYValuesMultipleBar(addedBySourceOvertime, [
                        'Web',
                        'Email',
                        'Facebook',
                        'Twitter',
                        'Internal',
                        'Contact Form',
                        'Form',
                        'Sms',
                        'Widget',
                      ]),
                      legend: 'Count',
                      legendPosition: 'middle',
                      legendOffset: -40,
                    }}
                    enableLabel={false}
                    labelSkipHeight={1}
                    layers={['grid', 'axes', 'bars', TotalLabels, 'markers', 'legends']}
                    labelTextColor={{
                      from: 'color',
                      modifiers: [['darker', 1.6]],
                    }}
                    legends={[
                      {
                        dataFrom: 'keys',
                        anchor: 'top',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: -30,
                        itemsSpacing: 0,
                        itemWidth: 80,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 10,
                        symbolShape: 'circle',
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                  />
                )
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
    </div>
  )
}

export default Contacts
