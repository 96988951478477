import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import {
  KooperButton,
  KooperCheckbox,
  KooperNoData,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableFooter,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
} from 'kooper-library'
import {useSelector} from 'react-redux'
import {MOVE_PROJECT_FINANCE_BILLING_ITEMS} from 'actions/types'
import {getFormattedDate, wrapText} from 'utils/helper'
import ProjectBillingMoveTimeModal from './ProjectBillingMoveTimeModal'

const ProjectBillingTimeTableList = ({timeList = [], currency = '', projectId, budgetId, invoiceId}) => {
  const [isMoveTimeModalOpen, setIsMoveTimeModalOpen] = useState(false)
  const [checked, setChecked] = useState([])

  const {successLabels = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    if (successLabels.includes(MOVE_PROJECT_FINANCE_BILLING_ITEMS)) {
      setChecked([])
      setIsMoveTimeModalOpen(false)
    }
  }, [successLabels])

  const isCheckedAll = () => {
    const allIds = timeList.map(lead => lead.id)

    const checkAll = _.xor(allIds, checked).length === 0 && allIds.length !== 0
    if (checkAll) {
      return true
    }
    return false
  }

  const handleCheckAll = () => {
    const allIds = timeList.map(lead => lead.id)

    if (_.xor(allIds, checked).length === 0) {
      setChecked([])
    } else setChecked(allIds)
  }

  const handleCheck = id => {
    const isChecked = checked.some(lead => lead === id)

    if (isChecked) {
      const removeChecked = checked.filter(lead => lead !== id)
      setChecked(removeChecked)
    } else {
      setChecked([...checked, id])
    }
  }

  const isChecked = id => {
    const check = checked.find(lead => lead === id)
    if (check) {
      return true
    }
    return false
  }

  const getHourMinuteFormatFromMinutes = minutes => {
    const hour = Math.floor(minutes / 60)
    const minute = minutes % 60
    return `${hour} hrs ${minute} mins`
  }

  const getTotalTime = () => {
    const totalMinutes = timeList.reduce((acc, item) => acc + item.loggedHrs * 60 + item.loggedMins, 0)
    return getHourMinuteFormatFromMinutes(totalMinutes)
  }

  const getTotalCost = () => {
    return timeList.reduce((acc, item) => acc + item.cost, 0).toFixed(2)
  }

  const renderTableBody = () => {
    if (timeList.length === 0) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="6" className="emptyValue">
            <KooperNoData type="table" noDataText="No Data Available" />
          </KooperTableCell>
        </KooperTableRow>
      )
    }
    return timeList.map(time => (
      <KooperTableRow key={time.id} className="tableLink">
        <KooperTableCell>
          <KooperCheckbox checked={isChecked(time.id)} onChange={() => handleCheck(time.id)} />
        </KooperTableCell>
        <KooperTableCell>{getFormattedDate(time.date)}</KooperTableCell>
        <KooperTableCell>
          {time.createdByDetails?.firstName} {time.createdByDetails?.lastName}
        </KooperTableCell>
        <KooperTableCell>
          {time.tasklistDetails && (
            <p>
              {time.tasklistDetails?.title} - {time.taskDetails?.title}
            </p>
          )}
          <p>{wrapText(`${time.description}`, 20) || 'No description'}</p>
        </KooperTableCell>
        <KooperTableCell>{getHourMinuteFormatFromMinutes(time.loggedHrs * 60 + time.loggedMins)}</KooperTableCell>
        <KooperTableCell>
          {currency} {time.cost.toFixed(2)}
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  return (
    <>
      <KooperTable basic>
        <KooperTableHeader>
          <KooperTableRow>
            <KooperTableHeaderCell>
              <KooperCheckbox checked={isCheckedAll()} onChange={handleCheckAll} />
            </KooperTableHeaderCell>
            <KooperTableHeaderCell>Date</KooperTableHeaderCell>
            <KooperTableHeaderCell>Created By</KooperTableHeaderCell>
            <KooperTableHeaderCell>Description</KooperTableHeaderCell>
            <KooperTableHeaderCell>Time</KooperTableHeaderCell>
            <KooperTableHeaderCell>Cost</KooperTableHeaderCell>
          </KooperTableRow>
        </KooperTableHeader>
        <KooperTableBody>{renderTableBody()}</KooperTableBody>
        {timeList.length !== 0 && (
          <KooperTableFooter>
            <KooperTableRow>
              <KooperTableCell colSpan="3" />
              <KooperTableCell textAlign="right">Sub-Total:</KooperTableCell>
              <KooperTableCell>{getTotalTime()}</KooperTableCell>
              <KooperTableCell>
                {currency} {getTotalCost()}
              </KooperTableCell>
            </KooperTableRow>
          </KooperTableFooter>
        )}
      </KooperTable>

      {timeList.length !== 0 && (
        <KooperButton
          className="mt-3"
          basic
          primary
          disabled={!checked.length}
          onClick={() => setIsMoveTimeModalOpen(true)}
        >
          Move Items
        </KooperButton>
      )}

      {isMoveTimeModalOpen && (
        <ProjectBillingMoveTimeModal
          open={isMoveTimeModalOpen}
          toggle={setIsMoveTimeModalOpen}
          projectId={projectId}
          budgetId={budgetId}
          timeLogIds={checked}
          invoiceId={invoiceId}
        />
      )}
    </>
  )
}

export default ProjectBillingTimeTableList
