import SvgIcon from 'components/common/SvgIcon'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperGrid,
  KooperGridColumn,
  KooperHeader,
  KooperIcon,
  KooperLabel,
  KooperProgress,
  KooperTooltip,
} from 'kooper-library'
import {useState} from 'react'
import ActivityCard from './ActivityCard'
import UpdateGoalsModal from './UpdateGoalsModal'

const PersonalGoalsDetails = () => {
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false)
  return (
    <>
      <KooperCard fluid>
        <KooperCardContent>
          <div className="d-flex">
            <div className="d-flex">
              <KooperIcon className="mr-2" name="target" color="teal" circular />
              <div>
                <span style={{fontSize: '0.9em', color: 'rgba(0,0,0,.6)'}}>Individual GOAL</span>
                {/* <KooperLabel size="tiny" style={{marginLeft: '1em', background: '#f3f4f5'}}>
                    Self-development
                  </KooperLabel> */}
              </div>
            </div>
            <div className="icon-row" style={{display: 'flex', gap: '1rem'}}>
              <KooperTooltip
                content="Delete"
                size="mini"
                position="top center"
                trigger={<KooperButton icon={<SvgIcon path="common/delete" />} />}
              />
              <KooperTooltip
                content="Copy"
                size="mini"
                position="top center"
                trigger={<KooperButton icon={<SvgIcon path="common/clone" />} />}
              />

              <KooperTooltip
                content="Edit"
                size="mini"
                position="top center"
                trigger={<KooperButton icon={<SvgIcon path="common/edit2" />} />}
              />
            </div>
          </div>
          <KooperHeader as="h2" className="mt-3">
            Organization
          </KooperHeader>
          <KooperHeader as="h3" color="grey" className="mb-2">
            fdgdfg
          </KooperHeader>
          <KooperGrid columns={2} style={{marginBottom: '2em'}}>
            <KooperGridColumn>
              <div className="d-flex jcfs">
                <KooperIcon name="user circle" size="large" />
                <div className="ml-2">
                  <div style={{color: 'rgba(0,0,0,.6)', fontSize: '0.9em', marginBottom: '0.5em'}}>GOAL OWNER</div>
                  <span>You</span>
                </div>
              </div>
            </KooperGridColumn>
            <KooperGridColumn>
              <div>
                <div style={{color: 'rgba(0,0,0,.6)', fontSize: '0.9em', marginBottom: '0.5em'}}>TIMELINE</div>
                <div>—</div>
              </div>
            </KooperGridColumn>
          </KooperGrid>

          <div style={{marginBottom: '2em'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1em'}}>
              <div>
                <span style={{fontSize: '1.2em', fontWeight: '500', marginRight: '1em'}}>Progress</span>
                <KooperLabel size="tiny" color="blue" style={{background: '#f0f5ff', color: '#3182ce'}}>
                  On track
                </KooperLabel>
              </div>
              <KooperButton onClick={() => setIsOpenUpdateModal(true)} primary>
                Update progress
              </KooperButton>
            </div>
            <div className="d-flex" style={{gap: 10}}>
              <div style={{flex: 1}}>
                <KooperProgress
                  size="tiny"
                  style={{
                    margin: 0,
                    flex: 1,
                    background: '#edf2f7',
                    height: '8px',
                  }}
                  percent={0}
                />
              </div>
              <div style={{textAlign: 'right', color: 'rgba(0,0,0,.6)', fontWeight: 600}}>0%</div>
            </div>
          </div>

          <div
            style={{
              background: '#f9fafb',
              padding: '2em',
              borderRadius: '0.5em',
              textAlign: 'center',
            }}
          >
            {/* <KooperImage
              src="/placeholder.svg?height=100&width=100"
              size="tiny"
              centered
              style={{marginBottom: '1em'}}
            /> */}
            <h3>No goal details?</h3>
            <p style={{color: 'rgba(0,0,0,.6)'}}>
              Remember to fill in the details of your goal. That's how you can create a clear path on how to achieve it.
            </p>
          </div>
        </KooperCardContent>
      </KooperCard>
      <ActivityCard
        activities={[
          {title: 'activity 1', time: 'Today, 10:00 AM'},
          {title: 'activity 2', time: 'Today, 11:00 AM'},
        ]}
      />
      {isOpenUpdateModal && <UpdateGoalsModal isModalOpen={isOpenUpdateModal} setIsModalOpen={setIsOpenUpdateModal} />}
    </>
  )
}

export default PersonalGoalsDetails
