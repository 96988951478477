/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  KooperButton,
  KooperCheckbox,
  KooperDropdown,
  KooperForm,
  KooperFormDropdown,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperRadio,
  KooperTooltip,
} from 'kooper-library'
import {
  ADD_FORECAST,
  GET_FORECAST_DETAIL,
  GET_FORECAST_SETTING,
  GET_TEAMS_LIST,
  GET_USERS,
  UPDATE_FORECAST,
} from 'actions/types'
import {addForecast, getForecastDetail, getForecastList, updateForecast} from 'actions/forecast'
import {removeDoubleQuotes} from 'utils/helper'
import {year, KpiList, recursionMonthly, recursionQuarterly, recursionYearly} from 'constants/sales/forecast'
import {createForecastSchema} from 'validation/Sales/forecast.schema'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {getUsers} from 'actions/settings'
import {getAllTeams} from 'actions/settings_organisation'

const CreateForecastModal = ({createForecast, setCreateForecast, forecastId, setForecastId}) => {
  const dispatch = useDispatch()

  const [usersList, setUsersList] = useState([])
  const [teamList, setTeamList] = useState([])

  const {successLabels = [], isLoadingData, type} = useSelector(state => state.apiReducer)
  const {forecastSetting: forecastData, forecastDetail = {}} = useSelector(state => state.forecast)
  const {getAllPipelineData = []} = useSelector(state => state.deals)

  const initailData = {
    name: forecastId ? _.get(forecastDetail, 'name', null) : _.get(forecastData, 'name', null),
    assigneeId: _.get(forecastDetail, 'assigneeId', null),
    teamId: _.get(forecastDetail, 'teamId', null),
    pipelineId: forecastId
      ? _.get(forecastDetail, 'pipelineId', _.get(getAllPipelineData, '[0][id]'))
      : _.get(forecastData, 'pipelineId', _.get(getAllPipelineData, '[0][id]')),
    timePeriod: forecastId
      ? _.get(forecastDetail, 'timePeriod', 'monthly')
      : _.get(forecastData, 'timePeriod', 'monthly'),
    startFrom: forecastId
      ? _.get(forecastDetail, 'startFrom', year[new Date().getMonth()].value)?.split(',')[0]
      : _.get(forecastData, 'startFrom', year[new Date().getMonth()].value),
    kpi: _.get(forecastDetail, 'kpi', null),
    target: _.get(forecastDetail, 'target', 1),
    isRecurring: _.get(forecastDetail, 'isRecurring', true),
    selection: _.get(forecastDetail, 'teamId') ? 'team' : 'user',
    recurringTime: _.get(forecastDetail, 'recurringTime', 1),
  }

  const {
    control,
    errors,
    reset,
    watch,
    clearErrors,
    setValue,
    handleSubmit,
    formState: {isDirty},
  } = useForm({
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: initailData,
    resolver: joiResolver(createForecastSchema),
  })

  const watchKpi = watch('kpi')
  const watchIsRecurring = watch('isRecurring')
  const watchTimePeriod = watch('timePeriod')
  const watchSelection = watch('selection')
  const watchName = watch('name')

  useEffect(() => {
    if (watchTimePeriod) {
      setValue('recurringTime', 1)
    }
  }, [watchTimePeriod, watchKpi])

  useEffect(() => {
    if (successLabels.includes(GET_FORECAST_SETTING)) {
      reset(initailData)
    }
    if (successLabels.includes(GET_FORECAST_DETAIL)) {
      reset(initailData)
    }
    if (successLabels.includes(ADD_FORECAST) || successLabels.includes(UPDATE_FORECAST)) {
      setCreateForecast()
      setForecastId(null)
      dispatch(getForecastList())
    }
  }, [successLabels])

  useEffect(() => {
    if (forecastId) {
      dispatch(getForecastDetail(forecastId))
    } else {
      reset({
        name: '',
        assigneeId: null,
        pipelineId: _.get(forecastData, 'pipelineId', _.get(getAllPipelineData, '[0][id]')),
        timePeriod: _.get(forecastData, 'timePeriod', 'monthly'),
        startFrom: _.get(forecastData, 'startFrom', year[new Date().getMonth()].value),
        kpi: '',
        target: 1,
        isRecurring: true,
        recurringTime: 1,
        selection: _.get(forecastDetail, 'assigneeId') ? 'team' : 'user',
      })
    }
  }, [forecastId])

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const usersList = getUsersList.users.map(list => ({
        value: list.id,
        key: list.id,
        text: `${list.firstName} ${list.lastName}`,
      }))

      setUsersList(usersList)
    },
  })

  useApiResponse({
    action: getAllTeams,
    enabled: true,
    label: GET_TEAMS_LIST,
    storePath: 'settingsOrganisation.teamsList',
    onSuccess: teamsList => {
      const teams = teamsList.map(team => ({
        key: team.id,
        value: team.id,
        text: team.name,
      }))
      setTeamList(teams)
    },
  })

  const createForecastFunction = data => {
    dispatch(addForecast(_.omit(data, 'selection')))
  }
  const updateForecastFunction = data => {
    dispatch(updateForecast(forecastId, _.omit(data, 'selection')))
  }

  return (
    <KooperModal
      size="small"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={createForecast}
      onClose={() => {
        setForecastId(null)
        setCreateForecast()
      }}
    >
      <KooperModalHeader>{forecastId ? 'Update ' : 'Create'} Forecast</KooperModalHeader>
      <KooperModalContent scrolling>
        <KooperForm className="errorLabel">
          {/* <KooperFormGroup widths="equal"> */}
          <KooperFormField required>
            <label>Forecast Name</label>
            <Controller
              control={control}
              name="name"
              render={({onChange, value}) => (
                <KooperFormInput
                  type="text"
                  value={value}
                  maxLength={30}
                  placeholder="Forecast Name"
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.name && {
                      content: removeDoubleQuotes(errors.name.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required>
            <div className="info-header">
              <label className="label-class">Define KPI</label>
              <KooperTooltip
                content="Select a KPI for which you wish to forecast"
                size="mini"
                position="right center"
                trigger={<SvgIcon path="common/question" className="ml-2" />}
              />
            </div>
            <Controller
              control={control}
              name="kpi"
              render={({onChange, value}) => (
                <KooperFormDropdown
                  selection
                  placeholder="Select KPI"
                  options={KpiList}
                  value={value}
                  onClick={() => errors.kpi && clearErrors('kpi')}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.kpi && {
                      content: errors.kpi.message,
                    }
                  }
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required>
            <div className="info-header">
              <label className="label-class">Target</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Set number of targets that need to be achieved"
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              control={control}
              name="target"
              render={({onChange, value}) => (
                <KooperFormInput
                  type="number"
                  value={value}
                  placeholder="Set Target"
                  onChange={(e, {value}) => {
                    onChange(value)
                  }}
                  onKeyPress={e => {
                    if (e.key === '.' && watchKpi !== 'Revenue') {
                      e.preventDefault()
                    }
                  }}
                  error={
                    errors.target && {
                      content: removeDoubleQuotes(errors.target.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>
          {watchKpi === 'Revenue' && (
            <KooperFormField>
              <div className="info-header">
                <label>Pipeline</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Set a pipeline for this forecast"
                  size="mini"
                  position="top left"
                />
              </div>
              <Controller
                control={control}
                name="pipelineId"
                render={({onChange, value}) => (
                  <KooperDropdown
                    selection
                    placeholder="Select pipeline"
                    options={getAllPipelineData.map(pipeline => ({
                      key: pipeline.id,
                      text: pipeline.pipeline,
                      value: pipeline.id,
                    }))}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={
                      errors.pipelineId && {
                        content: removeDoubleQuotes(errors.pipelineId.message),
                      }
                    }
                  />
                )}
              />
            </KooperFormField>
          )}
          <KooperFormGroup inline>
            <Controller
              control={control}
              name="selection"
              render={({onChange, value}) => (
                <>
                  <KooperFormField>
                    <KooperRadio
                      label="Users"
                      name="radioGroup"
                      value={value}
                      checked={value === 'user'}
                      onChange={() => onChange('user')}
                    />
                  </KooperFormField>
                  <KooperFormField>
                    <KooperRadio
                      label="Teams"
                      name="radioGroup"
                      value={value}
                      checked={value === 'team'}
                      onChange={() => onChange('team')}
                    />
                  </KooperFormField>
                </>
              )}
            />
          </KooperFormGroup>
          {watchSelection === 'user' && (
            <KooperFormField required>
              <div className="info-header">
                <label className="label-class">User</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Select Users for whom you are doing the forecast"
                  size="mini"
                  position="top left"
                />
              </div>
              <Controller
                control={control}
                name="assigneeId"
                render={({onChange, value}) => (
                  <KooperFormDropdown
                    selection
                    search
                    placeholder="Select User"
                    options={usersList}
                    onClick={() => errors.assigneeId && clearErrors('assigneeId')}
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    error={
                      errors.assigneeId && {
                        content: removeDoubleQuotes(errors.assigneeId.message),
                      }
                    }
                  />
                )}
              />
            </KooperFormField>
          )}
          {watchSelection === 'team' && (
            <KooperFormField required>
              <div className="info-header">
                <label>Team</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Select team for whom you are doing the forecast"
                  size="mini"
                  position="top left"
                />
              </div>

              <Controller
                control={control}
                name="teamId"
                render={({onChange, value}) => (
                  <KooperFormDropdown
                    selection
                    search
                    placeholder="Select Team"
                    options={teamList}
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    error={
                      errors.teamId && {
                        content: removeDoubleQuotes(errors.teamId.message),
                      }
                    }
                  />
                )}
              />
            </KooperFormField>
          )}
          <KooperFormField>
            <div className="info-header">
              <label>Time Period</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Set a period for the forecast i.e monthly, quarterly or annually"
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              control={control}
              name="timePeriod"
              render={({onChange, value}) => (
                <KooperDropdown
                  selection
                  placeholder="select pipeline"
                  options={[
                    {key: 1, value: 'monthly', text: 'Monthly'},
                    {key: 2, value: 'quarterly', text: 'Quarterly'},
                    {key: 3, value: 'yearly', text: 'Yearly'},
                  ]}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.timePeriod && {
                      content: removeDoubleQuotes(errors.timePeriod.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>
          <KooperFormGroup widths="equal">
            <KooperFormField>
              <Controller
                control={control}
                name="isRecurring"
                render={({onChange, value}) => (
                  <KooperCheckbox
                    label="Check for recursion"
                    onChange={(e, {checked}) => onChange(checked)}
                    checked={value}
                    className="mb-3"
                  />
                )}
              />
              {watchIsRecurring && (
                <Controller
                  control={control}
                  name="recurringTime"
                  render={({onChange, value}) => (
                    <KooperDropdown
                      placeholder="select pipeline"
                      selection
                      className="recurrungdd"
                      options={
                        watchTimePeriod === 'monthly'
                          ? recursionMonthly
                          : watchTimePeriod === 'quarterly'
                          ? recursionQuarterly
                          : watchTimePeriod === 'yearly'
                          ? recursionYearly
                          : null
                      }
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                    />
                  )}
                />
              )}
            </KooperFormField>
          </KooperFormGroup>
          <KooperFormGroup widths="equal">
            <KooperFormField>
              <div className="info-header">
                <label>Start Date</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Set start date of the forecast"
                  size="mini"
                  position="top left"
                />
              </div>
              <Controller
                control={control}
                name="startFrom"
                render={({onChange, value}) => (
                  <KooperDropdown
                    selection
                    placeholder="Select Start Month"
                    options={year}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                  />
                )}
              />
            </KooperFormField>
          </KooperFormGroup>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton
          basic
          disabled={isLoadingData && type.includes(ADD_FORECAST)}
          onClick={() => {
            setForecastId(null)
            setCreateForecast(false)
          }}
        >
          Cancel
        </KooperButton>
        <KooperButton
          primary
          loading={(isLoadingData && type.includes(ADD_FORECAST)) || type.includes(UPDATE_FORECAST)}
          disabled={!isDirty || (isLoadingData && type.includes(ADD_FORECAST)) || type.includes(UPDATE_FORECAST)}
          onClick={handleSubmit(forecastId ? updateForecastFunction : createForecastFunction)}
        >
          {forecastId ? 'Update' : 'Create'}
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default CreateForecastModal
