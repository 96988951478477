import React, {useEffect, useState} from 'react'
import {KooperButton, KooperModal, KooperModalActions, KooperModalContent, KooperModalHeader} from 'kooper-library'
import {useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {getUsers} from 'actions/settings'
import {createProjectTemplate, getProjectsLookup} from 'actions/projects'
import {getAllContactsIds} from 'actions/contacts'
import {CREATE_PROJECT_TEMPLATES, GET_ALL_CONTACTS_IDS, GET_PROJECTS_LOOKUP, GET_USERS} from 'actions/types'
import {joiResolver} from '@hookform/resolvers'
import {PROJECT_FINANCE_BUDGET_TYPES} from 'constants/projects'
import {
  createProjectTemplateAdvanceOptionSchema,
  createProjectTemplateDetailsSchema,
} from 'validation/Projects/projects/templates.schema'
import useApiResponse from 'hooks/impure/useApiResponse'
import SvgIcon from 'components/common/SvgIcon'
import ProjectTemplateAdvanceOptionCarousel from './ProjectTemplateAdvanceOptionCarousel'
import ProjectTemplateDetailsCarousel from './ProjectTemplateDetailsCarousel'

const detailsDefaultValues = {
  title: '',
  description: '',
  budgetType: PROJECT_FINANCE_BUDGET_TYPES.NO_BUDGET,
  rangeType: 'financial',
  budgetAmount: 0,
  budgetTimeHour: 0,
  budgetTimeMinute: 0,
  timelogType: 'all',
  repeatUnit: 'month',
  carryUnderspend: false,
  carryOverspend: false,
  budgetProfitMargin: 0,
  budgetTargetProfit: 0,
  budgetTargetCosts: 0,
}

const advanceOptionDefaultValues = {
  assignedTo: [],
  managerId: null,
  contactIds: [],
  categoryId: null,
}

const carousels = ['projectDetails', 'advanceOption']

const CreateProjectTemplateModalStep = ({open, toggle}) => {
  const dispatch = useDispatch()

  const [currentSlide, setCurrentSlide] = useState(0)
  const [budgetTimeError, setBudgetTimeError] = useState('')
  const [usersList, setUsersList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [contactsList, setContactsList] = useState([])
  const [template, setTemplateCreateData] = useState({})

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {
    handleSubmit: handleSubmitProjectDetails,
    control: controlProjectDetails,
    errors: errorsProjectDetails,
    watch: watchProjectDetails,
    setValue: setValueProjectDetails,
    getValues: getValuesProjectDetails,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    defaultValues: detailsDefaultValues,
    resolver: joiResolver(createProjectTemplateDetailsSchema),
  })

  const {
    handleSubmit: handleSubmitProjectAdvanceOptions,
    control: controlProjectAdvanceOptions,
    errors: errorsProjectAdvanceOptions,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: advanceOptionDefaultValues,
    resolver: joiResolver(createProjectTemplateAdvanceOptionSchema),
  })

  const watchBudgetType = watchProjectDetails('budgetType')
  const watchRangeType = watchProjectDetails('rangeType')

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(({id, firstName, lastName}) => ({
        key: id,
        value: id,
        text: `${firstName} ${lastName}`,
      }))
      setUsersList(list || [])
    },
  })

  useApiResponse({
    action: getProjectsLookup,
    payload: {lookup: ['categories']},
    enabled: true,
    label: GET_PROJECTS_LOOKUP,
    storePath: 'projects.categories',
    onSuccess: categories => {
      if (categories?.length === 0) {
        setCategoryList([{key: 0, value: 0, text: 'General'}])
      } else {
        setCategoryList([...categories.map(({id, name}) => ({key: id, value: id, text: name}))])
      }
    },
  })

  useApiResponse({
    action: getAllContactsIds,
    enabled: true,
    label: GET_ALL_CONTACTS_IDS,
    storePath: 'contacts.contactsIdsList',
    onSuccess: contactsIdsList => {
      const list = contactsIdsList?.map(({id, name}) => ({key: id, value: id, text: name}))
      setContactsList(list || [])
    },
  })

  useEffect(() => {
    if (successLabels.includes(CREATE_PROJECT_TEMPLATES)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const actionCancelModal = action => {
    if (action === 'prev') {
      setCurrentSlide(currentSlide => currentSlide - 1)
    }
    if (action === 'next') {
      setCurrentSlide(currentSlide => currentSlide + 1)
    }
  }

  const submitForm = data => {
    if (currentSlide === 0) {
      const payload = {...data}
      if (payload.rangeType === 'time') {
        if (payload.budgetTimeHour * 60 + payload.budgetTimeMinute === 0) {
          setBudgetTimeError('Please enter a value greater than 0')
          return
        }
        payload.budgetAmount = payload.budgetTimeHour * 60 + payload.budgetTimeMinute
      }
      setTemplateCreateData({...template, ...payload})
      actionCancelModal('next')
    }
    if (currentSlide === 1) {
      setTemplateCreateData({...template, ...data})
      const {projectStartDate, projectEndDate, ...resetData} = data
      const {
        title,
        description,
        budgetType,
        rangeType,
        budgetAmount,
        startDate,
        endDate,
        timelogType,
        repeatUnit,
        carryUnderspend,
        carryOverspend,
        budgetProfitMargin,
      } = template
      const budgetData = {
        budgetType,
        rangeType,
        budgetAmount,
        startDate,
        endDate,
        timelogType,
        repeatUnit,
        carryUnderspend,
        carryOverspend,
        isDefaultBillableRateSet: false,
        defaultBillableRate: 0,
        budgetProfitMargin,
      }
      const payload = {
        title,
        description,
        startDate: projectStartDate,
        endDate: projectEndDate,
        ...resetData,
        budgetData,
      }
      dispatch(createProjectTemplate(payload))
    }
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader className="project-modal-header">Create Template</KooperModalHeader>
      <KooperModalContent scrolling>
        <ProjectTemplateDetailsCarousel
          style={{display: currentSlide === 0 ? '' : 'none'}}
          control={controlProjectDetails}
          errors={errorsProjectDetails}
          budgetTimeError={budgetTimeError}
          setBudgetTimeError={setBudgetTimeError}
          budgetType={watchBudgetType}
          rangeType={watchRangeType}
          setValue={setValueProjectDetails}
          getValues={getValuesProjectDetails}
        />
        <ProjectTemplateAdvanceOptionCarousel
          style={{display: currentSlide === 1 ? '' : 'none'}}
          control={controlProjectAdvanceOptions}
          errors={errorsProjectAdvanceOptions}
          usersList={usersList}
          contactsList={contactsList}
          categoryList={categoryList}
          setCategoryList={setCategoryList}
        />
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        {currentSlide > 0 && (
          <KooperButton basic onClick={() => actionCancelModal('prev')}>
            Prev Step
          </KooperButton>
        )}
        <KooperButton
          primary
          onClick={
            currentSlide === 0 ? handleSubmitProjectDetails(submitForm) : handleSubmitProjectAdvanceOptions(submitForm)
          }
        >
          {currentSlide === carousels.length - 1 ? 'Create Template' : 'Next Step'}
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default CreateProjectTemplateModalStep
