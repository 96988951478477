/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {
  KooperButton,
  KooperCheckbox,
  KooperDivider,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperForm,
  KooperFormField,
  KooperGrid,
  KooperGridColumn,
  KooperHeader,
  KooperIcon,
  KooperImage,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperNoData,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'

import {GOOGLE_CALENDAR_DISCONNECT, MEETING_CALENDAR_LIST} from 'actions/types'
import {calendarList, calendarTwoWaySync, googleCalendarDisconnect} from 'actions/meeting'
import {MEETINGS_ENDPOINT} from 'actions/endpoint'
import img from 'assets/calender.svg'
import {get, getFormattedDate, getPermissionTooltipContent} from 'utils/helper'
import CreateFirst from 'components/common/CreateFirst'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'

const GoogleDisconnectModal = props => {
  const {isModalOpen, setIsModalOpen, data} = props
  const [SecondModalOpen, setSecondModalOpen] = useState(false)
  const [checkboxAgreeDisconnect, setCheckboxAgreeDisconnect] = useState(false)
  const dispatch = useDispatch()
  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const logout = () => {
    dispatch(googleCalendarDisconnect(data.calendarId))
  }

  useEffect(() => {
    if (successLabels.includes(GOOGLE_CALENDAR_DISCONNECT)) {
      setIsModalOpen()
    }
  }, [successLabels, setIsModalOpen])

  // const email = localStorage.getItem("GEMAIL");

  return (
    <>
      <KooperModal
        size="large"
        open={isModalOpen}
        closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
        onClose={setIsModalOpen}
      >
        <KooperHeader>Google Disconnect</KooperHeader>
        <KooperModalContent>
          <div className="createPage">
            <h2>Meetings</h2>
            <KooperGrid columns="equal">
              <KooperGridColumn>
                <div className="createPageInfo">
                  <p>
                    Calendar sync Calendar sync logs new meetings from your calendar to existing contacts in Kooper. You
                    can also create and send meeting invites from Kooper. This calendar setting is separate from the
                    meetings tool.
                  </p>
                  <p>{`${_.get(data, 'family_name', '') || ''}`}</p>
                  <p>{`Now connected to Google Calendar account ${_.get(data, 'email', '') || ''}`}</p>
                  <KooperButton negative onClick={() => setSecondModalOpen(!SecondModalOpen)}>
                    Disconnect
                  </KooperButton>
                </div>
              </KooperGridColumn>
              <KooperGridColumn>
                <KooperImage src={img} />
              </KooperGridColumn>
            </KooperGrid>
          </div>
        </KooperModalContent>
      </KooperModal>
      {SecondModalOpen && (
        <KooperModal
          size="tiny"
          open={SecondModalOpen}
          closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
          onClose={setSecondModalOpen}
        >
          <KooperHeader>Warning</KooperHeader>
          <KooperModalContent>
            <KooperForm>
              <KooperFormField>
                <label>All Meeting will be disable are sure you want to disconnect google.</label>
                <KooperCheckbox
                  label="Are you agree to disconnect with google"
                  checked={checkboxAgreeDisconnect}
                  onChange={() => setCheckboxAgreeDisconnect(!checkboxAgreeDisconnect)}
                />
              </KooperFormField>
            </KooperForm>
          </KooperModalContent>
          <KooperModalActions>
            <KooperButton
              basic
              disabled={isLoadingData || type.includes(GOOGLE_CALENDAR_DISCONNECT)}
              onClick={() => {
                setSecondModalOpen(!SecondModalOpen)
                setCheckboxAgreeDisconnect(false)
              }}
            >
              Cancel
            </KooperButton>
            <KooperButton
              primary
              loading={isLoadingData || type.includes(GOOGLE_CALENDAR_DISCONNECT)}
              disabled={!checkboxAgreeDisconnect || isLoadingData || type.includes(GOOGLE_CALENDAR_DISCONNECT)}
              onClick={() => logout()}
            >
              Yes
            </KooperButton>
          </KooperModalActions>
        </KooperModal>
      )}
    </>
  )
}

const Meetings = props => {
  const {style} = props
  const dispatch = useDispatch()
  const [googleDisconnect, setGoogleDisconnect] = useState(false)
  const [calId, setCalId] = useState(null)

  const {meetingCalendarList = [], addCalendar} = useSelector(state => state.meetings)
  const {isLoadingData, type} = useSelector(state => state.apiReducer)

  const {salesAndCrmAccess, meetingsPermissions} = useUserPermissions()
  const meetingsAccess = salesAndCrmAccess?.meetings
  const manageMeetingsPermissions = meetingsPermissions?.manage_meetings

  const uId = get(['id'], JSON.parse(window.localStorage.getItem('userInfo')))

  useEffect(() => {
    if (meetingsAccess) {
      dispatch(calendarList())
    }
  }, [meetingsAccess, dispatch])

  const renderCalendarList = () => {
    if (meetingCalendarList) {
      return meetingCalendarList.map(list => (
        <KooperTableRow>
          <KooperTableCell className="pl-4">
            <KooperHeader as="h5"> {list.family_name} </KooperHeader>
          </KooperTableCell>
          <KooperTableCell>{list.email}</KooperTableCell>
          <KooperTableCell>{getFormattedDate(list.createdAt)}</KooperTableCell>
          <KooperTableCell>
            <LockPermissionTooltip isRoleAccessDenied={!manageMeetingsPermissions}>
              <KooperTooltip
                content={list.twoWaySync ? 'Enabled' : 'Disabled'}
                size="mini"
                position="top center"
                trigger={
                  <KooperCheckbox
                    toggle
                    checked={list.twoWaySync}
                    onChange={e => {
                      e.stopPropagation()
                      if (manageMeetingsPermissions) {
                        dispatch(calendarTwoWaySync(list.calendarId))
                      }
                    }}
                  />
                }
              />
            </LockPermissionTooltip>
          </KooperTableCell>
          <KooperTableCell>
            <KooperDropdown
              icon={<SvgIcon path="common/more-verticle" id="TooltipProductInbox" />}
              button
              className="icon"
            >
              <KooperDropdownMenu direction="left">
                <LockPermissionTooltip isRoleAccessDenied={!manageMeetingsPermissions}>
                  <KooperDropdownItem
                    onClick={() => {
                      if (manageMeetingsPermissions) {
                        setCalId(list)
                        setGoogleDisconnect(!googleDisconnect)
                      }
                    }}
                  >
                    <KooperIcon name="cancel" /> Disconnect
                  </KooperDropdownItem>
                </LockPermissionTooltip>
                {/* <KooperDropdownItem
                  onClick={() => dispatch(meetingSetDefault(list.id))}
                >
                  {!list.isDefault ? "Set as Default" : "Active"}
                </KooperDropdownItem> */}
              </KooperDropdownMenu>
            </KooperDropdown>
          </KooperTableCell>
        </KooperTableRow>
      ))
    }
  }

  return (
    <div className="setEmpty-wrap">
      {!meetingsAccess ||
      !Array.isArray(meetingCalendarList) ||
      _.size(meetingCalendarList) === 0 ||
      !meetingCalendarList.some(
        l => l.assigneeId === get(['id'], JSON.parse(window.localStorage.getItem('userInfo')))
      ) ? (
        <CreateFirst
          src={<SvgIcon path="settings/users" />}
          Feature1="Sync your calendar"
          Feature2="Set availability"
          Feature3="Automated reminder"
          list1="Connect with Google Calendar to manage your schedule."
          list2="Customize availability based on preferences & timezone."
          list3="Get meeting reminder before start for smooth workflow"
          addFirst={() =>
            meetingsAccess &&
            manageMeetingsPermissions &&
            window.open(`${MEETINGS_ENDPOINT}/google_auth/googleAuth/${uId}`)
          }
          tooltip={!meetingsAccess || !manageMeetingsPermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: meetingsAccess,
            roleAccess: manageMeetingsPermissions,
          })}
          buttonText="Connect your Google calendar"
          img={img}
          header="Meetings"
          subHeader="Schedule and keep track of all meetings at an unified place"
        />
      ) : (
        <div className="kooper-side-wrap" style={style}>
          <div className="page-header">
            <div className="page-title">
              <h4 className="mb-1">
                All Calendar
                {_.size(meetingCalendarList) !== 0 && `(${_.size(meetingCalendarList)})`}
              </h4>
              <p>Manage all of your integrated google calendars</p>
            </div>
            <div className="page-action">
              {!addCalendar && (
                <KooperTooltip
                  size="mini"
                  position="bottom center"
                  content={
                    manageMeetingsPermissions
                      ? !addCalendar
                        ? 'Not Access'
                        : 'Add Calendar'
                      : getPermissionTooltipContent({roleAccess: manageMeetingsPermissions})
                  }
                  trigger={
                    <KooperButton
                      primary
                      className={!addCalendar || !manageMeetingsPermissions ? 'disabled-button' : ''}
                      onClick={() =>
                        addCalendar &&
                        manageMeetingsPermissions &&
                        window.open(`${MEETINGS_ENDPOINT}/google_auth/googleAuth/${uId}`)
                      }
                    >
                      Add Calendar {!addCalendar && <KooperIcon className="ml-1" name="lock" />}
                    </KooperButton>
                  }
                />
              )}
            </div>
          </div>
          <KooperDivider className="mt-0" />

          <KooperTable sortable basic selectable style={{marginTop: '24px'}}>
            <KooperTableHeader>
              <KooperTableRow>
                <KooperTableHeaderCell className="pl-4">Name</KooperTableHeaderCell>
                <KooperTableHeaderCell>Email</KooperTableHeaderCell>
                <KooperTableHeaderCell>Created At</KooperTableHeaderCell>
                <KooperTableHeaderCell>Sync</KooperTableHeaderCell>
                <KooperTableHeaderCell>Action</KooperTableHeaderCell>
              </KooperTableRow>
            </KooperTableHeader>
            <KooperTableBody>
              {isLoadingData && type.includes(MEETING_CALENDAR_LIST) ? (
                <KooperTablePlaceholder columns={4} rows={4} />
              ) : !meetingCalendarList || meetingCalendarList.length > 0 ? (
                renderCalendarList()
              ) : (
                <tr>
                  <td colSpan="8" className="emptyValue">
                    <KooperNoData type="table" noDataText="No Data Available" />{' '}
                  </td>
                </tr>
              )}
            </KooperTableBody>
          </KooperTable>
        </div>
      )}
      {googleDisconnect && (
        <GoogleDisconnectModal
          data={calId}
          isModalOpen={googleDisconnect}
          setIsModalOpen={() => setGoogleDisconnect(!googleDisconnect)}
        />
      )}
    </div>
  )
}

export default Meetings
