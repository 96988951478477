import SvgIcon from 'components/common/SvgIcon'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperDragDropContext,
  KooperDraggable,
  KooperDroppable,
  KooperHeader,
  KooperIcon,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'
import React from 'react'

const ReorderModal = ({isModalOpen, setIsModalOpen, questions = []}) => {
  const items = [{title: 'Question 1'}, {title: 'Question 2'}]

  const onDragEnd = result => {
    // handle reordering logic here
    const {source, destination} = result
    if (!destination) return // No destination (dragged outside)

    // Handle the item reordering logic here based on source.index and destination.index
    const reorderedItems = Array.from(items)
    const [removed] = reorderedItems.splice(source.index, 1)
    reorderedItems.splice(destination.index, 0, removed)

    // Update your state with the reordered items
    console.log(reorderedItems)
  }
  return (
    <KooperModal
      size="small"
      open={isModalOpen}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={() => setIsModalOpen(!isModalOpen)}
    >
      <KooperModalHeader>Reorder questioms</KooperModalHeader>
      <KooperModalContent style={{position: 'relative', overflow: 'auto'}}>
        <div>
          <KooperDragDropContext onDragEnd={onDragEnd}>
            <KooperDroppable droppableId="cards" type="list">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {items.map((item, index) => (
                    <KooperDraggable key={index} draggableId={index.toString()} index={index}>
                      {provided => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <KooperCard fluid className="mb-4">
                            <KooperCardContent>
                              <div className="card-content">
                                <KooperIcon name="building outline" className="card-icon" />
                                <div>
                                  <div className="card-title">{item.title}</div>
                                </div>
                              </div>
                            </KooperCardContent>
                          </KooperCard>
                        </div>
                      )}
                    </KooperDraggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </KooperDroppable>
          </KooperDragDropContext>
        </div>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton primary>Cancel</KooperButton>
        <KooperButton primary disabled>
          Apply changes
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default ReorderModal
