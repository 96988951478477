import {apiAction} from './api'
import {GOAL_ENDPOINT} from './endpoint'
import {GET_ALL_GOALS} from './types'

export function getGoalList(params = {}) {
  const keys = Object.keys(params)
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return apiAction({
    url: `${GOAL_ENDPOINT}/?${query}`,
    method: 'GET',
    label: GET_ALL_GOALS,
  })
}
