import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import OneOnOneSectionForm from 'components/pages/Teams/OneOnOneS/CreateOneOnOnes/OneOnOneSectionForm'
import OneOnOneTalkingPointForm from 'components/pages/Teams/OneOnOneS/CreateOneOnOnes/OneOnOneTalkingPointForm'
import InviteMemberModal from 'components/pages/Teams/OneOnOneS/InviteMemberModal'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperContainer,
  KooperDragDropContext,
  KooperDraggable,
  KooperDroppable,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormTextArea,
  KooperIcon,
  KooperTooltip,
} from 'kooper-library'
import {useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useHistory, useParams} from 'react-router-dom'

const CreateOneOnOneS = () => {
  const history = useHistory()
  const {type = 'personal'} = useParams()
  const isDraft = true
  const {
    handleSubmit,
    errors,
    control,
    watch,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    // resolver: joiResolver(),
    defaultValues: {},
  })

  const [points, setPoints] = useState([
    {
      id: 'check-in',
      title: 'Check-in',
      duration: 10,
      type: 'section',
    },
    {
      id: 'tp1',
      content: 'How have you been since our last 1-on-1?',
      type: 'talkingPoint',
    },
    {
      id: 'whats-up',
      title: "What's up?",
      duration: 10,
      type: 'section',
    },
    {
      id: 'tp2',
      content: 'What went well since the last time we met?',
      type: 'talkingPoint',
    },
    {
      id: 'tp3',
      content:
        'When you think of your current goals, what actions are you currently doing or want to start doing to reach them?',
      type: 'talkingPoint',
    },
    {
      id: 'wrap-up',
      title: 'Wrap-up',
      duration: 5,
      type: 'section',
    },
    {
      id: 'tp4',
      content: "Is there anything we haven't covered that you'd like to discuss?",
      type: 'talkingPoint',
    },
    {
      id: 'tp5',
      content: 'What are the main points you got out of our conversation?',
      type: 'talkingPoint',
    },
  ])

  const onDragEnd = result => {
    const {source, destination, type} = result

    // If no destination, do nothing
    if (!destination) return

    // Handle reordering sections
    if (type === 'section') {
      const reorderedSections = Array.from(points)
      const [movedItem] = reorderedSections.splice(source.index, 1)
      reorderedSections.splice(destination.index, 0, movedItem)
      setPoints(reorderedSections)
      return
    }

    // Handle reordering talking points within or across sections
    if (type === 'talkingPoint') {
      const sourceSectionIndex = points.findIndex(point => point.id === source.droppableId)
      const destinationSectionIndex = points.findIndex(point => point.id === destination.droppableId)

      if (sourceSectionIndex === -1 || destinationSectionIndex === -1) return

      const sourceSection = points[sourceSectionIndex]
      const destinationSection = points[destinationSectionIndex]

      // Ensure both source and destination sections are of the same type
      if (sourceSection.type !== 'section' || destinationSection.type !== 'section') return

      const sourceTalkingPoints = sourceSection.talkingPoints || []
      const destinationTalkingPoints = destinationSection.talkingPoints || []

      const [movedTalkingPoint] = sourceTalkingPoints.splice(source.index, 1)

      if (sourceSectionIndex === destinationSectionIndex) {
        // Reorder within the same section
        sourceTalkingPoints.splice(destination.index, 0, movedTalkingPoint)
      } else {
        // Move to another section
        destinationTalkingPoints.splice(destination.index, 0, movedTalkingPoint)
      }

      const updatedSections = points.map((section, index) => {
        if (index === sourceSectionIndex) {
          return {...section, talkingPoints: sourceTalkingPoints}
        }
        if (index === destinationSectionIndex) {
          return {...section, talkingPoints: destinationTalkingPoints}
        }
        return section
      })

      setPoints(updatedSections)
    }
  }

  const addTalkingPoint = () => {
    const updatedSections = [...points]
    updatedSections.push({
      id: `tp${Date.now()}`,
      content: '',
      type: 'talkingPoint',
      new: true,
    })
    setPoints(updatedSections)
  }

  const addSection = () => {
    const updatedSections = [...points]
    updatedSections.push({
      type: 'section',
      new: true,

      id: `section${Date.now()}`,
      title: '',
    })
    setPoints(updatedSections)
  }
  const [open, setOpen] = useState(false)

  return (
    <div className="kooper-full-page">
      <div className="page-header p-0">
        <div className="campaignNavbar">
          <div>
            <KooperButton primary onClick={() => history.goBack()}>
              Exit
            </KooperButton>
          </div>
          <h3 className="m-0">New 1-on-1</h3>
          <div>
            {isDraft && (
              <LockPermissionTooltip>
                <KooperButton primary className="mr-3">
                  Save as draft
                </KooperButton>
              </LockPermissionTooltip>
            )}
            <LockPermissionTooltip>
              <KooperButton
                onClick={() => setOpen(true)}
                primary // className="disabled-button"
              >
                Use this agenda
              </KooperButton>
            </LockPermissionTooltip>
          </div>
        </div>
      </div>

      <KooperContainer>
        <KooperForm className="errorLabel">
          <KooperCard fluid>
            <KooperCardContent>
              <KooperFormGroup>
                <KooperFormField width={8} required>
                  <div className="info-header">
                    <label>Agenda Title</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Agenda Title"
                      size="mini"
                      position="top center"
                    />
                  </div>
                  <Controller
                    name="title"
                    control={control}
                    render={({onChange, value}) => <KooperFormInput maxLength={20} placeholder="Enter agenda title" />}
                  />
                </KooperFormField>
              </KooperFormGroup>
              <KooperFormGroup>
                <KooperFormField width={8}>
                  <div className="info-header">
                    <label>Agenda Description</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Agenda Description"
                      size="mini"
                      position="top center"
                    />
                  </div>
                  <Controller
                    name="description"
                    control={control}
                    render={({onChange, value}) => (
                      <KooperFormTextArea maxLength={100} placeholder="Enter  agenda description" />
                    )}
                  />
                </KooperFormField>
              </KooperFormGroup>
              {points?.length === 0 && (
                <KooperFormField width={8}>
                  <div className="info-header">
                    <label>What do you want to talk about?</label>
                  </div>
                </KooperFormField>
              )}
              <KooperDragDropContext onDragEnd={onDragEnd}>
                <KooperDroppable droppableId="sections" type="section">
                  {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {points.map((point, index) => (
                        <KooperDraggable key={point.id} draggableId={point.id} index={index}>
                          {provided => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              {point.type === 'section' && <OneOnOneSectionForm section={point} />}
                              {point.type === 'talkingPoint' && <OneOnOneTalkingPointForm talkingPoint={point} />}
                            </div>
                          )}
                        </KooperDraggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </KooperDroppable>
              </KooperDragDropContext>

              <div className="d-flex jcfs">
                <KooperButton basic color="blue" className="mr-4" onClick={addTalkingPoint}>
                  <KooperIcon name="plus" />
                  Add talking point
                </KooperButton>
                <KooperButton basic color="blue" onClick={addSection}>
                  <KooperIcon name="plus" />
                  Add section
                </KooperButton>
              </div>
            </KooperCardContent>
          </KooperCard>
        </KooperForm>
      </KooperContainer>
      {open && (
        <InviteMemberModal
          type={type}
          open={open}
          onClose={e => {
            e.stopPropagation()
            setOpen(false)
          }}
        />
      )}
    </div>
  )
}

export default CreateOneOnOneS
