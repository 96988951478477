/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import {
  KooperCheckbox,
  KooperDatePicker,
  KooperEditor,
  KooperFormDropdown,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormRadio,
  KooperFormTextArea,
  KooperGrid,
  KooperGridColumn,
  KooperTooltip,
} from 'kooper-library'

import {Controller, get} from 'react-hook-form'
import {removeDoubleQuotes} from 'utils/helper'
import SvgIcon from '../SvgIcon'

function Form({children, control, errors, onSubmit = () => {}, ...rest}) {
  return (
    <form noValidate className="ui form errorLabel" onSubmit={onSubmit} {...rest}>
      <KooperGrid>
        {React.Children.map(children, child => {
          const {name, width = 16} = child.props

          if (!name) return child

          const error = get(errors, name, null)
          return React.createElement(child.type, {
            ...{
              ...child.props,
              control,
              key: name,
              error: error && {content: removeDoubleQuotes(error?.message)},
              width,
            },
          })
        })}
      </KooperGrid>
    </form>
  )
}
function Input({
  control,
  tooltipProps = {},
  defaultValue,
  name,
  width = 16,
  label,
  required = false,
  maxLength,
  errors,
  extraContent,
  ...rest
}) {
  return (
    <>
      <KooperFormField required={required} width={width}>
        <div className="info-header">
          <label htmlFor={name}>{label}</label>
          {Object.keys(tooltipProps).length ? (
            <KooperTooltip
              trigger={<SvgIcon path="common/question" />}
              size="mini"
              position="top center"
              {...tooltipProps}
            />
          ) : null}
        </div>
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({onChange, value}) => (
            <KooperFormInput
              maxLength={maxLength}
              value={value}
              id={name}
              onChange={(e, {value: val}) => onChange(val)}
              error={get(errors, name) && {content: removeDoubleQuotes(get(errors, name)?.message)}}
              {...rest}
            />
          )}
        />
        {extraContent}
      </KooperFormField>
    </>
  )
}
function TextArea({
  control,
  name,
  width = 16,
  label,
  required = false,
  maxLength,
  tooltipProps = {},
  extraContent,
  errors,
  ...rest
}) {
  return (
    <KooperFormField required={required} width={width}>
      <div className="info-header">
        <label htmlFor={name}>{label}</label>
        {Object.keys(tooltipProps).length ? (
          <KooperTooltip
            trigger={<SvgIcon path="common/question" />}
            size="mini"
            position="top center"
            {...tooltipProps}
          />
        ) : null}
      </div>
      <Controller
        control={control}
        name={name}
        render={({onChange, value}) => (
          <KooperFormTextArea
            maxLength={maxLength}
            value={value}
            onChange={(e, {value: val}) => onChange(val)}
            error={get(errors, name) && {content: removeDoubleQuotes(get(errors, name)?.message)}}
            {...rest}
          />
        )}
      />
      {extraContent}
    </KooperFormField>
  )
}

function Select({control, errors, name, width = 16, tooltipProps = {}, children, required = false, label, ...rest}) {
  return (
    <KooperFormField required={required} width={width}>
      <div className="info-header">
        <label htmlFor={name}>{label}</label>
        {Object.keys(tooltipProps).length ? (
          <KooperTooltip
            trigger={<SvgIcon path="common/question" />}
            size="mini"
            position="top center"
            {...tooltipProps}
          />
        ) : null}
      </div>
      <Controller
        control={control}
        name={name}
        render={({value, onChange}) => (
          <KooperFormDropdown
            selection
            id={name}
            value={value}
            onChange={(e, {value: val}) => onChange(val)}
            error={get(errors, name) && {content: removeDoubleQuotes(get(errors, name)?.message)}}
            {...rest}
          />
        )}
      />
    </KooperFormField>
  )
}

function Checkbox({control, name, width, required, label, customClass = '', children, ...rest}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({value, onChange}) => (
        <KooperFormField className={customClass} required={required} width={width}>
          <KooperCheckbox id={name} checked={value} onChange={(e, {checked}) => onChange(checked)} {...rest} />
          <label htmlFor={name}>{label}</label>
          {children}
        </KooperFormField>
      )}
    />
  )
}

//! extremely poor API , would update or discard in future
function Radio({width, control, name, label, optionList, ...rest}) {
  // ? options example : [{label:'Male', radioValue:'male'}]
  return (
    <KooperGridColumn width={width}>
      <Controller
        name={name}
        control={control}
        render={({value, onChange}) => (
          <KooperFormField>
            <label>{label}</label>
            <KooperFormGroup>
              {optionList.map(({radioValue, label, ...restOptions}) => (
                <KooperFormRadio
                  key={`radiovalue__${radioValue}`}
                  value={radioValue}
                  checked={value === radioValue}
                  label={label}
                  onChange={(e, {value: val}) => onChange(val)}
                  {...restOptions}
                />
              ))}
            </KooperFormGroup>
          </KooperFormField>
        )}
      />
    </KooperGridColumn>
  )
}

function Editor({control, errors, name, width, label, formFieldProps, tooltipProps = {}, ...rest}) {
  return (
    <KooperFormField width={width} {...formFieldProps}>
      <div className="info-header">
        <label htmlFor={name}>{label}</label>
        {Object.keys(tooltipProps).length ? (
          <KooperTooltip
            trigger={<SvgIcon path="common/question" />}
            size="mini"
            position="top center"
            {...tooltipProps}
          />
        ) : null}
      </div>
      <Controller
        name={name}
        control={control}
        render={({value, onChange, onBlur}) => (
          <>
            <KooperEditor
              setContents={value}
              onBlur={onBlur}
              onChange={content => {
                onChange(content)
              }}
              {...rest}
            />
            {get(errors, name) && (
              <div className="ui pointing above prompt label" role="alert" aria-atomic="true">
                {removeDoubleQuotes(get(errors, name)?.message)}
              </div>
            )}
          </>
        )}
      />
    </KooperFormField>
  )
}

function DateTimePicker({control, name = '', label, errors, width, required = false, tooltipProps = {}, ...rest}) {
  return (
    <KooperFormField width={width} required={required}>
      <div className="info-header">
        <label htmlFor={name}>{label}</label>
        {Object.keys(tooltipProps).length ? (
          <KooperTooltip
            trigger={<SvgIcon path="common/question" />}
            size="mini"
            position="top center"
            {...tooltipProps}
          />
        ) : null}
      </div>
      <Controller
        name={name}
        control={control}
        render={({value, onChange}) => (
          <>
            <KooperDatePicker
              id="date-time-picker"
              className="Kooper-picker"
              value={value}
              onChange={value => onChange(value)}
              inputProps={{
                component: props => <input {...props} readOnly />,
              }}
              {...rest}
            />
            {get(errors, name) && (
              <div className="ui pointing above prompt label" role="alert" aria-atomic="true">
                {removeDoubleQuotes(get(errors, name)?.message)}
              </div>
            )}
          </>
        )}
      />
    </KooperFormField>
  )
}

export {Checkbox, DateTimePicker, Editor, Form, Input, Radio, Select, TextArea}
