import React from 'react'
import {useSelector} from 'react-redux'
import {
  KooperHeader,
  KooperNoData,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
} from 'kooper-library'
import {GET_PROJECTS_HEALTH_LIST} from 'actions/types'
import {get, getFormattedDate, startCase} from 'utils/helper'

const ProjectHealthList = ({projectsHealthList = [], onProjectClick}) => {
  const {type = []} = useSelector(state => state.apiReducer)

  const renderTableBody = () => {
    if (type.includes(GET_PROJECTS_HEALTH_LIST)) {
      return <KooperTablePlaceholder columns={6} />
    }
    if (!type.includes(GET_PROJECTS_HEALTH_LIST) && !projectsHealthList.length) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="6" className="emptyValue">
            <KooperNoData type="table" noDataText="No Data Available" />
          </KooperTableCell>
        </KooperTableRow>
      )
    }
    return projectsHealthList.map(project => (
      <KooperTableRow className="tableLink" key={project.id} onClick={() => onProjectClick(project.id)}>
        <KooperTableCell>
          <KooperHeader as="h5">{startCase(project.title)}</KooperHeader>
        </KooperTableCell>
        <KooperTableCell>
          {get(['contactDetails'], project, []).map(contact => (
            <div>{contact}</div>
          ))}
        </KooperTableCell>
        <KooperTableCell>{project.managerName}</KooperTableCell>
        <KooperTableCell>
          {getFormattedDate(project.startDate)} - {getFormattedDate(project.endDate)}
        </KooperTableCell>
        <KooperTableCell>
          <div className="project-task-completion-cell">
            <div
              className="progress"
              style={{width: `${get(['taskCompletion', 'completedPercentage'], project, 0)}}%`}}
            />
            <div className="content">
              <span>{get(['taskCompletion', 'completedPercentage'], project, 0)}%</span>
              <span>{get(['taskCompletion', 'taskLeft'], project, '')}</span>
            </div>
          </div>
        </KooperTableCell>
        <KooperTableCell>
          {project.isBudgetCreated ? (
            <div style={{width: '130px'}}>
              <h5 className="mb-1">{get(['budget', 'usedPercentage'], project, 0).toFixed(2)}%</h5>
              <div className="budget-progress">
                <div
                  className="budget-progress-completed"
                  style={{width: `${get(['budget', 'usedPercentage'], project, 0).toFixed(2)}%`}}
                />
              </div>
              <p className="mt-1">
                ${get(['budget', 'usedAmount'], project, 0)} 0f ${get(['budget', 'budgetAmount'], project, 0)}
              </p>
            </div>
          ) : (
            '-'
          )}
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  return (
    <KooperTable basic>
      <KooperTableHeader>
        <KooperTableRow>
          <KooperTableHeaderCell>Name</KooperTableHeaderCell>
          <KooperTableHeaderCell>Contacts</KooperTableHeaderCell>
          <KooperTableHeaderCell>Owner</KooperTableHeaderCell>
          <KooperTableHeaderCell>Dates</KooperTableHeaderCell>
          <KooperTableHeaderCell>Task Completion</KooperTableHeaderCell>
          <KooperTableHeaderCell>Budget</KooperTableHeaderCell>
        </KooperTableRow>
      </KooperTableHeader>
      <KooperTableBody>{renderTableBody()}</KooperTableBody>
    </KooperTable>
  )
}

export default ProjectHealthList
