import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller} from 'react-hook-form'
import {useParams} from 'react-router'
import {
  KooperContainer,
  KooperCard,
  KooperCardContent,
  KooperForm,
  KooperFormSelect,
  KooperFormGroup,
  KooperFormField,
  KooperTooltip,
  KooperFormInput,
  KooperDatePicker,
  KooperGridColumn,
  KooperFormTextArea,
  KooperGrid,
  KooperCheckbox,
} from 'kooper-library'
import {
  GET_USERS,
  GET_INVOICES_PREFIX,
  GET_PURCHASE_OREDRS_LIST,
  GET_ALL_CONTACTS_IDS,
  GET_SALES_ORDERS_LIST,
} from 'actions/types'
import {getPurchaseOrderslist} from 'actions/PurchaseOrders'
import {getSalesOrderslist} from 'actions/salesOrders'
import {getAllContactsIds} from 'actions/contacts'

import {getNewInvoicesNumber, getInvoicesPrefixes} from 'actions/invoices'
import {removeDoubleQuotes, KooperCountryList} from 'utils/helper'
import {Status} from 'constants/variables'
import SvgIcon from 'components/common/SvgIcon'
import {getUsers} from 'actions/settings'
import useApiResponse from 'hooks/impure/useApiResponse'
import PreviewInvoices from '../Templates/PreviewInvoices'

function Setup(props) {
  const {style, errors, control, watch, setValue, InvoiceDate, setInvoiceDate} = props
  const dispatch = useDispatch()
  const {id: SalesOrdersId} = useParams()

  const [isShippingAddressChecked, setIsShippingAddressChecked] = useState(false)
  const [userOption, setUserOption] = useState([])
  const [purchaseOrderNumberList, setPurchaseOrderNumberList] = useState()
  const [salesOrderNumberList, setSalesOrderNumberList] = useState()
  const [prefixOptions, setPrefixOptions] = useState([])
  const [contactOption, setcontactOption] = useState([])

  const {
    InvoicesForm: {invoicesNumber},
  } = useSelector(state => state.invoices)

  const watchPrefixId = watch('prefixId')
  const watchAll = watch()

  useEffect(() => {
    if (!SalesOrdersId && !invoicesNumber && watchPrefixId) {
      dispatch(getNewInvoicesNumber(watchPrefixId))
    }
  }, [watchPrefixId, SalesOrdersId, invoicesNumber, dispatch])

  useEffect(() => {
    if (invoicesNumber) {
      setValue('invoiceNumber', invoicesNumber, {
        shouldDirty: true,
        shouldValidate: true,
      })
    }
  }, [invoicesNumber, setValue])

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUserOption(list)
    },
  })

  useApiResponse({
    action: getInvoicesPrefixes,
    enabled: true,
    label: GET_INVOICES_PREFIX,
    storePath: 'invoices.invoicesPrefixList',
    onSuccess: invoicesPrefixList => {
      const prefixes = invoicesPrefixList?.map(({id, prefix}) => ({
        key: id,
        value: id,
        text: prefix,
      }))
      setPrefixOptions(prefixes)
    },
  })

  useApiResponse({
    action: getPurchaseOrderslist,
    enabled: true,
    label: GET_PURCHASE_OREDRS_LIST,
    storePath: 'purchaseOrders.purchaseOrdersList',
    onSuccess: purchaseOrdersList => {
      const poNumber = purchaseOrdersList?.map(({poNumber}) => ({
        key: poNumber,
        value: poNumber,
        text: poNumber,
      }))
      setPurchaseOrderNumberList(poNumber)
    },
  })

  useApiResponse({
    action: getSalesOrderslist,
    enabled: true,
    label: GET_SALES_ORDERS_LIST,
    storePath: 'salesOrders.salesOrdersList',
    onSuccess: salesOrdersList => {
      const soNumber = salesOrdersList?.map(({soNumber}) => ({
        key: soNumber,
        value: soNumber,
        text: soNumber,
      }))
      setSalesOrderNumberList(soNumber)
    },
  })

  useApiResponse({
    action: getAllContactsIds,
    enabled: true,
    label: GET_ALL_CONTACTS_IDS,
    storePath: 'contacts.contactsIdsList',
    onSuccess: contactsIdsList => {
      const list = contactsIdsList.map(contact => ({
        value: contact.id,
        text: contact.name,
        id: contact.id,
      }))
      setcontactOption(list)
    },
  })

  const handleShippingAddressCheckboxChange = (e, {checked}) => {
    setIsShippingAddressChecked(checked)

    if (checked) {
      // Copy billing address to shipping address
      const billingAddress = {
        streetAddress: watch('billingAddress.streetAddress'),
        locality: watch('billingAddress.locality'),
        state: watch('billingAddress.state'),
        postalCode: watch('billingAddress.postalCode'),
        country: watch('billingAddress.country'),
      }

      setValue('shippingAddress.streetAddress', billingAddress.streetAddress)
      setValue('shippingAddress.locality', billingAddress.locality)
      setValue('shippingAddress.state', billingAddress.state)
      setValue('shippingAddress.postalCode', billingAddress.postalCode)
      setValue('shippingAddress.country', billingAddress.country)
    } else {
      // Clear shipping address fields
      setValue('shippingAddress.streetAddress', '')
      setValue('shippingAddress.locality', '')
      setValue('shippingAddress.state', '')
      setValue('shippingAddress.postalCode', '')
      setValue('shippingAddress.country', '')
    }
  }
  return (
    <KooperContainer style={style}>
      <KooperCard fluid>
        <KooperCardContent style={{padding: '18px'}}>
          <h3 className="mb-0">Invoices Information</h3>
          <p className="mt-0 mb-4 card-description">Create an invoice by mentioning all the required information</p>
          <KooperForm className="errorLabel">
            <KooperFormGroup>
              <KooperFormField width={8}>
                <div className="info-header">
                  <label>Invoice Owner</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Set ownership of the invoice"
                    size="mini"
                    position="top left"
                  />
                </div>
                <Controller
                  name="assigneeId"
                  render={({onChange, value, onBlur}) => (
                    <KooperFormSelect
                      fluid
                      options={userOption}
                      selection
                      placeholder="Owner"
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.assigneeId && {
                          content: removeDoubleQuotes(errors?.assigneeId?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>

              <KooperFormField width={4} style={{paddingLeft: '0'}}>
                <div className="info-header ml-3">
                  <label>Prefix Id</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select billing prefix id"
                    size="mini"
                    position="top left"
                  />
                </div>

                <Controller
                  control={control}
                  name="prefixId"
                  render={({value, onChange}) => (
                    <KooperFormSelect
                      width={16}
                      placeholder="ID Prefix"
                      options={prefixOptions}
                      value={value}
                      search
                      clearable
                      noResultsMessage={
                        setPrefixOptions.length ? 'No prefixes found' : 'You have not created any Prefixes.'
                      }
                      onChange={(e, {value}) => onChange(value)}
                    />
                  )}
                />
              </KooperFormField>

              <KooperFormField width={4} required>
                <div className="info-header">
                  <label className="label-class">Invoice Number</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify invoice number"
                    size="mini"
                    position="top left"
                  />
                </div>
                <Controller
                  name="invoiceNumber"
                  control={control}
                  render={({onChange, value}) => (
                    <KooperFormInput
                      placeholder="Invoice Number"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors.invoiceNumber && {
                          content: removeDoubleQuotes(errors.invoiceNumber.message),
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>
            </KooperFormGroup>

            <KooperFormGroup widths="equal">
              <KooperFormField>
                <div className="info-header">
                  <label className="label-class">Subject</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify subject of invoice"
                    size="mini"
                    position="top left"
                  />
                </div>
                <Controller
                  name="subject"
                  control={control}
                  render={({onChange, value}) => (
                    <KooperFormInput
                      maxLength={20}
                      className="mb-0"
                      type="text"
                      placeholder="Subject"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors.subject && {
                          content: removeDoubleQuotes(errors.subject.message),
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>

              <KooperFormField>
                <div className="info-header">
                  <label>Purchase Order</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select Purchase order from the list"
                    size="mini"
                    position="top left"
                  />
                </div>
                <Controller
                  name="purchaseOrderNumber"
                  render={({onChange, value, onBlur}) => (
                    <KooperFormSelect
                      fluid
                      options={purchaseOrderNumberList}
                      selection
                      placeholder="Purchase Order"
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors.purchaseOrderNumber && {
                          content: removeDoubleQuotes(errors.purchaseOrderNumber.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>
            </KooperFormGroup>

            <KooperFormGroup widths="equal">
              <KooperFormField>
                <div className="info-header">
                  <label>Sales Order</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select sales order from the list"
                    size="mini"
                    position="top left"
                  />
                </div>
                <Controller
                  name="salesOrderNumber"
                  render={({onChange, value, onBlur}) => (
                    <KooperFormSelect
                      fluid
                      options={salesOrderNumberList}
                      selection
                      placeholder="Sales Order"
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors.salesOrderNumber && {
                          content: removeDoubleQuotes(errors.salesOrderNumber.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>

              <KooperFormField>
                <div className="info-header">
                  <label>Invoice Date</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Set invoice issue date"
                    size="mini"
                    position="top left"
                  />
                </div>
                <Controller
                  name="invoiceDate"
                  render={({onChange, value}) => (
                    <KooperDatePicker
                      type="date"
                      value={typeof value === 'string' ? new Date(InvoiceDate) : InvoiceDate}
                      inputProps={{readOnly: true}}
                      time={false}
                      onChange={value => {
                        onChange(value)
                        setInvoiceDate(value)
                      }}
                      error={
                        errors?.invoiceDate && {
                          content: removeDoubleQuotes(errors?.invoiceDate?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>
            </KooperFormGroup>

            <KooperFormGroup widths="equal">
              <KooperFormField>
                <div className="info-header">
                  <label>Contact Name</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select a contact name to display on the invoice"
                    size="mini"
                    position="top left"
                  />
                </div>
                <Controller
                  name="contactId"
                  render={({onChange, value}) => (
                    <KooperFormSelect
                      fluid
                      selection
                      options={contactOption}
                      placeholder="Contact Name"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.contactId && {
                          content: removeDoubleQuotes(errors?.contactId?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>

              <KooperFormField>
                <div className="info-header">
                  <label>Excise Duty</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Set the excise duty of invoice"
                    size="mini"
                    position="top left"
                  />
                </div>
                <Controller
                  name="exciseDuty"
                  control={control}
                  render={({onChange, value}) => (
                    <KooperFormInput
                      placeholder="00.00"
                      type="number"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                    />
                  )}
                />
              </KooperFormField>
            </KooperFormGroup>

            <KooperFormGroup widths="equal">
              <KooperFormField>
                <div className="info-header">
                  <label>Status</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Set the status of invoice"
                    size="mini"
                    position="top left"
                  />
                </div>
                <Controller
                  name="status"
                  render={({onChange, value}) => (
                    <KooperFormSelect
                      fluid
                      selection
                      options={Status}
                      placeholder="Status"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.status && {
                          content: removeDoubleQuotes(errors?.status?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>

              <KooperFormField>
                <div className="info-header">
                  <label>Sales Commission</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Add if there is any sales commission"
                    size="mini"
                    position="top left"
                  />
                </div>
                <Controller
                  name="salesCommission"
                  control={control}
                  render={({onChange, value}) => (
                    <KooperFormInput type="number" onChange={(e, {value}) => onChange(value)} value={value} />
                  )}
                />
              </KooperFormField>
            </KooperFormGroup>

            <KooperFormGroup>
              <KooperFormField width={8}>
                <div className="info-header">
                  <label>Description</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Brief description about invoice"
                    size="mini"
                    position="top left"
                  />
                </div>
                <Controller
                  name="description"
                  render={({onChange, value}) => (
                    <KooperFormTextArea
                      maxLength={160}
                      type="text"
                      value={value}
                      placeholder="Description"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors.description && {
                          content: removeDoubleQuotes(errors.description.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>
            </KooperFormGroup>
          </KooperForm>
        </KooperCardContent>
      </KooperCard>
      <KooperCard fluid>
        <KooperCardContent>
          <h3 className="mb-0">Address Information</h3>
          <p className="mt-0 mb-4 card-description">Add billing and shipping address to display on the invoice</p>
          <KooperForm className="errorLabel">
            <KooperGrid>
              <div style={{width: '50%'}}>
                <p>Billing Address</p>
                <KooperFormField>
                  <div className="info-header">
                    <label>Street</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Mention billing street to show on the invoice"
                      size="mini"
                      position="top left"
                    />
                  </div>
                  <Controller
                    name="billingAddress.streetAddress"
                    render={({onChange, value}) => (
                      <KooperFormInput
                        type="text"
                        maxLength={20}
                        fluid
                        value={value}
                        placeholder="Street"
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        error={
                          errors?.billingAddress?.streetAddress && {
                            content: removeDoubleQuotes(errors?.billingAddress?.streetAddress?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </KooperFormField>
                <KooperFormField>
                  <div className="info-header">
                    <label>City</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Mention billing city"
                      size="mini"
                      position="top left"
                    />
                  </div>
                  <Controller
                    name="billingAddress.locality"
                    render={({onChange, value}) => (
                      <KooperFormInput
                        maxLength={20}
                        type="text"
                        fluid
                        value={value}
                        placeholder="Locality"
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        error={
                          errors?.billingAddress?.locality && {
                            content: removeDoubleQuotes(errors?.billingAddress?.locality?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </KooperFormField>
                <KooperFormField>
                  <div className="info-header">
                    <label>State</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Mention billing state to show on the invoice"
                      size="mini"
                      position="top left"
                    />
                  </div>
                  <Controller
                    name="billingAddress.state"
                    render={({onChange, value}) => (
                      <KooperFormInput
                        type="text"
                        fluid
                        maxLength={20}
                        value={value}
                        placeholder="State"
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        error={
                          errors?.billingAddress?.state && {
                            content: removeDoubleQuotes(errors?.billingAddress?.state?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </KooperFormField>
                <KooperFormField>
                  <div className="info-header">
                    <label>Postal Code</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Mention the billing postal code to show on the invoice"
                      size="mini"
                      position="top left"
                    />
                  </div>
                  <Controller
                    name="billingAddress.postalCode"
                    render={({onChange, value, onBlur}) => (
                      <KooperFormInput
                        type="text"
                        fluid
                        maxLength={10}
                        placeholder="Postal Code"
                        onBlur={onBlur}
                        value={value}
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        error={
                          errors?.billingAddress?.postalCode && {
                            content: removeDoubleQuotes(errors?.billingAddress?.postalCode?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </KooperFormField>
                <KooperFormField>
                  <div className="info-header">
                    <label>Country</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Mention billing country to show on the invoice"
                      size="mini"
                      position="top left"
                    />
                  </div>
                  <Controller
                    name="billingAddress.country"
                    render={({onChange, value, onBlur}) => (
                      <KooperFormSelect
                        search
                        fluid
                        options={KooperCountryList}
                        selection
                        placeholder="Country"
                        onBlur={onBlur}
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                        error={
                          errors?.billingAddress?.country && {
                            content: removeDoubleQuotes(errors?.billingAddress?.country?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </KooperFormField>
              </div>
              <div style={{width: '50%'}}>
                <KooperCheckbox
                  label="Shipping Address (Enable if As Same Billing Address)"
                  onChange={handleShippingAddressCheckboxChange}
                  checked={isShippingAddressChecked}
                />
                <div className="mt-2">
                  <KooperFormField>
                    <div className="info-header">
                      <label>Street</label>
                      <KooperTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Mention shipping street to show on the invoice"
                        size="mini"
                        position="top left"
                      />
                    </div>
                    <Controller
                      name="shippingAddress.streetAddress"
                      render={({onChange, value}) => (
                        <KooperFormInput
                          type="text"
                          maxLength={20}
                          fluid
                          value={value}
                          placeholder="Street"
                          onChange={e => {
                            onChange(e.target.value)
                          }}
                          error={
                            errors?.shippingAddress?.streetAddress && {
                              content: removeDoubleQuotes(errors?.shippingAddress?.streetAddress?.message),
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </KooperFormField>
                  <KooperFormField>
                    <div className="info-header">
                      <label>City</label>
                      <KooperTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Mention shipping city"
                        size="mini"
                        position="top left"
                      />
                    </div>
                    <Controller
                      name="shippingAddress.locality"
                      render={({onChange, value}) => (
                        <KooperFormInput
                          type="text"
                          fluid
                          maxLength={20}
                          value={value}
                          placeholder="Locality"
                          onChange={e => {
                            onChange(e.target.value)
                          }}
                          error={
                            errors?.shippingAddress?.locality && {
                              content: removeDoubleQuotes(errors?.shippingAddress?.locality?.message),
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </KooperFormField>
                  <KooperFormField>
                    <div className="info-header">
                      <label>State</label>
                      <KooperTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Mention shipping state to show on the invoice"
                        size="mini"
                        position="top left"
                      />
                    </div>
                    <Controller
                      name="shippingAddress.state"
                      render={({onChange, value}) => (
                        <KooperFormInput
                          type="text"
                          fluid
                          maxLength={20}
                          value={value}
                          placeholder="State"
                          onChange={e => {
                            onChange(e.target.value)
                          }}
                          error={
                            errors?.shippingAddress?.state && {
                              content: removeDoubleQuotes(errors?.shippingAddress?.state?.message),
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </KooperFormField>
                  <KooperFormField>
                    <div className="info-header">
                      <label>Postal Code</label>
                      <KooperTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Mention shipping postal code to show on the invoice"
                        size="mini"
                        position="top left"
                      />
                    </div>
                    <Controller
                      name="shippingAddress.postalCode"
                      render={({onChange, value, onBlur}) => (
                        <KooperFormInput
                          type="text"
                          fluid
                          maxLength={10}
                          placeholder="Postal Code"
                          onBlur={onBlur}
                          value={value}
                          onChange={e => {
                            onChange(e.target.value)
                          }}
                          error={
                            errors?.shippingAddress?.postalCode && {
                              content: removeDoubleQuotes(errors?.shippingAddress?.postalCode?.message),
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </KooperFormField>
                  <KooperFormField>
                    <div className="info-header">
                      <label>Country</label>
                      <KooperTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Mention shipping country to show on the invoice"
                        size="mini"
                        position="top left"
                      />
                    </div>
                    <Controller
                      name="shippingAddress.country"
                      render={({onChange, value, onBlur}) => (
                        <KooperFormSelect
                          search
                          fluid
                          maxLength={20}
                          options={KooperCountryList}
                          selection
                          placeholder="Country"
                          onBlur={onBlur}
                          value={value}
                          onChange={(e, {value}) => {
                            onChange(value)
                          }}
                          error={
                            errors?.shippingAddress?.country && {
                              content: removeDoubleQuotes(errors?.shippingAddress?.country?.message),
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </KooperFormField>
                </div>
              </div>
            </KooperGrid>
          </KooperForm>
        </KooperCardContent>
      </KooperCard>
      <KooperGridColumn width={8} className="quotePreBox" style={{display: 'none'}}>
        <PreviewInvoices watch={{setupData: watchAll}} />
      </KooperGridColumn>
    </KooperContainer>
  )
}

export default Setup
