import {GET_A_GOAL, GET_ACTIVITY_LOGS} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_A_GOAL: {
      return {
        ...state,
        ...action.payload,
      }
    }
    // case GET_ACTIVITY_LOGS: {
    //   return {
    //     ...state,
    //     activityLogs: {...action.payload},
    //   }
    // }
    default:
      return state
  }
}
