import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ResponsiveLine} from '@nivo/line'
import {
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperDropdown,
  KooperGrid,
  KooperGridColumn,
  KooperNoData,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'

import {TEMPLATE_OVER_TIME} from 'actions/types'
import {getEmailTemplate} from 'actions/settings'
import {emailTemplate, templateOverTime} from 'actions/reports_template'
import {startCase} from 'utils/helper'
import {getGridYValues, maxOfGraph} from 'utils/graph'
import CustomLoader from 'components/common/CustomLoader'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import {DATE_RANGE_KEY} from 'constants/variables'

const TemplateAnalytics = () => {
  const dispatch = useDispatch()
  // states used in component
  const [usersList, setUsersList] = useState([])
  const [user, setUser] = useState('all')
  const [data, setData] = useState(JSON.parse(localStorage.getItem(DATE_RANGE_KEY)).persistDateRangeDataToDispatch)

  const {type = []} = useSelector(state => state.apiReducer)
  const {templateOverTimeData = [], emailTemplateData = []} = useSelector(state => state.reportsTemplate)
  const {getResponseList: responseList} = useSelector(state => state.settings)
  const {analyticsAccess, reportsPermissions} = useUserPermissions()
  const productivityAccess = analyticsAccess?.productivity
  const productivityReportsPermissions = reportsPermissions?.productivity_reports

  const dependencies = [user]

  // Actions to dispatch in Date function
  const actionsToDispatch = useCallback(data => {
    setData(data)
  }, [])

  useEffect(() => {
    if (!productivityReportsPermissions || !productivityAccess) return
    if (user !== 'all') {
      data.templateId = user
    }
    dispatch(templateOverTime(data))
    dispatch(emailTemplate(data))
  }, [productivityReportsPermissions, user, data, dispatch, productivityAccess])

  // UseEffect for users data
  useEffect(() => {
    if (responseList) {
      const usersList = responseList.map(list => ({
        value: list.id,
        key: list.id,
        text: `${list.title}`,
      }))

      setUsersList(usersList)
    }
  }, [responseList])

  useEffect(() => {
    if (productivityAccess && productivityReportsPermissions) {
      dispatch(getEmailTemplate())
    }
  }, [dispatch, productivityAccess, productivityReportsPermissions])

  const getUserListArray = () => {
    return [{value: 'all', text: 'All', key: 'all'}, ...usersList]
  }

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/email-templates" />
            <h5>Email Templates</h5>
          </div>
        </div>
        {productivityAccess && productivityReportsPermissions && (
          <div className="page-action">
            <KooperTooltip
              position="top center"
              size="mini"
              content="Templates"
              trigger={
                <KooperDropdown
                  icon="chevron down"
                  button
                  className="dropdown-ellipsis"
                  options={getUserListArray()}
                  onChange={(e, {value}) => setUser(value)}
                  defaultValue={getUserListArray()[0].value}
                />
              }
            />

            <DateRangeGroup actionsToDispatch={actionsToDispatch} dependencies={dependencies} />
          </div>
        )}
      </div>

      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Time Based Chart of Template</KooperCardHeader>
              <KooperTooltip
                content="Graphical representation of template usage over a timeframe"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(TEMPLATE_OVER_TIME) && <CustomLoader />}
              {!type.includes(TEMPLATE_OVER_TIME) &&
              !templateOverTimeData.map(({data}) => data.every(({y}) => y === 0)).includes(false) ? (
                <KooperNoData type="chart" noDataText="No Data Available" />
              ) : (
                <ResponsiveLine
                  data={templateOverTimeData}
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  xScale={{type: 'point'}}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: maxOfGraph(templateOverTimeData),
                    stacked: false,
                    reverse: false,
                  }}
                  enableGridX={false}
                  curve="monotoneX"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Time',
                    legendOffset: 40,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValues(templateOverTimeData),
                    legend: 'Template',
                    legendOffset: -40,
                    legendPosition: 'middle',
                  }}
                  // colors={(d) => d.color}
                  lineWidth={3}
                  pointSize={7}
                  pointColor={{theme: 'grid.line.stroke'}}
                  pointBorderWidth={2}
                  pointBorderColor={{from: 'serieColor'}}
                  pointLabel="y"
                  pointLabelYOffset={-10}
                  useMesh
                  enableSlices="x"
                  gridYValues={getGridYValues(templateOverTimeData)}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>

        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Template Data</KooperCardHeader>
              <KooperTooltip
                content="List of templates based on their usage and performance"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0">
              <KooperTable responsive basic>
                <KooperTableHeader>
                  <KooperTableRow>
                    <KooperTableHeaderCell>Name</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Created By</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Send</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Clicked</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Reply</KooperTableHeaderCell>
                  </KooperTableRow>
                </KooperTableHeader>
                <KooperTableBody>
                  {emailTemplateData && emailTemplateData?.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="emptyValue">
                        <KooperNoData type="table" noDataText="No Data Available" />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {emailTemplateData
                        .sort((a, b) => a.id - b.id)
                        .map(template => (
                          <KooperTableRow key={template.id}>
                            <KooperTableCell>{startCase(template.title)}</KooperTableCell>
                            <KooperTableCell>{startCase(template.createdBy)}</KooperTableCell>
                            <KooperTableCell>{template.sendCount}</KooperTableCell>
                            <KooperTableCell>{template.openPercentage}%</KooperTableCell>
                            <KooperTableCell>{template.replyPercentage}%</KooperTableCell>
                          </KooperTableRow>
                        ))}
                    </>
                  )}
                </KooperTableBody>
              </KooperTable>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
    </div>
  )
}
export default TemplateAnalytics
