import DeleteModal from 'components/common/DeleteModal'
import KooperCustomPagination from 'components/common/KooperCustomPagination'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SearchComponent from 'components/common/SearchComponent'
import SvgIcon from 'components/common/SvgIcon'
import {SURVEYS_PATH} from 'constants/path'
import {FaClockRotateLeft} from 'react-icons/fa6'
import {VscEdit} from 'react-icons/vsc'
import {RxCopy} from 'react-icons/rx'
import {LuPanelTopClose} from 'react-icons/lu'
import {
  KooperButton,
  KooperContainer,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperHeader,
  KooperIcon,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {getFormattedDate, getFormattedTime, startCase} from 'utils/helper'

const AllSurveys = () => {
  const history = useHistory()
  const [deleteModal, setDeleteModal] = useState(false)
  const [currentListParams, setCurrentListParams] = useState({
    page: 1,
    status: '',
    sortBy: '',
    sortType: '',
    text: '',
  })

  const surveysList = [
    {
      id: 1,
      title: 'test',
      completion: 100,
      recipients: 2,
      activatedOn: new Date(),
      createdBy: 'Jordan banks',
    },
    {
      id: 2,
      title: 'test 2',
      completion: 0,
      recipients: 5,
      activatedOn: new Date(),
      createdBy: 'Jordan banks',
    },
  ]
  const creators = [
    {value: 'all', key: 'all', text: 'All Creator'},
    {value: 'Jordan', key: 'Jordan', text: 'Jordan banks'},
  ]

  const teams = [
    {value: 'all', key: 'all', text: 'All Teams'},
    {value: 'team 1', key: 'team 1', text: 'Team 1'},
    {value: 'team 2', key: 'team 2', text: 'Team 2'},
  ]
  const statuses = [
    {value: 'active', key: 'active', text: 'Active'},
    {value: 'draft', key: 'draft', text: 'Draft'},
    {value: 'closed', key: 'closed', text: 'Closed'},
  ]
  const completionRates = [
    {value: 'all', key: 'all', text: 'All completion rates'},
    {value: '80', key: '80', text: 'More than 80%'},
    {value: '50', key: '50', text: 'More than 50%'},
    {value: '49', key: '49', text: 'Less than 50%'},
  ]
  const pageSize = 6
  const totalPages = 1
  const currentPage = 1
  const isSurveysDataLoading = false

  // if (surveysList.length === 0) {
  //   return (
  //     <SettingLayout>
  //       <CreateFirst
  //         header="Sales Orders"
  //         // addFirst={() => manageSalesOrdersPermissions && history.push(`/sales-orders/create`)}
  //         // tooltip={!manageSalesOrdersPermissions}
  //         // tooltipContent={getPermissionTooltipContent({
  //         //   roleAccess: manageSalesOrdersPermissions,
  //         // })}
  //         buttonText="Create Sales Order"
  //         imageSrc={create}
  //         subHeader="Manage the sale of products to a customer at an agreed-upon price"
  //         Feature1="Order Management"
  //         list1="Easily create and modify sales orders with customizable templates"
  //         Feature2="Multi-Currency Support"
  //         list2="Process orders in various currencies to conduct business globally"
  //         Feature3="Comprehensive Credits and Discounts"
  //         list3="Define total order credits based on tax and discount percentages"
  //       />
  //     </SettingLayout>
  //   )
  // }

  return (
    <div className="kooper-side-wrap">
      <KooperContainer>
        <div className="page-header">
          <div className="page-title">
            <div className="page-titleIcon">
              <SvgIcon path="navigation/sales-orders" />
              <h5>Custom Surveys ({surveysList ? surveysList.length : 0})</h5>
            </div>
          </div>
          <div className="page-action">
            <SearchComponent onSuccess={value => setCurrentListParams({text: value})} />
            <div className="mailActivity">
              <KooperTooltip
                position="top center"
                size="mini"
                content="Select Status"
                trigger={
                  <KooperDropdown
                    icon="chevron down"
                    button
                    defaultValue="active"
                    options={statuses}
                    onChange={(e, {value}) => {
                      setCurrentListParams({
                        ...currentListParams,
                        status: value,
                      })
                    }}
                  />
                }
              />
              <KooperTooltip
                position="top center"
                size="mini"
                content="Select Teams"
                trigger={
                  <KooperDropdown
                    icon="chevron down"
                    button
                    search
                    defaultValue="all"
                    options={teams}
                    onChange={(e, {value}) => {
                      setCurrentListParams({
                        ...currentListParams,
                        status: value,
                      })
                    }}
                  />
                }
              />

              <KooperTooltip
                position="top center"
                size="mini"
                content="Select Creators"
                trigger={
                  <KooperDropdown
                    icon="chevron down"
                    button
                    search
                    defaultValue="all"
                    options={creators}
                    onChange={(e, {value}) => {
                      setCurrentListParams({
                        ...currentListParams,
                        status: value,
                      })
                    }}
                  />
                }
              />

              <KooperTooltip
                position="top center"
                size="mini"
                content="Select Completion rates"
                trigger={
                  <KooperDropdown
                    icon="chevron down"
                    button
                    search
                    defaultValue="all"
                    options={completionRates}
                    onChange={(e, {value}) => {
                      setCurrentListParams({
                        ...currentListParams,
                        status: value,
                      })
                    }}
                  />
                }
              />
            </div>
            <LockPermissionTooltip // </div>isRoleAccessDenied={!manageSalesOrdersPermissions}
            >
              <KooperButton
                primary
                onClick={() => history.push(`/${SURVEYS_PATH.PREFIX}/create`)}
                // className={!manageContactsCompanyPermissions ? 'disabled-button' : ''}
                // onClick={() => manageContactsCompanyPermissions && setIsCreateModal(!isCreateModal)}
                content="Create Custom Surveys"
              />
            </LockPermissionTooltip>
          </div>
        </div>

        <KooperTable basic selectable className="m-0">
          <KooperTableHeader>
            <KooperTableRow>
              <KooperTableHeaderCell className="pl-4">#</KooperTableHeaderCell>
              <KooperTableHeaderCell>Title</KooperTableHeaderCell>
              <KooperTableHeaderCell>Completion</KooperTableHeaderCell>
              <KooperTableHeaderCell>Recipients</KooperTableHeaderCell>
              <KooperTableHeaderCell>Activated on</KooperTableHeaderCell>
              <KooperTableHeaderCell colSpan="2">Created by</KooperTableHeaderCell>
            </KooperTableRow>
          </KooperTableHeader>
          <KooperTableBody>
            {surveysList?.length > 0 && !isSurveysDataLoading
              ? surveysList?.map(({title, completion, recipients, activatedOn, createdBy, id}, index) => {
                  return (
                    <KooperTableRow
                      key={id}
                      className="tableLink"
                      onClick={() => history.push(`/${SURVEYS_PATH.PREFIX}/details/${id}`)}
                    >
                      <KooperTableCell className="pl-4">{pageSize * (currentPage - 1) + index + 1}</KooperTableCell>
                      <KooperTableCell className="pl-4">
                        <KooperHeader as="h5">{startCase(title)}</KooperHeader>
                      </KooperTableCell>
                      <KooperTableCell>
                        <KooperIcon name="circle outline" style={{color: completion === 100 ? 'green' : 'gray'}} />
                        <strong>{completion}</strong>%
                      </KooperTableCell>
                      <KooperTableCell>{recipients}</KooperTableCell>

                      <KooperTableCell>
                        {activatedOn ? `${getFormattedDate(activatedOn)} ${getFormattedTime(activatedOn)}` : '-'}
                      </KooperTableCell>
                      <KooperTableCell>{createdBy}</KooperTableCell>

                      <KooperTableCell>
                        <KooperDropdown
                          button
                          icon={<SvgIcon path="common/more-verticle" />}
                          className="icon"
                          direction="left"
                        >
                          <KooperDropdownMenu>
                            <KooperDropdownItem>
                              <FaClockRotateLeft /> Send reminder
                            </KooperDropdownItem>

                            <KooperDropdownItem>
                              <VscEdit /> Edit
                            </KooperDropdownItem>
                            <KooperDropdownItem>
                              <RxCopy /> Duplicate
                            </KooperDropdownItem>

                            <KooperDropdownItem>
                              <LuPanelTopClose /> Close survey
                            </KooperDropdownItem>
                          </KooperDropdownMenu>
                        </KooperDropdown>
                      </KooperTableCell>
                    </KooperTableRow>
                  )
                })
              : null}
            {isSurveysDataLoading && <KooperTablePlaceholder columns={5} line={1} row={3} />}
            {surveysList?.length === 0 && !isSurveysDataLoading && (
              <KooperTableRow>
                <KooperTableCell colSpan="5" className="emptyValue">
                  No Data Available
                </KooperTableCell>
              </KooperTableRow>
            )}
          </KooperTableBody>
        </KooperTable>

        {totalPages > 1 && (
          <KooperCustomPagination
            totalPages={totalPages}
            activePage={currentPage}
            //   onPageChange={page => {
            //     setSort(sort => ({...sort, page}))
            //   }}
          />
        )}

        {deleteModal && (
          <DeleteModal
            isModalOpen={deleteModal}
            setIsModalOpen={setDeleteModal}
            modalText="Are you sure you want to delete ?"
            //   deleteAction={deleteQuote}
            //   idTobeDeleted={editId}
            //   type={DELETE_QUOTE}
          />
        )}
      </KooperContainer>
    </div>
  )
}

export default AllSurveys
