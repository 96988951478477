/* eslint-disable no-nested-ternary */
import React, {useCallback, useEffect, useState} from 'react'
import {joiResolver} from '@hookform/resolvers'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import _ from 'lodash'
import {
  KooperButton,
  KooperCheckbox,
  KooperEditorSmall,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormSelect,
  KooperGrid,
  KooperGridColumn,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'
import {CREATE_PROJECT_RISK, EDIT_PROJECT_RISK, GET_SINGLE_PROJECT_RISK} from 'actions/types'
import {createProjectRisk, editProjectRisk, getAllProjectRisks, getProjectsTasksList} from 'actions/projects'
import {addRisksSchema} from 'validation/Projects/projects/risks.schema'
import {get, removeDoubleQuotes} from 'utils/helper'
import {riskProbabilityValueOptions, projectRiskStatusOptions} from 'constants/projects'
import SvgIcon from 'components/common/SvgIcon'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'

const initialCreateInfo = {
  riskSource: '',
  mitigationPlan: '',
  probabilityValue: 1,
  impactValue: 1,
  isImpactOnCost: false,
  isImpactOnPerformance: false,
  isImpactOnSchedule: false,
  status: 'Open',
  tasklistId: null,
  taskId: null,
}

const getProbabilityType = probabilityValue => {
  if (probabilityValue <= 3) {
    return 'Low'
  }
  if (probabilityValue <= 6 && probabilityValue >= 4) {
    return 'Medium'
  }
  if (probabilityValue >= 7) {
    return 'High'
  }
}
const getImpactType = impactValue => {
  if (impactValue <= 3) {
    return 'Low'
  }
  if (impactValue <= 6 && impactValue >= 4) {
    return 'Medium'
  }
  if (impactValue >= 7) {
    return 'High'
  }
}

function RiskModal(props) {
  const {isAddRiskOpen, setIsAddRiskOpen, projectId, rolePermissions} = props
  const dispatch = useDispatch()

  const [probabilityType, setProbabilityType] = useState('')
  const [impactType, setImpactType] = useState('')
  const [editId, setEditId] = useState(null)
  const [selectedProbabilityColor, setSelectedProbabilityColor] = useState('#59c894')
  const [selectedImpactColor, setSelectedImpactColor] = useState('#59c894')

  const {tasksList = [], getSingleRiskData} = useSelector(state => state.projects)
  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)

  const {
    handleSubmit,
    errors,
    control,
    watch,
    setValue,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange',
    resolver: joiResolver(addRisksSchema),
    defaultValues: initialCreateInfo,
  })

  const watchProbabilityValue = watch('probabilityValue')
  const watchImpactValue = watch('impactValue')
  const watchTasklistId = watch('tasklistId')

  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_PROJECT_RISK)) {
      reset({
        riskSource: get(['riskSource'], getSingleRiskData, ''),
        probabilityValue: get(['probabilityValue'], getSingleRiskData, null),
        impactValue: get(['impactValue'], getSingleRiskData, null),
        status: get(['status'], getSingleRiskData, ''),
        mitigationPlan: get(['mitigationPlan'], getSingleRiskData, ''),
        tasklistId: get(['tasklistId'], getSingleRiskData, null),
        taskId: get(['taskId'], getSingleRiskData, null),
        isImpactOnCost: get(['isImpactOnCost'], getSingleRiskData, false),
        isImpactOnPerformance: get(['isImpactOnPerformance'], getSingleRiskData, false),
        isImpactOnSchedule: get(['isImpactOnSchedule'], getSingleRiskData, false),
      })

      setEditId(get(['id'], getSingleRiskData, null))
    }
  }, [successLabels, getSingleRiskData])

  useEffect(() => {
    if (projectId) {
      dispatch(getProjectsTasksList(projectId))
    }
  }, [projectId, dispatch])

  useEffect(() => {
    if (watchProbabilityValue <= 3) {
      setProbabilityType('Low')
    } else if (watchProbabilityValue <= 6 && watchProbabilityValue >= 4) {
      setProbabilityType('Medium')
    } else if (watchProbabilityValue >= 7) {
      setProbabilityType('High')
    }
  }, [watchProbabilityValue])

  useEffect(() => {
    if (watchImpactValue <= 3) {
      setImpactType('Low')
    } else if (watchImpactValue <= 6 && watchImpactValue >= 4) {
      setImpactType('Medium')
    } else if (watchImpactValue >= 7) {
      setImpactType('High')
    }
  }, [watchImpactValue])

  useEffect(() => {
    if (successLabels.includes(CREATE_PROJECT_RISK) || successLabels.includes(EDIT_PROJECT_RISK)) {
      reset(initialCreateInfo)
      dispatch(getAllProjectRisks(projectId))
      setIsAddRiskOpen(false)
    }
  }, [successLabels, dispatch, projectId, initialCreateInfo])

  useEffect(() => {
    const selectedProbability = riskProbabilityValueOptions.find(option => option.value === watchProbabilityValue)
    setSelectedProbabilityColor(selectedProbability?.color || '#59c894')
    setProbabilityType(getProbabilityType(watchProbabilityValue))
  }, [watchProbabilityValue])

  useEffect(() => {
    const selectedImpact = riskProbabilityValueOptions.find(option => option.value === watchImpactValue)
    setSelectedImpactColor(selectedImpact?.color || '#59c894')
    setImpactType(getImpactType(watchImpactValue))
  }, [watchImpactValue])

  const getTaskOptions = useCallback(() => {
    if (watchTasklistId) {
      const tasks = tasksList.find(tasklist => tasklist.id === watchTasklistId)?.tasks
      if (tasks) {
        return tasks.map(({id, title}) => ({key: id, value: id, text: title}))
      }
      return []
    }
    return []
  }, [tasksList, watchTasklistId])

  const submitForm = data => {
    data.probabilityType = probabilityType
    data.impactType = impactType

    const newList = _.omitBy(data, value => !!(value === '' || value === null || _.isEqual(value, [])))

    if (editId) {
      dispatch(editProjectRisk({data: newList, riskId: editId, projectId}))
    } else {
      dispatch(createProjectRisk({data: newList, projectId}))
    }
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={isAddRiskOpen}
      onClose={() => setIsAddRiskOpen(false)}
      size="small"
      centered={false}
    >
      <KooperModalHeader>{editId ? 'Edit Risk' : 'Create Risk'}</KooperModalHeader>
      <KooperModalContent scrolling>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <div className="info-header">
              <label>Risk Source</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Specify the source of risk eg, data migration issue, funding issue, etc"
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              name="riskSource"
              render={({value, onChange, onBlur}) => (
                <KooperFormInput
                  type="text"
                  maxLength={50}
                  placeholder="Risk Source..."
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  onBlur={onBlur}
                  error={
                    errors.riskSource && {
                      content: removeDoubleQuotes(errors.riskSource.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>
          <KooperFormGroup>
            <KooperFormField width={8}>
              <div className="info-header">
                <label>Probability Of Risk Source Happening</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Set the probability of risk source to happen Impact"
                  size="mini"
                  position="top left"
                />
              </div>
              <KooperGrid>
                <KooperGridColumn width={10}>
                  <Controller
                    name="probabilityValue"
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormSelect
                        options={riskProbabilityValueOptions?.map(label => ({
                          value: label.value,
                          text: label.text,
                          key: label.value,
                          label: {
                            style: {background: label.color},
                            empty: true,
                            circular: true,
                          },
                        }))}
                        value={value}
                        defaultValues={1}
                        onChange={(e, {value}) => {
                          const selectedOption = riskProbabilityValueOptions.find(option => option.value === value)
                          setSelectedProbabilityColor(selectedOption?.color || '#59c894')
                          onChange(value)
                        }}
                      />
                    )}
                  />
                </KooperGridColumn>
                <KooperGridColumn width={6}>
                  <p className="mt-2" style={{fontSize: '13px', color: selectedProbabilityColor}}>
                    {getProbabilityType(watchProbabilityValue)}
                  </p>
                </KooperGridColumn>
              </KooperGrid>
            </KooperFormField>

            <KooperFormField width={8}>
              <div className="info-header">
                <label>Impact On Project If Risk Source Happens</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Set the impact on project if risk source happen"
                  size="mini"
                  position="top right"
                />
              </div>
              <KooperGrid>
                <KooperGridColumn width={10}>
                  <Controller
                    name="impactValue"
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormSelect
                        options={riskProbabilityValueOptions?.map(label => ({
                          value: label.value,
                          text: label.text,
                          key: label.value,
                          label: {
                            style: {background: label.color},
                            empty: true,
                            circular: true,
                          },
                        }))}
                        value={value}
                        defaultValues={1}
                        onChange={(e, {value}) => {
                          const selectedOption = riskProbabilityValueOptions.find(option => option.value === value)
                          setSelectedImpactColor(selectedOption?.color || '#59c894')
                          onChange(value)
                        }}
                      />
                    )}
                  />
                </KooperGridColumn>
                <KooperGridColumn width={6}>
                  <p className="mt-2" style={{fontSize: '13px', color: selectedImpactColor}}>
                    {getImpactType(watchImpactValue)}
                  </p>
                </KooperGridColumn>
              </KooperGrid>
            </KooperFormField>
          </KooperFormGroup>
          <KooperFormGroup>
            <KooperFormField width={8}>
              <div className="info-header">
                <label>Impact Areas</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Set impact area of the risk source i.e cost, schedule or performance"
                  size="mini"
                  position="top left"
                />
              </div>
              <Controller
                name="isImpactOnCost"
                render={({value, onChange}) => (
                  <KooperCheckbox label="Cost" checked={value} onChange={(e, {checked}) => onChange(checked)} />
                )}
                control={control}
              />
              <Controller
                name="isImpactOnSchedule"
                render={({value, onChange}) => (
                  <KooperCheckbox
                    className="ml-2"
                    label="Schedule"
                    checked={value}
                    onChange={(e, {checked}) => onChange(checked)}
                  />
                )}
                control={control}
              />
              <Controller
                name="isImpactOnPerformance"
                render={({value, onChange}) => (
                  <KooperCheckbox
                    className="ml-2"
                    label="Performance"
                    checked={value}
                    onChange={(e, {checked}) => onChange(checked)}
                  />
                )}
                control={control}
              />
            </KooperFormField>

            <KooperFormField width={8} required>
              <div className="info-header">
                <label>Status</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Set status of the risk source i.e open, pending or close"
                  size="mini"
                  position="top left"
                />
              </div>
              <Controller
                name="status"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormSelect
                    options={projectRiskStatusOptions?.map(label => ({
                      value: label.value,
                      text: label.text,
                      key: label.value,
                      label: {
                        style: {background: label.color},
                        empty: true,
                        circular: true,
                      },
                    }))}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                  />
                )}
              />
            </KooperFormField>
          </KooperFormGroup>
          <KooperFormGroup widths="equal">
            <KooperFormField>
              <div className="info-header">
                <label>Tasklist</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Select a tasklist from project"
                  size="mini"
                  position="top left"
                />
              </div>

              <Controller
                name="tasklistId"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormSelect
                    placeholder="Select"
                    options={tasksList.map(({id, title}) => ({key: id, value: id, text: title}))}
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                      setValue('taskId', null)
                    }}
                    error={errors.tasklistId && {content: removeDoubleQuotes(errors.tasklistId.message)}}
                  />
                )}
              />
            </KooperFormField>

            {watch('tasklistId') && (
              <KooperFormField>
                <div className="info-header">
                  <label>Task</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select task from the list"
                    size="mini"
                    position="top left"
                  />
                </div>

                <Controller
                  name="taskId"
                  control={control}
                  render={({value, onChange}) => (
                    <KooperFormSelect
                      placeholder="Select"
                      options={getTaskOptions()}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={errors.taskId && {content: removeDoubleQuotes(errors.taskId.message)}}
                    />
                  )}
                />
              </KooperFormField>
            )}
          </KooperFormGroup>
          <KooperFormField>
            <div className="info-header">
              <label>Mitigation/Response Plan</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Create a response plan to tackle the risk source"
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              name="mitigationPlan"
              control={control}
              render={({value, onChange, onBlur}) => (
                <KooperEditorSmall
                  style={{border: '1px solid #000'}}
                  setContents={value || ''}
                  onChange={content => onChange(content)}
                  onBlur={onBlur}
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions style={{position: 'relative'}}>
        <KooperButton
          basic
          onClick={() => setIsAddRiskOpen(false)}
          disabled={isLoadingData && type.includes(CREATE_PROJECT_RISK)}
        >
          Cancel
        </KooperButton>
        <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
          <KooperButton
            primary
            {...(rolePermissions && {
              onClick: handleSubmit(submitForm),
            })}
            className={!rolePermissions ? 'disabled-button' : ''}
            loading={(isLoadingData && type.includes(CREATE_PROJECT_RISK)) || type.includes(EDIT_PROJECT_RISK)}
            disabled={!isDirty}
          >
            {editId ? 'Update Risk' : 'Create Risk'}
          </KooperButton>
        </LockPermissionTooltip>
      </KooperModalActions>
    </KooperModal>
  )
}

export default RiskModal
