import React from 'react'
import {Controller} from 'react-hook-form'
import {
  KooperCheckbox,
  KooperDatePicker,
  KooperFormField,
  KooperFormInput,
  KooperFormSelect,
  KooperGrid,
  KooperGridColumn,
  KooperTooltip,
} from 'kooper-library'
import {
  PROJECT_FINANCE_BUDGET_TYPES,
  projectBudgetsBasedOnOptions,
  projectBudgetsPeriodLengthOptions,
} from 'constants/projects'
import {removeDoubleQuotes} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

const BudgetForm = ({control, errors, budgetType, rangeType, budgetTimeError, setBudgetTimeError, budgetId}) => {
  return (
    <>
      <KooperFormField required width={8} className="mb-2 pr-2">
        <div className="info-header">
          <label>
            {budgetType === PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS ? 'Budget Amount' : 'Retainer Budget'}
          </label>
          <KooperTooltip
            trigger={<SvgIcon path="common/question" />}
            content={
              budgetType === PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS
                ? 'Set the budget amount for planning'
                : 'Set the retainer budget for planning'
            }
            size="mini"
            position="top left"
          />
        </div>

        <KooperGrid>
          <KooperGridColumn width={4}>
            <Controller
              name="rangeType"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  fluid
                  options={[
                    {key: 'financial', value: 'financial', text: '$'},
                    {key: 'time', value: 'time', text: '🕐'},
                  ]}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.rangeType && {content: removeDoubleQuotes(errors.rangeType.message)}}
                />
              )}
            />
          </KooperGridColumn>
          <KooperGridColumn width={12}>
            {rangeType === 'financial' && (
              <Controller
                name="budgetAmount"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormInput
                    type="number"
                    fluid
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.budgetAmount && {content: removeDoubleQuotes(errors.budgetAmount.message)}}
                  />
                )}
              />
            )}
            {rangeType === 'time' && (
              <div className="d-flex-js">
                <Controller
                  name="budgetTimeHour"
                  control={control}
                  render={({value, onChange}) => (
                    <KooperFormInput
                      fluid
                      width={4}
                      className="mb-0 mr-2"
                      value={value}
                      onChange={(e, {value}) => {
                        setBudgetTimeError('')
                        onChange(value)
                      }}
                      error={errors.budgetTimeHour && {content: removeDoubleQuotes(errors.budgetTimeHour.message)}}
                    />
                  )}
                />
                <span>h</span>
                <Controller
                  name="budgetTimeMinute"
                  control={control}
                  render={({value, onChange}) => (
                    <KooperFormInput
                      fluid
                      width={4}
                      className="mb-0 mx-2"
                      value={value}
                      onChange={(e, {value}) => {
                        setBudgetTimeError('')
                        onChange(value)
                      }}
                      error={errors.budgetTimeMinute && {content: removeDoubleQuotes(errors.budgetTimeMinute.message)}}
                    />
                  )}
                />
                <span>m</span>
              </div>
            )}
            {budgetTimeError && <p className="errorLabelElement">{budgetTimeError}</p>}
          </KooperGridColumn>
        </KooperGrid>
      </KooperFormField>

      <KooperGrid columns="equal">
        <KooperGridColumn>
          <KooperFormField required>
            <div className="info-header">
              <label>Budget Start Date</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content={
                  budgetType === PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS
                    ? 'Set the start date for the budget amount'
                    : 'Set the start date for the retainer budget'
                }
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              name="startDate"
              control={control}
              render={({value, onChange}) => (
                <KooperDatePicker
                  disabled={budgetId}
                  placeholder="MM/DD/YYYY"
                  time={false}
                  value={value ? new Date(value) : value}
                  onChange={value => onChange(value)}
                  inputProps={{readOnly: true}}
                />
              )}
            />
            {errors.startDate && <p className="errorLabelElement">{removeDoubleQuotes(errors.startDate.message)}</p>}
          </KooperFormField>
        </KooperGridColumn>
        <KooperGridColumn>
          <KooperFormField required>
            <div className="info-header">
              <label>Budget End Date</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content={
                  budgetType === PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS
                    ? 'Set the end date for the budget amount'
                    : 'Set the end date for the retainer budget'
                }
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              name="endDate"
              control={control}
              render={({value, onChange}) => (
                <KooperDatePicker
                  placeholder="MM/DD/YYYY"
                  time={false}
                  value={value ? new Date(value) : value}
                  onChange={value => onChange(value)}
                  inputProps={{readOnly: true}}
                />
              )}
            />
            {errors.endDate && <p className="errorLabelElement">{removeDoubleQuotes(errors.endDate.message)}</p>}
          </KooperFormField>
        </KooperGridColumn>
      </KooperGrid>

      <KooperGrid>
        <KooperGridColumn width={8}>
          <KooperFormField required>
            <div className="info-header">
              <label>Budget Based On</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Select on which the budget planning will be based"
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              name="timelogType"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  fluid
                  upward
                  options={projectBudgetsBasedOnOptions}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.timelogType && {content: removeDoubleQuotes(errors.timelogType.message)}}
                />
              )}
            />
          </KooperFormField>
        </KooperGridColumn>
        {budgetType === PROJECT_FINANCE_BUDGET_TYPES.RETAINER && (
          <KooperGridColumn width={8}>
            <KooperFormField required>
              <div className="info-header">
                <label>Period Length</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Set the period length of recurring budget i.e quarterly or monthly"
                  size="mini"
                  position="top left"
                />
              </div>
              <Controller
                name="repeatUnit"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormSelect
                    fluid
                    upward
                    isabled={budgetId}
                    options={projectBudgetsPeriodLengthOptions}
                    disabled={budgetId}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.repeatUnit && {content: removeDoubleQuotes(errors.repeatUnit.message)}}
                  />
                )}
              />
            </KooperFormField>
          </KooperGridColumn>
        )}
      </KooperGrid>

      {budgetType === PROJECT_FINANCE_BUDGET_TYPES.RETAINER && (
        <>
          <p className="mb-2">
            <strong>Retainer Options</strong>
          </p>
          <Controller
            name="carryUnderspend"
            control={control}
            render={({value, onChange}) => (
              <KooperCheckbox
                className="mb-2"
                label="Add unspent hours to next period’s budget"
                checked={value}
                onChange={(e, {checked}) => onChange(checked)}
                disabled={budgetId}
              />
            )}
          />
          <br />
          <Controller
            name="carryOverspend"
            control={control}
            render={({value, onChange}) => (
              <KooperCheckbox
                label="Subtract overspent hours from next period’s budget"
                checked={value}
                onChange={(e, {checked}) => onChange(checked)}
                disabled={budgetId}
              />
            )}
          />
        </>
      )}
    </>
  )
}

export default BudgetForm
