/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  KooperButton,
  KooperDivider,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormSelect,
  KooperFormTextArea,
  KooperGrid,
  KooperGridColumn,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'

import {CREATE_PRODUCTS_FIELD, EDIT_PRODUCTS_FIELD} from 'actions/types'
import {createProductField, editProductsField, getProductFieldlist} from 'actions/productLibrary'
import {fieldsSchema} from 'validation/Sales/Products/fields.schema'
import SvgIcon from 'components/common/SvgIcon'

const typeOption = [
  {key: 'Text', value: 'Text', text: 'Text'},
  {key: 'Textarea', value: 'Textarea', text: 'Textarea'},
  {key: 'Number', value: 'Number', text: 'Number'},
  {key: 'Dropdown', value: 'Dropdown', text: 'Dropdown'},
  {
    key: 'Multiple Option',
    value: 'Multiple Option',
    text: 'Multiple Option',
  },
  {key: 'Date', value: 'Date', text: 'Date'},
]

function FieldsModal({open, toggle, editId, clearEditId}) {
  const dispatch = useDispatch()
  const [categoryList, setCategoryList] = useState([])

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {categoryList: allCategories, fieldData} = useSelector(state => state.productLibrary)

  const fieldDataValue = fieldData && fieldData[0]
  const defaultValues = {
    type: 'Text',
    required: false,
    categoryIds: [],
    description: '',
    helpText: '',
    name: '',
    values: [{value: ''}],
  }

  const {handleSubmit, errors, control, watch, reset} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(fieldsSchema),
    defaultValues,
  })

  const {
    fields: valueFields,
    remove,
    append,
  } = useFieldArray({
    name: 'values',
    control,
  })

  useEffect(() => {
    return () => {
      clearEditId()
      dispatch({type: 'CLEAR_PRODUCTS_FIELD'})
    }
  }, [])

  useEffect(() => {
    if (fieldDataValue?.id) {
      const {categoryIds, values, ...data} = fieldDataValue
      data.categoryIds = categoryIds.map(({id}) => id)
      reset({
        ...data,
        values: values ? values.map(value => ({value})) : [],
      })
    }
  }, [fieldDataValue])

  useEffect(() => {
    if (allCategories) {
      setCategoryList(
        allCategories.map(({id, category}) => ({
          key: id,
          value: id,
          text: category,
        }))
      )
    }
  }, [allCategories])

  useEffect(() => {
    if (successLabels.includes(CREATE_PRODUCTS_FIELD)) {
      dispatch(getProductFieldlist())
      toggle()
    }
    if (successLabels.includes(EDIT_PRODUCTS_FIELD)) {
      dispatch(getProductFieldlist())
      toggle()
    }
  }, [successLabels])

  const typeOfInput = watch('type')

  const isDropdown = typeOfInput === 'Dropdown' || typeOfInput === 'Multiple Option'

  function submitForm({values, ...data}) {
    if (isDropdown) {
      data.values = values.map(({value}) => value)
    }

    if (editId) {
      dispatch(editProductsField({id: editId, ...data}))
    } else {
      dispatch(createProductField(data))
    }
  }

  return (
    <KooperModal
      size="small"
      open={open}
      onClose={toggle}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
    >
      <KooperModalHeader content={editId ? 'Edit Field' : 'Create New Field'} />
      <KooperModalContent scrolling>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <label>Name</label>
            <Controller
              name="name"
              render={({value, onChange, onBlur}) => (
                <KooperFormInput
                  maxLength={20}
                  type="text"
                  placeholder="Name.."
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  error={
                    errors.name && {
                      content: errors.name.message,
                    }
                  }
                  onBlur={onBlur}
                />
              )}
              control={control}
            />
          </KooperFormField>

          <KooperFormField required>
            <label>Description</label>
            <Controller
              name="description"
              render={({value, onChange, onBlur}) => (
                <KooperFormTextArea
                  id="version"
                  placeholder="Write description.."
                  value={value}
                  maxLength={200}
                  onChange={e => onChange(e.target.value)}
                  error={
                    errors.description && {
                      content: errors.description.message,
                    }
                  }
                  onBlur={onBlur}
                />
              )}
              control={control}
            />
          </KooperFormField>

          <KooperFormGroup widths="equal">
            <KooperFormField>
              <div className="info-header">
                <label>Type</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Choose type of the field"
                  size="mini"
                  position="top left"
                />
              </div>
              <Controller
                name="type"
                render={({value, onChange, onBlur}) => (
                  <KooperFormSelect
                    fluid
                    disabled={editId}
                    options={typeOption}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={
                      errors.type && {
                        content: errors.type.message,
                      }
                    }
                    onBlur={onBlur}
                  />
                )}
                control={control}
              />
            </KooperFormField>

            <KooperFormField required>
              <div className="info-header">
                <label className="label-class">Link Category</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Link field with a category"
                  size="mini"
                  position="top left"
                />
              </div>
              <Controller
                name="categoryIds"
                render={({value, onChange}) => (
                  <KooperFormSelect
                    search
                    multiple
                    fluid
                    options={categoryList}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={
                      errors.categoryIds && {
                        content: errors.categoryIds.message,
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>
          </KooperFormGroup>

          {isDropdown && (
            <KooperFormField>
              <label>Dropdown Field</label>
              <KooperDivider hidden />
              <KooperGrid width={16}>
                {valueFields.map((item, index) => {
                  const {id, value} = item
                  return (
                    <KooperGridColumn key={item.id} width={8}>
                      <KooperFormGroup inline>
                        <KooperFormField required>
                          <label>{index + 1}</label>
                          <Controller
                            control={control}
                            defaultValue={item.value}
                            name={`values[${index}].value`}
                            render={({value, onChange}) => (
                              <KooperFormInput
                                value={value}
                                onChange={e => onChange(e.target.value)}
                                error={
                                  _.get(errors, `values[${index}][value]`) && {
                                    content: errors.values[index].value.message,
                                  }
                                }
                                icon={
                                  <KooperButton icon onClick={() => remove(index)}>
                                    <SvgIcon path="common/delete" />
                                  </KooperButton>
                                }
                              />
                            )}
                          />
                        </KooperFormField>
                      </KooperFormGroup>
                    </KooperGridColumn>
                  )
                })}
                <KooperGridColumn width={16}>
                  <KooperButton floated="right" size="small" primary onClick={() => append({value: ''})}>
                    Add Dropdown Field
                  </KooperButton>
                </KooperGridColumn>
              </KooperGrid>
            </KooperFormField>
          )}
          <KooperFormField>
            <div className="info-header">
              <label>Required</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Select if it is required or not"
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              name="required"
              render={({value, onChange}) => (
                <KooperFormSelect
                  selection
                  fluid
                  value={value}
                  options={[
                    {key: 'yes', value: true, text: 'Yes'},
                    {key: 'no', value: false, text: 'No'},
                  ]}
                  onChange={(e, {value}) => onChange(value)}
                />
              )}
              control={control}
            />
          </KooperFormField>
          <KooperFormField required>
            <div className="info-header">
              <label>Description</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Write a brief description for agents to understand about the field"
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              name="helpText"
              render={({value, onChange, onBlur}) => (
                <KooperFormTextArea
                  maxLength={200}
                  placeholder="write here.."
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  error={
                    errors.helpText && {
                      content: errors.helpText.message,
                    }
                  }
                  onBlur={onBlur}
                />
              )}
              control={control}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={toggle}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          {editId ? 'Update' : 'Create'}
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default FieldsModal
