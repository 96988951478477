import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Moment from 'moment'
import {
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperGrid,
  KooperGridColumn,
  KooperGridRow,
  KooperNoData,
  KooperTooltip,
} from 'kooper-library'
import {
  salesOrderAmount,
  salesOrderAmountByUser,
  salesOrderAmountOvertime,
  salesOrderCountOvertime,
  salesOrderNumber,
  salesOrderNumberByUser,
  totalSalesOrders,
} from 'actions/reports_salesOrders'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import {UserDropdown} from 'components/pages/Analytics/AnalyticsUtils'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import {get} from 'utils/helper'
import CustomLoader from 'components/common/CustomLoader'
import {ResponsivePie} from '@nivo/pie'
import {
  SALES_ORDER_NUMBER_BREAKDOWN_BY_PRODUCTS,
  SALES_ORDER_AMOUNT_BREAKDOWN_BY_PRODUCTS,
  NUMBER_OF_SALES_ORDER_BY_USERS,
  AMOUNT_OF_SALES_ORDER_BY_USERS,
  SALES_ORDER_COUNT_OVERTIME,
  SALES_ORDER_AMOUNT_OVERTIME,
} from 'actions/types'
import NoDataChart from 'components/common/NoDataChart'
import {ResponsiveBar} from '@nivo/bar'
import {getGridYValues, getGridYValuesBar, maxOfGraph, maxOfGraphBar} from 'utils/graph'
import {ResponsiveLine} from '@nivo/line'
import SvgIcon from 'components/common/SvgIcon'
import {DATE_RANGE_KEY} from 'constants/variables'

function SalesOrders() {
  const dispatch = useDispatch()

  const {
    totalSalesOrdersData = [],
    numberSalesOrdersData = [],
    amountSalesOrdersData = [],
    salesOrderNumberByUserData = [],
    salesOrderAmountByUserData = [],
    salesOrderCountOvertimeData = [],
    salesOrderAmountOvertimeData = [],
  } = useSelector(state => state.reportsSalesOrders)
  const {type = []} = useSelector(state => state.apiReducer)

  const {analyticsAccess, reportsPermissions} = useUserPermissions()
  const financeAccess = analyticsAccess?.finance
  const financeReportsPermissions = reportsPermissions?.finance_reports

  const {analyticsSelectedAssignee} = useSelector(state => state.analyticsInbox)

  const diff = Moment(get(['persistDateRangeEndDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))).diff(
    Moment(get(['persistDateRangeStartDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))),
    'days'
  )

  const actionsToDispatch = useCallback(
    data => {
      dispatch(totalSalesOrders(data))
      dispatch(salesOrderNumber(data))
      dispatch(salesOrderAmount(data))
      dispatch(salesOrderNumberByUser(data))
      dispatch(salesOrderAmountByUser(data))
      dispatch(salesOrderCountOvertime(data))
      dispatch(salesOrderAmountOvertime(data))
    },
    [dispatch]
  )

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/sales-orders" />
            <h5>Sales Orders</h5>
          </div>
        </div>
        {financeAccess && financeReportsPermissions && (
          <div className="page-action">
            <UserDropdown />
            <DateRangeGroup
              actionsToDispatch={actionsToDispatch}
              userId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'assignee'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
            />
          </div>
        )}
      </div>
      <KooperGrid columns="equal">
        <KooperGridColumn width={8} className="small-cards-home">
          <KooperCard fluid>
            <KooperCardContent>
              <KooperGrid columns={2} divided>
                <KooperGridRow>
                  <KooperGridColumn>
                    <KooperCardContent className="valueBox">
                      <KooperCardHeader>Total Amount</KooperCardHeader>
                      <h3>
                        {get(['totalAmount', 'value'], totalSalesOrdersData, '')}
                        <KooperTooltip
                          content="Total Amount"
                          size="mini"
                          position="bottom center"
                          trigger={
                            <span
                              className={
                                get(['totalAmount', 'growth'], totalSalesOrdersData, []) === 'positive'
                                  ? ''
                                  : 'text-danger'
                              }
                            >
                              {get(['totalAmount', 'growth'], totalSalesOrdersData, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['totalAmount', 'percentage'], totalSalesOrdersData, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </KooperCardContent>
                  </KooperGridColumn>
                  <KooperGridColumn>
                    <KooperCardContent className="valueBox">
                      <KooperCardHeader>Total Number</KooperCardHeader>
                      <h3>
                        {get(['totalNumber', 'value'], totalSalesOrdersData, '')}
                        <KooperTooltip
                          content="Total Number"
                          size="mini"
                          position="bottom center"
                          trigger={
                            <span
                              className={
                                get(['totalNumber', 'growth'], totalSalesOrdersData, []) === 'positive'
                                  ? ''
                                  : 'text-danger'
                              }
                            >
                              {get(['totalNumber', 'growth'], totalSalesOrdersData, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['totalNumber', 'percentage'], totalSalesOrdersData, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </KooperCardContent>
                  </KooperGridColumn>
                </KooperGridRow>
              </KooperGrid>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>

      <KooperGrid columns="equal">
        <KooperGridColumn width={8}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Sales Orders Number By Products</KooperCardHeader>
              <KooperTooltip
                content="Breakdown of number of sales order based on products"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(SALES_ORDER_NUMBER_BREAKDOWN_BY_PRODUCTS) && <CustomLoader />}
              {!type.includes(SALES_ORDER_NUMBER_BREAKDOWN_BY_PRODUCTS) &&
              !numberSalesOrdersData?.every(({value}) => value === 0) ? (
                <ResponsivePie
                  data={numberSalesOrdersData}
                  margin={{top: 30, right: 30, bottom: 30, left: 30}}
                  startAngle={-45}
                  innerRadius={0.5}
                  padAngle={2}
                  cornerRadius={0}
                  borderWidth={1}
                  enableArcLinkLabels={false}
                  sliceLabel={({value}) => `${value}`}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  radialLabelsSkipAngle={10}
                  radialLabelsTextXOffset={6}
                  radialLabelsTextColor="#333333"
                  radialLabelsLinkOffset={0}
                  radialLabelsLinkDiagonalLength={7}
                  radialLabelsLinkHorizontalLength={13}
                  radialLabelsLinkStrokeWidth={1}
                  radialLabelsLinkColor={{from: 'color'}}
                  slicesLabelsSkipAngle={10}
                  slicesLabelsTextColor="#333333"
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                  tooltip={({
                    datum: {
                      data: {id, value, percentage},
                    },
                  }) => (
                    <>
                      <strong>
                        {id}: {value} ({percentage}%)
                      </strong>
                    </>
                  )}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                !type.includes(SALES_ORDER_NUMBER_BREAKDOWN_BY_PRODUCTS) && (
                  <KooperNoData type="chart" noDataText="No Data Available" />
                )
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>

        <KooperGridColumn width={8}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Sales Order Amount By Products</KooperCardHeader>
              <KooperTooltip
                content="Breakdown of sales order amount based on products"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(SALES_ORDER_AMOUNT_BREAKDOWN_BY_PRODUCTS) && <CustomLoader />}
              {!type.includes(SALES_ORDER_AMOUNT_BREAKDOWN_BY_PRODUCTS) &&
              !amountSalesOrdersData?.every(({value}) => value === 0) ? (
                <ResponsivePie
                  data={amountSalesOrdersData}
                  margin={{top: 30, right: 30, bottom: 30, left: 30}}
                  startAngle={-45}
                  innerRadius={0.5}
                  padAngle={2}
                  cornerRadius={0}
                  borderWidth={1}
                  enableArcLinkLabels={false}
                  sliceLabel={({value}) => `${value}`}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  radialLabelsSkipAngle={10}
                  radialLabelsTextXOffset={6}
                  radialLabelsTextColor="#333333"
                  radialLabelsLinkOffset={0}
                  radialLabelsLinkDiagonalLength={7}
                  radialLabelsLinkHorizontalLength={13}
                  radialLabelsLinkStrokeWidth={1}
                  radialLabelsLinkColor={{from: 'color'}}
                  slicesLabelsSkipAngle={10}
                  slicesLabelsTextColor="#333333"
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                  tooltip={({
                    datum: {
                      data: {id, value, percentage},
                    },
                  }) => (
                    <>
                      <strong>
                        {id}: {value} ({percentage}%)
                      </strong>
                    </>
                  )}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                !type.includes(SALES_ORDER_AMOUNT_BREAKDOWN_BY_PRODUCTS) && (
                  <KooperNoData type="chart" noDataText="No Data Available" />
                )
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>

      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Number of Sales Orderss By User</KooperCardHeader>
              <KooperTooltip
                content="Graphical representation of number of Sales order with respect to users"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(NUMBER_OF_SALES_ORDER_BY_USERS) && <CustomLoader />}
              {!type.includes(NUMBER_OF_SALES_ORDER_BY_USERS) &&
              salesOrderNumberByUserData.every(({count}) => count === 0) ? (
                <KooperNoData type="chart" noDataText="No Data Available" />
              ) : (
                <ResponsiveBar
                  data={salesOrderNumberByUserData}
                  keys={['count']}
                  indexBy="name"
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  padding={0.3}
                  colors={{scheme: 'nivo'}}
                  enableLabel
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  minValue={0}
                  maxValue={maxOfGraphBar(salesOrderNumberByUserData, 'count')}
                  gridYValues={getGridYValuesBar(salesOrderNumberByUserData, 'count')}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    format: tick => {
                      if (diff > 0 && diff < 17) {
                        return tick
                      }
                      return Number(tick?.match(/\d+/)) % 2 ? '' : tick
                    },
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: 40,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesBar(salesOrderNumberByUserData, 'count'),
                    legend: 'count',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>

      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Amount of Sales Order By Users</KooperCardHeader>
              <KooperTooltip
                content="Graphical representation of sales order amount based on users"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(AMOUNT_OF_SALES_ORDER_BY_USERS) && <CustomLoader />}
              {!type.includes(AMOUNT_OF_SALES_ORDER_BY_USERS) &&
              salesOrderNumberByUserData.every(({amount}) => amount === 0) ? (
                <KooperNoData type="chart" noDataText="No Data Available" />
              ) : (
                <ResponsiveBar
                  data={salesOrderAmountByUserData}
                  keys={['amount']}
                  indexBy="name"
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  padding={0.3}
                  colors={{scheme: 'nivo'}}
                  enableLabel
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  minValue={0}
                  maxValue={maxOfGraphBar(salesOrderAmountByUserData, 'amount')}
                  gridYValues={getGridYValuesBar(salesOrderAmountByUserData, 'amount')}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    format: tick => {
                      if (diff > 0 && diff < 17) {
                        return tick
                      }
                      return Number(tick?.match(/\d+/)) % 2 ? '' : tick
                    },
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: 40,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesBar(salesOrderAmountByUserData, 'amount'),
                    legend: 'amout',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>

      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Sales Order Count Over Time</KooperCardHeader>
              <KooperTooltip
                content="Graphical representation of sales order count over specific time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(SALES_ORDER_COUNT_OVERTIME) && <CustomLoader />}
              {!type.includes(SALES_ORDER_COUNT_OVERTIME) &&
              !salesOrderCountOvertimeData?.map(({data}) => data.every(({y}) => y === 0)).includes(false) ? (
                <KooperNoData type="chart" noDataText="No Data Available" />
              ) : (
                <ResponsiveLine
                  data={salesOrderCountOvertimeData}
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  xScale={{type: 'point'}}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: maxOfGraph(salesOrderCountOvertimeData),
                    stacked: false,
                    reverse: false,
                  }}
                  enableGridX={false}
                  curve="monotoneX"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    format: tick => {
                      if (diff > 0 && diff < 17) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Time',
                    legendOffset: 40,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValues(salesOrderCountOvertimeData),
                    legend: 'Sales Orders Count',
                    legendOffset: -40,
                    legendPosition: 'middle',
                  }}
                  // colors={(d) => d.color}
                  lineWidth={3}
                  pointSize={5}
                  pointColor={{theme: 'grid.line.stroke'}}
                  pointBorderWidth={2}
                  pointBorderColor={{from: 'serieColor'}}
                  pointLabel="y"
                  pointLabelYOffset={-10}
                  useMesh
                  enableSlices="x"
                  gridYValues={getGridYValues(salesOrderCountOvertimeData)}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>

      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Sales Order Amount Over Time</KooperCardHeader>
              <KooperTooltip
                content="Graphical representation of sales order amount over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(SALES_ORDER_AMOUNT_OVERTIME) && <CustomLoader />}
              {!type.includes(SALES_ORDER_AMOUNT_OVERTIME) &&
              !salesOrderAmountOvertimeData?.map(({data}) => data.every(({y}) => y === 0)).includes(false) ? (
                <KooperNoData type="chart" noDataText="No Data Available" />
              ) : (
                <ResponsiveLine
                  data={salesOrderAmountOvertimeData}
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  xScale={{type: 'point'}}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: maxOfGraph(salesOrderAmountOvertimeData),
                    stacked: false,
                    reverse: false,
                  }}
                  enableGridX={false}
                  curve="monotoneX"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    format: tick => {
                      if (diff > 0 && diff < 17) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Time',
                    legendOffset: 40,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValues(salesOrderAmountOvertimeData),
                    legend: 'Sales Orders Amount',
                    legendOffset: -40,
                    legendPosition: 'middle',
                  }}
                  // colors={(d) => d.color}
                  lineWidth={3}
                  pointSize={5}
                  pointColor={{theme: 'grid.line.stroke'}}
                  pointBorderWidth={2}
                  pointBorderColor={{from: 'serieColor'}}
                  pointLabel="y"
                  pointLabelYOffset={-10}
                  useMesh
                  enableSlices="x"
                  gridYValues={getGridYValues(salesOrderAmountOvertimeData)}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
    </div>
  )
}

export default SalesOrders
