import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router'
import {FiCalendar, FiChevronRight} from 'react-icons/fi'
import {KooperAccordion, KooperAccordionContent, KooperAccordionTitle, KooperMenu, KooperMenuItem} from 'kooper-library'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import PermissionIcon from 'components/common/PermissionIcon'
import SvgIcon from 'components/common/SvgIcon'
import {useRouteMatch} from 'react-router-dom'
import {isActiveNavlink} from 'utils/helper'
import {REPORTS_PATH} from 'constants/path'

const ReportsMenu = () => {
  const history = useHistory()
  const location = useLocation()
  const {url} = useRouteMatch()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  const [expand, setExpand] = useState([])
  // const [activeIndex, setActiveIndex] = useState({
  //   conversations: true,
  //   clients: false,
  //   performance: false,
  //   campaigns: false,
  //   customerSupport: false,
  //   sales: false,
  //   finance: false,
  //   projects: false,
  //   hr: false,
  // })

  const {analyticsAccess, reportsPermissions} = useUserPermissions()
  const emailTrackingAccess = analyticsAccess?.emailTracking
  const dealConversionsAccess = analyticsAccess?.dealConversions
  const forecastingAccess = analyticsAccess?.forecasting
  const productsAccess = analyticsAccess?.products
  const quotesAccess = analyticsAccess?.quotes
  const metricsAccess = analyticsAccess?.metrics
  const usersAccess = analyticsAccess?.users
  const tasksAccess = analyticsAccess?.tasks
  const slaAccess = analyticsAccess?.sla
  const campaignsAccess = analyticsAccess?.campaigns
  const productivityAccess = analyticsAccess?.productivity
  const financeAccess = analyticsAccess?.finance
  const projectsAccess = analyticsAccess?.projects

  const conversationsReportsPermissions = reportsPermissions?.conversations_reports
  const customersReportsPermissions = reportsPermissions?.customers_reports
  const dealsReportsPermissions = reportsPermissions?.deals_reports
  const revenueReportsPermissions = reportsPermissions?.revenue_reports
  const performanceReportsPermissions = reportsPermissions?.performance_reports
  const campaignReportsPermissions = reportsPermissions?.campaign_reports
  const productivityReportsPermissions = reportsPermissions?.productivity_reports
  const financeReportsPermissions = reportsPermissions?.finance_reports
  const projectsReportsPermissions = reportsPermissions?.projects_reports

  const menus = [
    {
      key: 'conversations',
      title: 'Conversations',
      items: [
        {
          path: REPORTS_PATH.OVERVIEW,
          title: 'Overview',
          icon: <SvgIcon path="analytics/reports/overview" />,
          isRoleAccessDenied: !conversationsReportsPermissions,
        },
        {
          path: REPORTS_PATH.INBOX,
          title: 'Inbox',
          icon: <SvgIcon path="analytics/reports/inbox" />,
          isRoleAccessDenied: !conversationsReportsPermissions,
        },
        {
          path: REPORTS_PATH.CHANNELS,
          title: 'Channels',
          icon: <SvgIcon path="analytics/reports/channels" />,
          isRoleAccessDenied: !conversationsReportsPermissions,
        },
        {
          path: REPORTS_PATH.TAGS,
          title: 'Tags',
          icon: <SvgIcon path="analytics/reports/tags" />,
          isRoleAccessDenied: !conversationsReportsPermissions,
        },
        {
          path: REPORTS_PATH.EMAIL_TRACKING,
          title: 'Email Tracking',
          icon: <SvgIcon path="analytics/reports/email-tracking" />,
          isRoleAccessDenied: !conversationsReportsPermissions,
          isFeatureAccessDenied: !emailTrackingAccess,
        },
      ],
    },
    {
      key: 'clients',
      title: 'Clients',
      items: [
        {
          path: REPORTS_PATH.CONTACTS,
          title: 'Contacts',
          icon: <SvgIcon path="analytics/reports/contacts" />,
          isRoleAccessDenied: !customersReportsPermissions,
        },
        {
          path: REPORTS_PATH.COMPANIES,
          title: 'Companies',
          icon: <SvgIcon path="analytics/reports/companies" />,
          isRoleAccessDenied: !customersReportsPermissions,
        },
        /*      {
          path: REPORTS_PATH.VISITORS,
          title: 'Visitors',
          icon: <SvgIcon path="analytics/reports/visitors" />,
          isRoleAccessDenied: !customersReportsPermissions,
        }, */
      ],
    },
    {
      key: 'sales',
      title: 'Sales',
      items: [
        {
          path: REPORTS_PATH.DEALS,
          title: 'Deals',
          icon: <SvgIcon path="analytics/reports/deals" />,
          isRoleAccessDenied: !dealsReportsPermissions,
        },
        {
          path: REPORTS_PATH.CONVERSIONS,
          title: 'Conversions',
          icon: <SvgIcon path="analytics/reports/conversion" />,
          isRoleAccessDenied: !dealsReportsPermissions,
          isFeatureAccessDenied: !dealConversionsAccess,
        },
        {
          path: REPORTS_PATH.FORECASTS,
          title: 'Forecasts',
          icon: <SvgIcon path="analytics/reports/forecast" />,
          isRoleAccessDenied: !revenueReportsPermissions,
          isFeatureAccessDenied: !forecastingAccess,
        },
        {
          path: REPORTS_PATH.PRODUCTS,
          title: 'Products',
          icon: <SvgIcon path="analytics/reports/products" />,
          isRoleAccessDenied: !revenueReportsPermissions,
          isFeatureAccessDenied: !productsAccess,
        },
        {
          path: REPORTS_PATH.QUOTES,
          title: 'Quotes',
          icon: <SvgIcon path="analytics/reports/quotes" />,
          isRoleAccessDenied: !revenueReportsPermissions,
          isFeatureAccessDenied: !quotesAccess,
        },
      ],
    },
    {
      key: 'projects',
      title: 'Projects',
      items: [
        {
          path: REPORTS_PATH.PROJECT_HEALTH,
          title: 'Project Health',
          icon: <SvgIcon path="analytics/reports/project-health" />,
        },
        {
          path: REPORTS_PATH.PROJECT_TIMESHEET,
          title: 'Time Sheet by Projects',
          icon: <SvgIcon path="analytics/reports/time-sheet-projects" />,
          isExact: true,
        },
        {
          path: REPORTS_PATH.PROJECT_TIMESHEET_USERS,
          title: 'Time Sheet by Users',
          icon: <SvgIcon path="analytics/reports/time-sheet-users" />,
        },
        {
          path: REPORTS_PATH.PROJECT_TASK_USERS,
          title: 'Task by Users',
          icon: <SvgIcon path="analytics/reports/task-users" />,
        },
        {
          path: REPORTS_PATH.RESOURCE_UTILIZATION,
          title: 'Resource Utilization',
          icon: <SvgIcon path="analytics/reports/resource-utilization" />,
        },
        /*        {
          path: REPORTS_PATH.PROJECTS,
          title: 'Projects',
          icon: <SvgIcon path="analytics/reports/projects" />,
          isFeatureAccessDenied: !projectsAccess,
          isRoleAccessDenied: !projectsReportsPermissions,
        },
        {
          path: REPORTS_PATH.RESOURCES,
          title: 'Resources',
          icon: <SvgIcon path="analytics/reports/resources" />,
          isFeatureAccessDenied: !projectsAccess,
          isRoleAccessDenied: !projectsReportsPermissions,
        }, */
      ],
    },
    {
      key: 'finance',
      title: 'Finance',
      items: [
        {
          path: REPORTS_PATH.VENDORS,
          title: 'Vendors',
          icon: <SvgIcon path="analytics/reports/vendor" />,
          isFeatureAccessDenied: !financeAccess,
          isRoleAccessDenied: !financeReportsPermissions,
        },
        {
          path: REPORTS_PATH.PURCHASES,
          title: 'Purchases',
          icon: <SvgIcon path="analytics/reports/purchases" />,
          isFeatureAccessDenied: !financeAccess,
          isRoleAccessDenied: !financeReportsPermissions,
        },
        {
          path: REPORTS_PATH.PURCHASE_ORDERS,
          title: 'Purchase Orders',
          icon: <SvgIcon path="analytics/reports/purchase-orders" />,
          isFeatureAccessDenied: !financeAccess,
          isRoleAccessDenied: !financeReportsPermissions,
        },
        {
          path: REPORTS_PATH.SALES_ORDERS,
          title: 'Sales Orders',
          icon: <SvgIcon path="analytics/reports/sales-orders" />,
          isFeatureAccessDenied: !financeAccess,
          isRoleAccessDenied: !financeReportsPermissions,
        },
        {
          path: REPORTS_PATH.INVOICES,
          title: 'Invoices',
          icon: <SvgIcon path="analytics/reports/invoice" />,
          isFeatureAccessDenied: !financeAccess,
          isRoleAccessDenied: !financeReportsPermissions,
        },
        /*       {
          path: REPORTS_PATH.BUDGETS_PLANNER,
          title: 'Budgets Planner',
          icon: <SvgIcon path="analytics/reports/budgets-planner" />,
          isFeatureAccessDenied: !financeAccess,
          isRoleAccessDenied: !financeReportsPermissions,
        }, */
      ],
    },
    {
      key: 'teams',
      title: 'Teams',
      items: [
        {
          path: REPORTS_PATH.GOALS,
          title: 'Goals',
          icon: <SvgIcon path="analytics/reports/project-health" />,
        },
        {
          path: REPORTS_PATH.ONE_ON_ONE,
          title: '1-on-1s',
          icon: <SvgIcon path="analytics/reports/project-health" />,
        },
      ],
    },
    {
      key: 'performance',
      title: 'Performance',
      items: [
        {
          path: REPORTS_PATH.METRICS,
          title: 'Metrics',
          icon: <SvgIcon path="analytics/reports/metrics" />,
          isFeatureAccessDenied: !metricsAccess,
          isRoleAccessDenied: !performanceReportsPermissions,
        },
        {
          path: REPORTS_PATH.USERS,
          title: 'Users',
          icon: <SvgIcon path="analytics/reports/users" />,
          isFeatureAccessDenied: !usersAccess,
          isRoleAccessDenied: !performanceReportsPermissions,
        },
        {
          path: REPORTS_PATH.SLA,
          title: 'SLA',
          icon: <SvgIcon path="analytics/reports/sla" />,
          isFeatureAccessDenied: !slaAccess,
          isRoleAccessDenied: !performanceReportsPermissions,
        },
        {
          path: REPORTS_PATH.TASKS,
          title: 'Task',
          icon: <SvgIcon path="analytics/reports/task" />,
          isFeatureAccessDenied: !tasksAccess,
          isRoleAccessDenied: !performanceReportsPermissions,
        },
        {
          path: REPORTS_PATH.EMAIL_TEMPLATES,
          title: 'Email Templates',
          icon: <SvgIcon path="analytics/reports/email-templates" />,
          isFeatureAccessDenied: !productivityAccess,
          isRoleAccessDenied: !productivityReportsPermissions,
        },
      ],
    },
    // {
    //   key: 'campaigns',
    //   title: 'Campaigns',
    //   items: [
    //     {
    //       path: REPORTS_PATH.CHAT,
    //       title: 'Chat',
    //       icon: <HiOutlineChatAlt2 />,
    //       isFeatureAccessDenied: !campaignsAccess,
    //       isRoleAccessDenied: !campaignReportsPermissions,
    //     },
    //     {
    //       path: REPORTS_PATH.EMAIL,
    //       title: 'Email',
    //       icon: <SvgIcon path="common/mail" />,
    //       isFeatureAccessDenied: !campaignsAccess,
    //       isRoleAccessDenied: !campaignReportsPermissions,
    //     },
    //     {
    //       path: REPORTS_PATH.SMS,
    //       title: 'Sms',
    //       icon: <RiMessage2Line />,
    //       isFeatureAccessDenied: !campaignsAccess,
    //       isRoleAccessDenied: !campaignReportsPermissions,
    //     },
    //   ],
    // },
    // {
    //   key: 'hr',
    //   title: 'HR',
    //   items: [
    //     {
    //       path: REPORTS_PATH.EMPLOYEES,
    //       title: 'Employees',
    //       icon: <FiCalendar />,
    //     },
    //     {
    //       path: REPORTS_PATH.TIME_OFF,
    //       title: 'Time Off',
    //       icon: <FiCalendar />,
    //     },
    //     {
    //       path: REPORTS_PATH.EXPENSES,
    //       title: 'Expenses',
    //       icon: <FiCalendar />,
    //     },
    //   ],
    // },
  ]

  const handleMenuExpand = key => {
    const menus = [...expand]
    if (menus.includes(key)) {
      menus.splice(menus.indexOf(key), 1)
      setExpand(menus)
    } else {
      setExpand([...menus, key])
    }
  }

  const isActive = key => !expand.includes(key)

  return (
    <>
      <KooperMenu vertical secondary className="kooper-menu scrollbar-setting">
        {menus.map(({key, title, items = []}) => (
          <KooperAccordion key={key}>
            <KooperAccordionTitle onClick={() => handleMenuExpand(key)}>
              <div className="d-flex-ac">
                {isActive(key) ? <SvgIcon path="common/down" /> : <FiChevronRight />} {title}
              </div>
            </KooperAccordionTitle>
            <KooperAccordionContent active={isActive(key)}>
              {items.map(({path, title, icon, isFeatureAccessDenied = false, isRoleAccessDenied = false, isExact}) => (
                <KooperMenuItem
                  key={path}
                  active={isActiveNavlink(`${url}/${path}`, isExact)}
                  onClick={() => history.push(`${url}/${path}`)}
                >
                  {icon}
                  {title}
                  {(isFeatureAccessDenied || isRoleAccessDenied) && (
                    <span>
                      <PermissionIcon
                        isFeatureAccessDenied={isFeatureAccessDenied}
                        isRoleAccessDenied={isRoleAccessDenied}
                      />
                    </span>
                  )}
                </KooperMenuItem>
              ))}
            </KooperAccordionContent>
          </KooperAccordion>
        ))}
      </KooperMenu>
    </>
  )
}

export default ReportsMenu
