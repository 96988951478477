import React, {useState} from 'react'
import {joiResolver} from '@hookform/resolvers'
import {uploadAttachments} from 'actions/settings_productivity'
import axios from 'axios'
import AttachmentPreview from 'components/common/AttachmentPreview'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {useDispatch} from 'react-redux'
import {removeDoubleQuotes} from 'utils/helper'
import {newVersionSchema} from 'validation/Projects/projects/proofs.schema'
import {
  KooperAttachment,
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormRadio,
  KooperFormTextArea,
  KooperGrid,
  KooperGridColumn,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'
import {addNewVersion} from 'actions/proofs'
import SvgIcon from 'components/common/SvgIcon'

const {CancelToken} = axios
const requestToken = CancelToken.source()

function AddNewVersionModal(props) {
  const {isVersionModalOpen, setIsVersionModalOpen, editId} = props
  const dispatch = useDispatch()

  const [files, setFiles] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState([])

  const {handleSubmit, errors, control, watch} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(newVersionSchema),
    defaultValues: {
      option: 'file',
      description: '',
      links: [
        {
          link: '',
        },
      ],
    },
  })

  const {
    fields: linkField,
    append: addLink,
    remove: removeLink,
  } = useFieldArray({
    name: 'links',
    control,
  })

  const watchSelectOption = watch('option')
  const watchLinks = watch('links')

  const submitNewVersion = data => {
    if (uploadedFiles?.length > 0) {
      data.attachmentIds = uploadedFiles.map(file => file.id)
    }

    if (watchSelectOption === 'link') {
      data.links = watchLinks.map(link => link.link)
    }

    dispatch(addNewVersion(editId, data))
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={isVersionModalOpen}
      onClose={() => setIsVersionModalOpen(false)}
      size="small"
      centered={false}
    >
      <KooperModalHeader>
        <div className="info-header">
          <header>Add a new version</header>
          <KooperTooltip
            trigger={<SvgIcon path="common/question" />}
            content="Create an updated version of existing proof"
            size="mini"
            position="top left"
          />
        </div>
      </KooperModalHeader>

      <KooperModalContent scrolling>
        <KooperForm className="errorLabel">
          <KooperFormField>
            <div className="info-header">
              <label>Description</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Mention brief description about version"
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              name="description"
              render={({onChange, value}) => (
                <KooperFormTextArea
                  maxLength={160}
                  type="text"
                  value={value}
                  placeholder="Description"
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  error={
                    errors.description && {
                      content: removeDoubleQuotes(errors.description.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>

          <KooperGrid>
            <KooperGridColumn width={4}>
              <KooperFormField>
                <Controller
                  name="option"
                  control={control}
                  render={({value, onChange}) => (
                    <>
                      <KooperFormRadio
                        className="mb-2"
                        value="file"
                        checked={value === 'file'}
                        onChange={(e, {value}) => onChange(value)}
                        label="Upload a File"
                      />
                      <KooperFormRadio
                        value="link"
                        checked={value === 'link'}
                        onChange={(e, {value}) => onChange(value)}
                        label="Add a Link"
                      />
                    </>
                  )}
                />
              </KooperFormField>
            </KooperGridColumn>

            <KooperGridColumn width={12}>
              {watchSelectOption === 'link' && (
                <>
                  {linkField?.map(({id, link}, i) => {
                    return (
                      <>
                        <KooperFormGroup>
                          <KooperFormField
                            required={watchSelectOption === 'link'}
                            width={linkField.length > 1 ? 14 : 16}
                          >
                            <Controller
                              name={`links[${i}].link`}
                              defaultValue={link}
                              render={({value, onChange}) => (
                                <KooperFormInput
                                  type="text"
                                  placeholder="www.yourlinkhere.com"
                                  value={value}
                                  onChange={(e, {value}) => {
                                    onChange(value)
                                  }}
                                  error={
                                    errors?.links?.[i]?.link && {
                                      content: removeDoubleQuotes(errors?.links?.[i].link?.message),
                                    }
                                  }
                                />
                              )}
                              control={control}
                            />
                          </KooperFormField>
                          {linkField.length > 1 && (
                            <KooperFormField width={2}>
                              <KooperTooltip
                                size="mini"
                                position="top center"
                                content="Delete"
                                trigger={
                                  <KooperButton icon onClick={() => removeLink(i)}>
                                    <SvgIcon path="common/delete" />
                                  </KooperButton>
                                }
                              />
                            </KooperFormField>
                          )}
                        </KooperFormGroup>
                      </>
                    )
                  })}
                  {linkField.length !== 5 && (
                    <KooperFormField>
                      <KooperButton
                        icon
                        onClick={() =>
                          addLink({
                            status: '',
                            reviewerIds: null,
                          })
                        }
                      >
                        <SvgIcon path="common/plus" />
                      </KooperButton>
                    </KooperFormField>
                  )}
                </>
              )}

              {watchSelectOption === 'file' && (
                <KooperFormField required width={16} className="expense-file-input">
                  <AttachmentPreview
                    files={files}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    success="composeAttachment"
                    cancelToken={requestToken}
                  />
                  {uploadedFiles?.length !== 5 && (
                    <KooperAttachment
                      content={
                        <>
                          <SvgIcon path="common/attachment" /> Upload Attachment
                        </>
                      }
                      multiple
                      onChange={e => {
                        const data = {
                          file: e,
                        }
                        if (e.length > 0) {
                          dispatch(uploadAttachments(data, 'composeAttachment', requestToken))
                        }
                      }}
                      onClick={e => {
                        e.target.value = null
                      }}
                      fileList={files => {
                        setFiles(files)
                      }}
                    />
                  )}
                </KooperFormField>
              )}
            </KooperGridColumn>
          </KooperGrid>
        </KooperForm>
      </KooperModalContent>

      <KooperModalActions style={{position: 'relative'}}>
        <KooperButton basic onClick={() => setIsVersionModalOpen(false)}>
          Cancel
        </KooperButton>

        <KooperButton primary onClick={handleSubmit(submitNewVersion)}>
          Add New Version
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default AddNewVersionModal
