import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {
  KooperButton,
  KooperCheckbox,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperForm,
  KooperFormField,
  KooperIcon,
  KooperInput,
  KooperTooltip,
} from 'kooper-library'
import {get, startCase} from 'utils/helper'
import {priorityList} from 'constants/variables'
import SvgIcon from 'components/common/SvgIcon'

export const EmailOptionUnchecked = props => {
  const {
    conversations,
    checkedConversations,
    setCheckedConversations,
    updateCheckedConversation,
    searchConvseration,
    setSearchConvseration,
    bulkSelect,
    setBulkSelect,
    renderInboxName,
    renderInboxIcon,
    obj,
    setObj,
    setSelectedInbox,
  } = props

  const dispatch = useDispatch()

  const [customFilter, setCustomFilter] = useState({})
  const [time, setTime] = useState(0)

  const {statusList = []} = useSelector(state => state.emailInbox)

  const sortByTime = () => {
    dispatch({type: 'CLEAR_CONVERSATION_LIST'})
    if (get(['sortBy'], obj) && get(['sortBy'], obj) === 'time') {
      if (get(['sortType'], obj) === 'asc') {
        setObj({
          ...obj,
          sortBy: 'time',
          sortType: 'desc',
        })
      } else
        setObj({
          ...obj,
          sortBy: 'time',
          sortType: 'asc',
        })
    } else {
      setObj({
        ...obj,
        sortBy: 'time',
        sortType: 'asc',
      })
    }
  }
  const sortByPriority = () => {
    dispatch({type: 'CLEAR_CONVERSATION_LIST'})
    if (get(['sortBy'], obj) && get(['sortBy'], obj) === 'priority') {
      if (get(['sortType'], obj) === 'asc') {
        setObj({
          ...obj,
          sortBy: 'priority',
          sortType: 'desc',
        })
      } else
        setObj({
          ...obj,
          sortBy: 'priority',
          sortType: 'asc',
        })
    } else
      setObj({
        ...obj,
        sortBy: 'priority',
        sortType: 'asc',
      })
  }

  const isCheckedAll = () => {
    if (conversations.length === checkedConversations.length && conversations.length > 0) return true
    return false
  }

  const filterLogic = () => {
    let temp = {...customFilter}

    ;['statusId', 'priority'].forEach(fil => {
      if (Object.keys(obj).indexOf(fil) !== -1) {
        temp = {
          ...temp,
          [fil]: obj[fil],
        }
      } else {
        temp = {...temp, [fil]: 'none'}
      }
    })

    setCustomFilter(temp)
  }

  const doSearch = () => {
    if (searchConvseration === '') {
      setSelectedInbox({
        type: 'default',
        name: 'all_inbox',
        label: 'All Inbox',
      })
      dispatch({type: 'CLEAR_CONVERSATION_LIST'})
    } else {
      setSelectedInbox({
        type: 'default',
        name: 'search_results',
        label: 'Search Results',
      })
    }
    if (time) clearTimeout(time)
    setTime(
      setTimeout(() => {
        dispatch({type: 'CLEAR_CONVERSATION_LIST'})
        setObj({text: searchConvseration})
      }, 300)
    )
  }

  useEffect(() => {
    if (searchConvseration !== null) {
      doSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchConvseration])

  return (
    <>
      {(bulkSelect !== undefined ? bulkSelect : true) && (
        <div className="actBtnGrp d-flex">
          <KooperCheckbox
            className="ml-2"
            checked={isCheckedAll()}
            onClick={() => {
              if (!isCheckedAll()) updateCheckedConversation('addAll')
              else updateCheckedConversation('removeAll')
            }}
          />
          <span>
            {checkedConversations.length > 0 ? (
              `${checkedConversations.length} conversations out of ${conversations.length}`
            ) : (
              <h5>{renderInboxName()}</h5>
            )}
          </span>
          <KooperButton
            className="closeBarBtn"
            onClick={() => {
              setCheckedConversations([])
              setBulkSelect(false)
            }}
            icon
          >
            <SvgIcon path="common/close" />
          </KooperButton>
        </div>
      )}
      {(bulkSelect !== undefined ? !bulkSelect : checkedConversations.length === 0) && (
        <div className="actBtnGrp">
          <div>
            <KooperInput
              clearable
              fluid
              type="search"
              icon="search"
              iconPosition="left"
              placeholder="Search..."
              action={
                searchConvseration.length > 0 && {
                  icon: (
                    <i className="link">
                      <SvgIcon
                        path="common/close"
                        onClick={() => {
                          setSearchConvseration('')
                        }}
                      />
                    </i>
                  ),
                  className: 'serchclosebtn',
                }
              }
              onChange={e => {
                setSearchConvseration(e.target.value)
              }}
              value={searchConvseration}
            />
            <div className="subHeader">
              <div className="inboxName">
                {renderInboxIcon()}
                <h5>{renderInboxName()}</h5>
              </div>
              <div className="d-flex inbox-action-btns">
                <KooperTooltip
                  content="Filter"
                  size="mini"
                  position="top center"
                  trigger={
                    <KooperDropdown
                      className="icon button m-0"
                      onOpen={filterLogic}
                      direction="right"
                      icon={<SvgIcon path="common/filter" />}
                    >
                      <KooperDropdownMenu className="filterDropDd">
                        <h5>Filter By :</h5>
                        <KooperForm>
                          <KooperFormField>
                            <label>Status</label>
                            <KooperDropdown
                              selection
                              key={get(['statusId'], customFilter, 'none')}
                              defaultValue={get(['statusId'], customFilter, 'none')}
                              options={[
                                {
                                  value: 'none',
                                  text: 'None',
                                  key: 'none',
                                },
                                ...statusList.map(status => ({
                                  key: status.id,
                                  value: status.id,
                                  text: startCase(status.status),
                                })),
                              ]}
                              onChange={(e, {value}) => {
                                setCustomFilter({
                                  ...customFilter,
                                  statusId: value,
                                })
                              }}
                            />
                          </KooperFormField>
                          <KooperFormField>
                            <label>Priority</label>
                            <KooperDropdown
                              selection
                              key={get(['priority'], customFilter, 'default')}
                              defaultValue={get(['priority'], customFilter, 'default')}
                              options={priorityList}
                              onChange={(e, {value}) => {
                                setCustomFilter({
                                  ...customFilter,
                                  priority: value,
                                })
                              }}
                            />
                          </KooperFormField>

                          <KooperFormField>
                            <KooperButton
                              basic
                              onClick={() => {
                                const reset = _.omit(obj, [
                                  // "assigneeId",
                                  'statusId',
                                  'priority',
                                ])

                                setObj(reset)
                                dispatch({
                                  type: 'CLEAR_CONVERSATION_LIST',
                                })
                              }}
                            >
                              Reset
                            </KooperButton>
                            <KooperButton
                              primary
                              floated="right"
                              onClick={() => {
                                const newList = {
                                  ...obj,
                                  ...customFilter,
                                }
                                const newCurrentListParams = _.omitBy(newList, value => {
                                  if (value === 'none' || value === 'default') {
                                    return true
                                  }
                                  return false
                                })
                                dispatch({
                                  type: 'CLEAR_CONVERSATION_LIST',
                                })
                                setObj(newCurrentListParams)
                              }}
                            >
                              Apply Filter
                            </KooperButton>
                          </KooperFormField>
                        </KooperForm>
                      </KooperDropdownMenu>
                    </KooperDropdown>
                  }
                />

                <KooperTooltip
                  content="Order By"
                  size="mini"
                  position="top center"
                  trigger={
                    <KooperDropdown
                      icon={<SvgIcon path="inbox/inbox-dashboard/order-to" />}
                      className="icon button m-0"
                      direction="right"
                    >
                      <KooperDropdownMenu className="sortDd left">
                        <KooperDropdownItem onClick={sortByTime}>
                          <KooperIcon
                            name={`sort amount ${
                              get(['sortType'], obj) === 'asc' && get(['sortBy'], obj) === 'time' ? 'up' : 'down'
                            }`}
                          />
                          Time
                        </KooperDropdownItem>
                        <KooperDropdownItem onClick={sortByPriority}>
                          <KooperIcon
                            name={`sort amount ${
                              get(['sortType'], obj) === 'asc' && get(['sortBy'], obj) === 'priority' ? 'up' : 'down'
                            }`}
                          />
                          Priority
                        </KooperDropdownItem>
                      </KooperDropdownMenu>
                    </KooperDropdown>
                  }
                />

                <KooperTooltip
                  content="More"
                  size="mini"
                  position="top center"
                  trigger={
                    <KooperDropdown button icon={<SvgIcon path="common/more-verticle" />} className="icon mr-0 button">
                      <KooperDropdownMenu direction="left">
                        <KooperDropdownItem
                          onClick={() => {
                            setBulkSelect(true)
                          }}
                        >
                          Bulk Select
                        </KooperDropdownItem>
                      </KooperDropdownMenu>
                    </KooperDropdown>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
