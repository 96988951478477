/* eslint-disable react/no-unknown-property */
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {MdChat} from 'react-icons/md'
import {
  KooperButton,
  KooperContainer,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperHeader,
  KooperNoData,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import {DELETE_SCORING_RULES, GET_SCORING_RULES} from 'actions/types'
import DeleteModal from 'components/common/DeleteModal'
import {deleteScoringRules, getAllScoringRules} from 'actions/settings_scoringrules'
import {getFormattedDate, getPermissionTooltipContent, startCase} from 'utils/helper'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import ScoringModal from './ScoringModal'

function ScoringList(props) {
  const dispatch = useDispatch()

  const [entity, setEntity] = useState('')

  const {isLoadingData, type = [], successLabels = []} = useSelector(state => state.apiReducer)

  const {salesPermissions, salesAndCrmAccess} = useUserPermissions()
  const scoringRulesAccess = salesAndCrmAccess?.scoringRules
  const manageScoringRulesPermissions = salesPermissions?.manage_scoring_rules

  const {data: getScoringRulesData = []} = useApiResponse({
    action: getAllScoringRules,
    enabled: true,
    label: GET_SCORING_RULES,
    storePath: 'settingsScoringRules.getScoringRulesData',
  })

  useEffect(() => {
    if (successLabels.includes(DELETE_SCORING_RULES)) {
      dispatch(getAllScoringRules())
    }
  }, [dispatch, successLabels])

  const [idToDelete, setIdToDelete] = useState(0)
  const [deleteModal, setDeleteModal] = useState(false)
  const [open, setOpen] = useState(false)
  const [editId, setEditId] = useState(null)

  const renderScoringList = () => {
    if (isLoadingData && type.includes(GET_SCORING_RULES)) {
      return <KooperTablePlaceholder columns={5} />
    }

    if (!isLoadingData && getScoringRulesData.length === 0) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="7" className="emptyValue">
            <div align="center" className="pb-2">
              <KooperNoData type="table" noDataText="No Data Available" />{' '}
            </div>
          </KooperTableCell>
        </KooperTableRow>
      )
    }

    return getScoringRulesData.map((list, index) => (
      <KooperTableRow
        className="tableLink"
        onClick={() => {
          if (manageScoringRulesPermissions && scoringRulesAccess) {
            setEditId(list.id)
            setEntity(list.entity)
            setOpen(true)
          }
        }}
        key={list.id}
      >
        <KooperTableCell className="pl-4">{index + 1}</KooperTableCell>
        <KooperTableCell>
          <KooperHeader as="h5">{startCase(list.name)}</KooperHeader>
        </KooperTableCell>
        <KooperTableCell>{startCase(list.entity)}</KooperTableCell>
        <KooperTableCell>{list.points}</KooperTableCell>
        <KooperTableCell>{`${list.createdBy.firstName} ${list.createdBy.lastName}`}</KooperTableCell>
        <KooperTableCell>{getFormattedDate(list.createdAt)}</KooperTableCell>
        <KooperTableCell>
          <KooperTooltip
            content={
              manageScoringRulesPermissions && scoringRulesAccess
                ? 'Delete'
                : getPermissionTooltipContent({
                    roleAccess: manageScoringRulesPermissions,
                    featureAccess: scoringRulesAccess,
                  })
            }
            size="mini"
            position="top center"
            trigger={
              <KooperButton
                icon
                onClick={e => {
                  e.stopPropagation()
                  if (manageScoringRulesPermissions && scoringRulesAccess) {
                    setIdToDelete(list.id)
                    setDeleteModal(true)
                  }
                }}
              >
                <SvgIcon path="common/delete" />
              </KooperButton>
            }
          />
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  const RenderDropdown = () => {
    return (
      <div className="scoring-rules-drp">
        <KooperDropdown button className="primary" text="Create Scorings" direction="left">
          <KooperDropdownMenu className="creMultiDd">
            <LockPermissionTooltip
              isRoleAccessDenied={!manageScoringRulesPermissions}
              isFeatureAccessDenied={!scoringRulesAccess}
            >
              <KooperDropdownItem
                onClick={() => {
                  if (manageScoringRulesPermissions && scoringRulesAccess) {
                    setEntity('contact')
                    setOpen(true)
                    setEditId(null)
                  }
                }}
              >
                <div className="multiddIcon">
                  <MdChat />
                </div>
                <div className="multiddContent">
                  <h5>Contact</h5>
                  <p>Scoring for Contact</p>
                </div>
              </KooperDropdownItem>
            </LockPermissionTooltip>
            <LockPermissionTooltip
              isRoleAccessDenied={!manageScoringRulesPermissions}
              isFeatureAccessDenied={!scoringRulesAccess}
            >
              <KooperDropdownItem
                onClick={() => {
                  if (manageScoringRulesPermissions && scoringRulesAccess) {
                    setEntity('company')
                    setOpen(true)
                    setEditId(null)
                  }
                }}
              >
                <div className="multiddIcon">
                  <SvgIcon path="navigation/companies" />
                </div>
                <div className="multiddContent">
                  <h5>Company</h5>
                  <p>Scoring for Company</p>
                </div>
              </KooperDropdownItem>
            </LockPermissionTooltip>
            <LockPermissionTooltip
              isRoleAccessDenied={!manageScoringRulesPermissions}
              isFeatureAccessDenied={!scoringRulesAccess}
            >
              <KooperDropdownItem
                onClick={() => {
                  if (manageScoringRulesPermissions && scoringRulesAccess) {
                    setEntity('deal')
                    setOpen(true)
                    setEditId(null)
                  }
                }}
              >
                <div className="multiddIcon">
                  <SvgIcon path="navigation/deals" />
                </div>
                <div className="multiddContent">
                  <h5>Deal</h5>
                  <p>Scoring for Deal</p>
                </div>
              </KooperDropdownItem>
            </LockPermissionTooltip>
          </KooperDropdownMenu>
        </KooperDropdown>
      </div>
    )
  }

  return (
    <>
      <KooperContainer fluid>
        <div className="pageHeader px-0">
          <div className="pageTitle">
            <h5>Scoring ({getScoringRulesData.length})</h5>
          </div>
          <div className="pageAction">
            <RenderDropdown {...props} />
          </div>
        </div>
        <KooperTable basic selectable>
          <KooperTableHeader>
            <KooperTableRow>
              <KooperTableHeaderCell className="pl-4">#</KooperTableHeaderCell>
              <KooperTableHeaderCell>Name</KooperTableHeaderCell>
              <KooperTableHeaderCell>Entity</KooperTableHeaderCell>
              <KooperTableHeaderCell>Points</KooperTableHeaderCell>
              <KooperTableHeaderCell>Created By</KooperTableHeaderCell>
              <KooperTableHeaderCell>Created Date</KooperTableHeaderCell>
              <KooperTableHeaderCell>Action</KooperTableHeaderCell>
            </KooperTableRow>
          </KooperTableHeader>
          <KooperTableBody>{renderScoringList()}</KooperTableBody>
        </KooperTable>
      </KooperContainer>
      {open && (
        <ScoringModal toggle={() => setOpen(false)} open={open} entity={entity} editId={editId} setEditId={setEditId} />
      )}
      {deleteModal && (
        <DeleteModal
          idTobeDeleted={idToDelete}
          deleteAction={deleteScoringRules}
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete?"
          type={DELETE_SCORING_RULES}
        />
      )}
    </>
  )
}

export default ScoringList
