import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormTextArea,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'
import {
  GET_ALL_CONTACTS_IDS,
  GET_PROJECTS_LOOKUP,
  GET_PROJECTS_SINGLE_TEMPLATE,
  GET_USERS,
  UPDATE_PROJECTS_TEMPLATE,
} from 'actions/types'
import {getProjectsLookup, getProjectsSingleTemplate, updateProjectsTemplate} from 'actions/projects'
import {getUsers} from 'actions/settings'
import {removeDoubleQuotes} from 'utils/helper'
import {getAllContactsIds} from 'actions/contacts'
import {AiOutlineQuestionCircle} from 'react-icons/ai'
import {
  createProjectTemplateAdvanceOptionSchema,
  editProjectTemplateDetailsSchema,
} from 'validation/Projects/projects/templates.schema'
import useApiResponse from 'hooks/impure/useApiResponse'
import SvgIcon from 'components/common/SvgIcon'
import ProjectTemplateAdvanceOptionCarousel from './ProjectTemplateAdvanceOptionCarousel'

const detailsDefaultValues = {
  title: '',
  description: '',
}

const advanceOptionsDefaultValues = {
  assignedTo: [],
  managerId: null,
  contactIds: [],
  categoryId: null,
}

const carousels = ['projectDetails', 'advanceOption']

const EditProjectTemplatesModal = ({open, toggle, id}) => {
  const dispatch = useDispatch()

  const [usersList, setUsersList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [contactsList, setContactsList] = useState([])
  const [currentSlide, setCurrentSlide] = useState(0)
  const [templateEditData, setTemplateEditData] = useState({})

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {
    handleSubmit: handleSubmitProjectDetails,
    control: controlProjectDetails,
    errors: errorsProjectDetails,
    reset: resetProjectDetails,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    defaultValues: detailsDefaultValues,
    resolver: joiResolver(editProjectTemplateDetailsSchema),
  })

  const {
    handleSubmit: handleSubmitProjectAdvanceOptions,
    control: controlProjectAdvanceOptions,
    errors: errorsProjectAdvanceOptions,
    reset: resetProjectAdvanceOptions,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: advanceOptionsDefaultValues,
    resolver: joiResolver(createProjectTemplateAdvanceOptionSchema),
  })

  useApiResponse({
    action: getProjectsSingleTemplate,
    payload: id,
    dependency: [id],
    enabled: id,
    label: GET_PROJECTS_SINGLE_TEMPLATE,
    storePath: 'projects.projectsTemplateData',
    onSuccess: projectsTemplateData => {
      const projectDetailsData = _.pick(projectsTemplateData, ['title', 'description'])
      const projectAdvanceOptionData = _.pick(projectsTemplateData, [
        'assignedTo',
        'managerId',
        'contactIds',
        'categoryId',
      ])
      resetProjectDetails(projectDetailsData)
      resetProjectAdvanceOptions(projectAdvanceOptionData)
    },
  })

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(({id, firstName, lastName}) => ({
        key: id,
        value: id,
        text: `${firstName} ${lastName}`,
      }))
      setUsersList(list || [])
    },
  })

  useApiResponse({
    action: getProjectsLookup,
    payload: {lookup: ['categories']},
    enabled: true,
    label: GET_PROJECTS_LOOKUP,
    storePath: 'projects.categories',
    onSuccess: categories => {
      if (categories?.length === 0) {
        setCategoryList([{key: 0, value: 0, text: 'General'}])
      } else {
        setCategoryList([...categories.map(({id, name}) => ({key: id, value: id, text: name}))])
      }
    },
  })

  useApiResponse({
    action: getAllContactsIds,
    enabled: true,
    label: GET_ALL_CONTACTS_IDS,
    storePath: 'contacts.contactsIdsList',
    onSuccess: contactsIdsList => {
      const list = contactsIdsList?.map(({id, name}) => ({key: id, value: id, text: name}))
      setContactsList(list || [])
    },
  })

  useEffect(() => {
    if (successLabels.includes(UPDATE_PROJECTS_TEMPLATE)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const actionCancelModal = action => {
    if (action === 'prev') {
      setCurrentSlide(currentSlide => currentSlide - 1)
    }
    if (action === 'next') {
      setCurrentSlide(currentSlide => currentSlide + 1)
    }
  }

  const submitForm = data => {
    if (currentSlide === 0) {
      const payload = {...data}
      setTemplateEditData({...templateEditData, ...payload})
      actionCancelModal('next')
    }
    if (currentSlide === 1) {
      setTemplateEditData({...templateEditData, ...data})
      const {projectStartDate, projectEndDate, ...resetData} = data
      const payload = {
        ...templateEditData,
        ...resetData,
        startDate: projectStartDate,
        endDate: projectEndDate,
      }
      dispatch(updateProjectsTemplate({templateId: id, data: payload}))
    }
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader className="project-modal-header">Edit Template</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <div style={{display: currentSlide === 0 ? '' : 'none'}}>
            <KooperFormField required>
              <div className="info-header">
                <label>Title</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Give your template a title"
                  size="mini"
                  position="top left"
                />
              </div>
              <Controller
                name="title"
                control={controlProjectDetails}
                render={({value, onChange}) => (
                  <KooperFormInput
                    maxLength={20}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={
                      errorsProjectDetails.title && {content: removeDoubleQuotes(errorsProjectDetails.title.message)}
                    }
                  />
                )}
              />
            </KooperFormField>

            <KooperFormField required>
              <div className="info-header">
                <label>Description</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Write a brief description about the template"
                  size="mini"
                  position="top left"
                />
              </div>
              <Controller
                name="description"
                control={controlProjectDetails}
                render={({value, onChange}) => (
                  <KooperFormTextArea
                    maxLength={200}
                    style={{resize: 'none'}}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={
                      errorsProjectDetails.description && {
                        content: removeDoubleQuotes(errorsProjectDetails.description.message),
                      }
                    }
                  />
                )}
              />
            </KooperFormField>
          </div>

          <ProjectTemplateAdvanceOptionCarousel
            style={{display: currentSlide === 1 ? '' : 'none'}}
            control={controlProjectAdvanceOptions}
            errors={errorsProjectAdvanceOptions}
            usersList={usersList}
            contactsList={contactsList}
            categoryList={categoryList}
            setCategoryList={setCategoryList}
          />
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        {currentSlide > 0 && (
          <KooperButton basic onClick={() => actionCancelModal('prev')}>
            Prev Step
          </KooperButton>
        )}
        <KooperButton
          primary
          onClick={
            currentSlide === 0 ? handleSubmitProjectDetails(submitForm) : handleSubmitProjectAdvanceOptions(submitForm)
          }
        >
          {currentSlide === carousels.length - 1 ? 'Update Template' : 'Next Step'}
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default EditProjectTemplatesModal
