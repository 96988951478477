import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import Joi from 'joi'
import _ from 'lodash'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormSelect,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'

import {GET_ALL_CONTACTS_IDS, GET_SINGLE_PROJECT, GET_USERS, UPDATE_PROJECT_PEOPLE} from 'actions/types'
import {getSingleProject, updateProjectPeople} from 'actions/projects'
import {getUsers} from 'actions/settings'
import {removeDoubleQuotes} from 'utils/helper'
import {getAllContactsIds} from 'actions/contacts'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const ManagePeopleModal = ({open, toggle, projectId}) => {
  const dispatch = useDispatch()

  const [usersList, setUsersList] = useState([])
  const [contactsList, setContactsList] = useState([])

  const {successLabels = [], type = []} = useSelector(state => state.apiReducer)
  const {projectData} = useSelector(state => state.projects)

  const {
    handleSubmit,
    control,
    errors,
    reset,
    formState: {isDirty},
    watch,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        assignedTo: Joi.array().items(Joi.number()).min(1).required().messages({
          'array.min': 'Assignee must be specified',
        }),
        managerId: Joi.number().required().messages({
          'number.base': 'Manager must be specified',
        }),
        contactIds: Joi.array().items(Joi.number()).min(1).required().messages({
          'array.min': 'Contact must be specified',
        }),
      })
    ),
    defaultValues: {
      assignedTo: [],
      managerId: null,
      contactIds: [],
    },
  })

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUsersList(list || [])
    },
  })

  useApiResponse({
    action: getAllContactsIds,
    enabled: true,
    label: GET_ALL_CONTACTS_IDS,
    storePath: 'contacts.contactsIdsList',
    onSuccess: contactsIdsList => {
      const list = contactsIdsList.map(({id, name}) => ({
        key: id,
        value: id,
        text: name,
      }))
      setContactsList(list || [])
    },
  })

  useEffect(() => {
    dispatch(getSingleProject(projectId))
  }, [projectId, dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_PROJECT)) {
      reset(_.pick(projectData, ['assignedTo', 'managerId', 'contactIds']))
    }
  }, [successLabels, projectData, reset])

  useEffect(() => {
    if (successLabels.includes(UPDATE_PROJECT_PEOPLE)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    dispatch(updateProjectPeople(projectId, data))
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader>Manage People</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <div className="info-header">
              <label>Assignees</label>
              <KooperTooltip
                content="Assign team member who will manage the project"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </div>
            <Controller
              name="assignedTo"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  search
                  multiple
                  options={usersList}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.assignedTo && {content: removeDoubleQuotes(errors.assignedTo.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required>
            <div className="info-header">
              <label>Project Owner</label>
              <KooperTooltip
                content="Set owner of the project"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </div>
            <Controller
              name="managerId"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  search
                  options={usersList}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.managerId && {content: removeDoubleQuotes(errors.managerId.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required>
            <div className="info-header">
              <label>Contacts</label>
              <KooperTooltip
                content="Add contacts to the project"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </div>
            <Controller
              name="contactIds"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  search
                  multiple
                  options={contactsList}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.contactIds && {content: removeDoubleQuotes(errors.contactIds.message)}}
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton
          primary
          disabled={!isDirty}
          loading={type.includes(UPDATE_PROJECT_PEOPLE)}
          onClick={handleSubmit(submitForm)}
        >
          Update
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default ManagePeopleModal
