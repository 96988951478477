import DeleteModal from 'components/common/DeleteModal'
import SvgIcon from 'components/common/SvgIcon'
import {
  KooperCard,
  KooperCardContent,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperIcon,
  KooperLabel,
  KooperProgress,
  KooperTooltip,
} from 'kooper-library'

import {useState} from 'react'
import {FaArchive} from 'react-icons/fa'
import {useHistory} from 'react-router-dom'
import {getFormattedDate, startCase} from 'utils/helper'
import UpdateGoalsModal from '../GoalsDetails/UpdateGoalsModal'

const GoalsCardItem = ({goal, rolePermissions = true, isSubGoal = false}) => {
  const history = useHistory()

  const getStatus = status => {
    if (status === 'active') return 'Active'
    if (status === 'inactive') return 'In-Active'
    if (status === 'archived') return 'Archived'
    return null
  }
  const goalsIconPath = {
    individual: 'inbox/user',
    organizational: 'team/organization',
    team: 'team/team',
  }
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  return (
    <KooperCard fluid className="goals-list-card">
      <KooperCardContent onClick={() => history.push(`/goals/details/${goal?.type}/${goal?.id}`)}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '2rem',
          }}
        >
          <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem', minWidth: '35%'}}>
            <SvgIcon
              path={goalsIconPath[goal.type]}
              className={`goal_list_card_icon ${goal.type === 'individual' ? '' : 'team-goal-icon'}`}
            />
            <span className="goal-title">{startCase(goal.name)}</span>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '2rem',
              flex: 1,
              color: 'rgba(0,0,0,.6)',
            }}
          >
            <span style={{minWidth: '20%'}}>
              {goal.type === 'team'
                ? goal?.teamName
                : goal.type === 'individual'
                ? goal?.createdBy?.firstName
                : 'Organization Name'}
            </span>
            <span>{goal?.endDate ? getFormattedDate(goal.endDate) : '—'}</span>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                flex: 1,
              }}
            >
              <span>{goal?.progress}%</span>
              <KooperProgress
                percent={goal?.progress}
                size="tiny"
                style={{
                  margin: 0,
                  flex: 1,
                  background: '#edf2f7',
                  height: '4px',
                }}
              />
              <KooperLabel
                size="tiny"
                style={{
                  background: '#ebf5ff',
                  color: '#3182ce',
                  fontWeight: 'normal',
                  marginLeft: '1rem',
                }}
              >
                {startCase(goal?.status)}
              </KooperLabel>
            </div>
          </div>

          {!isSubGoal && (
            <KooperTooltip
              content="More"
              size="mini"
              position="top center"
              trigger={
                <KooperDropdown button icon={<SvgIcon path="common/more-verticle" />} className="icon mr-0 button">
                  <KooperDropdownMenu direction="left">
                    <KooperDropdownItem onClick={() => setIsOpenUpdateModal(true)}>
                      <SvgIcon path="common/check-circle" /> Update Progress
                    </KooperDropdownItem>
                    <KooperDropdownItem>
                      <SvgIcon path="common/edit2" /> Edit
                    </KooperDropdownItem>
                    <KooperDropdownItem>
                      <FaArchive /> Archive
                    </KooperDropdownItem>
                    <KooperDropdownItem onClick={() => setDeleteModal(true)}>
                      <SvgIcon path="common/delete" /> Delete
                    </KooperDropdownItem>
                  </KooperDropdownMenu>
                </KooperDropdown>
              }
            />
          )}
        </div>
      </KooperCardContent>
      {deleteModal && (
        <DeleteModal
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete ?"
          // deleteAction={deleteSalesOrders}
          // idTobeDeleted={editId}
          // type={DELETE_SALES_ORDERS}
        />
      )}
      {isOpenUpdateModal && <UpdateGoalsModal isModalOpen={isOpenUpdateModal} setIsModalOpen={setIsOpenUpdateModal} />}
    </KooperCard>
  )
}

export default GoalsCardItem
