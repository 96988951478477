import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {KooperList} from 'kooper-library'
import {get} from 'utils/helper'
import {GET_CONVERSATION_DATA} from 'actions/types'
import {EmptyInboxPlaceholder, ListPlaceholder} from 'components/common/InboxPlaceholders'
import {EmailListItem} from './EmailListItem'
import {EmailOptionUnchecked} from './EmailOptionUnchecked'

export const EmailListBar = props => {
  const {
    currentlySelectedItem,
    updateCheckedConversation,
    checkedConversations,
    conversations,
    onClickItem,
    obj,
    setObj,
    typingConversationId,
    searchConvseration,
    setSearchConvseration,
    bulkSelect,
    setBulkSelect,
  } = props

  const [selected, setSelected] = useState('None')

  const [loadMore, setLoadMore] = useState(false)

  const {isLoadingData, type = []} = useSelector(state => state.apiReducer)
  const {page} = useSelector(state => state.emailInbox)

  const onScroll = e => {
    const el = e.target

    if (el.scrollTop + el.clientHeight === el.scrollHeight) {
      setLoadMore(true)
    }
  }

  const getData = () => {
    if (page) {
      setObj({
        ...obj,
        page: page + 1,
      })
    }

    setLoadMore(false)
  }

  useEffect(() => {
    if (loadMore) {
      getData()
    }
  }, [loadMore])

  useEffect(() => {
    const checkedConvLen = checkedConversations.length
    const convLen = conversations.length
    if (checkedConversations.length > 0 && checkedConvLen < convLen) {
      setSelected('indeterminate')
    }
  }, [checkedConversations, conversations])

  const selectAllCheckRef = useRef(null)

  useRef(() => {
    selectAllCheckRef.current.indeterminate = true
  })

  // Get Channel name for icon
  const getChannel = type => {
    if (type === 'email') return 'mail outline'
    if (type === 'support_form') return 'call'
    if (type === 'facebook_dm') return 'facebook'
    if (type === 'twitter_dm') return 'twitter'
    if (type === 'custom_sms') return 'mail'

    return 'folder outline'
  }

  const renderMailItem = () => {
    if (isLoadingData && type.includes(GET_CONVERSATION_DATA)) return <ListPlaceholder />

    if (conversations.length === 0 && !type.includes(GET_CONVERSATION_DATA)) {
      return <EmptyInboxPlaceholder sidebarText="NO DATA AVAILABLE" />
    }
    return conversations.map(conversationItem => (
      <EmailListItem
        emailListItemData={conversationItem}
        bulkSelect={bulkSelect}
        key={`conversation${conversationItem.id}`}
        id={conversationItem.id}
        currentlySelectedItem={currentlySelectedItem}
        subTitle={conversationItem.subject}
        mailText={get(['message', 'messageText'], conversationItem, null)}
        mime={get(['message', 'mime'], conversationItem, null)}
        mailDraftStatus={get(['message', 'isDraft'], conversationItem)}
        isRead={conversationItem.isRead}
        selected={selected}
        conversationType={conversationItem.type}
        onClick={() => onClickItem(conversationItem.id)}
        updateCheckedConversation={updateCheckedConversation}
        checkedConversations={checkedConversations}
        updatedAt={conversationItem.updatedAt}
        status={conversationItem.status}
        isInbound={conversationItem.isInbound}
        iconName={getChannel(conversationItem.type)}
        typingConversationId={typingConversationId}
      />
    ))
  }

  return (
    <div className="email-01">
      <div className="emailGridBar">
        <div className="headerBar">
          <EmailOptionUnchecked
            searchConvseration={searchConvseration}
            setSearchConvseration={setSearchConvseration}
            bulkSelect={bulkSelect}
            setBulkSelect={setBulkSelect}
            {...props}
          />
        </div>
        <KooperList
          onScroll={onScroll}
          className="emailGridList"
          style={{
            height: bulkSelect ? 'calc(100vh - 7.7rem)' : 'calc(100vh - 11.2rem)',
            margin: 0,
          }}
          celled
          selection
          verticalAlign="middle"
        >
          {renderMailItem()}
        </KooperList>
      </div>
    </div>
  )
}
