/* eslint-disable no-nested-ternary */
import CreateFirst from 'components/common/CreateFirst'
import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import create from 'assets/create.svg'
import _ from 'lodash'
import {
  KooperButton,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperIcon,
  KooperLabel,
  KooperMenu,
  KooperMenuItem,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperNoData,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
} from 'kooper-library'
import {DELETE_BUDGET, GET_BUDGET_LIST, UPDATE_BUDGET_STATUS} from 'actions/types'
import {getBudgetlist, deleteBudget, updateBudgetStatus} from 'actions/budget'
import SearchComponent from 'components/common/SearchComponent'
import DeleteModal from 'components/common/DeleteModal'
import {getPermissionTooltipContent, startCase} from 'utils/helper'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {BUDGETS_PLANNER_PATH} from 'constants/path'

const BudgetsPlanner = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false)
  const [idToChangeStatus, setIdToChangeStatus] = useState(null)
  const [newStatus, setNewStatus] = useState(null)

  const [deleteModal, setDeleteModal] = useState(false)
  const [editId, setEditId] = useState()
  const [currentListParams, setCurrentListParams] = useState({
    page: 1,
    category: '',
    sortBy: '',
    sortType: '',
    text: '',
  })

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)
  const {isLoading} = useApiLoader({label: GET_BUDGET_LIST})
  const {financeAccess} = useUserPermissions()
  const budgetPlannerAccess = financeAccess?.budgetPlanner
  const {financePermissions} = useUserPermissions()
  const manageBudgetPlannerPermissions = financePermissions?.manage_budget_planner

  const {data: budgetPlannerList = []} = useApiResponse({
    action: getBudgetlist,
    enabled: budgetPlannerAccess,
    dependency: [budgetPlannerAccess],
    label: GET_BUDGET_LIST,
    storePath: 'budget.budgetPlannerList',
  })

  useEffect(() => {
    if (successLabels.includes(DELETE_BUDGET) || successLabels.includes(UPDATE_BUDGET_STATUS)) {
      dispatch(getBudgetlist())
    }
  }, [successLabels, dispatch])

  useEffect(() => {
    if (!budgetPlannerAccess) return
    const newCurrentListParams = _.omitBy(currentListParams, value => {
      if (value === 'all' || value === '') {
        return true
      }
      return false
    })

    dispatch(getBudgetlist(newCurrentListParams))
  }, [budgetPlannerAccess, currentListParams, dispatch])

  const handleStatusChangeConfirm = () => {
    if (idToChangeStatus && newStatus !== null) {
      dispatch(updateBudgetStatus(idToChangeStatus, {isActive: newStatus}))
      setIsStatusModalOpen(false)
      setIdToChangeStatus(null)
      setNewStatus(null)
    }
  }

  const renderTableBody = () => {
    return budgetPlannerList.map(
      ({id, name, rangePeriod, compareWith, startMonth, startYear, endMonth, endYear, rangeTotal, isActive}, index) => {
        return (
          <KooperTableRow
            key={id}
            className="tableLink"
            onClick={() => {
              history.push(`/${BUDGETS_PLANNER_PATH.PREFIX}/${BUDGETS_PLANNER_PATH.NEW}/${id}`)
            }}
          >
            <KooperTableCell>{index + 1}</KooperTableCell>
            <KooperTableCell>{startCase(name)}</KooperTableCell>
            <KooperTableCell>{startCase(compareWith)}</KooperTableCell>
            <KooperTableCell>{startCase(rangePeriod)}</KooperTableCell>
            <KooperTableCell>{`${startMonth || ''}-${startYear || ''}`}</KooperTableCell>
            <KooperTableCell>{`${endMonth || ''}-${endYear || ''}`}</KooperTableCell>
            <KooperTableCell>{rangeTotal}</KooperTableCell>
            <KooperTableCell>
              <KooperLabel className="kooper-label" color={isActive ? 'green' : 'red'}>
                {isActive ? 'Active' : 'inActive'}
              </KooperLabel>
            </KooperTableCell>
            <KooperTableCell>
              <KooperDropdown button icon={<SvgIcon path="common/more-verticle" />} className="icon" direction="left">
                <KooperDropdownMenu>
                  <LockPermissionTooltip isRoleAccessDenied={!manageBudgetPlannerPermissions}>
                    <KooperDropdownItem
                      onClick={() => {
                        if (manageBudgetPlannerPermissions) {
                          setNewStatus(!isActive)
                          setIdToChangeStatus(id)
                          setIsStatusModalOpen(true)
                        }
                      }}
                    >
                      <KooperIcon name={isActive ? 'eye slash' : 'share square'} />
                      {isActive ? 'InActive' : 'Active'}
                    </KooperDropdownItem>
                  </LockPermissionTooltip>

                  <LockPermissionTooltip isRoleAccessDenied={!manageBudgetPlannerPermissions}>
                    <KooperDropdownItem
                      onClick={() => {
                        if (manageBudgetPlannerPermissions) {
                          setEditId(id)
                          setDeleteModal(true)
                        }
                      }}
                    >
                      <SvgIcon path="common/delete" /> Delete
                    </KooperDropdownItem>
                  </LockPermissionTooltip>
                </KooperDropdownMenu>
              </KooperDropdown>
            </KooperTableCell>
          </KooperTableRow>
        )
      }
    )
  }

  if (isLoading && budgetPlannerAccess) {
    return <PageLoader />
  }

  return (
    <>
      {budgetPlannerList.length > 0 || currentListParams.text ? (
        <>
          <KooperMenu vertical secondary className="kooper-menu">
            <KooperMenuItem
              active={window.location.pathname.includes(`/${BUDGETS_PLANNER_PATH.PREFIX}`)}
              onClick={() => history.push(`/${BUDGETS_PLANNER_PATH.PREFIX}`)}
            >
              All Budget plan
            </KooperMenuItem>
          </KooperMenu>

          <div className="kooper-side-wrap">
            <div className="page-header">
              <div className="page-title">
                <div className="page-titleIcon">
                  <SvgIcon path="navigation/budget-planner" />
                  <h5>Budget Planner List ({budgetPlannerList ? budgetPlannerList.length : 0})</h5>
                </div>
              </div>
              <div className="page-action">
                <SearchComponent onSuccess={value => setCurrentListParams({text: value})} />
                <LockPermissionTooltip isRoleAccessDenied={!manageBudgetPlannerPermissions}>
                  <KooperButton
                    className={!manageBudgetPlannerPermissions ? 'disabled-button' : ''}
                    content="Create"
                    primary
                    onClick={() =>
                      manageBudgetPlannerPermissions &&
                      history.push(`/${BUDGETS_PLANNER_PATH.PREFIX}/${BUDGETS_PLANNER_PATH.NEW}`)
                    }
                  />
                </LockPermissionTooltip>
              </div>
            </div>
            <KooperTable sortable basic selectable>
              <KooperTableHeader>
                <KooperTableRow>
                  <KooperTableHeaderCell>#</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Name</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Compare With</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Range Period</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Start Month Year</KooperTableHeaderCell>
                  <KooperTableHeaderCell>End Month Year</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Range Total</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Status</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Actions</KooperTableHeaderCell>
                </KooperTableRow>
              </KooperTableHeader>
              <KooperTableBody>
                {isLoadingData && type.includes(GET_BUDGET_LIST) ? (
                  <KooperTablePlaceholder columns={9} />
                ) : (budgetPlannerList || []).length > 0 ? (
                  renderTableBody()
                ) : (
                  <tr>
                    <td colSpan={9} className="emptyValue">
                      <KooperNoData type="table" noDataText="No Data Available" />
                    </td>
                  </tr>
                )}
              </KooperTableBody>
            </KooperTable>

            {deleteModal && (
              <DeleteModal
                isModalOpen={deleteModal}
                setIsModalOpen={setDeleteModal}
                modalText="Are you sure you want to delete ?"
                deleteAction={deleteBudget}
                idTobeDeleted={editId}
                type={DELETE_BUDGET}
              />
            )}

            {isStatusModalOpen && (
              <KooperModal
                closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
                size="tiny"
                centered={false}
                open={isStatusModalOpen}
                onClose={() => setIsStatusModalOpen(false)}
              >
                <KooperModalHeader>Status Change</KooperModalHeader>
                <KooperModalContent>
                  Are you sure you want to change the status to {newStatus ? 'Active' : 'InActive'}?
                </KooperModalContent>
                <KooperModalActions>
                  <KooperButton basic onClick={() => setIsStatusModalOpen(false)}>
                    Cancel
                  </KooperButton>
                  <KooperButton primary onClick={handleStatusChangeConfirm}>
                    Confirm
                  </KooperButton>
                </KooperModalActions>
              </KooperModal>
            )}
          </div>
        </>
      ) : (
        <CreateFirst
          header="Budgets Planner"
          addFirst={() =>
            budgetPlannerAccess &&
            manageBudgetPlannerPermissions &&
            history.push(`/${BUDGETS_PLANNER_PATH.PREFIX}/${BUDGETS_PLANNER_PATH.NEW}`)
          }
          buttonText="Create"
          tooltip={!budgetPlannerAccess || !manageBudgetPlannerPermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: budgetPlannerAccess,
            roleAccess: manageBudgetPlannerPermissions,
          })}
          imageSrc={create}
          subHeader="Budget planner helps to create budgets in a simpler and easier way"
          Feature1="Employee reimbursement"
          list1="Eliminate costly process of reimbursing employees easily"
          Feature2="Budget planning period"
          list2="Set custom time period for budget planning"
          Feature3="Comparison"
          list3="Pick an entity and compared to budget in real time"
        />
      )}
    </>
  )
}

export default BudgetsPlanner
