import React, {useState} from 'react'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperContainer,
  KooperDatePicker,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormSelect,
  KooperIcon,
  KooperMessage,
  KooperMessageContent,
  KooperMessageHeader,
  KooperRadio,
  KooperTooltip,
} from 'kooper-library'
import {Controller, useForm} from 'react-hook-form'
import {FiTrash2, FiPlus} from 'react-icons/fi'
import {removeDoubleQuotes} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'
import {surveyFreequency} from 'constants/Teams/Goals/surveys'

const renderKooperRadio = (name, label, value, checkedValue, onChange) => (
  <KooperRadio
    label={label}
    value={value}
    checked={checkedValue === value}
    onChange={(_, {value}) => {
      onChange(value)
      // disableSaveBtn()
    }}
  />
)
function PulseSurveyInfo() {
  return (
    <KooperMessage
      info
      style={{
        backgroundColor: '#f8faff',
        border: '1px solid #e1e8ff',
        borderRadius: '8px',
        padding: '16px 20px',
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
      }}
    >
      <KooperIcon
        name="info circle"
        style={{
          fontSize: '24px',
          color: '#0052cc',
          marginRight: '12px',
          verticalAlign: 'top',
        }}
      />
      <KooperMessageContent style={{display: 'inline-block', width: 'calc(100% - 40px)'}}>
        <KooperMessageHeader
          style={{
            fontSize: '16px',
            fontWeight: '600',
            color: '#1a1f36',
            marginBottom: '4px',
          }}
        >
          What's a Pulse Survey?
        </KooperMessageHeader>
        <p
          style={{
            margin: '0',
            color: '#424866',
            lineHeight: '1.5',
            fontSize: '14px',
          }}
        >
          Pulse Surveys are automated Officevibe surveys that capture how your team is feeling across 10 key engagement
          metrics. Your Custom Survey will be completed after members fill out their Pulse Survey.
        </p>
      </KooperMessageContent>
      <KooperIcon
        name="close"
        style={{
          position: 'absolute',
          top: '12px',
          right: '12px',
          cursor: 'pointer',
          fontSize: '16px',
          color: '#666',
        }}
        onClick={() => console.log('Close clicked')}
      />
    </KooperMessage>
  )
}
const Delivery = () => {
  const {handleSubmit, control, errors, watch} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    defaultValues: {
      deliveryOptions: 'nextPulseSurvey',
      closingOptions: 'closeOn',
    },
  })

  const deliveryTypeWatch = watch('deliveryType')
  const [deliveryOptions, setDeliveryOptions] = useState(1)

  const onSubmit = data => {
    console.log('Delivery Details:', data)
  }

  return (
    <KooperContainer>
      <KooperCard fluid>
        <KooperCardContent>
          {/* <h3 className="mb-0">Delivery Settings</h3>
          <p className="mt-0 mb-4 card-description">Configure your delivery options and preferences.</p>
          <KooperForm className="errorLabel">
            <KooperFormField>
              <div className="info-header">
                <label>Delivery Options</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Delivery Options"
                  size="mini"
                  psoition="top center"
                />
              </div>
              <KooperFormField width={8}>
                <Controller
                  control={control}
                  name="deliveryOptions"
                  render={({onChange, value}) => (
                    <>
                      {renderKooperRadio(
                        'deliveryOptions',
                        'Add to the next Pulse Survey',
                        'nextPulseSurvey',
                        value,
                        onChange
                      )}
                      <p className="Kooper-lead ml-4 pl-1">
                        Your Custom Survey will display after audience members complete their pulse survey
                      </p>

                      {renderKooperRadio(
                        'deliveryOptions',
                        'Add to Pulse Surveys after',
                        'afterPulseSurvey',
                        value,
                        onChange
                      )}
                      <p className="Kooper-lead ml-4 pl-1">
                        Your Custom Survey will display after audience members complete their pulse survey
                      </p>
                      {watch('deliveryOptions') === 'afterPulseSurvey' && (
                        <Controller
                          name="afterPulsesurveyDate"
                          control={control}
                          render={({onChange, value}) => (
                            <KooperDatePicker
                              style={{height: 50}}
                              className="mt-0 pl-4 pr-3"
                              format="MM/yyyy"
                              value={value || new Date()}
                              onChange={value => {
                                onChange(value)
                              }}
                              time={false}
                            />
                          )}
                        />
                      )}
                    </>
                  )}
                />
              </KooperFormField>
            </KooperFormField>
            <KooperFormGroup>
              <KooperFormField width={8}>
                <div className="info-header">
                  <label>Freequency</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select Freequency"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="freequency"
                  control={control}
                  defaultValue="none"
                  render={({onChange, value, onBlur}) => (
                    <KooperFormSelect
                      fluid
                      options={surveyFreequency}
                      selection
                      placeholder="Select freequency"
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors.purchaseOrderNumber && {
                          content: removeDoubleQuotes(errors.purchaseOrderNumber.message),
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>
            </KooperFormGroup>
          </KooperForm> */}
          <h3 className="mb-0">Closing Settings</h3>
          <p className="mt-0 mb-4 card-description">
            Custom Surveys remain active until you close them. You can choose when to stop receiving answers or manually
            close them later.
          </p>
          <KooperForm className="errorLabel">
            <KooperFormField>
              <div className="info-header">
                <label>Closing Options</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Closing Options"
                  size="mini"
                  psoition="top center"
                />
              </div>
              <KooperFormField width={8}>
                <Controller
                  control={control}
                  name="closingOptions"
                  render={({onChange, value}) => (
                    <>
                      {renderKooperRadio('closingOptions', 'Close Custom Survey on', 'closeOn', value, onChange)}
                      <p className="Kooper-lead ml-4 pl-1">Close Custom Survey on</p>
                      {watch('closingOptions') === 'closeOn' && (
                        <Controller
                          name="closeOnDate"
                          control={control}
                          render={({onChange, value}) => (
                            <KooperDatePicker
                              fluid
                              style={{height: 50}}
                              className="mt-0 pl-4 pr-3"
                              format="MM/yyyy"
                              value={value || new Date()}
                              onChange={value => {
                                onChange(value)
                              }}
                              time={false}
                            />
                          )}
                        />
                      )}
                      {renderKooperRadio(
                        'closingOptions',
                        'Close Custom Survey after participation reaches',
                        'participationCount',
                        value,
                        onChange
                      )}
                      <p className="Kooper-lead ml-4 pl-1">Close Custom Survey after participation reaches</p>
                      {watch('closingOptions') === 'participationCount' && (
                        <Controller
                          name="participationCountNumber"
                          control={control}
                          render={({onChange, value}) => (
                            <KooperFormInput
                              className="pl-4 pr-3"
                              placeholder="Enter participation count number"
                              onChange={(e, {value}) => onChange(value)}
                              value={value}
                              type="number"
                              error={
                                errors?.title && {
                                  content: removeDoubleQuotes(errors.title.message),
                                }
                              }
                            />
                          )}
                        />
                      )}
                      {renderKooperRadio('closingOptions', 'Do not close Custom Survey', 'notClose', value, onChange)}
                      <p className="Kooper-lead ml-4 pl-1">
                        This survey will continue to remain open to future members who join your audience until you
                        close it.
                      </p>
                    </>
                  )}
                />
              </KooperFormField>
            </KooperFormField>
          </KooperForm>
          <PulseSurveyInfo />
        </KooperCardContent>
      </KooperCard>
    </KooperContainer>
  )
}

export default Delivery
