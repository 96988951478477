/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller} from 'react-hook-form'
import moment from 'moment'
import {
  KooperCard,
  KooperCardContent,
  KooperDatePicker,
  KooperDropdownItem,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormSelect,
  KooperGrid,
  KooperGridColumn,
  KooperTooltip,
} from 'kooper-library'

import {getAllDeals, getSingleDeals} from 'actions/deals'
import {getNewQuoteNumber, getQuotePrefixes} from 'actions/quotes'
import CreateDealModal from 'components/pages/Sales/Deals/CreateModal'
import SvgIcon from 'components/common/SvgIcon'
import {GET_ALL_DEALS, GET_QUOTE_PREFIX} from 'actions/types'
import useApiResponse from 'hooks/impure/useApiResponse'
import PreviewQuote from '../Templates/PreviewQuote'

const dateOptions = [
  {key: '07', value: moment().add(7, 'day').toISOString(), text: '07 days'},
  {key: '30', value: moment().add(30, 'day').toISOString(), text: '30 days'},
  {key: '60', value: moment().add(60, 'day').toISOString(), text: '60 days'},
  {key: '90', value: moment().add(90, 'day').toISOString(), text: '90 days'},
]

function Setup({formData, quoteId, setWarningModal}) {
  const {errors, control, watch, reset, setValue, clearErrors} = formData
  const dispatch = useDispatch()

  const watchAll = watch()
  const watchDealId = watch('dealId')
  const watchPrefixId = watch('prefixId')

  const [modal, setModal] = useState(false)
  const [dealOptions, setDealOptions] = useState([])
  const [prefixOptions, setPrefixOptions] = useState([])
  const [isCustomDate, setIsCustomDate] = useState(false)

  const {
    quoteForm: {setupData, quoteNo},
  } = useSelector(state => state.quotes)

  useEffect(() => {
    if (setupData) {
      reset(setupData)
    }
  }, [setupData])

  useApiResponse({
    action: getQuotePrefixes,
    enabled: true,
    label: GET_QUOTE_PREFIX,
    storePath: 'quotes.prefixList',
    onSuccess: prefixList => {
      const prefixes = prefixList.map(({id, prefix}) => ({
        key: id,
        value: id,
        text: prefix,
      }))
      setPrefixOptions(prefixes)
    },
  })

  useApiResponse({
    action: getAllDeals,
    payload: {page: 'all'},
    enabled: true,
    label: GET_ALL_DEALS,
    storePath: 'deals.getAllDealsList.deals',
    onSuccess: dealList => {
      if (dealList?.length > 0) {
        const deals = dealList.map(({name, id}) => ({
          key: id,
          value: id,
          text: name,
        }))

        setDealOptions(deals)
      } else {
        setDealOptions([{text: 'No Deals Are Available', value: false, disabled: true}])
      }
    },
  })

  useEffect(() => {
    if (watchDealId) {
      dispatch(getSingleDeals(watchDealId))
    }
  }, [watchDealId])

  useEffect(() => {
    if (!quoteId && !quoteNo && watchPrefixId) {
      dispatch(getNewQuoteNumber(watchPrefixId))
    }
  }, [watchPrefixId])

  useEffect(() => {
    if (quoteNo) {
      setValue('quoteNo', quoteNo, {shouldDirty: true, shouldValidate: true})
    }
  }, [quoteNo])

  useEffect(() => {
    quoteId && setIsCustomDate(true)
  }, [quoteId])

  return (
    <div className="stepWrap">
      <KooperGrid>
        <KooperGridColumn width={8} className="pr-5">
          <KooperCard fluid>
            <KooperCardContent>
              <KooperForm className="errorLabel">
                <h3 className="mb-0">Setup</h3>
                <p className="mt-0 mb-4 card-description">Setup your quote with basic informations</p>
                <KooperFormField required width={8}>
                  <label>Quote Name</label>
                  <Controller
                    name="name"
                    control={control}
                    render={({value, onChange}) => {
                      return (
                        <KooperFormInput
                          placeholder="Name"
                          value={value}
                          maxLength={30}
                          onChange={e => onChange(e.target.value)}
                          error={
                            errors.name && {
                              content: errors.name.message,
                            }
                          }
                        />
                      )
                    }}
                  />
                </KooperFormField>

                <KooperFormField required>
                  <div className="info-header">
                    <label className="label-class">Assign To a Deal</label>
                    <KooperTooltip
                      content="Assign a deal which is related to this quote"
                      size="mini"
                      position="right center"
                      trigger={<SvgIcon path="common/question" />}
                    />
                  </div>

                  <Controller
                    control={control}
                    name="dealId"
                    render={({value, onChange}) => {
                      return (
                        <KooperFormSelect
                          placeholder="Select Deal"
                          width={8}
                          options={dealOptions}
                          value={value}
                          onClick={() => errors.dealId && clearErrors('dealId')}
                          noResultsMessage={
                            dealOptions.length ? 'No Results Found.' : 'Please create Deals first to create quotes'
                          }
                          onChange={(e, {value}) => onChange(value)}
                          error={
                            errors.dealId && {
                              content: errors.dealId.message,
                            }
                          }
                        />
                      )
                    }}
                  />
                </KooperFormField>

                <KooperFormField required>
                  <div className="info-header">
                    <label className="label-class">Quote is Valid Until</label>
                    <KooperTooltip
                      content="Select the deadline till which this quote will be valid"
                      size="mini"
                      position="right center"
                      trigger={<SvgIcon path="common/question" className="ml-2" />}
                    />
                  </div>

                  {isCustomDate ? (
                    <>
                      <Controller
                        name="validTill"
                        render={({value, onChange}) => (
                          <KooperDatePicker
                            inputProps={{
                              component: props => <input {...props} readOnly />,
                            }}
                            value={quoteId && new Date(value)}
                            onChange={value => onChange(value.toISOString())}
                            time={false}
                            min={new Date()}
                            style={{width: '50%'}}
                          />
                        )}
                        control={control}
                      />
                      {errors.validTill && <p className="required">{errors.validTill.message}</p>}
                    </>
                  ) : (
                    <Controller
                      control={control}
                      name="validTill"
                      render={({value, onChange}) => {
                        return (
                          <KooperFormSelect
                            width={8}
                            options={[
                              ...dateOptions,
                              <KooperDropdownItem onClick={() => setIsCustomDate(true)}>
                                Set Custom Date
                              </KooperDropdownItem>,
                            ]}
                            value={value}
                            placeholder="Select Custom Date"
                            onChange={(e, {value}) => onChange(value)}
                            onClick={() => errors.validTill && clearErrors('validTill')}
                            error={
                              errors.validTill && {
                                content: errors.validTill.message,
                              }
                            }
                          />
                        )
                      }}
                    />
                  )}
                </KooperFormField>

                <KooperFormField>
                  <div className="info-header">
                    <label className="m-0">Quote ID</label>
                    <KooperTooltip
                      content="Select the ID which you would like to assign to this quote"
                      size="mini"
                      position="right center"
                      trigger={<SvgIcon path="common/question" className="ml-2" />}
                    />
                  </div>

                  <Controller
                    control={control}
                    name="prefixId"
                    render={({value, onChange}) => (
                      <KooperFormSelect
                        width={8}
                        placeholder="Quotes ID Prefix"
                        options={prefixOptions}
                        value={value}
                        search
                        clearable
                        noResultsMessage={
                          setPrefixOptions.length ? 'No prefixes found' : 'You have not created any Prefixes.'
                        }
                        onChange={(e, {value}) => onChange(value)}
                        error={
                          errors.prefixId && {
                            content: errors.prefixId.message,
                          }
                        }
                      />
                    )}
                  />
                </KooperFormField>
                <KooperFormField required width={8}>
                  <div className="info-header">
                    <label className="label-class">Quote Number</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Give your quote a unique number."
                      size="mini"
                      position="top left"
                    />
                  </div>
                  <Controller
                    control={control}
                    name="quoteNo"
                    render={({value, onChange}) => (
                      <KooperFormInput
                        maxLength={10}
                        // type="number"
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        error={
                          errors.quoteNo && {
                            content: errors.quoteNo.message,
                          }
                        }
                      />
                    )}
                  />
                </KooperFormField>
              </KooperForm>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
        <KooperGridColumn width={8} className="quotePreBox">
          <PreviewQuote watch={{setupData: watchAll}} />
        </KooperGridColumn>
      </KooperGrid>

      {modal && <CreateDealModal isModalOpen={modal} setIsModalOpen={setModal} />}
    </div>
  )
}

export default Setup
