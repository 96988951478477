/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useRef, useState} from 'react'
import {Controller} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {
  KooperGrid,
  KooperGridColumn,
  KooperCard,
  KooperCardContent,
  KooperForm,
  KooperFormField,
  KooperTooltip,
  KooperFormSelect,
  KooperLabel,
  KooperColorPicker,
  KooperFormInput,
  KooperButton,
} from 'kooper-library'
import {GET_ALL_ADDRESSESS} from 'actions/types'
import {getAllAddressess} from 'actions/settings_organisation'
import {deleteSoLogo} from 'actions/salesOrders'
import {removeDoubleQuotes} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import PreviewSalesOrder from '../Templates/PreviewSalesOrder'

const templateOptions = [
  {key: 'basic', value: 1, text: 'Basic'},
  {
    key: 'standard',
    value: 2,
    text: 'Standard',
    content: (
      <>
        Standard
        <KooperLabel className="kooper-label ml-2">Recommended</KooperLabel>
      </>
    ),
  },
  {key: 'advanced', value: 3, text: 'Advanced'},
]

function Customization(props) {
  const {control, watch, style, addressTemplate, setAddressTemplate, editId, errors} = props
  const dispatch = useDispatch()
  const inputRef = useRef()

  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)
  const [addressOptions, setAddressOptions] = useState([])

  const {
    uploadFile: {selectedFile, previewFile},
  } = useSelector(state => state.salesOrders)

  const watchColor = watch('template.color')
  const watchTemplateNo = watch('templateNo')
  const watchAll = watch()

  const {data: allAddressess = []} = useApiResponse({
    action: getAllAddressess,
    enabled: true,
    label: GET_ALL_ADDRESSESS,
    storePath: 'settingsOrganisation.allAddressess',
    onSuccess: allAddressess => {
      const list = allAddressess?.map(address => ({
        value: address.id,
        text: address.companyName,
        id: address.id,
      }))
      setAddressOptions(list)
    },
  })

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      // setSelectedFile(undefined)
      dispatch({type: 'SET_SO_SELECTED_FILE', payload: null})
    }

    const fileFormData = new FormData()
    fileFormData.append('logo', e.target.files[0])
    // setSelectedFile(e.target.files[0]);
    dispatch({type: 'SET_SO_SELECTED_FILE', payload: fileFormData})
    // setSelectedFile(e.target.files[0])
  }

  useEffect(() => {
    if (!selectedFile) {
      // setPreview(undefined)
      dispatch({type: 'SET_SO_PREVIEW_FILE', payload: null})
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    // setPreview(objectUrl)
    dispatch({type: 'SET_SO_PREVIEW_FILE', payload: objectUrl})

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  return (
    <div className="stepWrap" style={style}>
      <KooperGrid>
        <KooperGridColumn width={8}>
          <KooperCard fluid>
            <KooperCardContent>
              <h3 className="mb-0">Sales Orders Customization</h3>
              <p className="mt-0 mb-4 card-description">
                Customize sales order to make it unique and match with your brand
              </p>
              <KooperForm className="errorLabel mt-4">
                <KooperFormField>
                  <div className="info-header">
                    <label>Choose Template</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Choose a sales order template from the list"
                      size="mini"
                      position="top left"
                    />
                  </div>
                  <Controller
                    control={control}
                    name="template.templateNo"
                    render={({onChange, value}) => (
                      <KooperFormSelect
                        value={value}
                        width={7}
                        onChange={(e, {value}) => onChange(value)}
                        options={templateOptions}
                      />
                    )}
                  />
                </KooperFormField>

                <KooperFormField>
                  <div className="info-header">
                    <label>Company Logo</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Upload logo of your company"
                      size="mini"
                      position="top left"
                    />
                  </div>

                  {previewFile ? (
                    <>
                      <img
                        onClick={() => inputRef.current.click()}
                        accept="image/*"
                        src={previewFile}
                        alt="product"
                        height="200"
                        width="200"
                      />
                      <div>
                        <KooperTooltip
                          content="Delete"
                          size="mini"
                          position="top left"
                          trigger={
                            <KooperButton
                              icon
                              onClick={() => {
                                // setPreview(null)
                                // setSelectedFile(null)

                                if (editId) {
                                  dispatch(deleteSoLogo(editId))
                                }

                                dispatch({
                                  type: 'CLEAR_SO_UPLOADFILE',
                                })
                              }}
                            >
                              <SvgIcon path="common/delete" />
                            </KooperButton>
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <KooperButton basic size="small" onClick={() => inputRef.current.click()}>
                      Upload Image
                    </KooperButton>
                  )}
                </KooperFormField>

                <KooperFormField>
                  <div className="info-header">
                    <label>Brand Color</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Set the color of the sales order to match your branding"
                      size="mini"
                      position="top left"
                    />
                  </div>
                  <div
                    style={{
                      padding: 5,
                      background: watchColor,
                      borderRadius: '1px',
                      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                      display: 'inline-block',
                      cursor: 'pointer',
                    }}
                    onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                  >
                    <div
                      style={{
                        width: '50px',
                        height: '15px',
                        borderRadius: '2px',
                        background: `#${watchColor}`,
                      }}
                    />
                  </div>

                  <div
                    style={{
                      position: 'absolute',
                      zIndex: '2',
                      visibility: isColorPickerOpen ? '' : 'hidden',
                    }}
                  >
                    <div
                      style={{
                        position: 'fixed',
                        top: '0px',
                        right: '0px',
                        bottom: '0px',
                        left: '0px',
                      }}
                      onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                    />
                    <Controller
                      control={control}
                      name="template.color"
                      render={({onChange, value}) => (
                        <KooperColorPicker color={value} onChange={value => onChange(value.hex)} />
                      )}
                    />
                  </div>
                </KooperFormField>

                <KooperFormField width={14}>
                  <div className="info-header">
                    <label>Header</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Mention a text to display on header of sales order"
                      size="mini"
                      position="top left"
                    />
                  </div>
                  <Controller
                    control={control}
                    name="template.header"
                    render={({value, onChange}) => (
                      <KooperFormInput
                        maxLength={20}
                        placeholder="Enter header text"
                        value={value}
                        onChange={e => onChange(e.target.value)}
                      />
                    )}
                  />
                </KooperFormField>

                <KooperFormField width={14}>
                  <div className="info-header">
                    <label>Footer</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Mention a text to display on footer of sales order"
                      size="mini"
                      position="top left"
                    />
                  </div>
                  <Controller
                    control={control}
                    name="template.footer"
                    render={({value, onChange}) => (
                      <KooperFormInput
                        maxLength={20}
                        placeholder="Enter Footer text"
                        value={value}
                        onChange={e => onChange(e.target.value)}
                      />
                    )}
                  />
                </KooperFormField>

                <KooperFormField width={14}>
                  <div className="info-header">
                    <label className="label-class">Address</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Specify an address to display on sales order"
                      size="mini"
                      position="top left"
                    />
                  </div>
                  <Controller
                    name="template.addressId"
                    render={({onChange, value}) => (
                      <KooperFormSelect
                        fluid
                        selection
                        options={addressOptions}
                        placeholder="Address"
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                          setAddressTemplate(
                            allAddressess?.filter(({id}) => {
                              return id === value
                            })
                          )
                        }}
                        error={
                          errors?.template?.addressId && {
                            content: removeDoubleQuotes(errors?.template?.addressId?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </KooperFormField>
                <input hidden type="file" accept=".png, .jpg, .jpeg" onChange={onSelectFile} ref={inputRef} />
              </KooperForm>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
        <KooperGridColumn width={8} className="quotePreBox">
          <PreviewSalesOrder
            watch={{template: {logo: previewFile, ...watchAll}}}
            addressTemplate={addressTemplate}
            liveNumber={watchTemplateNo}
          />
        </KooperGridColumn>
      </KooperGrid>
    </div>
  )
}

export default Customization
