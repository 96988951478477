/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {FiUserPlus} from 'react-icons/fi'
import _ from 'lodash'
import {
  KooperButton,
  KooperDivider,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormSelect,
  KooperFormTextArea,
  KooperTooltip,
  KooperButtonGroup,
} from 'kooper-library'

import {CREATE_TASK_TEMPLATE, EDIT_TASK_TEMPLATE, GET_TASK_TEMPLATE, GET_USERS} from 'actions/types'

import KooperCustomDropdown from 'components/common/KooperCustomDropdown'
import UserImage from 'components/common/UserImage'
import {createTaskTemplate, editTaskTemplate, getAllTaskTemplate, getTaskTemplate} from 'actions/settings_task'
import SettingLayout from 'layouts/settingLayout'
import {priorityList, statusList} from 'constants/variables'
import {removeDoubleQuotes} from 'utils/helper'
import {templateSchema} from 'validation/Settings/productivity/template.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import {getUsers} from 'actions/settings'
import useApiResponse from 'hooks/impure/useApiResponse'
import {SETTINGS_PATH} from 'constants/path'

const TemplateForm = props => {
  const {editId, setNewTemplate} = props
  const dispatch = useDispatch()
  const defaultValue = {
    applyTo: 'me',
    assigneeId: null,
    description: '',
    duration: '',
    mailboxIds: null,
    name: '',
    priority: 'high',
    status: 'on_hold',
    subTasks: [],
  }
  const {handleSubmit, errors, control, reset, formState, setValue, clearErrors, watch} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(templateSchema),
    defaultValues: defaultValue,
  })
  const {
    fields: subTasksList,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'subTasks',
  })
  const {isDirty} = formState
  const initialSubtaskForm = {
    name: '',
    assigneeId: null,
    duration: '',
  }
  const [subTaskForm, setSubTaskForm] = useState(initialSubtaskForm)
  const [usersList, setUsersList] = useState([])
  const {taskTemplate} = useSelector(state => state.settingsTasks)

  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const taskTemplatesAddLearnMore = settingsLearnMoreUrl?.productivity?.taskTemplate?.addLearnMore
  const taskTemplatesUpdateLearnMore = settingsLearnMoreUrl?.productivity?.taskTemplate?.updateLearnMore

  const {tasksPermissions} = useUserPermissions()
  const manageTaskTemplatesPermissions = tasksPermissions?.manage_task_templates

  const {data: getUsersList = {}} = useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUsersList(list || [])
    },
  })

  const resetData = () => {
    if (editId) {
      const data = _.omit(taskTemplate, ['userId', 'id', 'createdBy', 'createdAt', 'accountId'])
      reset(_.omit(taskTemplate, ['userId', 'id', 'createdBy', 'createdAt', 'accountId', 'subTasks']))
      if (taskTemplate?.subTasks) setValue('subTasks', taskTemplate.subTasks)
    } else {
      reset(defaultValue)
    }
  }

  useEffect(() => {
    if (editId) dispatch(getTaskTemplate(editId))
  }, [])

  useEffect(() => {
    if (editId) {
      if (isDirty) {
        props.setWarningModal(true)
      } else {
        props.setWarningModal(false)
      }
    }
    return () => {
      props.setWarningModal(false)
    }
  }, [isDirty])

  useEffect(() => {
    if (successLabels.includes(GET_TASK_TEMPLATE)) {
      resetData()
    }
    if (successLabels.includes(CREATE_TASK_TEMPLATE)) {
      props.setNewTemplate(false)
      dispatch(getAllTaskTemplate())
    }
    if (successLabels.includes(EDIT_TASK_TEMPLATE)) {
      dispatch(getTaskTemplate(editId))
      props.history.push(`${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.TASK_TEMPLATES}`)
    }
  }, [successLabels])

  const handleTaskTemplate = data => {
    if (editId) {
      dispatch(editTaskTemplate(editId, data))
      props.setWarningModal(false)
    } else {
      dispatch(createTaskTemplate(data))
    }
  }

  return (
    <SettingLayout
      header={editId ? 'Edit Task Template' : 'Create Task Template'}
      headerbackbtn={{
        onClick: () => {
          if (editId) {
            props.history.push(`/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.TASK_TEMPLATES}`)
            props.setWarningModal(false)
          } else {
            setNewTemplate(false)
          }
        },
      }}
      subHeader="Create task template and save time from creating common task multiple times"
      learnMoreUrl={editId ? taskTemplatesUpdateLearnMore : taskTemplatesAddLearnMore}
      table={false}
      headerDivider
      actionButton={{
        cancel: {
          content: 'Cancel',
          onClick: () => {
            if (editId) {
              props.setWarningModal(false)
              props.history.push(`${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.TASK_TEMPLATES}`)
              resetData()
            } else {
              setNewTemplate(false)
            }
          },
        },
        success: {
          content: editId ? 'Update' : 'Create',
          loading: isLoadingData && type.includes(EDIT_TASK_TEMPLATE || CREATE_TASK_TEMPLATE),
          ...(manageTaskTemplatesPermissions && {
            onClick: handleSubmit(data => handleTaskTemplate(data)),
          }),
          disabled:
            !isDirty || (isLoadingData && (type.includes(EDIT_TASK_TEMPLATE) || type.includes(CREATE_TASK_TEMPLATE))),
        },
      }}
      lockRole={!manageTaskTemplatesPermissions}
    >
      <KooperForm className="errorLabel">
        <KooperFormGroup>
          <KooperFormField required width={8}>
            <div className="info-header">
              <label>Name</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Specify a task name"
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              control={control}
              name="name"
              render={({value, onChange, onBlur}) => (
                <KooperFormInput
                  maxLength={20}
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  placeholder="Name..."
                  onBlur={onBlur}
                  error={
                    errors.name && {
                      content: errors.name.message,
                    }
                  }
                />
              )}
            />
          </KooperFormField>
        </KooperFormGroup>

        <KooperFormGroup>
          <KooperFormField width={8}>
            <div className="info-header">
              <label>Description</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Specify a brief description"
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              control={control}
              name="description"
              render={({value, onChange, onBlur}) => (
                <KooperFormTextArea
                  maxLength={200}
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  placeholder="Type your description here..."
                  onBlur={onBlur}
                  error={
                    errors.description && {
                      content: errors.description.message,
                    }
                  }
                />
              )}
            />
          </KooperFormField>
        </KooperFormGroup>

        <KooperFormGroup widths="equal">
          <KooperFormField required>
            <div className="info-header">
              <label>Status</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Set status of task"
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              control={control}
              name="status"
              render={({value, onChange}) => (
                <KooperFormSelect
                  clearable
                  fluid
                  options={statusList}
                  placeholder="Select Status"
                  onChange={(e, {value}) => onChange(value)}
                  onClick={() => errors.status && clearErrors('status')}
                  value={value}
                  error={
                    errors.status && {
                      content: removeDoubleQuotes(errors.status.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required>
            <div className="info-header">
              <label>Priority</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Set priority of task"
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              control={control}
              name="priority"
              render={({value, onChange}) => (
                <KooperFormSelect
                  clearable
                  options={priorityList}
                  fluid
                  placeholder="Select Priority"
                  onChange={(e, {value}) => onChange(value)}
                  onClick={() => errors.priority && clearErrors('priority')}
                  value={value}
                  error={
                    errors.priority && {
                      content: errors.priority.message,
                    }
                  }
                />
              )}
            />
          </KooperFormField>
        </KooperFormGroup>

        <KooperFormGroup widths="equal">
          <KooperFormField required>
            <div className="info-header">
              <label>Duration</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Set task deadline"
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              control={control}
              name="duration"
              render={({value, onChange, onBlur}) => (
                <KooperFormInput
                  value={value}
                  onChange={e => e.target.value.length < 4 && onChange(e.target.value)}
                  placeholder="Duration in days"
                  onBlur={onBlur}
                  error={
                    errors.duration && {
                      content: removeDoubleQuotes(errors.duration.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required>
            <div className="info-header">
              <label>Assign to</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Assign task to user"
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              control={control}
              name="assigneeId"
              render={({value, onChange}) => (
                <KooperFormSelect
                  clearable
                  fluid
                  options={usersList}
                  placeholder="Select Assignee"
                  onChange={(e, {value}) => onChange(value)}
                  onClick={() => errors.assigneeId && clearErrors('assigneeId')}
                  value={value}
                  error={
                    errors.assigneeId && {
                      content: removeDoubleQuotes(errors.assigneeId.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>
        </KooperFormGroup>

        <KooperFormGroup>
          <KooperFormField required width={8}>
            <div className="info-header">
              <label>Available to</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Set to whom task is available"
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              control={control}
              name="applyTo"
              render={({value, onChange}) => (
                <KooperFormSelect
                  clearable
                  options={[
                    {value: 'me', key: 'me', text: 'Me'},
                    {value: 'all', key: 'all', text: 'All'},
                  ]}
                  onChange={(e, {value}) => onChange(value)}
                  value={value}
                  error={
                    errors.applyTo && {
                      content: errors.applyTo.message,
                    }
                  }
                />
              )}
            />
          </KooperFormField>
        </KooperFormGroup>

        <div className="subHeaderForm">
          <h5 className="mt-5 mb-0">Sub Task</h5>
          <p className="kooper-lead mb-4">
            Add subtask to the main task with an assignee to make the overall execution of the task rapid and effective
          </p>
          <KooperDivider />

          <div className="subTaskView">
            {subTasksList?.map(({id, name, assigneeId, duration}, index) => {
              const watchAssignee = watch(`subTasks[${index}].assigneeId`)
              const getAssignee = usersList?.find(user => (user.value === watchAssignee ? user.text : null))
              return (
                <>
                  {/* <div className="subTaskViewBody" key={id}> */}
                  <KooperFormGroup>
                    <KooperFormField width={7}>
                      <Controller
                        control={control}
                        name={`subTasks[${index}].name`}
                        defaultValue={name}
                        render={({value, onChange, onBlur}) => (
                          <KooperFormInput
                            maxLength={30}
                            placeholder="Enter the name of Sub-task"
                            value={value}
                            onChange={e => onChange(e.target.value)}
                            onBlur={onBlur}
                            error={
                              errors.subTasks &&
                              errors.subTasks[index]?.name && {
                                content: errors.subTasks[index].name.message,
                              }
                            }
                          />
                        )}
                      />
                    </KooperFormField>

                    <KooperFormField width={7}>
                      <Controller
                        control={control}
                        name={`subTasks[${index}].duration`}
                        defaultValue={duration}
                        render={({value, onChange, onBlur}) => (
                          <KooperFormInput
                            value={value}
                            className="m-0 mr-4"
                            onChange={e => onChange(e.target.value)}
                            onBlur={onBlur}
                            placeholder="Subtask duration"
                            error={
                              errors.subTasks &&
                              errors.subTasks[index]?.duration && {
                                content: errors.subTasks[index].duration.message,
                              }
                            }
                          />
                        )}
                      />
                    </KooperFormField>

                    <KooperFormField width={2}>
                      <KooperButtonGroup>
                        <KooperTooltip
                          content={watchAssignee ? getAssignee?.text : 'Add'}
                          size="mini"
                          position="top left"
                          trigger={
                            <KooperButton icon className="mr-3" style={{fontSize: '0rem'}}>
                              <Controller
                                control={control}
                                name={`subTasks[${index}].assigneeId`}
                                defaultValue={assigneeId}
                                render={({value, onChange}) => (
                                  <KooperCustomDropdown
                                    options={[
                                      {
                                        key: 'unass',
                                        value: null,
                                        text: 'Unassign',
                                      },
                                      ...usersList,
                                    ]}
                                    dropdownProps={{
                                      pointing: 'right',
                                      item: true,
                                      icon: value ? (
                                        <UserImage
                                          profile={getUsersList?.users?.find(user => (user.id === value ? user : null))}
                                        />
                                      ) : (
                                        <FiUserPlus />
                                      ),
                                    }}
                                    menu={{scrolling: true}}
                                    input={{
                                      icon: 'search',
                                      iconPosition: 'left',
                                      className: 'search',
                                    }}
                                    activeIndicator
                                    header="Assignee"
                                    value={value}
                                    onClick={value => onChange(value)}
                                  />
                                )}
                              />
                            </KooperButton>
                          }
                        />
                        <KooperTooltip
                          content="Remove"
                          size="mini"
                          position="top left"
                          trigger={
                            <KooperButton icon onClick={() => remove(index)}>
                              <SvgIcon path="common/delete" />
                            </KooperButton>
                          }
                        />
                      </KooperButtonGroup>
                    </KooperFormField>
                  </KooperFormGroup>
                  {/* </div> */}
                </>
              )
            })}
          </div>

          <div className="addTaskView">
            <KooperForm>
              <KooperFormGroup>
                <KooperFormField width={7}>
                  <KooperFormInput
                    width={16}
                    maxLength={30}
                    value={subTaskForm.name}
                    label="Name"
                    onChange={(e, {value}) =>
                      setSubTaskForm(subTaskForm => ({
                        ...subTaskForm,
                        name: value,
                      }))
                    }
                  />
                </KooperFormField>

                <KooperFormInput
                  width={2}
                  value={subTaskForm.duration}
                  label="Duration"
                  type="number"
                  onChange={(e, {value}) =>
                    setSubTaskForm(subTaskForm => ({
                      ...subTaskForm,
                      duration: value,
                    }))
                  }
                />
                <KooperFormSelect
                  width={4}
                  label="Assignee"
                  options={[
                    {
                      key: 'unass',
                      value: 'unass',
                      text: 'Unassigned',
                    },
                    ...usersList,
                  ]}
                  value={subTaskForm.assigneeId ? subTaskForm.assigneeId : 'unass'}
                  onChange={(e, {value}) =>
                    setSubTaskForm(subTaskForm => ({
                      ...subTaskForm,
                      assigneeId: value === 'unass' ? null : value,
                    }))
                  }
                />
                <KooperFormField width={3}>
                  <KooperButton
                    fluid
                    style={{marginTop: '26px'}}
                    primary
                    onClick={() => {
                      append(subTaskForm)
                      setSubTaskForm(initialSubtaskForm)
                    }}
                  >
                    Add Subtask
                  </KooperButton>
                </KooperFormField>
              </KooperFormGroup>
            </KooperForm>
          </div>
        </div>
      </KooperForm>
    </SettingLayout>
  )
}
export default TemplateForm
