import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ResponsivePie} from '@nivo/pie'
import {ResponsiveBar} from '@nivo/bar'
import {ResponsiveLine} from '@nivo/line'
import {ResponsiveFunnel} from '@nivo/funnel'
import Moment from 'moment'
import _ from 'lodash'
import {
  KooperGrid,
  KooperCard,
  KooperGridColumn,
  KooperCardContent,
  KooperDropdown,
  KooperCardMeta,
  KooperCardHeader,
  KooperTable,
  KooperTableHeader,
  KooperTableRow,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeaderCell,
  KooperTooltip,
  KooperFormField,
  KooperNoData,
} from 'kooper-library'

import {
  TOTAL_DEALS,
  OPEN_DEALS_OVER_TIME,
  WON_DEALS_OVER_TIME,
  LOST_DEALS_OVER_TIME,
  DEAL_BY_USERS,
  DEAL_BY_LOSS_REASON,
  DEAL_FUNNEL_REPORT,
  SALES_INFO,
  DEAL_ANALYTICS_BY_USERS,
} from 'actions/types'
import {
  totalDeals,
  dealStageBreakdown,
  dealStatusBreakdown,
  openDealsByStage,
  openDealsOverTime,
  wonDealsOverTime,
  lostDealsOverTime,
  dealsValueByUser,
  dealsByLossReason,
  dealsFunnelReport,
  salesInfo,
  dealAnalyticsByUsers,
} from 'actions/reports_deals'
import {get, startCase, capitalize} from 'utils/helper'
import {
  maxOfGraph,
  maxOfGraphMultipleBar,
  maxOfGraphBar,
  getGridYValuesBar,
  getGridYValues,
  getGridYValuesMultipleBar,
} from 'utils/graph'
import {getPipelines} from 'actions/deals'
import CustomLoader from 'components/common/CustomLoader'
import {AssigneeDropdown} from 'components/pages/Analytics/AnalyticsUtils'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import {DATE_RANGE_KEY} from 'constants/variables'

const DealsAnalytics = () => {
  const dispatch = useDispatch()
  const [allData, setAllData] = useState({})
  const [selectedPipeline, setSelectedPipeline] = useState()
  const [pipelineOptions, setPipelineOptions] = useState(null)
  const [funnelReportGraphData, setFunnelReportGraphData] = useState([])
  const [selectedPipelineforStage, setSelectedPipelineforStage] = useState()
  const [selectedPipelineforOpenStage, setSelectedPipelineforOpenStage] = useState()

  const {type = []} = useSelector(state => state.apiReducer)
  const {getAllPipelineData} = useSelector(state => state.deals)
  const {reportsPermissions} = useUserPermissions()
  const dealsReportsPermissions = reportsPermissions?.deals_reports

  const diff = Moment(get(['persistDateRangeEndDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))).diff(
    Moment(get(['persistDateRangeStartDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))),
    'days'
  )

  // Actions to dispatch in Date function
  const actionsToDispatch = useCallback(
    data => {
      dispatch(totalDeals(data))
      dispatch(dealStatusBreakdown(data))
      dispatch(openDealsOverTime(data))
      dispatch(wonDealsOverTime(data))
      dispatch(lostDealsOverTime(data))
      dispatch(dealsValueByUser(data))
      dispatch(dealsByLossReason(data))
      dispatch(salesInfo(data))
      dispatch(dealAnalyticsByUsers(data))
      setAllData(data)
    },
    [dispatch]
  )

  useEffect(() => {
    if (selectedPipeline) {
      allData.pipelineId = selectedPipeline
      dispatch(dealsFunnelReport(allData))
    }
  }, [dealsReportsPermissions, dispatch, allData, selectedPipeline])

  useEffect(() => {
    if (dealsReportsPermissions) return
    if (selectedPipelineforStage) {
      allData.pipelineId = selectedPipelineforStage
      dispatch(dealStageBreakdown(allData))
    }
  }, [dealsReportsPermissions, dispatch, allData, selectedPipelineforStage])

  useEffect(() => {
    if (dealsReportsPermissions) return
    if (selectedPipelineforOpenStage) {
      allData.pipelineId = selectedPipelineforOpenStage
      dispatch(openDealsByStage(allData))
    }
  }, [dealsReportsPermissions, dispatch, allData, selectedPipelineforOpenStage])

  useEffect(() => {
    if (getAllPipelineData) {
      const pilelineList = getAllPipelineData.map(pipeline => ({
        key: pipeline.id,
        text: pipeline.pipeline,
        value: pipeline.id,
      }))

      setPipelineOptions(pilelineList)
      setSelectedPipeline(getAllPipelineData[0]?.id)
      setSelectedPipelineforStage(getAllPipelineData[0].id)
      setSelectedPipelineforOpenStage(getAllPipelineData[0].id)
    }
  }, [getAllPipelineData])

  useEffect(() => {
    if (dealsReportsPermissions) {
      dispatch(getPipelines())
    }
  }, [dispatch, dealsReportsPermissions])

  const {
    dealsFunnelReportData,
    totalDealsData = [],
    openDealsByStagesData = [],
    openDealsOverTimeData = [],
    wonDealsOverTimeData = [],
    lostDealsOverTimeData = [],
    dealStageBreakdownData = [],
    dealStatusBreakdownData = [],
    dealsByUsersData = [],
    dealsByLossReasonData = [],
    salesInfodata = [],
    dealAnalyticsByUsersData = [],
  } = useSelector(state => state.reportsDeals)
  useEffect(() => {
    if (dealsFunnelReportData) {
      const temp = dealsFunnelReportData.map(val => {
        return {
          ...val,
          id: val.stage,
          value: val.percentage,
          label: val.stage,
        }
      })
      setFunnelReportGraphData(temp)
    }
  }, [dealsFunnelReportData])

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/deals" />
            <h5>Deals Analytics</h5>
          </div>
        </div>
        {dealsReportsPermissions && (
          <div className="page-action">
            <AssigneeDropdown />
            <DateRangeGroup actionsToDispatch={actionsToDispatch} />
          </div>
        )}
      </div>
      <KooperGrid columns="equal">
        <KooperGridColumn width={8}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Total Deals</KooperCardHeader>
              <KooperTooltip
                content="Breakdown of all open and closed deals over a period of time"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(TOTAL_DEALS) && <CustomLoader />}
              {!type.includes(TOTAL_DEALS) && !totalDealsData.every(({value}) => value === 0) ? (
                <ResponsivePie
                  data={totalDealsData}
                  margin={{top: 30, right: 30, bottom: 30, left: 30}}
                  startAngle={-45}
                  innerRadius={0.5}
                  padAngle={2}
                  cornerRadius={0}
                  borderWidth={1}
                  enableArcLinkLabels={false}
                  sliceLabel={({id}) => `${id}`}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  radialLabelsSkipAngle={10}
                  radialLabelsTextXOffset={6}
                  radialLabelsTextColor="#333333"
                  radialLabelsLinkOffset={0}
                  radialLabelsLinkDiagonalLength={7}
                  radialLabelsLinkHorizontalLength={13}
                  radialLabelsLinkStrokeWidth={1}
                  radialLabelsLinkColor={{from: 'color'}}
                  slicesLabelsSkipAngle={10}
                  slicesLabelsTextColor="#333333"
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                  tooltip={({
                    datum: {
                      data: {id, value, percentage},
                    },
                  }) => (
                    <>
                      <strong>
                        {id}: {value} ({percentage}%)
                      </strong>
                    </>
                  )}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 30,
                      itemsSpacing: 5,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 20,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                !type.includes(TOTAL_DEALS) && <KooperNoData type="chart" noDataText="No Data Available" />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
        <KooperGridColumn width={8}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Open Deals By Stage</KooperCardHeader>
              <KooperTooltip
                content="Graphical representation of open deals based on stages"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
              <KooperCardMeta>
                <KooperFormField width={5} style={{position: 'absolute', right: '20px', top: '20px'}}>
                  <KooperTooltip
                    position="top center"
                    size="mini"
                    content="Pipeline"
                    trigger={
                      <KooperDropdown
                        selection
                        placeholder="Select pipeline"
                        options={pipelineOptions}
                        value={selectedPipelineforOpenStage}
                        onChange={(e, {value}) => {
                          setSelectedPipelineforOpenStage(value)
                        }}
                      />
                    }
                  />
                </KooperFormField>
              </KooperCardMeta>
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              <ResponsiveBar
                data={openDealsByStagesData}
                // keys={'value'}
                indexBy="stage"
                margin={{top: 27, right: 27, bottom: 60, left: 60}}
                padding={0.3}
                // colors={(d) => d.data[`${d.id}Color`]}
                colors={{scheme: 'nivo'}}
                enableLabel
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 1.6]],
                }}
                minValue={0}
                maxValue={maxOfGraphBar(openDealsByStagesData, 'value')}
                gridYValues={getGridYValuesBar(openDealsByStagesData, 'value')}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Stages',
                  legendPosition: 'middle',
                  legendOffset: 40,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  tickValues: getGridYValuesBar(openDealsByStagesData, 'value'),
                  legend: 'Value',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: 'color',
                  modifiers: [['darker', 1.6]],
                }}
                legends={[
                  {
                    anchor: 'top',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: -30,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 73,
                    itemHeight: 23,
                    itemOpacity: 0.75,
                    symbolSize: 10,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemTextColor: '#000',
                        },
                      },
                    ],
                  },
                ]}
                animate
                motionStiffness={90}
                motionDamping={15}
              />
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Open Deals Over Time</KooperCardHeader>
              <KooperTooltip
                content="Graphically represents all open deals based on the time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(OPEN_DEALS_OVER_TIME) && <CustomLoader />}
              {!type.includes(OPEN_DEALS_OVER_TIME) &&
              !openDealsOverTimeData?.map(({data}) => data.every(({y}) => y === 0)).includes(false) ? (
                <KooperNoData type="chart" noDataText="No Data Available" />
              ) : (
                <ResponsiveLine
                  data={openDealsOverTimeData}
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  xScale={{type: 'point'}}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: maxOfGraph(openDealsOverTimeData),
                    stacked: false,
                    reverse: false,
                  }}
                  enableGridX={false}
                  curve="monotoneX"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    format: tick => {
                      if (diff > 0 && diff < 17) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Time',
                    legendOffset: 40,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValues(openDealsOverTimeData),
                    legend: 'Lead Count',
                    legendOffset: -40,
                    legendPosition: 'middle',
                  }}
                  // colors={(d) => d.color}
                  lineWidth={3}
                  pointSize={5}
                  pointColor={{theme: 'grid.line.stroke'}}
                  pointBorderWidth={2}
                  pointBorderColor={{from: 'serieColor'}}
                  pointLabel="y"
                  pointLabelYOffset={-10}
                  useMesh
                  enableSlices="x"
                  gridYValues={getGridYValues(openDealsOverTimeData)}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Won Deals Over Time</KooperCardHeader>
              <KooperTooltip
                content="Graphically represents all won deals based on the time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(WON_DEALS_OVER_TIME) && <CustomLoader />}
              {!type.includes(WON_DEALS_OVER_TIME) &&
              !wonDealsOverTimeData?.map(({data}) => data.every(({y}) => y === 0)).includes(false) ? (
                <KooperNoData type="chart" noDataText="No Data Available" />
              ) : (
                <ResponsiveLine
                  data={wonDealsOverTimeData}
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  xScale={{type: 'point'}}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: maxOfGraph(wonDealsOverTimeData),
                    stacked: false,
                    reverse: false,
                  }}
                  enableGridX={false}
                  curve="monotoneX"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    format: tick => {
                      if (diff > 0 && diff < 17) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Time',
                    legendOffset: 40,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValues(wonDealsOverTimeData),
                    legend: 'Lead Count',
                    legendOffset: -40,
                    legendPosition: 'middle',
                  }}
                  // colors={(d) => d.color}
                  lineWidth={3}
                  pointSize={5}
                  pointColor={{theme: 'grid.line.stroke'}}
                  pointBorderWidth={2}
                  pointBorderColor={{from: 'serieColor'}}
                  pointLabel="y"
                  pointLabelYOffset={-10}
                  useMesh
                  enableSlices="x"
                  gridYValues={getGridYValues(wonDealsOverTimeData)}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Lost Deals Over Time</KooperCardHeader>
              <KooperTooltip
                content="Graphically represents all lost deals based on the time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(LOST_DEALS_OVER_TIME) && <CustomLoader />}
              {!type.includes(LOST_DEALS_OVER_TIME) &&
              !lostDealsOverTimeData?.map(({data}) => data.every(({y}) => y === 0)).includes(false) ? (
                <KooperNoData type="chart" noDataText="No Data Available" />
              ) : (
                <ResponsiveLine
                  data={lostDealsOverTimeData}
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  xScale={{type: 'point'}}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: maxOfGraph(lostDealsOverTimeData),
                    stacked: false,
                    reverse: false,
                  }}
                  enableGridX={false}
                  curve="monotoneX"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    format: tick => {
                      if (diff > 0 && diff < 17) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Time',
                    legendOffset: 40,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValues(lostDealsOverTimeData),
                    legend: 'Lead Count',
                    legendOffset: -40,
                    legendPosition: 'middle',
                  }}
                  // colors={(d) => d.color}
                  lineWidth={3}
                  pointSize={5}
                  pointColor={{theme: 'grid.line.stroke'}}
                  pointBorderWidth={2}
                  pointBorderColor={{from: 'serieColor'}}
                  pointLabel="y"
                  pointLabelYOffset={-10}
                  useMesh
                  enableSlices="x"
                  gridYValues={getGridYValues(lostDealsOverTimeData)}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
      <KooperGrid>
        <KooperGridColumn width={16} className="pb-0 ">
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Sales</KooperCardHeader>
              <KooperTooltip
                content="Graphically represents all sales based on their status over a specific time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(SALES_INFO) && <CustomLoader />}
              {!type.includes(SALES_INFO) && salesInfodata.every(({value}) => value === 0) ? (
                <KooperNoData type="chart" noDataText="No Data Available" />
              ) : (
                <ResponsiveBar
                  data={salesInfodata}
                  keys={['Abandoned', 'Lost', 'Open', 'Won']}
                  indexBy="time"
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  padding={0.3}
                  // colors={(d) => d.data[`${d.id}Color`]}
                  colors={{scheme: 'nivo'}}
                  enableLabel
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  minValue={0}
                  maxValue={maxOfGraphMultipleBar(salesInfodata, ['Abandoned', 'Lost', 'Open', 'Won'])}
                  gridYValues={getGridYValuesMultipleBar(salesInfodata, ['Abandoned', 'Lost', 'Open', 'Won'])}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: 40,
                    format: tick => {
                      if (diff > 0 && diff < 22) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesMultipleBar(salesInfodata, ['Abandoned', 'Lost', 'Open', 'Won']),
                    legend: 'Value',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 78,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
      <KooperGrid>
        <KooperGridColumn width={16} className="pb-0 ">
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Deal By Users</KooperCardHeader>
              <KooperTooltip
                content="Graphically represents all deals according to the status based  on users over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(DEAL_ANALYTICS_BY_USERS) && <CustomLoader />}
              {!type.includes(DEAL_ANALYTICS_BY_USERS) && dealAnalyticsByUsersData.every(({value}) => value === 0) ? (
                <KooperNoData type="chart" noDataText="No Data Available" />
              ) : (
                <ResponsiveBar
                  data={dealAnalyticsByUsersData}
                  keys={['abandoned', 'lost', 'won']}
                  indexBy="name"
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  padding={0.3}
                  // colors={(d) => d.data[`${d.id}Color`]}
                  colors={{scheme: 'nivo'}}
                  enableLabel
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  valueScale={{type: 'linear'}}
                  indexScale={{type: 'band', round: true}}
                  minValue={0}
                  maxValue={maxOfGraphMultipleBar(dealAnalyticsByUsersData, ['abandoned', 'lost', 'won'])}
                  gridYValues={getGridYValuesMultipleBar(dealAnalyticsByUsersData, ['abandoned', 'lost', 'won'])}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Name',
                    legendPosition: 'middle',
                    legendOffset: 40,
                    format: tick => {
                      if (diff > 0 && diff < 22) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesMultipleBar(dealAnalyticsByUsersData, ['abandoned', 'lost', 'won']),
                    legend: 'Value',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 78,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Deal Breakdown By Stage</KooperCardHeader>
              <KooperTooltip
                content="Breakdown of all stages of current deals"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
              <KooperCardMeta>
                <KooperFormField width={5} style={{position: 'absolute', right: '20px', top: '20px'}}>
                  <KooperTooltip
                    position="top center"
                    size="mini"
                    content="Pipeline"
                    trigger={
                      <KooperDropdown
                        selection
                        placeholder="Select pipeline"
                        options={pipelineOptions}
                        value={selectedPipelineforStage}
                        onChange={(e, {value}) => {
                          setSelectedPipelineforStage(value)
                        }}
                      />
                    }
                  />
                </KooperFormField>
              </KooperCardMeta>
            </KooperCardContent>
            <KooperCardContent className="pt-0">
              <KooperTable responsive basic>
                <KooperTableHeader>
                  <KooperTableRow>
                    <KooperTableHeaderCell>Stage</KooperTableHeaderCell>
                    <KooperTableHeaderCell>% of Total</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Deals</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Average Duration</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Total Value</KooperTableHeaderCell>
                  </KooperTableRow>
                </KooperTableHeader>
                <KooperTableBody>
                  {dealStageBreakdownData && dealStageBreakdownData.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="emptyValue">
                        <KooperNoData type="table" noDataText="No Data Available" />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {dealStageBreakdownData.map((data, index) => (
                        <KooperTableRow key={index}>
                          <KooperTableCell>{startCase(data.stage)}</KooperTableCell>
                          <KooperTableCell>{data.percentage}</KooperTableCell>
                          <KooperTableCell>{data.deals}</KooperTableCell>
                          <KooperTableCell>{data.stageDuration}</KooperTableCell>
                          <KooperTableCell>{data.value}</KooperTableCell>
                        </KooperTableRow>
                      ))}
                    </>
                  )}
                </KooperTableBody>
              </KooperTable>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Deal Breakdown By Status</KooperCardHeader>
              <KooperTooltip
                content="List of all deals based on status over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0">
              <KooperTable responsive basic>
                <KooperTableHeader>
                  <KooperTableRow>
                    <KooperTableHeaderCell>Status</KooperTableHeaderCell>
                    <KooperTableHeaderCell>% of total</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Deals</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Average Duration</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Total Value</KooperTableHeaderCell>
                  </KooperTableRow>
                </KooperTableHeader>
                <KooperTableBody>
                  {dealStatusBreakdownData && dealStatusBreakdownData.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="emptyValue">
                        <KooperNoData type="table" noDataText="No Data Available" />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {dealStatusBreakdownData.map((data, index) => (
                        <KooperTableRow key={index}>
                          <KooperTableCell>{capitalize(data.status)}</KooperTableCell>
                          <KooperTableCell>{data.percentage}</KooperTableCell>
                          <KooperTableCell>{data.deals}</KooperTableCell>
                          <KooperTableCell>{data.avgDuration}</KooperTableCell>
                          <KooperTableCell>{data.value}</KooperTableCell>
                        </KooperTableRow>
                      ))}
                    </>
                  )}
                </KooperTableBody>
              </KooperTable>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
      <KooperGrid>
        <KooperGridColumn width={8}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Deals By User</KooperCardHeader>
              <KooperTooltip
                content="Graphically represents all deals based on users over a period of time"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(DEAL_BY_USERS) && <CustomLoader />}
              {!type.includes(DEAL_BY_USERS) && dealsByUsersData.every(({value}) => value === 0) ? (
                <KooperNoData type="chart" noDataText="No Data Available" />
              ) : (
                <ResponsiveBar
                  data={dealsByUsersData}
                  // keys={'value'}
                  indexBy="name"
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  padding={0.3}
                  // colors={(d) => d.data[`${d.id}Color`]}
                  colors={{scheme: 'nivo'}}
                  enableLabel
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  minValue={0}
                  maxValue={maxOfGraphBar(dealsByUsersData, 'value')}
                  gridYValues={getGridYValuesBar(dealsByUsersData, 'value')}
                  axisTop={null}
                  axisRight={null}
                  tooltip={({value, data: {count}}) => (
                    <div className="customTooltip">
                      <span />
                      <strong className="pl-2">
                        value : ${value} , count : {count}
                      </strong>
                    </div>
                  )}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'name',
                    legendPosition: 'middle',
                    legendOffset: 40,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesBar(dealsByUsersData, 'value'),
                    legend: 'Value',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
        <KooperGridColumn width={8}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Deals By Loss Reason</KooperCardHeader>
              <KooperTooltip
                content="Graphically represents all deals based on loss reasons over a period of time"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(DEAL_BY_LOSS_REASON) && <CustomLoader />}
              {!type.includes(DEAL_BY_LOSS_REASON) && dealsByLossReasonData.every(({value}) => value === 0) ? (
                <KooperNoData type="chart" noDataText="No Data Available" />
              ) : (
                <ResponsiveBar
                  data={dealsByLossReasonData.map(data => ({
                    ...data,
                    reason: startCase(data.reason),
                  }))}
                  // keys={'value'}
                  indexBy="reason"
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  padding={0.3}
                  // colors={(d) => d.data[`${d.id}Color`]}
                  colors={{scheme: 'nivo'}}
                  enableLabel
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  minValue={0}
                  maxValue={maxOfGraphBar(dealsByLossReasonData, 'value')}
                  gridYValues={getGridYValuesBar(dealsByLossReasonData, 'value')}
                  axisTop={null}
                  axisRight={null}
                  tooltip={({value, data: {count}}) => (
                    <div className="customTooltip">
                      <span />
                      <strong className="pl-2">
                        value : ${value} , count : {count}
                      </strong>
                    </div>
                  )}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'name',
                    legendPosition: 'middle',
                    legendOffset: 40,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesBar(dealsByLossReasonData, 'value'),
                    legend: 'Value',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Funnel Report</KooperCardHeader>
              <KooperTooltip
                content="Representation on funnel report over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
              <KooperCardMeta>
                <KooperFormField
                  width={5}
                  style={{
                    position: 'absolute',
                    right: '20px',
                    top: '20px',
                  }}
                >
                  <KooperTooltip
                    position="top center"
                    size="mini"
                    content="Pipeline"
                    trigger={
                      <KooperDropdown
                        selection
                        placeholder="Select pipeline"
                        options={pipelineOptions}
                        value={selectedPipeline}
                        onChange={(e, {value}) => {
                          setSelectedPipeline(value)
                        }}
                      />
                    }
                  />
                </KooperFormField>
              </KooperCardMeta>
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(DEAL_FUNNEL_REPORT) && <CustomLoader />}
              {!type.includes(DEAL_FUNNEL_REPORT) && (
                <ResponsiveFunnel
                  data={
                    funnelReportGraphData.every(({value}) => value === 0)
                      ? [
                          {
                            value: 0,
                          },
                        ]
                      : funnelReportGraphData
                  }
                  margin={{top: 20, right: 20, bottom: 20, left: 20}}
                  valueFormat=">-.4s"
                  colors={{scheme: 'spectral'}}
                  borderWidth={20}
                  labelColor={{
                    from: 'color',
                    modifiers: [['darker', 3]],
                  }}
                  beforeSeparatorLength={100}
                  beforeSeparatorOffset={20}
                  afterSeparatorLength={100}
                  afterSeparatorOffset={20}
                  currentPartSizeExtension={10}
                  currentBorderWidth={40}
                  motionConfig="wobbly"
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
    </div>
  )
}
export default DealsAnalytics
