import SvgIcon from 'components/common/SvgIcon'
import {ONE_ON_ONE_INTERVALS} from 'constants/Teams/Goals/one-on-one'
import {
  KooperButton,
  KooperForm,
  KooperFormDropdown,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperHeader,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'

const ScheduleOneOnOneModal = ({isModalOpen, setIsModalOpen}) => {
  return (
    <KooperModal
      size="small"
      open={isModalOpen}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={() => setIsModalOpen(!isModalOpen)}
    >
      <KooperModalHeader>Schedule your 1-on-1s</KooperModalHeader>
      <KooperModalContent scrolling>
        <KooperHeader as="h4">
          You and test-survey-02 will get a reminder 3 days before the meeting to collaborate on the agenda.
        </KooperHeader>
        <KooperForm>
          <KooperFormField>
            <div className="info-header">
              <label>Date</label>
              <KooperTooltip
                content="Date"
                trigger={<SvgIcon path="common/question" />}
                size="mini"
                position="right center"
              />
              <KooperButton className="delete-scheduling">Delete Scheduling</KooperButton>
            </div>
            <KooperFormInput type="date" />
          </KooperFormField>
          <KooperFormGroup>
            <KooperFormField width={8}>
              <div className="info-header">
                <label>Start time</label>
                <KooperTooltip
                  content="Start time"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="right center"
                />
              </div>
              <KooperFormInput type="time" />
            </KooperFormField>
            <KooperFormField width={8}>
              <div className="info-header">
                <label>Duration</label>
                <KooperTooltip
                  content="Duration"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="right center"
                />
              </div>
              <KooperFormDropdown
                placeholder="select color"
                selection
                options={[
                  {key: 15, value: 15, text: '15 minutes'},
                  {key: 30, value: 30, text: '30 minutes'},
                  {key: 45, value: 45, text: '45 minutes'},
                  {key: 60, value: 60, text: '60 minutes'},
                  {key: 75, value: 75, text: '75 minutes'},
                  {key: 90, value: 90, text: '90 minutes'},
                  {key: 105, value: 105, text: '105 minutes'},
                  {key: 120, value: 120, text: '120 minutes'},
                ]}
                // onChange={(e, {value}) => onChange(value)}
                value={15}
              />
            </KooperFormField>
          </KooperFormGroup>
          <KooperFormGroup>
            <KooperFormField width={8}>
              <div className="info-header">
                <label>Recurrence</label>
                <KooperTooltip
                  content="Recurrence"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="right center"
                />
              </div>
              <KooperFormDropdown
                selection
                options={ONE_ON_ONE_INTERVALS}
                // onChange={(e, {value}) => onChange(value)}
                value={0}
              />
            </KooperFormField>
          </KooperFormGroup>
        </KooperForm>
      </KooperModalContent>

      <KooperModalActions>
        <KooperButton primary onClick={() => setIsModalOpen(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary disabled>
          Save
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default ScheduleOneOnOneModal
