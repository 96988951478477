export const surveyFreequency = [
  {
    key: 'none',
    value: 'none',
    text: 'None',
  },
  {
    key: 'weekly',
    value: 'weekly',
    text: 'Weekly',
  },
  {
    key: 'biweekly',
    value: 'biweekly',
    text: 'Every Two Weeks',
  },
  {
    key: 'monthly',
    value: 'monthly',
    text: 'Monthly',
  },
  {
    key: 'quarterly',
    value: 'quarterly',
    text: 'Quarterly',
  },
  {
    key: 'every6months',
    value: 'every6months',
    text: 'Every 6 Months',
  },
  {
    key: 'every12months',
    value: 'every12months',
    text: 'Every 12 Months',
  },
]
