/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useState, useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller} from 'react-hook-form'
import {
  KooperAttachment,
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCheckbox,
  KooperColorPicker,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormSelect,
  KooperGrid,
  KooperGridColumn,
  KooperLabel,
  KooperTooltip,
} from 'kooper-library'
import {deleteLogo} from 'actions/quotes'
import PreviewQuote from 'components/pages/Sales/Quotes/Templates/PreviewQuote'
import SvgIcon from 'components/common/SvgIcon'

const templateOptions = [
  {key: 'basic', value: 1, text: 'Basic'},
  {
    key: 'standard',
    value: 2,
    text: 'Standard',
    content: (
      <>
        Standard
        <KooperLabel className="kooper-label ml-2">Recommended</KooperLabel>
      </>
    ),
  },
  {key: 'advanced', value: 3, text: 'Advanced'},
]

function Customization({formData, quoteId}) {
  const {control, watch, reset, setValue} = formData

  const dispatch = useDispatch()

  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)
  const [termsAndConditions, setTermsAndConditions] = useState(true)
  const [paymentProcess, setPaymentProcess] = useState(true)

  // const [preview, setPreview] = previewState;
  // const [selectedFile, setSelectedFile] = selectedFileState;

  const inputRef = useRef()

  const watchColor = watch('color')
  const watchTemplateNo = watch('templateNo')
  const watchAll = watch()

  const {
    quoteForm: {template},
    quoteDetails: {template: {id, accountId, quoteId: tempQuoteId, ...templateData} = {}},
    uploadFile: {selectedFile, previewFile},
  } = useSelector(state => state.quotes)

  useEffect(() => {
    if (template) {
      reset(template)
    }
  }, [template])

  useEffect(() => {
    if (!selectedFile) {
      // setPreview(undefined);
      dispatch({type: 'SET_PREVIEW_FILE', payload: null})
      return
    }

    if (typeof selectedFile === 'string') return

    const objectUrl = URL.createObjectURL(selectedFile)
    // setPreview(objectUrl);
    dispatch({type: 'SET_PREVIEW_FILE', payload: objectUrl})

    // !alert: don't enable unmount functionalilty yet, images would not work across components
    // return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile])

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      // setSelectedFile(undefined);
      dispatch({type: 'SET_SELECTED_FILE', payload: null})
      return
    }

    const fileFormData = new FormData()
    fileFormData.append('logo', e.target.files[0])
    // setSelectedFile(e.target.files[0]);
    dispatch({type: 'SET_SELECTED_FILE', payload: fileFormData})
  }

  return (
    <div className="stepWrap">
      <KooperGrid>
        <KooperGridColumn width={8}>
          <KooperCard fluid style={{overflowY: 'scroll'}}>
            <KooperCardContent>
              <h3 className="mb-0">Quote Customization</h3>
              <p className="mt-0 mb-4 card-description">
                Customize the quote to make it unique and more business-friendly
              </p>
              <KooperForm className="mt-4">
                <KooperFormField>
                  <div className="info-header">
                    <label>Choose Template</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content=" Choose a quote template from the drop-down list"
                      size="mini"
                      position="top left"
                    />
                  </div>
                  <Controller
                    control={control}
                    name="templateNo"
                    render={({onChange, value}) => (
                      <KooperFormSelect
                        value={value}
                        width={7}
                        onChange={(e, {value}) => onChange(value)}
                        options={templateOptions}
                      />
                    )}
                  />
                </KooperFormField>

                <KooperFormField className="custom-upload-btn">
                  <div className="info-header">
                    <label>Company Logo</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Select a company logo which will be present on the top of your quote"
                      size="mini"
                      position="top left"
                    />
                  </div>

                  <input hidden type="file" onInput={onSelectFile} ref={inputRef} accept="image/*" />

                  {previewFile ? (
                    <>
                      <img
                        onClick={() => inputRef.current.click()}
                        src={previewFile}
                        alt="product"
                        height="200"
                        width="200"
                      />
                      <div>
                        <KooperTooltip
                          content="Delete"
                          size="mini"
                          position="top left"
                          trigger={
                            <KooperButton
                              icon
                              onClick={() => {
                                // setPreview(null);
                                // setSelectedFile(null);

                                if (quoteId) {
                                  dispatch(deleteLogo(quoteId))
                                }

                                dispatch({
                                  type: 'CLEAR_QUOTE_UPLOADFILE',
                                })
                              }}
                            >
                              <SvgIcon path="common/delete" />
                            </KooperButton>
                          }
                        />
                      </div>
                    </>
                  ) : (
                    // <KooperButton basic size="small" onClick={() => inputRef.current.click()}>
                    //   Upload Company Logo
                    // </KooperButton>
                    <KooperAttachment
                      content={
                        <>
                          <SvgIcon path="common/attachment" /> Upload Company Logo
                        </>
                      }
                      accept=".png, .jpg, .jpeg"
                      onClick={() => inputRef.current.click()}
                    />
                  )}
                </KooperFormField>
                <KooperFormField>
                  <div className="info-header">
                    <label>Brand Color</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Select a color of your quote to match with your brand"
                      size="mini"
                      position="top left"
                    />
                  </div>
                  <div
                    style={{
                      padding: 5,
                      background: watchColor,
                      borderRadius: '1px',
                      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                      display: 'inline-block',
                      cursor: 'pointer',
                    }}
                    onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                  >
                    <div
                      style={{
                        width: '50px',
                        height: '15px',
                        borderRadius: '2px',
                        background: `#${watchColor}`,
                      }}
                    />
                  </div>

                  <div
                    style={{
                      position: 'absolute',
                      zIndex: '2',
                      visibility: isColorPickerOpen ? '' : 'hidden',
                    }}
                  >
                    <div
                      style={{
                        position: 'fixed',
                        top: '0px',
                        right: '0px',
                        bottom: '0px',
                        left: '0px',
                      }}
                      onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                    />
                    <Controller
                      control={control}
                      name="color"
                      render={({onChange, value}) => (
                        <KooperColorPicker color={value} onChange={value => onChange(value.hex)} />
                      )}
                    />
                  </div>
                </KooperFormField>

                <KooperFormField width={14}>
                  <div className="info-header">
                    <label>Header</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Provide a header input for your quote"
                      size="mini"
                      position="top left"
                    />
                  </div>
                  <Controller
                    control={control}
                    name="header"
                    render={({value, onChange}) => (
                      <KooperFormInput
                        maxLength={20}
                        placeholder="Enter header text"
                        value={value}
                        onChange={e => onChange(e.target.value)}
                      />
                    )}
                  />
                </KooperFormField>

                <KooperFormField width={14}>
                  <div className="info-header">
                    <label>Footer</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Provide a footer input for your quote"
                      size="mini"
                      position="top left"
                    />
                  </div>
                  <Controller
                    control={control}
                    name="footer"
                    render={({value, onChange}) => (
                      <KooperFormInput
                        maxLength={20}
                        placeholder="Enter Footer text"
                        value={value}
                        onChange={e => onChange(e.target.value)}
                      />
                    )}
                  />
                </KooperFormField>

                <KooperFormField width={14}>
                  <label>
                    Terms & Conditions / Terms of services
                    <KooperCheckbox
                      toggle
                      style={{float: 'right'}}
                      checked={termsAndConditions}
                      onChange={(e, {checked}) => {
                        setTermsAndConditions(checked)
                        if (!checked) {
                          setValue('terms', '')
                        }
                      }}
                    />
                  </label>
                  <p className="kooper-lead">Show terms and conditions text in the quote</p>
                  <Controller
                    control={control}
                    name="terms"
                    render={({value, onChange}) => {
                      return (
                        <KooperFormInput
                          maxLength={50}
                          style={{
                            visibility: !termsAndConditions ? 'hidden' : '',
                          }}
                          placeholder="Terms text"
                          value={value}
                          onChange={e => onChange(e.target.value)}
                        />
                      )
                    }}
                  />
                </KooperFormField>

                <KooperFormField width={14}>
                  <label>
                    Payment Process
                    <KooperCheckbox
                      toggle
                      style={{float: 'right'}}
                      checked={paymentProcess}
                      onChange={(e, {checked}) => {
                        setPaymentProcess(checked)
                        if (!checked) {
                          setValue('paymentProcess', '')
                        }
                      }}
                    />
                  </label>
                  <p className="kooper-lead">Manage payment process details visibility in the quote</p>
                  <Controller
                    control={control}
                    name="paymentProcess"
                    render={({value, onChange}) => {
                      return (
                        <KooperFormInput
                          maxLength={200}
                          style={{
                            visibility: !paymentProcess ? 'hidden' : '',
                          }}
                          value={value}
                          onChange={e => onChange(e.target.value)}
                        />
                      )
                    }}
                  />
                </KooperFormField>
              </KooperForm>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>

        <KooperGridColumn width={8} className="quotePreBox">
          <PreviewQuote watch={{template: {logo: previewFile, ...watchAll}}} liveNumber={watchTemplateNo} />
        </KooperGridColumn>
      </KooperGrid>
    </div>
  )
}

export default Customization
