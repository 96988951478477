import SurveysMenu from 'navigation/sub-routes/Teams/Surveys/SurveysMenu'
import SurveysRoutes from 'navigation/sub-routes/Teams/Surveys/SurvyesRoute'

function Surveys() {
  //   const {financeAccess} = useUserPermissions()
  //   const salesOrdersAccess = financeAccess?.salesOrders

  //   if (!salesOrdersAccess) {
  //     return (
  //       <CreateFirst
  //         header="Sales Orders"
  //         buttonText="Create"
  //         tooltip={!salesOrdersAccess}
  //         tooltipContent={getPermissionTooltipContent({featureAccess: salesOrdersAccess})}
  //         imageSrc={create}
  //         subHeader="Manage sales orders for products that have been sold to customers."
  //         Feature1="Add Prefix"
  //         list1="Prefix helps to identify and search sales orders"
  //         Feature2="Customization"
  //         list2="Customize sales order according to brand"
  //         Feature3="Add Taxes"
  //         list3="Charge tax for the products on sales receipt"
  //       />
  //     )
  //   }
  return (
    <>
      <SurveysMenu />
      <SurveysRoutes />
    </>
  )
}

export default Surveys
