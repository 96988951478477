/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {KooperButton, KooperModal, KooperModalActions, KooperModalContent, KooperModalHeader} from 'kooper-library'
import SvgIcon from './SvgIcon'

const DeleteModal = props => {
  const {isLoadingData, type: reducerType, successLabels = []} = useSelector(state => state.apiReducer)
  const {
    idTobeDeleted,
    deleteAction,
    isModalOpen,
    setIsModalOpen,
    modalText,
    onDelete,
    deleteModelHeader,
    type,
    deleteButtonText,
  } = props

  const dispatch = useDispatch()

  useEffect(() => {
    if (successLabels.includes(type)) {
      setIsModalOpen(false)
    }
  }, [successLabels])
  const handleClose = e => {
    e.stopPropagation()
    setIsModalOpen(false)
  }
  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      centered={false}
      open={isModalOpen}
      onClose={handleClose}
    >
      <KooperModalHeader>{deleteModelHeader || 'Delete'}</KooperModalHeader>
      <KooperModalContent>{modalText || 'Are you sure you want to delete ?'}</KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={handleClose}>
          Cancel
        </KooperButton>
        <KooperButton
          primary
          loading={isLoadingData && reducerType.includes(type)}
          onClick={e => {
            if (onDelete) {
              onDelete()
              handleClose(e)
            } else {
              dispatch(deleteAction(idTobeDeleted))
              handleClose(e)
            }
          }}
        >
          {deleteButtonText || 'Delete'}
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default DeleteModal
