import SvgIcon from 'components/common/SvgIcon'
import React from 'react'
import {Controller} from 'react-hook-form'
import {
  KooperCard,
  KooperCardContent,
  KooperCardDescription,
  KooperCardHeader,
  KooperFormField,
  KooperGrid,
  KooperGridColumn,
  KooperIcon,
  KooperTooltip,
} from 'kooper-library'

const BudgetTypeForm = ({control, budgetTypes = []}) => {
  return (
    <KooperFormField required>
      <div className="info-header mb-2">
        <label>Select Budget Type</label>
        <KooperTooltip
          trigger={<SvgIcon path="common/question" />}
          content="Select a budget type of your project"
          size="mini"
          position="top left"
        />
      </div>
      <Controller
        name="budgetType"
        control={control}
        render={({value, onChange}) => (
          <KooperGrid columns="equal">
            {budgetTypes.map(({title, type, description}) => (
              <KooperGridColumn>
                <KooperCard
                  fluid
                  className={`project-radio-box text-center ${value === type ? 'checked' : ''}`}
                  onClick={() => onChange(type)}
                >
                  <KooperCardContent>
                    <KooperIcon name="folder" style={{fontSize: '32px'}} />
                    <KooperCardHeader>{title}</KooperCardHeader>
                    <KooperCardDescription>{description}</KooperCardDescription>
                    <div className="circle">
                      <KooperIcon name="check" />
                    </div>
                  </KooperCardContent>
                </KooperCard>
              </KooperGridColumn>
            ))}
          </KooperGrid>
        )}
      />
    </KooperFormField>
  )
}

export default BudgetTypeForm
