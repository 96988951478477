/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {joiResolver} from '@hookform/resolvers'
import {Controller, useForm} from 'react-hook-form'
import {
  KooperButton,
  KooperCheckbox,
  KooperColorPicker,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormTextArea,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'
import {removeDoubleQuotes} from 'utils/helper'
import {CREATE_TIMEOFF_TYPES, EDIT_TIMEOFF_TYPE, GET_SINGLE_TIMEOFF_TYPE} from 'actions/types'
import {createTimeOffTypes, editTimeOffTypes, getSingleTimeOffTypes, getTimeOffTypes} from 'actions/timeOff'
import {createTimeOffSettingSchema} from 'validation/Hr/timeoff.schema'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const TimeOffTypes = props => {
  const dispatch = useDispatch()
  const {open, toggleModal, editId, typeToggle} = props

  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)

  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)

  const initialCreateInfo = {
    name: '',
    color: '',
    description: '',
    noOfDays: '',
    isAccrue: true,
    approvalRequire: false,
    documentRequire: true,
    visibleToEmployees: true,
    countForAbsent: false,
  }

  const {errors, control, handleSubmit, watch, reset} = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(createTimeOffSettingSchema),
    defaultValues: initialCreateInfo,
  })

  const watchColor = watch('color')

  useEffect(() => {
    return () => {
      typeToggle('')
    }
  }, [dispatch, typeToggle])

  const {data: getSingleTimeOffTypesData = {}} = useApiResponse({
    action: getSingleTimeOffTypes,
    payload: editId,
    dependency: [editId],
    enabled: editId,
    label: GET_SINGLE_TIMEOFF_TYPE,
    storePath: 'timeOff.getSingleTimeOffTypesData',
    onSuccess: () => {
      reset({
        name: getSingleTimeOffTypesData.name,
        description: getSingleTimeOffTypesData.description,
        color: getSingleTimeOffTypesData.color,
        noOfDays: JSON.stringify(getSingleTimeOffTypesData.noOfDays),
        isAccrue: getSingleTimeOffTypesData.isAccrue,
        approvalRequire: getSingleTimeOffTypesData.approvalRequire,
        documentRequire: getSingleTimeOffTypesData.documentRequire,
        visibleToEmployees: getSingleTimeOffTypesData.visibleToEmployees,
        countForAbsent: getSingleTimeOffTypesData.countForAbsent,
      })
    },
  })

  useEffect(() => {
    if (successLabels.includes(CREATE_TIMEOFF_TYPES) || successLabels.includes(EDIT_TIMEOFF_TYPE)) {
      toggleModal(false)
      dispatch(getTimeOffTypes())
    }
  }, [successLabels, toggleModal, dispatch])

  const handleSave = data => {
    if (editId) {
      dispatch(editTimeOffTypes(editId, data))
    } else {
      dispatch(createTimeOffTypes(data))
    }
  }

  return (
    <KooperModal
      size="small"
      open={open}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={() => toggleModal(false)}
    >
      <KooperModalHeader>Create Time Off Type</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormGroup>
            <KooperFormField width={12} required>
              <label>Name</label>
              <Controller
                name="name"
                control={control}
                render={({onChange, value}) => (
                  <KooperFormInput
                    maxLength={20}
                    type="text"
                    placeholder="Name"
                    onChange={(e, {value}) => onChange(value)}
                    value={value}
                    error={
                      errors.name && {
                        content: removeDoubleQuotes(errors.name.message),
                      }
                    }
                  />
                )}
              />
            </KooperFormField>
            <KooperFormField required>
              <div className="info-header">
                <label className="label-class">Color</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="set a color for this time-off"
                  size="mini"
                  position="top left"
                />
              </div>
              <div
                style={{
                  padding: 5,
                  background: watchColor,
                  borderRadius: '1px',
                  boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                  display: 'inline-block',
                  cursor: 'pointer',
                }}
                onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
              >
                <div
                  style={{
                    width: '50px',
                    height: '15px',
                    borderRadius: '2px',
                    background: `#${watchColor}`,
                  }}
                />
              </div>

              <div
                style={{
                  position: 'absolute',
                  zIndex: '2',
                  visibility: isColorPickerOpen ? '' : 'hidden',
                }}
              >
                <div
                  style={{
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                  }}
                  onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                />
                <Controller
                  control={control}
                  name="color"
                  render={({onChange, value}) => (
                    <KooperColorPicker color={value} onChange={value => onChange(value.hex)} />
                  )}
                />
              </div>
            </KooperFormField>
          </KooperFormGroup>

          <KooperFormField width={12}>
            <label>Description</label>
            <Controller
              name="description"
              render={({onChange, value}) => (
                <KooperFormTextArea
                  maxLength={200}
                  type="text"
                  value={value}
                  placeholder="Description"
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  error={
                    errors.description && {
                      content: removeDoubleQuotes(errors.description.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>

          <KooperFormField width={5} required>
            <div className="info-header">
              <label className="label-class">No of Leaves</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="mention number of leaves in this time-off"
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              name="noOfDays"
              render={({value, onChange, onBlur}) => (
                <KooperFormInput
                  fluid
                  type="text"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  onBlur={onBlur}
                  error={
                    errors.noOfDays && {
                      content: removeDoubleQuotes(errors.noOfDays.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>

          <KooperFormGroup>
            <KooperFormField width={16}>
              <Controller
                control={control}
                name="isAccrue"
                render={({onChange, value}) => (
                  <KooperFormField>
                    <div className="info-header">
                      <label>Does it accrue?</label>
                      <KooperTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Enable this if the time-off increase with time"
                        size="mini"
                        position="top left"
                      />
                    </div>
                    <KooperCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                  </KooperFormField>
                )}
              />
            </KooperFormField>
            <KooperFormField width={16}>
              <Controller
                control={control}
                name="approvalRequire"
                render={({onChange, value}) => (
                  <KooperFormField>
                    <div className="info-header">
                      <label>Needs Approval?</label>
                      <KooperTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Enable this and this type of time-off will ask for approval"
                        size="mini"
                        position="top left"
                      />
                    </div>
                    <KooperCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                  </KooperFormField>
                )}
              />
            </KooperFormField>
          </KooperFormGroup>

          <KooperFormGroup>
            <KooperFormField width={16}>
              <Controller
                control={control}
                name="documentRequire"
                render={({onChange, value}) => (
                  <KooperFormField>
                    <div className="info-header">
                      <label>Allow Attached Documents?</label>
                      <KooperTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Enable this to attach document"
                        size="mini"
                        position="top left"
                      />
                    </div>
                    <KooperCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                  </KooperFormField>
                )}
              />
            </KooperFormField>
            <KooperFormField width={16}>
              <Controller
                control={control}
                name="visibleToEmployees"
                render={({onChange, value}) => (
                  <KooperFormField>
                    <div className="info-header">
                      <label>Is it visible to employees?</label>
                      <KooperTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Enable this to make it visible to employee"
                        size="mini"
                        position="top left"
                      />
                    </div>
                    <KooperCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                  </KooperFormField>
                )}
              />
            </KooperFormField>
          </KooperFormGroup>

          <KooperFormGroup>
            <KooperFormField width={8}>
              <Controller
                control={control}
                name="countForAbsent"
                render={({onChange, value}) => (
                  <KooperFormField>
                    <div className="info-header">
                      <label>Counts for absence rate?</label>
                      <KooperTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Calculate the absence rate"
                        size="mini"
                        position="top left"
                      />
                    </div>
                    <KooperCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                  </KooperFormField>
                )}
              />
            </KooperFormField>
          </KooperFormGroup>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton
          disabled={isLoadingData && (type.includes(EDIT_TIMEOFF_TYPE) || type.includes(CREATE_TIMEOFF_TYPES))}
          basic
          onClick={() => toggleModal(false)}
        >
          Cancel
        </KooperButton>
        <KooperButton
          loading={isLoadingData && (type.includes(EDIT_TIMEOFF_TYPE) || type.includes(CREATE_TIMEOFF_TYPES))}
          disabled={isLoadingData && (type.includes(EDIT_TIMEOFF_TYPE) || type.includes(CREATE_TIMEOFF_TYPES))}
          primary
          onClick={handleSubmit(data => handleSave(data))}
        >
          {editId ? 'Save' : 'Create'}
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default TimeOffTypes
