import {DateTimePicker, Input, Select, TextArea} from 'components/common/form'
import {KooperCard, KooperCardContent, KooperFormField, KooperFormGroup} from 'kooper-library'
import AlignmentCreatCard from './AlignmentCreatCard'
import KeyResultCreatCard from './KeyResultCreatCard'

const CreateOrganizationalGoals = ({control, errors, watch}) => {
  return (
    <>
      <KooperCard fluid>
        <KooperCardContent>
          <KooperFormGroup>
            <Input
              width={8}
              control={control}
              errors={errors}
              name="name"
              label="Organizational Goal Title"
              tooltipProps={{
                content: 'Organizational Goal Title',
              }}
              maxLength={30}
              placeholder="Enter organizational goal title"
            />
          </KooperFormGroup>
          <KooperFormGroup>
            <TextArea
              width={8}
              control={control}
              errors={errors}
              name="description"
              label="Organizational Goal Description"
              tooltipProps={{
                content: 'Organizational Goal Description',
              }}
              maxLength={100}
              placeholder="Enter organizational goal description"
            />
          </KooperFormGroup>
        </KooperCardContent>
      </KooperCard>
      <KooperCard fluid>
        <KooperCardContent>
          <KooperFormGroup>
            <Select
              width={8}
              control={control}
              errors={errors}
              required
              name="ownerId"
              label="Goal owner"
              options={[
                {key: 'jordan', text: 'Jordan', value: '1'},
                {key: 'alex', text: 'Alex', value: '2'},
                {key: 'sam', text: 'Sam', value: '3'},
              ]}
              tooltipProps={{
                content: 'Owner of the goal',
              }}
            />
          </KooperFormGroup>
          <KooperFormField>
            <KooperFormGroup>
              <DateTimePicker
                control={control}
                errors={errors}
                min={new Date()}
                tooltipProps={{
                  content: 'Timeline start date',
                }}
                name="startDate"
                label="Timeline Start Date"
                width={8}
                time={false}
              />
              <DateTimePicker
                control={control}
                errors={errors}
                min={new Date()}
                tooltipProps={{
                  content: 'Timeline start date',
                }}
                name="endDate"
                label="Timeline End Date"
                width={8}
                time={false}
              />
            </KooperFormGroup>
          </KooperFormField>
        </KooperCardContent>
      </KooperCard>
      <KeyResultCreatCard control={control} errors={errors} watch={watch} />
      <AlignmentCreatCard control={control} errors={errors} watch={watch} />
    </>
  )
}

export default CreateOrganizationalGoals
