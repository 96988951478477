/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, {useState, useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  KooperButton,
  KooperContainer,
  KooperDropdown,
  KooperForm,
  KooperFormField,
  KooperGrid,
  KooperGridColumn,
  kooperToast,
  KooperTooltip,
} from 'kooper-library'

import {IMPORT_PRODUCTS, EXPORT_SAMPLE_PRODUCTS_CSV} from 'actions/types'
import {downloadSampleForCategory, getProductCategory, importProducts} from 'actions/productLibrary'
import {downloadResponseCSV} from 'utils/helper'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'

function ProductImport() {
  const dispatch = useDispatch()
  const fileRef = useRef()

  const [categoryId, setCategoryId] = useState(null)
  const [list, setList] = useState([])

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {categoryList, productImportData, sampleData} = useSelector(state => state.productLibrary)

  const {productLibraryPermissions} = useUserPermissions()
  const manageProductSettingsPermissions = productLibraryPermissions?.manage_product_settings

  useEffect(() => {
    if (successLabels.includes(EXPORT_SAMPLE_PRODUCTS_CSV)) {
      const category = list.find(({value}) => value === categoryId).text
      downloadResponseCSV(sampleData, `${category}_sample`)
      dispatch({type: 'CLEAR_SELECTED_PRODUCTS'})
    }

    if (successLabels.includes(IMPORT_PRODUCTS)) {
      if (productImportData) {
        productImportData?.Inserted === 0
          ? kooperToast.error(`
      Failed: ${productImportData?.Failed} |
      Inserted: ${productImportData?.Inserted} | 
      Total: ${productImportData?.total}   
      `)
          : kooperToast.info(`
      Failed: ${productImportData?.Failed} |
      Inserted: ${productImportData?.Inserted} | 
      Total: ${productImportData?.total}   
      `)
      }
    }
  }, [successLabels])

  useEffect(() => {
    if (!categoryList) {
      dispatch(getProductCategory())
    }
  }, [])

  useEffect(() => {
    if (categoryList?.length) {
      const newList = categoryList.map(cate => ({
        key: cate.id,
        value: cate.id,
        text: cate.category,
      }))
      setList(newList)
    }
  }, [categoryList])

  return (
    <>
      <KooperContainer fluid>
        <div className="pageHeader px-0">
          <div className="pageTitle">
            <h5>Import Products</h5>
          </div>
        </div>
        <KooperForm className="mt-4">
          <KooperGrid>
            <KooperGridColumn width={6}>
              <KooperFormField>
                <div className="info-header">
                  <label className="label-class">Select Category</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select category of product you want to import"
                    size="mini"
                    position="top left"
                  />
                </div>

                <KooperDropdown
                  value={categoryId}
                  onChange={(e, {value}) => setCategoryId(value)}
                  fluid
                  selection
                  options={list}
                  clearable
                />
              </KooperFormField>
              <p className="kooper-lead mt-2 mb-4">
                {categoryId ? `Download Sample CSV File : ` : `Select a Category to download its sample`}
                {categoryId && (
                  <a onClick={() => dispatch(downloadSampleForCategory(categoryId))} style={{cursor: 'pointer'}}>
                    Download File
                  </a>
                )}
              </p>
              <h5>Please upload csv file containing the Products details :</h5>
              <LockPermissionTooltip isRoleAccessDenied={!manageProductSettingsPermissions}>
                <KooperButton
                  primary
                  className={`fileBtn ${!manageProductSettingsPermissions ? 'disabled-button' : ''}`}
                  disabled={!categoryId}
                  onClick={() => manageProductSettingsPermissions && fileRef.current.click()}
                >
                  Upload File
                </KooperButton>
              </LockPermissionTooltip>
              <input
                style={{visibility: 'hidden', width: 0, height: 0}}
                type="file"
                ref={fileRef}
                onInput={e => {
                  const fd = new FormData()
                  fd.append('categoryId', categoryId)
                  fd.append('file', e.target.files[0])
                  dispatch(importProducts(fd))
                  e.target.files = null
                }}
              />
            </KooperGridColumn>
          </KooperGrid>
        </KooperForm>
      </KooperContainer>
    </>
  )
}

export default ProductImport
