export const CONTACTS_PATH = {
  PREFIX: 'contacts',
}

export const COMPANIES_PATH = {
  PREFIX: 'companies',
}

export const CLIENT_PORTAL_PATH = {
  PREFIX: 'portal',
  ANNOUNCEMENTS: 'announcements',
  ANNOUNCEMENTS_CREATE: 'create',
  ANNOUNCEMENTS_EDIT: 'edit',
  CUSTOMIZATION: 'customization',
  GENERAL: 'general',
  TICKETS: 'tickets',
}

export const DEALS_PATH = {
  PREFIX: 'deals',
}

export const CAMPAIGNS_PATH = {
  PREFIX: 'campaigns',
  MESSAGE: 'message',
  EMAIL: 'email',
  SMS: 'sms',
}

export const MEETINGS_PATH = {
  PREFIX: 'meetings',
  CREATE: 'create',
  CONFIRM: 'confirm',
}

export const FORECASTS_PATH = {
  PREFIX: 'forecasts',
}

export const SEQUENCES_PATH = {
  PREFIX: 'sequences',
  CREATE: 'create',
  INSIGHTS: 'insights',
}

export const QUOTES_PATH = {
  PREFIX: 'quotes',
  ADD: 'add',
}

export const PRODUCTS_PATH = {
  PREFIX: 'products',
  ALL: 'all',
  SETTINGS: 'products-settings',
}

export const PROJECTS_PATH = {
  PREFIX: 'projects',
  OVERVIEW: 'overview',
  DISCUSSIONS: 'discussions',
  TASKS: 'tasks',
  NOTES: 'notes',
  TIME: 'time',
  FINANCE: 'finance',
  FINANCE_SUMMARY: 'summary',
  FINANCE_BUDGETS: 'budgets',
  FINANCE_RATES: 'rates',
  FINANCE_BILLING: 'billing',
  PROOFS: 'proofs',
  RISKS: 'risks',
  MILESTONES: 'milestones',
  FORMS: 'forms',
  // Templates
  TEMPLATES: 'templates',
  TEMPLATES_TASKS: 'tasks',
  TEMPLATES_FINANCE: 'finance',
  TEMPLATES_FINANCE_SUMMARY: 'summary',
  TEMPLATES_FINANCE_RATES: 'rates',
}

export const BUDGETING_PATH = {
  PREFIX: 'budgeting',
}

export const TASKS_PATH = {
  PREFIX: 'tasks',
}

export const TIMESHEETS_PATH = {
  PREFIX: 'timesheets',
}

export const PROOFS_PATH = {
  PREFIX: 'proofs',
  APPROVE: 'approve',
}

export const RESOURCE_PLANNING_PATH = {
  PREFIX: 'resource-planning',
  OVERVIEW: 'overview',
  WORKLOAD: 'workload',
}

export const INVOICES_PATH = {
  PREFIX: 'invoices',
  ALL: 'all',
  CREATE: 'create',
  UPDATE: 'update',
  INVOICE_PREFIX: 'prefix',
}

export const SALES_ORDERS_PATH = {
  PREFIX: 'sales-orders',
  ALL: 'all',
  CREATE: 'create',
  UPDATE: 'update',
  SALES_ORDERS_PREFIX: 'prefix',
}

export const PURCHASE_ORDERS_PATH = {
  PREFIX: 'purchase-orders',
  ALL: 'all',
  CREATE: 'create',
  PURCHASE_ORDERS_PREFIX: 'prefix',
}

export const BUDGETS_PLANNER_PATH = {
  PREFIX: 'budgets-planner',
  NEW: 'new',
}

export const VENDORS_PATH = {
  PREFIX: 'vendors',
  ALL: 'all',
  CREATE: 'create',
  UPDATE: 'update',
  CATEGORY: 'category',
  FIELDS: 'fields',
}

export const PURCHASES_PATH = {
  PREFIX: 'purchases',
  ALL: 'all',
  ADD: 'add',
  UPDATE: 'update',
  CATEGORY: 'category',
}

export const AUTOMATIONS_PATH = {
  PREFIX: 'automations',
  BUILDER: 'builder',
}

export const EMPLOYEES_PATH = {
  PREFIX: 'employees',
  ALL: 'all',
  CREATE: 'create',
  UPDATE: 'update',
  GENERAL: 'general',
  DEPARTMENTS: 'departments',
}

export const TIME_OFF_PATH = {
  PREFIX: 'time-off',
  ALL: 'all',
  CREATE: 'create',
  UPDATE: 'update',
  TYPES: 'types',
}

export const EXPENSES_PATH = {
  PREFIX: 'expenses',
  ALL: 'all',
  CREATE: 'create',
  UPDATE: 'update',
  CATEGORIES: 'categories',
}

export const APPRAISALS_PATH = {
  PREFIX: 'appraisal',
  CREATE: 'create',
  UPDATE: 'update',
  MANAGE: 'manage',
  SEND: 'send',
  TEMPLATES: 'templates',
}

export const ANNOUNCEMENTS_PATH = {
  PREFIX: 'announcements',
  CREATE: 'create',
  UPDATE: 'update',
}

export const DASHBOARDS_PATH = {
  PREFIX: 'dashboards',
  OVERVIEW: 'overview',
  MARKETING: 'marketing',
  SALES: 'sales',
  SUPPORT: 'support',
  PROJECTS: 'projects',
  FINANCE: 'finance',
  HUMAN_RESOURCES: 'human-resources',
}

export const REPORTS_PATH = {
  PREFIX: 'reports',
  // Conversations
  OVERVIEW: 'overview',
  INBOX: 'inbox',
  CHANNELS: 'channels',
  TAGS: 'tags',
  EMAIL_TRACKING: 'emailtracking',
  // Clents
  CONTACTS: 'contacts',
  COMPANIES: 'company',
  VISITORS: 'visitors',
  // Sales
  DEALS: 'deals',
  CONVERSIONS: 'conversions',
  FORECASTS: 'forecasts',
  PRODUCTS: 'products',
  QUOTES: 'quotes',
  // Projects
  PROJECT_HEALTH: 'project-health',
  PROJECT_TIMESHEET: 'project-timesheet',
  PROJECT_TIMESHEET_USERS: 'project-timesheet-users',
  PROJECT_TASK_USERS: 'project-task-users',
  RESOURCE_UTILIZATION: 'resource-utilization',
  PROJECTS: 'projects',
  RESOURCES: 'resources',
  // Finance
  VENDORS: 'vendors',
  PURCHASES: 'purchases',
  PURCHASE_ORDERS: 'purchase-orders',
  SALES_ORDERS: 'sales-orders',
  INVOICES: 'invoices',
  BUDGETS_PLANNER: 'budgets-planner',
  // Teams
  GOALS: 'goals',
  ONE_ON_ONE: '1-on-1s',
  // Performance
  METRICS: 'metrics',
  USERS: 'users',
  SLA: 'sla',
  TASKS: 'tasks',
  EMAIL_TEMPLATES: 'email-templates',
  // Campaigns
  CHAT: 'chat',
  EMAIL: 'email',
  SMS: 'sms',
  // HR
  EMPLOYEES: 'employees',
  TIME_OFF: 'timeOff',
  EXPENSES: 'expenses',
}

export const SETTINGS_PATH = {
  PREFIX: 'settings',
  // My Settings
  MY_PROFILE: 'my-profile',
  NOTIFICATION: 'notification',
  // My Organization
  ACCOUNT_SETTINGS: 'account-settings',
  USERS: 'users',
  TEAMS: 'teams',
  ROLES: 'roles',
  HOLIDAYS: 'holidays',
  // Channels
  CHAT_MESSENGER: 'chat-messenger',
  CHAT_PAGES: 'chat-pages',
  EMAILS: 'emails',
  SMS: 'sms',
  TWITTER: 'twitter',
  FACEBOOK: 'facebook',
  CONTACT_FORM: 'contact-form',
  // Messenger
  APPEARANCE: 'appearance',
  CONFIGURE: 'configure',
  ADVANCED: 'advanced',
  OFFLINE_EXPERIENCE: 'offline-experience',
  // Automations
  ROUTING: 'routing',
  BUSINESS_HOURS: 'business-hours',
  SLA_MANAGEMENT: 'sla-policy',
  TIME_TRACKING: 'time-tracking',
  // Productivity
  TAGS: 'tags',
  CAMPAIGN_TEMPLATES: 'campaign-template',
  EMAIL_TEMPLATES: 'email-templates',
  TASK_TEMPLATES: 'task-template',
  TRUSTED_DOMAIN: 'trusted-domain',
  AUDIT_LOGS: 'audit-logs',
  // Sales
  CURRENCIES: 'currencies',
  FORECASTING: 'forecasting',
  MEETINGS: 'meetings',
  SCORING: 'scoring',
  // Data Management
  CONTACT_STATUS: 'contact-status',
  CONTACT_TYPES: 'contact-types',
  DEALS_SOURCES: 'sources',
  LOSS_REASONS: 'loss-reasons',
  DEALS: 'deals',
  ACTIVITIES: 'activities',
  QUOTES: 'quotes-prefix',
  IMPORT: 'import',
  EXPORT: 'export',
  // Projects
  PROJECT_CATEGORIES: 'project-categories',
  // Customer Satisfaction
  EMAIL_SURVEY: 'email-survey',
  CHAT_SURVEY: 'chat-survey',
  // Billing
  MY_PLAN: 'my-plan',
  INVOICES: 'invoices',
  // API & Integrations
  INTEGRATIONS: 'integrations',
  DEVELOPER_API: 'developer-api',
  WEBHOOKS: 'webhooks',
}

export const GOALS_PATH = {
  PREFIX: 'goals',
  ORGANIZATION: 'organization',
  TEAM: 'team',
  PERSONAL: 'personal',
  SETTINGS: 'settings',
}

export const ONE_ON_ONE_S_PATH = {
  PREFIX: '1-on-1s',
  TEAM: 'team',
  PERSONAL: 'personal',
  SETTINGS: 'settings',
}

export const SURVEYS_PATH = {
  PREFIX: 'surveys',
  ALL: 'all',
  PULSE_SURVEY: 'pulse-survey',
}
