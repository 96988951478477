import DeleteModal from 'components/common/DeleteModal'
import SvgIcon from 'components/common/SvgIcon'
import {
  KooperCheckbox,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperEditor,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperIcon,
  KooperTooltip,
} from 'kooper-library'
import {useState} from 'react'
import {FaUser} from 'react-icons/fa'

const editorOptions = {
  height: 200,
  paragraphStyles: [
    'spaced',
    {
      name: 'Custom',
      class: '__se__customClass',
    },
  ],
  buttonList: [['bold', 'italic', 'underline', 'list']],
}
const OneOnOneTalkingPointForm = ({talkingPoint, isDetails = false}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [openNote, setOpenNote] = useState(false)
  const [isEditing, setIsEditing] = useState(talkingPoint?.new || false)
  const [content, setContent] = useState(talkingPoint.content || '')

  const handleCancel = () => {
    setContent(talkingPoint.content || '') // Reset content on cancel
    setIsEditing(false)
  }

  return (
    <KooperFormField>
      {isEditing ? (
        <KooperFormGroup className="one-on-one-section-form-edit">
          <KooperFormField width={16}>
            <KooperFormInput
              value={content}
              onBlur={() => setIsEditing(false)}
              onChange={e => setContent(e.target.value)}
              placeholder="Enter section title"
            />
            <div className="instruction-text">
              <span>
                <strong>Enter</strong> to save
              </span>
              <span className="separator">•</span>
              <span>
                <strong>Esc</strong> to cancel
              </span>
            </div>
          </KooperFormField>
        </KooperFormGroup>
      ) : (
        <KooperFormGroup className="talking-point-form-display">
          <KooperFormField className="talking-point-title-container">
            {isDetails && <KooperCheckbox className="talking-point-confirm" />}
            <div className="talking-point-avatar-placeholder">
              <FaUser />
            </div>
            <div className="talking-point-content-text" onClick={() => setIsEditing(true)}>
              {content || 'No talking point provided'}
            </div>
          </KooperFormField>
          <KooperTooltip
            content="More"
            size="mini"
            position="top center"
            trigger={
              <KooperDropdown button icon={<SvgIcon path="common/more-verticle" />} className="icon mr-0 button">
                <KooperDropdownMenu direction="left">
                  <KooperDropdownItem onClick={() => setOpenNote(true)}>
                    <KooperIcon name="sticky note outline" size="sm" /> Add private note
                  </KooperDropdownItem>

                  <KooperDropdownItem onClick={() => setIsDeleteModalOpen(true)}>
                    <SvgIcon path="common/delete" /> Delete this section
                  </KooperDropdownItem>
                </KooperDropdownMenu>
              </KooperDropdown>
            }
          />
        </KooperFormGroup>
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          // idTobeDeleted={deleteId}
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          //   deleteAction={deleteProjectsCategory}
          //   type={DELETE_PROJECTS_CATEGORY}
        />
      )}
      {openNote && (
        <KooperEditor
          width="100%"
          setOptions={editorOptions}
          setDefaultStyle="font-size: 15px; line-height: 1.5;"
          //   setContents={value}
          //   onChange={content => onChange(content)}
          //   disable={!isEditable}
          onBlur={() => setOpenNote(false)}
        />
      )}
    </KooperFormField>
  )
}

export default OneOnOneTalkingPointForm
