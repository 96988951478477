import DeleteModal from 'components/common/DeleteModal'
import SvgIcon from 'components/common/SvgIcon'
import {
  KooperButton,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperEditor,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperIcon,
  KooperInput,
  KooperPopup,
  KooperTooltip,
} from 'kooper-library'
import {useState} from 'react'

const editorOptions = {
  height: 200,
  paragraphStyles: [
    'spaced',
    {
      name: 'Custom',
      class: '__se__customClass',
    },
  ],
  buttonList: [['bold', 'italic', 'underline', 'list']],
}
const OneOnOneSectionForm = ({section}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(section?.new || false)
  const [title, setTitle] = useState(section.title || '')
  const [duration, setDuration] = useState(section.duration || 0)

  const [open, setOpen] = useState(false)
  const [openNote, setOpenNote] = useState(false)
  return (
    <KooperFormField>
      {isEditing ? (
        <KooperFormGroup className="one-on-one-section-form-edit">
          <KooperFormField width={16}>
            <KooperFormInput
              value={title}
              onBlur={() => setIsEditing(false)}
              onChange={e => setTitle(e.target.value)}
              placeholder="Enter section title"
            />
            <div className="instruction-text">
              <span>
                <strong>Enter</strong> to save
              </span>
              <span className="separator">•</span>
              <span>
                <strong>Esc</strong> to cancel
              </span>
            </div>
          </KooperFormField>
        </KooperFormGroup>
      ) : (
        <KooperFormGroup className="one-on-one-section-form-display">
          <KooperFormField
            className="one-on-one-section-title-container"
            onClick={e => {
              e.stopPropagation()
              setIsEditing(true)
            }}
          >
            <div className="one-on-one-section-title mr-3">{title}</div>
            <KooperPopup
              eventsEnabled
              on="click"
              onClose={e => {
                e.stopPropagation()
                setOpen(false)
              }}
              onOpen={e => {
                e.stopPropagation()
                setOpen(true)
              }}
              open={open}
              content={
                <div className="d-flex" style={{gap: '8px'}}>
                  <span>Duration</span>
                  <KooperButton
                    icon="minus"
                    basic
                    compact
                    style={{
                      padding: '8px',
                      minWidth: '36px',
                      height: '36px',
                      border: '1px solid rgba(34, 36, 38, 0.15)',
                      boxShadow: 'none',
                    }}
                  />
                  <KooperInput
                    value="10"
                    style={{
                      width: '60px',
                      height: '36px',
                    }}
                  >
                    <input style={{textAlign: 'center', padding: '8px'}} />
                  </KooperInput>
                  <KooperButton
                    icon="plus"
                    basic
                    compact
                    style={{
                      padding: '8px',
                      minWidth: '36px',
                      height: '36px',
                      border: '1px solid rgba(34, 36, 38, 0.15)',
                      boxShadow: 'none',
                    }}
                  />
                  <KooperButton
                    icon="trash"
                    basic
                    compact
                    style={{
                      padding: '8px',
                      minWidth: '36px',
                      height: '36px',
                      border: '1px solid rgba(34, 36, 38, 0.15)',
                      boxShadow: 'none',
                    }}
                  />
                </div>
              }
              popper={{id: 'popper-container', style: {zIndex: 2000}}}
              trigger={
                <div className="one-on-one-duration-display">
                  <KooperIcon name="clock" size="sm" />
                  <span>{duration} min</span>
                </div>
              }
            />
          </KooperFormField>
          <KooperTooltip
            content="More"
            size="mini"
            position="top center"
            trigger={
              <KooperDropdown button icon={<SvgIcon path="common/more-verticle" />} className="icon mr-0 button">
                <KooperDropdownMenu direction="left">
                  <KooperDropdownItem>
                    <KooperIcon name="clock" size="sm" /> Add duration
                  </KooperDropdownItem>
                  <KooperDropdownItem onClick={() => setOpenNote(true)}>
                    <KooperIcon name="sticky note outline" size="sm" /> Add private note
                  </KooperDropdownItem>

                  <KooperDropdownItem onClick={() => setIsDeleteModalOpen(true)}>
                    <SvgIcon path="common/delete" /> Delete this section
                  </KooperDropdownItem>
                </KooperDropdownMenu>
              </KooperDropdown>
            }
          />
        </KooperFormGroup>
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          // idTobeDeleted={deleteId}
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          //   deleteAction={deleteProjectsCategory}
          //   type={DELETE_PROJECTS_CATEGORY}
        />
      )}
      {openNote && (
        <KooperEditor
          width="100%"
          setOptions={editorOptions}
          setDefaultStyle="font-size: 15px; line-height: 1.5;"
          //   setContents={value}
          //   onChange={content => onChange(content)}
          //   disable={!isEditable}
          onBlur={() => setOpenNote(false)}
        />
      )}
    </KooperFormField>
  )
}

export default OneOnOneSectionForm
