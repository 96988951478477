import {Input, Select} from 'components/common/form'
import SvgIcon from 'components/common/SvgIcon'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperFormField,
  KooperFormGroup,
  KooperTooltip,
} from 'kooper-library'
import {useFieldArray} from 'react-hook-form'

const KeyResultCreatCard = ({control, errors, watch}) => {
  const {
    fields: keyResultsFields,
    append: appendKeyResults,
    remove: removeKeyResults,
  } = useFieldArray({
    control,
    name: 'results',
  })

  return (
    <KooperCard fluid>
      <KooperCardContent>
        <KooperCardHeader>Key results</KooperCardHeader>
        <KooperFormField>
          <label>
            How will you achieve this goal? Break down your goal into specific and measurable steps to help you get
            there.
          </label>
        </KooperFormField>
        <KooperFormField>
          {keyResultsFields.map((keyResults, index) => (
            <div key={keyResults?.id}>
              <KooperFormGroup>
                <Input
                  width={12}
                  control={control}
                  errors={errors}
                  name={`results[${index}].title`}
                  label="Key Result Title"
                  tooltipProps={{
                    content: 'Key Result Title',
                  }}
                  maxLength={20}
                  placeholder="e.g. By the end of year, improve net paid users by 10%"
                />

                <KooperFormField className="d-flex">
                  <KooperTooltip
                    content="Delete Key Results"
                    size="mini"
                    position="top center"
                    trigger={
                      <KooperButton
                        className="mt-4"
                        icon={<SvgIcon path="common/delete" />}
                        onClick={() => removeKeyResults(index)}
                      />
                    }
                  />
                </KooperFormField>
              </KooperFormGroup>
              <KooperFormGroup>
                <Select
                  width={8}
                  control={control}
                  errors={errors}
                  name={`results[${index}].type`}
                  label="Select Metric"
                  tooltipProps={{
                    content: 'Select Metric',
                  }}
                  placeholder="Select goal owner"
                  options={[
                    {key: 'Numeric', text: 'Numeric', value: 'numeric'},
                    {key: 'Percentage', text: 'Percentage', value: 'percentage'},
                    {key: 'Yes/No', text: 'Yes/No', value: 'boolean'},
                  ]}
                />
                {['numeric', 'percentage'].includes(watch(`results[${index}].type`)) && (
                  <>
                    <Input
                      width={2}
                      control={control}
                      errors={errors}
                      name={`results[${index}].startValue`}
                      label="Start"
                      tooltipProps={{
                        content: 'Start',
                      }}
                      type="number"
                    />
                    <Input
                      width={2}
                      control={control}
                      errors={errors}
                      name={`results[${index}].targetValue`}
                      label="Target"
                      tooltipProps={{
                        content: 'Start',
                      }}
                      type="number"
                    />
                  </>
                )}
              </KooperFormGroup>
            </div>
          ))}
          <KooperButton onClick={() => appendKeyResults({type: 'numeric'})} basic content="+ Add key result" />
        </KooperFormField>
      </KooperCardContent>
    </KooperCard>
  )
}

export default KeyResultCreatCard
