import SvgIcon from 'components/common/SvgIcon'
import {ONE_ON_ONE_INTERVALS} from 'constants/Teams/Goals/one-on-one'
import {
  KooperButton,
  KooperDropdown,
  KooperForm,
  KooperFormDropdown,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperHeader,
  KooperIcon,
  KooperInput,
  KooperList,
  KooperListContent,
  KooperListHeader,
  KooperListItem,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperSegment,
  KooperTab,
  KooperTabPane,
  KooperTooltip,
} from 'kooper-library'
import {useState} from 'react'

const InviteMemberModal = ({open, onClose, type = 'personal'}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [activeTab, setActiveTab] = useState(0)
  const statusOptions = [
    {key: 'unscheduled', text: 'Unscheduled', value: 'unscheduled'},
    {key: 'scheduled', text: 'scheduled', value: 'scheduled'},
  ]
  const [status, setStatus] = useState('unscheduled')
  const [openScheduleModal, setOpenScheduleModal] = useState(false)
  const handleStatusChange = (_, {value}) => {
    if (value === 'scheduled') {
      setOpenScheduleModal(true)
    }
    setStatus(value)
  }
  const [selectUser, setSelectUser] = useState(false)
  const personalPane = [
    {
      menuItem: 'All team members (1)',
      render: () => (
        <KooperTabPane attached={false}>
          <KooperSegment basic style={{padding: '1em 0'}}>
            <KooperInput
              fluid
              icon="search"
              placeholder="Search for a team member"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
            />
          </KooperSegment>
          <KooperList>
            <KooperListItem>
              <div className="select-user-container">
                <div className="select-user-details">
                  <KooperIcon onClick={() => setSelectUser(false)} name="user" size="large" />
                  <span className="select-user-name">test-survey-02 two</span>
                </div>
                <div className="select-user-actions">
                  {selectUser ? (
                    <>
                      <KooperDropdown
                        selection
                        options={statusOptions}
                        value={status}
                        onChange={handleStatusChange}
                        className="status-dropdown"
                      />
                      <KooperIcon name="close" className="remove-icon" />
                    </>
                  ) : (
                    <button onClick={() => setSelectUser(true)} type="button">
                      Select this person
                    </button>
                  )}
                </div>
              </div>
            </KooperListItem>
          </KooperList>
        </KooperTabPane>
      ),
    },
  ]
  const teamsPane = [
    {
      menuItem: 'My Teams (1)',
      render: () => (
        <KooperTabPane attached={false}>
          <KooperSegment basic style={{padding: '1em 0'}}>
            <KooperInput
              fluid
              icon="search"
              placeholder="Search for a team member"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
            />
          </KooperSegment>
          <KooperList>
            <KooperListItem>
              <div className="select-user-container">
                <div className="select-user-details">
                  <KooperIcon onClick={() => setSelectUser(false)} name="users" size="large" />
                  <span className="select-user-name">Back end</span>
                </div>
                <div className="select-user-actions">
                  {selectUser ? (
                    <>
                      <KooperDropdown
                        selection
                        options={statusOptions}
                        value={status}
                        onChange={handleStatusChange}
                        className="status-dropdown"
                      />
                      <KooperIcon name="close" className="remove-icon" />
                    </>
                  ) : (
                    <button onClick={() => setSelectUser(true)} type="button">
                      Select this Team
                    </button>
                  )}
                </div>
              </div>
            </KooperListItem>
          </KooperList>
        </KooperTabPane>
      ),
    },
  ]

  return (
    <KooperModal
      open={open}
      onClose={onClose}
      size="small"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      scrollable
    >
      <KooperModalHeader>Select the 1-on-1s this agenda is for </KooperModalHeader>
      {openScheduleModal ? (
        <KooperModalContent scrolling>
          <KooperHeader as="h4">
            You and test-survey-02 will get a reminder 3 days before the meeting to collaborate on the agenda.
          </KooperHeader>
          <KooperForm>
            <KooperFormField>
              <div className="info-header">
                <label>Date</label>
                <KooperTooltip
                  content="Date"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="right center"
                />
                <KooperButton className="delete-scheduling">Delete Scheduling</KooperButton>
              </div>
              <KooperFormInput type="date" />
            </KooperFormField>
            <KooperFormGroup>
              <KooperFormField width={8}>
                <div className="info-header">
                  <label>Start time</label>
                  <KooperTooltip
                    content="Start time"
                    trigger={<SvgIcon path="common/question" />}
                    size="mini"
                    position="right center"
                  />
                </div>
                <KooperFormInput type="time" />
              </KooperFormField>
              <KooperFormField width={8}>
                <div className="info-header">
                  <label>Duration</label>
                  <KooperTooltip
                    content="Duration"
                    trigger={<SvgIcon path="common/question" />}
                    size="mini"
                    position="right center"
                  />
                </div>
                <KooperFormDropdown
                  placeholder="select color"
                  selection
                  options={[
                    {key: 15, value: 15, text: '15 minutes'},
                    {key: 30, value: 30, text: '30 minutes'},
                    {key: 45, value: 45, text: '45 minutes'},
                    {key: 60, value: 60, text: '60 minutes'},
                    {key: 75, value: 75, text: '75 minutes'},
                    {key: 90, value: 90, text: '90 minutes'},
                    {key: 105, value: 105, text: '105 minutes'},
                    {key: 120, value: 120, text: '120 minutes'},
                  ]}
                  // onChange={(e, {value}) => onChange(value)}
                  value={15}
                />
              </KooperFormField>
            </KooperFormGroup>
            <KooperFormGroup>
              <KooperFormField width={8}>
                <div className="info-header">
                  <label>Recurrence</label>
                  <KooperTooltip
                    content="Recurrence"
                    trigger={<SvgIcon path="common/question" />}
                    size="mini"
                    position="right center"
                  />
                </div>
                <KooperFormDropdown
                  selection
                  options={ONE_ON_ONE_INTERVALS}
                  // onChange={(e, {value}) => onChange(value)}
                  value={0}
                />
              </KooperFormField>
            </KooperFormGroup>
          </KooperForm>
        </KooperModalContent>
      ) : (
        <KooperModalContent>
          <KooperHeader as="h5" style={{fontWeight: 'normal', marginBottom: '20px'}}>
            Select one or more team members, and then the 1-on-1 dates this agenda will be applied to. Team members will
            be invited to collaborate with you on the agenda.
          </KooperHeader>

          <KooperTab
            menu={{secondary: true, pointing: true}}
            panes={type === 'personal' ? personalPane : teamsPane}
            activeIndex={activeTab}
            onTabChange={(_, data) => setActiveTab(data.activeIndex)}
          />
        </KooperModalContent>
      )}
      <KooperModalActions>
        <KooperButton
          basic
          primary
          onClick={e => {
            if (openScheduleModal) {
              setOpenScheduleModal(false)
            } else {
              onClose(e)
            }
          }}
        >
          Cancel
        </KooperButton>
        <KooperButton disabled basic primary={false}>
          Done
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default InviteMemberModal
