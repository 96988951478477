import {joiResolver} from '@hookform/resolvers'
import {
  createProjectFinanceExpense,
  getProjectFinanceSingleExpense,
  updateProjectFinanceExpense,
} from 'actions/projects'
import {GET_PROJECT_FINANCE_SINGLE_EXPENSE} from 'actions/types'
import SvgIcon from 'components/common/SvgIcon'
import Joi from 'joi'
import _ from 'lodash'
import React, {useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {removeDoubleQuotes} from 'utils/helper'
import {
  KooperButton,
  KooperDatePicker,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormTextArea,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'

const ProjectBillingExpenseModal = ({open, toggle, projectId, budgetId, expenseId}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {budgetExpenseData = {}} = useSelector(state => state.projects)

  const {handleSubmit, control, errors, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        title: Joi.string().trim().min(2).label('Expense').required(),
        cost: Joi.number().min(1).required().messages({
          'number.min': 'Please enter a value greater than 0',
        }),
        expenseDate: Joi.date().required(),
        description: Joi.string().trim().max(500).allow('', null).messages({
          'string.max': `Length must be less than or equal to 500 characters`,
        }),
      })
    ),
    defaultValues: {
      title: '',
      cost: 0,
      expenseDate: new Date(),
      description: '',
    },
  })

  useEffect(() => {
    if (projectId && budgetId && expenseId) {
      dispatch(getProjectFinanceSingleExpense({projectId, budgetId, expenseId}))
    }
  }, [dispatch, projectId, budgetId, expenseId])

  useEffect(() => {
    if (successLabels.includes(GET_PROJECT_FINANCE_SINGLE_EXPENSE)) {
      const data = _.pick(budgetExpenseData, ['title', 'cost', 'expenseDate', 'description'])
      reset(data)
    }
  }, [successLabels, budgetExpenseData, reset])

  const submitForm = data => {
    const payload = {...data, isBillable: false, isBudgetExpense: false}
    if (expenseId) {
      dispatch(updateProjectFinanceExpense({projectId, budgetId, expenseId, data: payload}))
    } else {
      dispatch(createProjectFinanceExpense({projectId, budgetId, data: payload}))
    }
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader>{expenseId ? 'Update Expense' : 'Create Expense'}</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <div className="info-header">
              <label>Expense Name</label>
              <KooperTooltip
                content="Specify expenses for billing"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </div>
            <Controller
              name="title"
              control={control}
              render={({value, onChange}) => (
                <KooperFormInput
                  maxLength={50}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.title && {content: removeDoubleQuotes(errors.title.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required>
            <div className="info-header">
              <label>Cost</label>
              <KooperTooltip
                content="Set the cost"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </div>
            <Controller
              name="cost"
              control={control}
              render={({value, onChange}) => (
                <KooperFormInput
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.cost && {content: removeDoubleQuotes(errors.cost.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required>
            <div className="info-header">
              <label>Date</label>
              <KooperTooltip
                content="Specify the date of expense"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </div>
            <Controller
              name="expenseDate"
              control={control}
              render={({value, onChange}) => (
                <KooperDatePicker
                  time={false}
                  value={value ? new Date(value) : value}
                  onChange={value => onChange(value)}
                  inputProps={{readOnly: true}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField>
            <div className="info-header">
              <label>Description</label>
              <KooperTooltip
                content="Specify a brief description about expense"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </div>
            <Controller
              name="description"
              control={control}
              render={({value, onChange}) => (
                <KooperFormTextArea
                  maxLength={500}
                  style={{resize: 'none'}}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.description && {content: removeDoubleQuotes(errors.description.message)}}
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          {expenseId ? 'Update Expense' : 'Save Expense'}
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default ProjectBillingExpenseModal
