import React, {useEffect, useState} from 'react'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperDivider,
  KooperNoData,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableFooter,
  KooperTableRow,
} from 'kooper-library'
import {useDispatch, useSelector} from 'react-redux'
import DeleteModal from 'components/common/DeleteModal'
import {
  deleteProjectFinanceBillingInvoice,
  getProjectFinanceBillingCompletedInvoicesList,
  getProjectFinanceBillingInvoiceLogs,
  reopenProjectFinanceBillingInvoice,
} from 'actions/projects'
import {DELETE_PROJECT_FINANCE_BILLING_INVOICE, REOPEN_PROJECT_FINANCE_BILLING_INVOICE} from 'actions/types'
import {PROJECTS_ENDPOINT} from 'actions/endpoint'
import {getFormattedDate} from 'utils/helper'
import ProjectBillingInvoiceList from '../ProjectBillingInvoiceList'

const ProjectBillingCompletedInvoices = ({projectId, budgetId}) => {
  const dispatch = useDispatch()

  const [invoiceId, setInvoiceId] = useState(null)
  const [isDeleteModalOpen, setisDeleteModalOpen] = useState(false)
  const [idToDeleteInvoice, setIdToDeleteInvoice] = useState(null)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {billingCompletedInvoicesList = [], billingInvoiceLogs = {}} = useSelector(state => state.projects)
  const {expenseList = [], timeLogs = [], currency = ''} = billingInvoiceLogs

  useEffect(() => {
    if (projectId && budgetId && invoiceId) {
      dispatch(getProjectFinanceBillingInvoiceLogs({projectId, budgetId, invoiceId}))
    }
  }, [dispatch, projectId, budgetId, invoiceId])

  useEffect(() => {
    if (
      successLabels.includes(DELETE_PROJECT_FINANCE_BILLING_INVOICE) ||
      successLabels.includes(REOPEN_PROJECT_FINANCE_BILLING_INVOICE)
    ) {
      setIdToDeleteInvoice(null)
      setInvoiceId(null)
      dispatch(getProjectFinanceBillingCompletedInvoicesList({projectId, budgetId}))
    }
  }, [successLabels, dispatch, projectId, budgetId])

  const getHourMinuteFormatFromMinutes = minutes => {
    const hour = Math.floor(minutes / 60)
    const minute = minutes % 60
    return `${hour} hrs ${minute} mins`
  }

  const getTotalTime = () => {
    const totalMinutes = timeLogs.reduce((acc, item) => acc + item.loggedHrs * 60 + item.loggedMins, 0)
    return getHourMinuteFormatFromMinutes(totalMinutes)
  }

  const getSubTotalCost = list => {
    return list.reduce((acc, item) => acc + item.cost, 0).toFixed(2)
  }

  const renderTimeTableBody = () => {
    if (timeLogs.length === 0) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="6" className="emptyValue">
            <KooperNoData type="table" noDataText="No Data Available" />
          </KooperTableCell>
        </KooperTableRow>
      )
    }
    return timeLogs.map(time => (
      <KooperTableRow key={time.id} className="tableLink">
        <KooperTableCell>{getFormattedDate(time.date)}</KooperTableCell>
        <KooperTableCell>
          {time.createdByDetails?.firstName} {time.createdByDetails?.lastName}
        </KooperTableCell>
        <KooperTableCell>
          {time.tasklistDetails && (
            <p>
              {time.tasklistDetails?.title} - {time.taskDetails?.title}
            </p>
          )}
          <p>{time.description || 'No description'}</p>
        </KooperTableCell>
        <KooperTableCell>{getHourMinuteFormatFromMinutes(time.loggedHrs * 60 + time.loggedMins)}</KooperTableCell>
        <KooperTableCell>
          {currency} {time.cost.toFixed(2)}
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  const renderExpenseTableBody = () => {
    return expenseList.map(expense => (
      <KooperTableRow key={expense.id} className="tableLink">
        <KooperTableCell>{getFormattedDate(expense.expenseDate)}</KooperTableCell>
        <KooperTableCell>{expense.title}</KooperTableCell>
        <KooperTableCell>{expense.description}</KooperTableCell>
        <KooperTableCell>{expense.createdBy?.name}</KooperTableCell>
        <KooperTableCell>
          {currency} {expense.cost.toFixed(2)}
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  return (
    <div className="project-finance-billing-content">
      <div className="project-finance-billing-content-left">
        <ProjectBillingInvoiceList
          invoiceList={billingCompletedInvoicesList}
          onListItemClick={invoiceId => {
            setInvoiceId(invoiceId)
          }}
          onDeleteClick={invoiceId => {
            setIdToDeleteInvoice(invoiceId)
            setisDeleteModalOpen(true)
          }}
        />
      </div>
      <div className="project-finance-billing-content-right">
        {invoiceId && (
          <KooperCard fluid>
            <KooperCardContent>
              <div>
                <p>Invoice No./ID : {billingInvoiceLogs.invoiceNumber}</p>
                <p>
                  Issue Date : {billingInvoiceLogs.invoiceDate ? getFormattedDate(billingInvoiceLogs.invoiceDate) : ''}
                </p>
              </div>
              <KooperDivider />
              {timeLogs.length ? (
                <>
                  <h3>Billable Time </h3>
                  <KooperTable basic>
                    <KooperTableBody>{renderTimeTableBody()}</KooperTableBody>
                    <KooperTableFooter>
                      <KooperTableRow>
                        <KooperTableCell colSpan="2" />
                        <KooperTableCell textAlign="right">Sub-Total:</KooperTableCell>
                        <KooperTableCell>{getTotalTime()}</KooperTableCell>
                        <KooperTableCell>
                          {currency} {getSubTotalCost(timeLogs)}
                        </KooperTableCell>
                      </KooperTableRow>
                    </KooperTableFooter>
                  </KooperTable>
                </>
              ) : (
                <p>No time logged on this invoice</p>
              )}

              {expenseList.length ? (
                <>
                  <h3>Expenses </h3>
                  <KooperTable basic>
                    <KooperTableBody>{renderExpenseTableBody()}</KooperTableBody>
                    <KooperTableFooter>
                      <KooperTableRow>
                        <KooperTableCell colSpan="3" />
                        <KooperTableCell textAlign="right">Sub-Total:</KooperTableCell>
                        <KooperTableCell>
                          {currency} {getSubTotalCost(expenseList)}
                        </KooperTableCell>
                      </KooperTableRow>
                    </KooperTableFooter>
                  </KooperTable>
                </>
              ) : (
                <p>No expenses logged on this invoice</p>
              )}

              <KooperDivider />

              <div className="d-flex">
                <div>
                  <KooperButton
                    basic
                    primary
                    icon="download"
                    content="Export"
                    onClick={() =>
                      window.open(`${PROJECTS_ENDPOINT}/budgets/invoice/download/${billingInvoiceLogs.link}`, '_blank')
                    }
                  />
                  <KooperButton
                    basic
                    primary
                    icon="lock open"
                    content="Reopen Invoice"
                    onClick={() => dispatch(reopenProjectFinanceBillingInvoice({projectId, budgetId, invoiceId}))}
                    className="ml-3"
                  />
                </div>
                <div>
                  <h2>
                    Total: {currency} {billingInvoiceLogs.finalTotal?.toFixed(2)}
                  </h2>
                  {billingInvoiceLogs.footerText && <span>({billingInvoiceLogs.footerText})</span>}
                </div>
              </div>
            </KooperCardContent>
          </KooperCard>
        )}
      </div>

      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setisDeleteModalOpen}
          modalText="Are you sure you want to delete this invoice?"
          onDelete={() =>
            dispatch(deleteProjectFinanceBillingInvoice({projectId, budgetId, invoiceId: idToDeleteInvoice}))
          }
          type={DELETE_PROJECT_FINANCE_BILLING_INVOICE}
        />
      )}
    </div>
  )
}

export default ProjectBillingCompletedInvoices
