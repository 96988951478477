import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import Deliveery from 'components/pages/Teams/Surveys/SurveyForms/Delivery/Delivery'
import GenerelSettings from 'components/pages/Teams/Surveys/SurveyForms/General/GenerelSettings'
import Questions from 'components/pages/Teams/Surveys/SurveyForms/Questions/Questions'
import ReviewAndLaunch from 'components/pages/Teams/Surveys/SurveyForms/ReviewAndLaunch/ReviewAndLaunch'
import {SURVEYS_PATH} from 'constants/path'
import {KooperButton, KooperTab} from 'kooper-library'
import React, {useState, useRef} from 'react'
import {useHistory, useLocation, useParams, useRouteMatch, Switch, Route, Redirect} from 'react-router-dom'

const CreateSurveys = () => {
  const history = useHistory()
  const {path, url} = useRouteMatch()
  const {checklistId} = useParams()
  const {pathname} = useLocation()
  const currentPage = pathname.split('/').slice(-1)[0]

  const [isNextStep, setIsNextStep] = useState(false)
  const [disableSave, setDisableSave] = useState(true)

  const surveyGeneralRef = useRef()
  const surveyQuestionsRef = useRef()
  const surveyDeliveryRef = useRef()
  const surveyReviewAndLaunchRef = useRef()
  const surveySteps = [
    {name: 'General', path: 'general', icon: 'paint brush'},
    {name: 'Questions', path: 'questions', icon: 'list'},
    {name: 'Delivery', path: 'delivery', icon: 'code'},
    {name: 'Review and launch', path: 'review-and-launch', icon: 'code'},
  ]

  const handleNext = async () => {
    setDisableSave(true)
    switch (currentPage) {
      case 'general':
        if (disableSave) {
          return history.push(pathname.replace(/[^/]*$/, 'questions'))
        }
        return surveyGeneralRef.current?.submitForm()

      case 'questions': {
        if (disableSave) {
          return history.push(pathname.replace(/[^/]*$/, 'delivery'))
        }
        return surveyQuestionsRef.current?.submitForm()
      }

      case 'delivery': {
        if (disableSave) {
          return history.push(pathname.replace(/[^/]*$/, 'review-and-launch'))
        }
        return surveyDeliveryRef.current?.submitForm()
      }

      case 'review-and-launch': {
        return surveyReviewAndLaunchRef.current?.submitForm() // Assuming you have a function for submitting the survey.
      }

      default:
        break
    }
  }

  const handleBack = async () => {
    setIsNextStep(false)
    setDisableSave(true)
    switch (currentPage) {
      case 'questions': {
        return history.push(pathname.replace(/[^/]*$/, 'general'))
      }
      case 'delivery': {
        return history.push(pathname.replace(/[^/]*$/, 'questions'))
      }
      case 'review-and-launch': {
        return history.push(pathname.replace(/[^/]*$/, 'delivery'))
      }
      default:
        break
    }
  }

  return (
    <div className="kooper-full-page">
      <div className="campaignNavbar tabCustomize">
        <div>
          <KooperButton primary onClick={() => history.push(`/${SURVEYS_PATH.PREFIX}`)}>
            Exit
          </KooperButton>
          {currentPage !== 'configuration' && (
            <KooperButton primary onClick={handleBack}>
              Back
            </KooperButton>
          )}
        </div>
        <KooperTab
          panes={surveySteps.map(item => ({
            menuItem: {
              key: item.name,
              icon: item.icon,
              content: item.name,
            },
            render: null,
          }))}
          menu={{
            secondary: true,
            pointing: true,
            color: 'blue',
          }}
          activeIndex={surveySteps.findIndex(item => item.path === currentPage)}
          onTabChange={(e, {activeIndex}) => {
            history.push(pathname.replace(/[^/]*$/, surveySteps[activeIndex].path))
          }}
          renderActiveOnly={false}
        />
        <div>
          <LockPermissionTooltip
          // </div> isRoleAccessDenied={!manageInvoicesPermissions}
          >
            <KooperButton
              primary
              // className={!manageInvoicesPermissions ? 'disabled-button' : ''}
              // {...(manageInvoicesPermissions && {
              //   onClick: handleSubmit(submitForm),
              // })}
            >
              Save as draft
            </KooperButton>
          </LockPermissionTooltip>
          <LockPermissionTooltip
          // </div> isRoleAccessDenied={!manageInvoicesPermissions}
          >
            <KooperButton
              primary
              onClick={() => {
                setIsNextStep(true)
                handleNext()
              }}
              // className={!manageInvoicesPermissions ? 'disabled-button' : ''}
              // {...(manageInvoicesPermissions && {
              //   onClick: handleSubmit(submitForm),
              // })}
            >
              {currentPage === 'review-and-launch' ? 'Publish' : 'Next'}
            </KooperButton>
          </LockPermissionTooltip>
        </div>
      </div>
      <Switch>
        <Route path={`${path}/general`}>
          <GenerelSettings
            ref={surveyGeneralRef}
            isNextStep={isNextStep}
            setDisableSave={setDisableSave}
            disableSave={disableSave}
          />
        </Route>
        <Route path={`${path}/questions`}>
          <Questions
            ref={surveyQuestionsRef}
            isNextStep={isNextStep}
            setDisableSave={setDisableSave}
            checklistId={checklistId}
          />
        </Route>
        <Route path={`${path}/delivery`}>
          <Deliveery ref={surveyDeliveryRef} />
        </Route>
        <Route path={`${path}/review-and-launch`}>
          <ReviewAndLaunch ref={surveyReviewAndLaunchRef} />
        </Route>
        <Redirect to={`${url}/general`} />
      </Switch>
    </div>
  )
}

export default CreateSurveys
