import {FaUser, FaUsers, FaWindowMaximize} from 'react-icons/fa'

export const createGoalsDropdownData = [
  {
    Icon: FaWindowMaximize,
    header: 'Organizational Goal',
    subHeader: 'Organizational Goals',
    value: 'organizational',
  },
  {
    Icon: FaUsers,
    header: 'Team Goal',
    subHeader: 'Team Goal',
    value: 'team',
  },
  {
    Icon: FaUser,
    header: 'Individual Goal',
    subHeader: 'Individual Goal',
    value: 'personal',
  },
]

export const goalTypes = [
  {value: 'all', key: 'all', text: 'All Types'},
  {value: 'organizational', key: 'organizational', text: 'Organizational'},
  {value: 'team', key: 'team', text: 'Team'},
  {value: 'individual', key: 'individual', text: 'Individual'},
]

export const goalStatuses = [
  {value: 'active', key: 'active', text: 'All Active'},
  {value: 'on_track', key: 'onTrack', text: 'On Track'},
  {value: 'completed', key: 'completed', text: 'Completed'},
  {value: 'delayed', key: 'delayed', text: 'Delayed'},
  {value: 'at_risk', key: 'atRisk', text: 'At Risk'},
  {value: 'abandoned', key: 'abandoned', text: 'Abandoned'},
  {value: 'archived', key: 'archived', text: 'Archived'},
]

export const goalConstants = {
  TYPE: {
    ORGANIZATIONAL: 'organizational',
    TEAM: 'team',
    INDIVIDUAL: 'individual',
  },
  METRICS: {
    NUMERIC: 'numeric',
    PERCENTAGE: 'percentage',
    BOOLEAN: 'boolean',
  },
  STATUS: {
    ON_TRACK: 'on_track',
    COMPLETED: 'completed',
    DELAYED: 'delayed',
    AT_RISK: 'at_risk',
    ABANDONED: 'abandoned',
    ARCHIVED: 'archived',
  },
}
