import CardLoader from 'components/common/CardLoader'
import KooperNoDataTable from 'components/common/KooperNoDataTable'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SearchComponent from 'components/common/SearchComponent'
import SvgIcon from 'components/common/SvgIcon'
import {KooperButton, KooperCard, KooperCardContent, KooperContainer, KooperDropdown} from 'kooper-library'
import {useState} from 'react'
import {ONE_ON_ONE_S_PATH} from 'constants/path'
import {useHistory} from 'react-router-dom'
import OneOnOneSCardItem from './OneOnOneSCardItem'

const PersonalOneOnOneSList = () => {
  const history = useHistory()
  const oneOnOneList = [
    {title: 'personal 1', taklingPoint: 2, status: 'Unscheduled', type: 'personal'},
    {title: 'Untitled agenda', taklingPoint: 5, status: 'Unscheduled', type: 'personal'},
    {title: 'Untitled agenda', taklingPoint: 0, status: 'Unscheduled', type: 'personal'},
    {title: 'ghfhfgh', taklingPoint: 2, status: 'Unscheduled', type: 'personal'},
    {title: 'Untitled agenda', taklingPoint: 2, status: 'Unscheduled', type: 'personal'},
    {title: 'Untitled agenda', taklingPoint: 0, status: 'Unscheduled', type: 'personal'},
  ]
  const isOneOnOneSDataLoading = false
  const teams = [
    {value: 'all', key: 'all', text: 'All user'},
    {value: 'team 1', key: 'team 1', text: 'user 1'},
    {value: 'team 2', key: 'team 2', text: 'user 2'},
  ]
  const timelines = [
    {value: 'all', key: 'all', text: 'All'},
    {value: 'This week', key: 'This week', text: 'This week'},
    {value: 'Unscheduled', key: 'Unscheduled', text: 'Unscheduled'},
    {value: 'Unassigned', key: 'Unassigned', text: 'Unassigned'},
  ]
  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <div className="kooper-side-wrap">
      <KooperContainer>
        <div className="page-header">
          <div className="page-title">
            <div className="page-titleIcon">
              <SvgIcon path="navigation/sales-orders" />
              <h5>1-on-1s ({oneOnOneList ? oneOnOneList.length : 0})</h5>
            </div>
          </div>
          <div className="page-action">
            {/* <SearchComponent /> */}
            <div className="mailActivity ml-3">
              <SearchComponent placeholder="search" />
              <KooperDropdown
                icon="chevron down"
                button
                search
                defaultValue="all"
                options={teams}
                // onChange={(e, {value}) => {
                //   setCurrentListParams({
                //     ...currentListParams,
                //     status: value,
                //   })
                // }}
              />
              <KooperDropdown icon="chevron down" button defaultValue="all" options={timelines} />
            </div>

            <LockPermissionTooltip // </div>isRoleAccessDenied={!manageSalesOrdersPermissions}
            >
              <KooperButton
                primary
                onClick={() => history.push(`/${ONE_ON_ONE_S_PATH.PREFIX}/create/personal`)}
                // className={!manageContactsCompanyPermissions ? 'disabled-button' : ''}
                // onClick={() => manageContactsCompanyPermissions && setIsCreateModal(!isCreateModal)}
                content="Create 1-on-1"
              />
            </LockPermissionTooltip>
          </div>
        </div>

        {isOneOnOneSDataLoading && <CardLoader length={3} />}
        {oneOnOneList?.length === 0 && !isOneOnOneSDataLoading && (
          <KooperCard fluid className="project-list-card">
            <KooperCardContent>
              <KooperNoDataTable />
            </KooperCardContent>
          </KooperCard>
        )}
        {oneOnOneList?.length > 0 && !isOneOnOneSDataLoading
          ? oneOnOneList?.map(item => <OneOnOneSCardItem key={item.id} item={item} />)
          : null}
        {/* {deleteModal && (
        <DeleteModal
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete ?"
          deleteAction={deleteSalesOrders}
          idTobeDeleted={editId}
          type={DELETE_SALES_ORDERS}
        />
      )} */}
        {/* {isModalOpen && <CreateOneOnOnesModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />} */}
      </KooperContainer>
    </div>
  )
}

export default PersonalOneOnOneSList
