import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperDivider,
  KooperGrid,
  KooperGridColumn,
  KooperHeader,
  KooperIcon,
  KooperLabel,
  KooperProgress,
  KooperTooltip,
} from 'kooper-library'
import SvgIcon from 'components/common/SvgIcon'
import {useEffect, useState} from 'react'

import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {getActivityLogs, getAGoal} from 'actions/goalDetails'
import {getFormattedDate, startCase} from 'utils/helper'
import {getUserInfo} from 'utils/local-storage'
import ActivityCard from './ActivityCard'
import UpdateGoalsModal from './UpdateGoalsModal'
import GoalsCardItem from '../GoalListPage/GoalsCardItem'

const TeamNOrganizationGoalsDetails = () => {
  const {goalId} = useParams()
  const dispatch = useDispatch()
  const [queryFilters, setQueryFilters] = useState({
    limit: 10,
    page: 1,
  })
  const localStorageData = getUserInfo()

  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false)
  const {
    type = '',
    name = '',
    description = '',
    ownerDetails = {},
    startDate = null,
    endDate = null,
    status = '',
    progress,
    results = [],
    goals = [],
  } = useSelector(state => state.goalDetails)

  useEffect(() => {
    if (goalId) {
      dispatch(getAGoal(goalId))
      dispatch(getActivityLogs(goalId, queryFilters))
    }
  }, [dispatch, goalId])
  return (
    <>
      <KooperCard fluid>
        <KooperCardContent>
          <div className="d-flex">
            <div className="d-flex">
              <SvgIcon path="team/organization2" className="mr-2" width="25px" height="25px" style={{stroke: 'none'}} />
              <div>
                <span style={{fontSize: '0.9em', color: 'rgba(0,0,0,.6)'}}>{startCase(type)} Goal</span>
              </div>
            </div>
            <div className="icon-row" style={{display: 'flex', gap: '1rem'}}>
              <KooperTooltip
                content="Delete"
                size="mini"
                position="top center"
                trigger={<KooperButton icon={<SvgIcon path="common/delete" />} />}
              />
              <KooperTooltip
                content="Copy"
                size="mini"
                position="top center"
                trigger={<KooperButton icon={<SvgIcon path="common/clone" />} />}
              />

              <KooperTooltip
                content="Edit"
                size="mini"
                position="top center"
                trigger={<KooperButton icon={<SvgIcon path="common/edit2" />} />}
              />
              {/* <KooperTooltip
                content="Delete"
                size="mini"
                position="top center"
                trigger={<KooperButton icon={<SvgIcon path="common/share" />} />}
              /> */}
            </div>
          </div>
          <KooperHeader as="h3" className="mt-3">
            {name}
          </KooperHeader>
          <KooperHeader as="h3" color="grey" className="my-3">
            {description}
          </KooperHeader>
          <KooperGrid columns={2} style={{marginBottom: '2em'}}>
            <KooperGridColumn>
              <div className="d-flex jcfs">
                <SvgIcon path="inbox/user" width="20px" height="20px" />
                <div className="ml-3">
                  <div style={{color: 'rgba(0,0,0,.6)', fontSize: '0.9em', marginBottom: '0.2em'}}>GOAL OWNER</div>
                  <span>
                    {ownerDetails?.id === localStorageData?.id
                      ? 'You'
                      : `${ownerDetails?.firstName} ${ownerDetails?.lastName}`}
                  </span>
                </div>
              </div>
            </KooperGridColumn>
            <KooperGridColumn>
              <div>
                <div style={{color: 'rgba(0,0,0,.6)', fontSize: '0.9em', marginBottom: '0.2em'}}>TIMELINE</div>
                <div>
                  {!startDate && !endDate
                    ? '—'
                    : startDate && endDate
                    ? `${getFormattedDate(startDate)} to ${getFormattedDate(endDate)}`
                    : startDate
                    ? `Start ${getFormattedDate(startDate)}`
                    : endDate
                    ? `Ends ${getFormattedDate(endDate)}`
                    : ''}
                </div>
              </div>
            </KooperGridColumn>
          </KooperGrid>
          <KooperDivider />
          <div style={{marginBottom: '2em'}} className="mt-2">
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1em'}}>
              <div>
                <span style={{fontSize: '1.2em', fontWeight: '500', marginRight: '1em'}}>Progress</span>
                <KooperLabel size="tiny" color="blue" style={{background: '#f0f5ff', color: '#3182ce'}}>
                  {startCase(status)}
                </KooperLabel>
              </div>
              <KooperButton onClick={() => setIsOpenUpdateModal(true)} primary>
                Update progress
              </KooperButton>
            </div>
            <div className="d-flex" style={{gap: 10}}>
              <div style={{flex: 1}}>
                <KooperProgress
                  size="tiny"
                  style={{
                    margin: 0,
                    flex: 1,
                    background: '#edf2f7',
                    height: '8px',
                  }}
                  percent={progress}
                />
              </div>
              <div style={{textAlign: 'right', color: 'rgba(0,0,0,.6)', fontWeight: 600}}>{progress}%</div>
            </div>
          </div>

          <div className="goal-key-results">
            <div className="key-results-title">Key results</div>
            {results?.map(({title}, index) => (
              <div className="d-flex">
                <div className="key-result-item" key={index}>
                  <KooperIcon name="flag outline" />
                  <span>{title}</span>
                </div>
                <div className="key-results-pagination">
                  <span>1 / 11</span>
                </div>
              </div>
            ))}
          </div>
        </KooperCardContent>
      </KooperCard>
      <KooperHeader as="h3">Sub-goals</KooperHeader>
      {goals && goals?.length > 0
        ? goals?.map(goal => (
            <GoalsCardItem
              isSubGoal
              // reportPermissions={reportPermissions?.banner}
              // managePermission={assistPermissions?.manage_banner}
              // setCustomDeleteBanner={setCustomDeleteBanner}
              key={goal.id}
              goal={goal}
              // setCustomPublish={setCustomPublish}
            />
          ))
        : null}
      <KooperHeader as="h3">Activity</KooperHeader>
      <ActivityCard
        activities={[
          {title: 'activity 1', time: 'Today, 10:00 AM'},
          {title: 'activity 2', time: 'Today, 11:00 AM'},
        ]}
      />
      {isOpenUpdateModal && <UpdateGoalsModal isModalOpen={isOpenUpdateModal} setIsModalOpen={setIsOpenUpdateModal} />}
    </>
  )
}

export default TeamNOrganizationGoalsDetails
