import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ResponsivePie} from '@nivo/pie'
import {ResponsiveLine} from '@nivo/line'
import _ from 'lodash'
import Moment from 'moment'
import {
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperGrid,
  KooperGridColumn,
  KooperNoData,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'

import {TAGGED_VS_NON_TAGGED_OVERTIME, TAGS_COUNT_TABLE, TOTAL_CONVERSATION, CONVERSATION_BY_INBOX} from 'actions/types'
import {
  averageRepliesResolution,
  averageResolutionTimeStats,
  firstContactResolved,
  firstResponseTimeStats,
  newContactsAnalytics,
  npsScoreStats,
  taggedVsNonTaggedOvertime,
  tagsCountTable,
  totalConversationAnalytics,
  conversationByInbox,
} from 'actions/analytics_inbox'
import CustomLoader from 'components/common/CustomLoader'
import NoDataChart from 'components/common/NoDataChart'
import {adjustTimeUnit, get, getSortType, startCase} from 'utils/helper'
import {getGridYValues, maxOfGraph} from 'utils/graph'
import KooperCustomPagination from 'components/common/KooperCustomPagination'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import {AssigneeDropdown} from 'components/pages/Analytics/AnalyticsUtils'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {DATE_RANGE_KEY} from 'constants/variables'

const Inbox = () => {
  const dispatch = useDispatch()

  const [data, setData] = useState(JSON.parse(localStorage.getItem(DATE_RANGE_KEY)).persistDateRangeDataToDispatch)
  const [currentListParams, setCurrentListParams] = useState({
    page: 1,
    sortBy: '',
    sortType: '',
  })

  const {type = []} = useSelector(state => state.apiReducer)
  const {
    totalConversation: totalConversationData = [],
    customSatisfactionData = {},
    firstResponseTimeData = {},
    firstContactResolvedData = {},
    averageRepliesResolutionData = {},
    averageResoltionTimeData = {},
    newContactsAnalyticsData = {},
    taggedVsNonTaggedOvertimeData = [],
    analyticsSelectedAssignee,
    conversationByInboxData = {},
  } = useSelector(state => state.analyticsInbox)
  const {conversationData = []} = conversationByInboxData
  const {reportsPermissions} = useUserPermissions()
  const conversationsReportsPermissions = reportsPermissions?.conversations_reports

  useEffect(() => {
    if (!conversationsReportsPermissions) return
    const newList = _.omitBy(currentListParams, value => {
      if (value === 'none' || value === '') {
        return true
      }
      return false
    })
    dispatch(tagsCountTable({...data, ...newList}))
  }, [conversationsReportsPermissions, currentListParams, dispatch, data])

  const {
    isLoading: isLoadingTagsCountTable,
    data: tagsCountTableData = {},
    totalPages,
    currentPage,
  } = useApiResponse({
    label: TAGS_COUNT_TABLE,
    storePath: 'analyticsInbox.tagsCountTableData',
  })

  const actionsToDispatch = useCallback(
    data => {
      dispatch(totalConversationAnalytics(data))
      dispatch(npsScoreStats(data))
      dispatch(firstResponseTimeStats(data))
      dispatch(firstContactResolved(data))
      dispatch(averageRepliesResolution(data))
      dispatch(averageResolutionTimeStats(data))
      dispatch(newContactsAnalytics(data))
      dispatch(taggedVsNonTaggedOvertime(data))
      dispatch(conversationByInbox(data))
      setData(data)
    },
    [dispatch]
  )

  const handleSort = clickedColumn => () => {
    if (currentListParams.sortBy !== clickedColumn) {
      setCurrentListParams({
        ...currentListParams,
        sortType: 'asc',
        sortBy: clickedColumn,
      })
    } else {
      setCurrentListParams({
        ...currentListParams,
        sortType: currentListParams.sortType === 'asc' ? 'desc' : 'asc',
      })
    }
  }

  const renderTagsBody = () => {
    if (isLoadingTagsCountTable) {
      return <KooperTablePlaceholder columns={3} lines={2} />
    }
    if (!isLoadingTagsCountTable && get(['data'], tagsCountTableData, []).length === 0) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="3" className="emptyValue">
            <KooperNoData type="table" noDataText="No Data Available" />
          </KooperTableCell>
        </KooperTableRow>
      )
    }
    return get(['data'], tagsCountTableData, []).map(item => (
      <KooperTableRow key={item.label}>
        <KooperTableCell>{startCase(item.label)}</KooperTableCell>
        <KooperTableCell>{item.count}</KooperTableCell>
        <KooperTableCell>{item.change.toFixed(1)}%</KooperTableCell>
      </KooperTableRow>
    ))
  }

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/inbox" />
            <h5>Inbox</h5>
          </div>
        </div>
        {conversationsReportsPermissions && (
          <div className="page-action">
            <AssigneeDropdown />
            <DateRangeGroup
              actionsToDispatch={actionsToDispatch}
              userId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'assignee'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
              teamId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'team'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
            />
          </div>
        )}
      </div>

      <KooperGrid columns="equal">
        <KooperGridColumn>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Total Conversations</KooperCardHeader>
              <KooperTooltip
                content="Overview of total conversations in inbox"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent style={{height: 250}}>
              {type.includes(TOTAL_CONVERSATION) && <CustomLoader />}
              {!type.includes(TOTAL_CONVERSATION) && !totalConversationData.every(({value}) => value === 0) ? (
                <ResponsivePie
                  data={totalConversationData.map(item => ({
                    ...item,
                    label: `${item.label}: ${item.percentage.toFixed(1)}%`,
                  }))}
                  margin={{top: 20, right: 150, bottom: 20, left: 20}}
                  startAngle={-50}
                  innerRadius={0.7}
                  padAngle={1}
                  cornerRadius={1}
                  colors={d => d.data.color}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.4]],
                  }}
                  arcLinkLabel={e => `${e.id} (${e.value})`}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsDiagonalLength={8}
                  arcLinkLabelsStraightLength={12}
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{from: 'color'}}
                  enableArcLabels={false}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [['darker', 2]],
                  }}
                  animate
                  legends={[
                    {
                      anchor: 'right',
                      direction: 'column',
                      justify: false,
                      translateX: 120,
                      translateY: 0,
                      itemsSpacing: 10,
                      itemWidth: 120,
                      itemHeight: 10,
                      itemTextColor: '#999',
                      itemDirection: 'left-to-right',
                      itemOpacity: 1,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                !type.includes(TOTAL_CONVERSATION) && <KooperNoData type="chart" noDataText="No Data Available" />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
        <KooperGridColumn>
          <KooperGrid>
            <KooperGridColumn width={8}>
              <KooperCard fluid>
                <KooperCardContent className="valueBox">
                  <KooperCardHeader>Customer Satisfaction</KooperCardHeader>
                  <h3>
                    {customSatisfactionData.nps}
                    <span
                      className={
                        get(['growth'], customSatisfactionData, 'positive') === 'positive' ? '' : 'text-danger'
                      }
                    >
                      <KooperTooltip
                        position="top center"
                        size="mini"
                        content="Compared to Previous Duration"
                        trigger={
                          <div>
                            {get(['growth'], customSatisfactionData, 'positive') === 'positive' ? (
                              <SvgIcon path="common/arrow-up-right" />
                            ) : (
                              <SvgIcon path="common/arrow-down-left" className="down-right" />
                            )}
                            {get(['percentage'], customSatisfactionData, 0)}%
                          </div>
                        }
                      />
                    </span>
                  </h3>
                </KooperCardContent>
              </KooperCard>
            </KooperGridColumn>
            <KooperGridColumn width={8}>
              <KooperCard fluid>
                <KooperCardContent className="valueBox">
                  <KooperCardHeader>First Response Time</KooperCardHeader>
                  <h3>
                    {adjustTimeUnit(firstResponseTimeData.avg)}
                    <span
                      className={get(['growth'], firstResponseTimeData, 'positive') === 'positive' ? '' : 'text-danger'}
                    >
                      <KooperTooltip
                        position="top center"
                        size="mini"
                        content="Compared to Previous Duration"
                        trigger={
                          <div>
                            {get(['growth'], firstResponseTimeData, 'positive') === 'positive' ? (
                              <SvgIcon path="common/arrow-up-right" />
                            ) : (
                              <SvgIcon path="common/arrow-down-left" className="down-right" />
                            )}
                            {firstResponseTimeData.percentage}%
                          </div>
                        }
                      />
                    </span>
                  </h3>
                </KooperCardContent>
              </KooperCard>
            </KooperGridColumn>
            <KooperGridColumn width={8}>
              <KooperCard fluid>
                <KooperCardContent className="valueBox">
                  <KooperCardHeader>First Contact Resolved</KooperCardHeader>
                  <h3>
                    {firstContactResolvedData.resolved}
                    <span
                      className={
                        get(['growth'], firstContactResolvedData, 'positive') === 'positive' ? '' : 'text-danger'
                      }
                    >
                      <KooperTooltip
                        position="top center"
                        size="mini"
                        content="Compared to Previous Duration"
                        trigger={
                          <div>
                            {get(['growth'], firstContactResolvedData, 'positive') === 'positive' ? (
                              <SvgIcon path="common/arrow-up-right" />
                            ) : (
                              <SvgIcon path="common/arrow-down-left" className="down-right" />
                            )}
                            {firstContactResolvedData.percentage}%
                          </div>
                        }
                      />
                    </span>
                  </h3>
                </KooperCardContent>
              </KooperCard>
            </KooperGridColumn>
            <KooperGridColumn width={8}>
              <KooperCard fluid>
                <KooperCardContent className="valueBox">
                  <KooperCardHeader>Avg Replies Per Resolution</KooperCardHeader>
                  <h3>
                    {averageRepliesResolutionData.avg}
                    <span
                      className={
                        get(['growth'], averageRepliesResolutionData, 'positive') === 'positive' ? '' : 'text-danger'
                      }
                    >
                      <KooperTooltip
                        position="top center"
                        size="mini"
                        content="Compared to Previous Duration"
                        trigger={
                          <div>
                            {get(['growth'], averageRepliesResolutionData, 'positive') === 'positive' ? (
                              <SvgIcon path="common/arrow-up-right" />
                            ) : (
                              <SvgIcon path="common/arrow-down-left" className="down-right" />
                            )}
                            {averageRepliesResolutionData.percentage}%
                          </div>
                        }
                      />
                    </span>
                  </h3>
                </KooperCardContent>
              </KooperCard>
            </KooperGridColumn>
            <KooperGridColumn width={8}>
              <KooperCard fluid>
                <KooperCardContent className="valueBox">
                  <KooperCardHeader>Avg Resolution Time</KooperCardHeader>
                  <h3>
                    {adjustTimeUnit(averageResoltionTimeData.avg)}
                    <span
                      className={
                        get(['growth'], averageResoltionTimeData, 'positive') === 'positive' ? '' : 'text-danger'
                      }
                    >
                      <KooperTooltip
                        position="top center"
                        size="mini"
                        content="Compared to Previous Duration"
                        trigger={
                          <div>
                            {get(['growth'], averageResoltionTimeData, 'positive') === 'positive' ? (
                              <SvgIcon path="common/arrow-up-right" />
                            ) : (
                              <SvgIcon path="common/arrow-down-left" className="down-right" />
                            )}
                            {averageResoltionTimeData.percentage}%
                          </div>
                        }
                      />
                    </span>
                  </h3>
                </KooperCardContent>
              </KooperCard>
            </KooperGridColumn>
            <KooperGridColumn width={8}>
              <KooperCard fluid>
                <KooperCardContent className="valueBox">
                  <KooperCardHeader>New Contacts</KooperCardHeader>
                  <h3>
                    {newContactsAnalyticsData.newContactsCreated}
                    <span
                      className={
                        get(['growth'], newContactsAnalyticsData, 'positive') === 'positive' ? '' : 'text-danger'
                      }
                    >
                      <KooperTooltip
                        position="top center"
                        size="mini"
                        content="Compared to Previous Duration"
                        trigger={
                          <div>
                            {newContactsAnalyticsData.growth === 'positive' ? (
                              <SvgIcon path="common/arrow-up-right" />
                            ) : (
                              <SvgIcon path="common/arrow-down-left" className="down-right" />
                            )}
                            {newContactsAnalyticsData.percentage}%
                          </div>
                        }
                      />
                    </span>
                  </h3>
                </KooperCardContent>
              </KooperCard>
            </KooperGridColumn>
          </KooperGrid>
        </KooperGridColumn>
      </KooperGrid>

      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Tags Usage</KooperCardHeader>
              <KooperTooltip
                content="Graphical representation of tag usages over specific period of time"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            {!type.includes(TAGGED_VS_NON_TAGGED_OVERTIME) && taggedVsNonTaggedOvertimeData.length > 0 && (
              <KooperCardContent className="pt-0" style={{marginLeft: '30px'}}>
                <KooperGrid>
                  <KooperGridColumn width={2}>
                    <KooperCard fluid style={{boxShadow: 'none'}}>
                      <KooperCardContent className="headerBox pb-0">
                        <KooperCardHeader>{taggedVsNonTaggedOvertimeData[1].id}</KooperCardHeader>
                        <KooperTooltip
                          content={taggedVsNonTaggedOvertimeData[1].id}
                          size="mini"
                          position="top center"
                          trigger={<SvgIcon path="common/question" />}
                        />
                      </KooperCardContent>
                      <KooperCardContent className="valueBox pt-2">
                        <h3>
                          {taggedVsNonTaggedOvertimeData[1].total}
                          <h5>
                            ({taggedVsNonTaggedOvertimeData[1].percentage.toFixed(1)}
                            %)
                          </h5>
                        </h3>
                      </KooperCardContent>
                    </KooperCard>
                  </KooperGridColumn>
                  <KooperGridColumn width={3}>
                    <KooperCard fluid style={{boxShadow: 'none'}}>
                      <KooperCardContent className="headerBox pb-0">
                        <KooperCardHeader>{taggedVsNonTaggedOvertimeData[0].id}</KooperCardHeader>
                        <KooperTooltip
                          content={taggedVsNonTaggedOvertimeData[0].id}
                          size="mini"
                          position="top center"
                          trigger={<SvgIcon path="common/question" />}
                        />
                      </KooperCardContent>
                      <KooperCardContent className="valueBox pt-2">
                        <h3>
                          {taggedVsNonTaggedOvertimeData[0].total}
                          <h5>
                            ({taggedVsNonTaggedOvertimeData[0].percentage.toFixed(1)}
                            %)
                          </h5>
                        </h3>
                      </KooperCardContent>
                    </KooperCard>
                  </KooperGridColumn>
                </KooperGrid>
              </KooperCardContent>
            )}
            <KooperCardContent className="pt-0" style={{height: 300}}>
              {type.includes(TAGGED_VS_NON_TAGGED_OVERTIME) && <CustomLoader />}
              {!type.includes(TAGGED_VS_NON_TAGGED_OVERTIME) &&
              !taggedVsNonTaggedOvertimeData.map(({data}) => data.every(({y}) => y === 0)).includes(false) ? (
                <KooperNoData type="chart" noDataText="No Data Available" />
              ) : (
                !type.includes(TAGGED_VS_NON_TAGGED_OVERTIME) && (
                  <ResponsiveLine
                    data={taggedVsNonTaggedOvertimeData}
                    margin={{
                      top: 27,
                      right: 27,
                      bottom: 60,
                      left: 60,
                    }}
                    xScale={{type: 'point'}}
                    yScale={{
                      type: 'linear',
                      min: 0,
                      max: maxOfGraph(taggedVsNonTaggedOvertimeData),
                      stacked: false,
                      reverse: false,
                    }}
                    enableSlices="x"
                    enableGridX={false}
                    gridYValues={getGridYValues(taggedVsNonTaggedOvertimeData)}
                    curve="monotoneX"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      orient: 'bottom',
                      tickSize: 5,
                      format: tick => {
                        const diff = Moment(
                          get(['persistDateRangeEndDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))
                        ).diff(
                          Moment(
                            get(['persistDateRangeStartDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))
                          ),
                          'days'
                        )
                        if (diff > 0 && diff < 17) {
                          return tick
                        }
                        return Number(tick.match(/\d+/)) % 2 ? '' : tick
                      },
                      tickPadding: 5,
                      legend: 'Time',
                      legendOffset: 40,
                      legendPosition: 'middle',
                    }}
                    axisLeft={{
                      orient: 'left',
                      tickPadding: 5,
                      tickRotation: 0,
                      tickValues: getGridYValues(taggedVsNonTaggedOvertimeData),
                      legend: 'Conversations',
                      legendOffset: -40,
                      legendPosition: 'middle',
                    }}
                    colors={d => d.color}
                    lineWidth={2}
                    pointSize={3}
                    pointColor={{theme: 'grid.line.stroke'}}
                    pointBorderWidth={2}
                    pointBorderColor={{from: 'serieColor'}}
                    pointLabel="y"
                    pointLabelYOffset={-10}
                    enableArea
                    useMesh
                    legends={[
                      {
                        anchor: 'top',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: -30,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 73,
                        itemHeight: 30,
                        itemOpacity: 1,
                        symbolSize: 10,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemTextColor: '#000',
                              itemOpacity: 0.75,
                            },
                          },
                        ],
                      },
                    ]}
                    animate
                    motionConfig="molasses"
                  />
                )
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Conversation By Inbox</KooperCardHeader>
              <KooperTooltip
                content="Breakdown of conversations based on inbox over a period of time"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 270}}>
              {type.includes(CONVERSATION_BY_INBOX) && <CustomLoader />}
              {!type.includes(CONVERSATION_BY_INBOX) && !conversationData.every(({value}) => value === 0) ? (
                <ResponsivePie
                  data={conversationData}
                  margin={{top: 20, right: 20, bottom: 20, left: 20}}
                  startAngle={-90}
                  innerRadius={0.5}
                  padAngle={2}
                  cornerRadius={0}
                  // colors={(d) => d.color}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.4]],
                  }}
                  enableRadialLabels
                  enableSlicesLabels
                  sliceLabel={({value}) => `${value}`}
                  radialLabelsSkipAngle={9}
                  radialLabelsTextXOffset={3}
                  radialLabelsTextColor="#333333"
                  radialLabelsLinkOffset={-10}
                  radialLabelsLinkDiagonalLength={16}
                  radialLabelsLinkHorizontalLength={8}
                  radialLabelsLinkStrokeWidth={1}
                  radialLabelsLinkColor={{from: 'color'}}
                  slicesLabelsSkipAngle={13}
                  slicesLabelsTextColor="#333333"
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                  tooltip={({meetingHeading, value}) => (
                    <>
                      <strong>
                        {meetingHeading}: {value}
                      </strong>
                    </>
                  )}
                />
              ) : (
                !type.includes(CONVERSATION_BY_INBOX) && <KooperNoData type="chart" noDataText="No Data Available" />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Tags Usage Breakdown</KooperCardHeader>
              <KooperTooltip
                content="List of tags along with their usage in conversations over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0">
              <KooperTable sortable basic>
                <KooperTableHeader>
                  <KooperTableRow>
                    <KooperTableHeaderCell
                      sorted={currentListParams.sortBy === 'label' ? getSortType(currentListParams.sortType) : null}
                      onClick={handleSort('label')}
                    >
                      Tag
                    </KooperTableHeaderCell>
                    <KooperTableHeaderCell
                      sorted={currentListParams.sortBy === 'count' ? getSortType(currentListParams.sortType) : null}
                      onClick={handleSort('count')}
                    >
                      No. of charts with this tag
                    </KooperTableHeaderCell>
                    <KooperTableHeaderCell
                      sorted={currentListParams.sortBy === 'change' ? getSortType(currentListParams.sortType) : null}
                      onClick={handleSort('change')}
                    >
                      % of tagged charts
                    </KooperTableHeaderCell>
                  </KooperTableRow>
                </KooperTableHeader>
                <KooperTableBody>{renderTagsBody()}</KooperTableBody>
              </KooperTable>
              <KooperCustomPagination
                activePage={currentPage}
                totalPages={totalPages}
                onPageChange={page =>
                  setCurrentListParams(currentListParams => ({
                    ...currentListParams,
                    page,
                  }))
                }
              />
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
    </div>
  )
}

export default Inbox
