import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ResponsivePie} from '@nivo/pie'
import {
  KooperGrid,
  KooperCard,
  KooperGridColumn,
  KooperCardContent,
  KooperCardHeader,
  KooperGridRow,
  KooperTooltip,
  KooperNoData,
} from 'kooper-library'

import {COMPANIES_BY_STATUS} from 'actions/types'
import {companiesStats, companiesByType} from 'actions/reports_companies'
import {AssigneeDropdown} from 'components/pages/Analytics/AnalyticsUtils'
import {get} from 'utils/helper'
import NoDataChart from 'components/common/NoDataChart'
import CustomLoader from 'components/common/CustomLoader'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'

const CompanyAnalytics = () => {
  const dispatch = useDispatch()

  const {companiesStatsData, companiesByStatusData = []} = useSelector(state => state.reportsCompanies)
  const {analyticsSelectedAssignee} = useSelector(state => state.analyticsInbox)
  const {type = []} = useSelector(state => state.apiReducer)
  const {reportsPermissions} = useUserPermissions()
  const customersReportsPermissions = reportsPermissions?.customers_reports

  const actionsToDispatch = useCallback(
    data => {
      dispatch(companiesStats(data))
      dispatch(companiesByType(data))
    },
    [dispatch]
  )
  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/companies" />
            <h5>Companies</h5>
          </div>
        </div>
        {customersReportsPermissions && (
          <div className="page-action">
            <AssigneeDropdown />
            <DateRangeGroup
              actionsToDispatch={actionsToDispatch}
              userId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'assignee'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
              teamId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'team'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
            />
          </div>
        )}
      </div>
      <KooperGrid columns="equal">
        <KooperGridColumn width={8} className="small-cards-home">
          <KooperCard fluid>
            <KooperCardContent>
              <KooperGrid columns={2}>
                <KooperGridRow>
                  <KooperGridColumn>
                    <KooperCardContent className="valueBox">
                      <KooperCardHeader>Total</KooperCardHeader>
                      <h3>
                        {get(['total'], companiesStatsData, '')}
                        <KooperTooltip
                          content="Total"
                          size="mini"
                          position="bottom center"
                          trigger={
                            <span
                              className={
                                get(['new', 'growth'], companiesStatsData, []) === 'positive' ? '' : 'text-danger'
                              }
                            >
                              {get(['new', 'growth'], companiesStatsData, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['new', 'percentage'], companiesStatsData, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </KooperCardContent>
                  </KooperGridColumn>
                  <KooperGridColumn>
                    <KooperCardContent className="valueBox">
                      <KooperCardHeader>New</KooperCardHeader>
                      <h3>
                        {get(['new', 'value'], companiesStatsData, '')}
                        <KooperTooltip
                          content="Total"
                          size="mini"
                          position="bottom center"
                          trigger={
                            <span
                              className={
                                get(['new', 'growth'], companiesStatsData, []) === 'positive' ? '' : 'text-danger'
                              }
                            >
                              {get(['new', 'growth'], companiesStatsData, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['new', 'percentage'], companiesStatsData, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </KooperCardContent>
                  </KooperGridColumn>
                </KooperGridRow>
              </KooperGrid>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
        <KooperGridColumn width={8}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Companies By Type</KooperCardHeader>
              <KooperTooltip
                content="Breakdown of companies with respect to type"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(COMPANIES_BY_STATUS) && <CustomLoader />}
              {!type.includes(COMPANIES_BY_STATUS) && !companiesByStatusData.every(({value}) => value === 0) ? (
                <ResponsivePie
                  data={companiesByStatusData}
                  margin={{top: 30, right: 30, bottom: 30, left: 30}}
                  startAngle={-45}
                  innerRadius={0.5}
                  padAngle={2}
                  cornerRadius={0}
                  borderWidth={1}
                  enableArcLinkLabels={false}
                  sliceLabel={({value}) => `${value}`}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  radialLabelsSkipAngle={10}
                  radialLabelsTextXOffset={6}
                  radialLabelsTextColor="#333333"
                  radialLabelsLinkOffset={0}
                  radialLabelsLinkDiagonalLength={7}
                  radialLabelsLinkHorizontalLength={13}
                  radialLabelsLinkStrokeWidth={1}
                  radialLabelsLinkColor={{from: 'color'}}
                  slicesLabelsSkipAngle={10}
                  slicesLabelsTextColor="#333333"
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                  tooltip={({
                    datum: {
                      data: {id, value, percentage},
                    },
                  }) => (
                    <>
                      <strong>
                        {id}: {value} ({percentage}%)
                      </strong>
                    </>
                  )}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                !type.includes(COMPANIES_BY_STATUS) && <KooperNoData type="chart" noDataText="No Data Available" />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
    </div>
  )
}
export default CompanyAnalytics
