/* eslint-disable no-nested-ternary */
import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ResponsivePie} from '@nivo/pie'
import {ResponsiveBar} from '@nivo/bar'
import _ from 'lodash'
import {
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperCardMeta,
  KooperDropdown,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperGrid,
  KooperGridColumn,
  KooperNoData,
  KooperRadio,
  KooperTooltip,
} from 'kooper-library'
import {
  CONVERSATION_BY_CHANNELS,
  GET_USERS,
  PROJECTS_BY_BUDGET_TYPE,
  PROJECTS_TIMES_BY_USER,
  STATUS_WISE_DEALS,
} from 'actions/types'
import {conversationByChannel, projectsByBudgetType, projectTimesByUser} from 'actions/home'
import SvgIcon from 'components/common/SvgIcon'

import {statusWiseDeals} from 'actions/dashboards_sales'
import {getGridYValuesBar, maxOfGraphBar} from 'utils/graph'
import NoDataChart from 'components/common/NoDataChart'
import CustomLoader from 'components/common/CustomLoader'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import {getUsers} from 'actions/settings'
import useApiResponse from 'hooks/impure/useApiResponse'

function Overview() {
  const dispatch = useDispatch()

  const [dealsRadio, setDealsRadio] = useState('value')
  const [allData, setAllData] = useState({})
  const [projectTimesUser, setProjectTimesUser] = useState('all')
  const [projectTimesUserData, setProjectTimesUserData] = useState({})
  const [userOption, setUserOption] = useState([])

  const {type = []} = useSelector(state => state.apiReducer)
  const {
    conversationByChannelsData = [],
    projectsByBudgetTypeData = [],
    projectTimesByUserData = [],
  } = useSelector(state => state.home)
  const {statusWiseDealsData = []} = useSelector(state => state.dashboardsSales)

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUserOption(list || [])
    },
  })

  const actionsToDispatch = useCallback(
    data => {
      dispatch(conversationByChannel(data))
      dispatch(projectsByBudgetType(data))
      setProjectTimesUserData(data)
      setAllData(_.omit(data, 'graphType'))
    },
    [dispatch]
  )

  useEffect(() => {
    if (dealsRadio) {
      allData.graphType = dealsRadio
      dispatch(statusWiseDeals(allData))
    }
  }, [dispatch, allData, dealsRadio])

  useEffect(() => {
    if (typeof projectTimesUser !== 'string') {
      dispatch(projectTimesByUser({...projectTimesUserData, ...projectTimesUser}))
    } else {
      dispatch(projectTimesByUser(projectTimesUserData))
    }
  }, [projectTimesUserData, projectTimesUser, dispatch])

  return (
    <div style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="pageHeader">
        <div className="pageTitle">
          <div className="pageTitleIcon">
            <SvgIcon path="home/overview" />
            <h5>Overview</h5>
          </div>
        </div>
        <div className="pageAction">
          <DateRangeGroup actionsToDispatch={actionsToDispatch} />
        </div>
      </div>
      <div style={{padding: '0px 18px'}}>
        <KooperGrid columns="equal">
          <KooperGridColumn width={8}>
            <KooperCard fluid className="chartBox">
              <KooperCardContent className="headerBox">
                <KooperCardHeader>Conversations By Channels</KooperCardHeader>
                <KooperTooltip
                  content="Breakdown of conversations based on channels"
                  size="mini"
                  position="top center"
                  trigger={<SvgIcon path="common/question" />}
                />
              </KooperCardContent>
              <KooperCardContent className="pt-0" style={{height: 330}}>
                {type.includes(CONVERSATION_BY_CHANNELS) && <CustomLoader />}
                {!type.includes(CONVERSATION_BY_CHANNELS) &&
                !conversationByChannelsData?.every(({value}) => value === 0) ? (
                  <ResponsivePie
                    data={conversationByChannelsData}
                    colors={{datum: 'data.color'}}
                    margin={{top: 30, right: 30, bottom: 30, left: 30}}
                    startAngle={-45}
                    innerRadius={0.5}
                    padAngle={2}
                    cornerRadius={0}
                    borderWidth={1}
                    enableArcLinkLabels={false}
                    sliceLabel={({value}) => `${value}`}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.2]],
                    }}
                    radialLabelsSkipAngle={10}
                    radialLabelsTextXOffset={6}
                    radialLabelsTextColor="#333333"
                    radialLabelsLinkOffset={0}
                    radialLabelsLinkDiagonalLength={7}
                    radialLabelsLinkHorizontalLength={13}
                    radialLabelsLinkStrokeWidth={1}
                    radialLabelsLinkColor={{from: 'color'}}
                    slicesLabelsSkipAngle={10}
                    slicesLabelsTextColor="#333333"
                    animate
                    motionStiffness={90}
                    motionDamping={15}
                    tooltip={({
                      datum: {
                        data: {id, value, percentage},
                      },
                    }) => (
                      <>
                        <strong>
                          {id}: {value} ({percentage}%)
                        </strong>
                      </>
                    )}
                    legends={[
                      {
                        anchor: 'right',
                        direction: 'column',
                        justify: false,
                        translateX: 0,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 100,
                        itemHeight: 23,
                        itemOpacity: 0.75,
                        symbolSize: 10,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemTextColor: '#000',
                            },
                          },
                        ],
                      },
                    ]}
                  />
                ) : (
                  !type.includes(CONVERSATION_BY_CHANNELS) && (
                    <KooperNoData type="chart" noDataText="No Data Available" />
                  )
                )}
              </KooperCardContent>
            </KooperCard>
          </KooperGridColumn>

          <KooperGridColumn width={8}>
            <KooperCard fluid className="chartBox">
              <KooperCardContent className="headerBox">
                <KooperCardHeader>Deals</KooperCardHeader>
                <KooperTooltip
                  content="Graphical representation of deals based on status"
                  size="mini"
                  position="top center"
                  trigger={<SvgIcon path="common/question" />}
                />
              </KooperCardContent>
              <KooperCardContent
                className="p-0"
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '15px',
                  zIndex: '1',
                }}
              >
                <KooperCardMeta className="d-flex" style={{alignItems: 'flex-start'}}>
                  <KooperForm>
                    <KooperFormGroup inline>
                      <KooperFormField>
                        <KooperRadio
                          label="Value"
                          checked={dealsRadio === 'value'}
                          onChange={() => {
                            setDealsRadio('value')
                          }}
                        />
                      </KooperFormField>
                      <KooperFormField>
                        <KooperRadio
                          label="Count"
                          checked={dealsRadio === 'count'}
                          onChange={() => setDealsRadio('count')}
                        />
                      </KooperFormField>
                    </KooperFormGroup>
                  </KooperForm>
                </KooperCardMeta>
              </KooperCardContent>
              <KooperCardContent className="pt-0" style={{height: 330}}>
                {type.includes(STATUS_WISE_DEALS) && <CustomLoader />}
                {!type.includes(STATUS_WISE_DEALS) &&
                  (statusWiseDealsData.every(({value}) => value === 0) ? (
                    <KooperNoData type="chart" noDataText="No Data Available" />
                  ) : (
                    <ResponsiveBar
                      data={statusWiseDealsData}
                      // keys={["urgent", "high", "medium", "low", "default"]}
                      indexBy="status"
                      margin={{top: 27, right: 27, bottom: 60, left: 60}}
                      padding={0.3}
                      // colors={(d) => d.data[`${d.id}Color`]}
                      minValue={0}
                      maxValue={maxOfGraphBar(statusWiseDealsData, 'value')}
                      // gridXValues={getGridYValuesBar(statusWiseDealsData, 'value')}
                      enableLabel
                      borderColor={{
                        from: 'color',
                        modifiers: [['darker', 1.6]],
                      }}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Status',
                        legendPosition: 'middle',
                        legendOffset: 40,
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Value',
                        // tickValues: getGridYValuesBar(statusWiseDealsData, 'value'),
                        legendPosition: 'middle',
                        legendOffset: -40,
                      }}
                      labelSkipWidth={12}
                      labelSkipHeight={12}
                      labelTextColor={{
                        from: 'color',
                        modifiers: [['darker', 1.6]],
                      }}
                      animate
                      motionStiffness={90}
                      motionDamping={15}
                      legends={[
                        {
                          anchor: 'top',
                          direction: 'row',
                          justify: false,
                          translateX: 0,
                          translateY: -30,
                          itemsSpacing: 0,
                          itemDirection: 'left-to-right',
                          itemWidth: 73,
                          itemHeight: 23,
                          itemOpacity: 0.75,
                          symbolSize: 10,
                          symbolShape: 'circle',
                          symbolBorderColor: 'rgba(0, 0, 0, .5)',
                          effects: [
                            {
                              on: 'hover',
                              style: {
                                itemTextColor: '#000',
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  ))}
              </KooperCardContent>
            </KooperCard>
          </KooperGridColumn>

          <KooperGridColumn width={8}>
            <KooperCard fluid className="chartBox">
              <KooperCardContent className="headerBox">
                <KooperCardHeader>Projects By Type</KooperCardHeader>
                <KooperTooltip
                  content="Breakdown of projects based on types"
                  size="mini"
                  position="top center"
                  trigger={<SvgIcon path="common/question" />}
                />
              </KooperCardContent>
              <KooperCardContent className="pt-0" style={{height: 330}}>
                {type.includes(PROJECTS_BY_BUDGET_TYPE) && <CustomLoader />}
                {!type.includes(PROJECTS_BY_BUDGET_TYPE) &&
                !projectsByBudgetTypeData?.every(({value}) => value === 0) ? (
                  <ResponsivePie
                    data={projectsByBudgetTypeData}
                    colors={{datum: 'data.color'}}
                    margin={{top: 30, right: 30, bottom: 30, left: 30}}
                    startAngle={-45}
                    innerRadius={0.5}
                    padAngle={2}
                    cornerRadius={0}
                    borderWidth={1}
                    enableArcLinkLabels={false}
                    sliceLabel={({value}) => `${value}`}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.2]],
                    }}
                    radialLabelsSkipAngle={10}
                    radialLabelsTextXOffset={6}
                    radialLabelsTextColor="#333333"
                    radialLabelsLinkOffset={0}
                    radialLabelsLinkDiagonalLength={7}
                    radialLabelsLinkHorizontalLength={13}
                    radialLabelsLinkStrokeWidth={1}
                    radialLabelsLinkColor={{from: 'color'}}
                    slicesLabelsSkipAngle={10}
                    slicesLabelsTextColor="#333333"
                    animate
                    motionStiffness={90}
                    motionDamping={15}
                    tooltip={({
                      datum: {
                        data: {id, value, percentage},
                      },
                    }) => (
                      <>
                        <strong>
                          {id}: {value} ({percentage}%)
                        </strong>
                      </>
                    )}
                    legends={[
                      {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: 30,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 120,
                        itemHeight: 23,
                        itemOpacity: 0.75,
                        symbolSize: 10,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemTextColor: '#000',
                            },
                          },
                        ],
                      },
                    ]}
                  />
                ) : (
                  !type.includes(PROJECTS_BY_BUDGET_TYPE) && (
                    <KooperNoData type="chart" noDataText="No Data Available" />
                  )
                )}
              </KooperCardContent>
            </KooperCard>
          </KooperGridColumn>

          <KooperGridColumn width={8}>
            <KooperCard fluid className="chartBox">
              <KooperCardContent className="headerBox">
                <KooperCardHeader>Projects By User</KooperCardHeader>
                <KooperTooltip
                  content="Graphical representation of projects based on users"
                  size="mini"
                  position="top center"
                  trigger={<SvgIcon path="common/question" />}
                />
              </KooperCardContent>
              <KooperCardContent
                className="p-0"
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '15px',
                  zIndex: '1',
                }}
              >
                <KooperTooltip
                  position="top center"
                  size="mini"
                  content="Users"
                  trigger={
                    <KooperDropdown
                      selection
                      size="mini"
                      className="graph-dropdown"
                      options={[{value: 'all', text: 'All', key: 'all'}, ...userOption]}
                      onChange={(e, {value, options}) =>
                        setProjectTimesUser(
                          options.find(l => l.value === value && l.isTeam)
                            ? {teamId: value}
                            : options.find(l => l.value === value && l.isUser)
                            ? {userId: value}
                            : 'all'
                        )
                      }
                      defaultValue="all"
                    />
                  }
                />
              </KooperCardContent>
              <KooperCardContent className="pt-0" style={{height: 330}}>
                {type.includes(PROJECTS_TIMES_BY_USER) && <CustomLoader />}
                {!type.includes(PROJECTS_TIMES_BY_USER) &&
                  (projectTimesByUserData.every(({value}) => value === 0) ? (
                    <KooperNoData type="chart" noDataText="No Data Available" />
                  ) : (
                    <ResponsiveBar
                      data={projectTimesByUserData}
                      keys={['workedPercentage']}
                      indexBy="title"
                      margin={{top: 27, right: 27, bottom: 60, left: 60}}
                      padding={0.3}
                      // colors={(d) => d.data[`${d.id}Color`]}
                      minValue={0}
                      maxValue={maxOfGraphBar(projectTimesByUserData, 'workedPercentage')}
                      gridXValues={getGridYValuesBar(projectTimesByUserData, 'workedPercentage')}
                      enableLabel
                      borderColor={{
                        from: 'color',
                        modifiers: [['darker', 1.6]],
                      }}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Title',
                        legendPosition: 'middle',
                        legendOffset: 40,
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'workedPercentage',
                        tickValues: getGridYValuesBar(projectTimesByUserData, 'workedPercentage'),
                        legendPosition: 'middle',
                        legendOffset: -40,
                      }}
                      labelSkipWidth={12}
                      labelSkipHeight={12}
                      labelTextColor={{
                        from: 'color',
                        modifiers: [['darker', 1.6]],
                      }}
                      animate
                      motionStiffness={90}
                      motionDamping={15}
                      legends={[
                        {
                          anchor: 'top',
                          direction: 'row',
                          justify: false,
                          translateX: 0,
                          translateY: -30,
                          itemsSpacing: 0,
                          itemDirection: 'left-to-right',
                          itemWidth: 73,
                          itemHeight: 23,
                          itemOpacity: 0.75,
                          symbolSize: 10,
                          symbolShape: 'circle',
                          symbolBorderColor: 'rgba(0, 0, 0, .5)',
                          effects: [
                            {
                              on: 'hover',
                              style: {
                                itemTextColor: '#000',
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  ))}
              </KooperCardContent>
            </KooperCard>
          </KooperGridColumn>
        </KooperGrid>
      </div>
    </div>
  )
}

export default Overview
