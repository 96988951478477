import {apiAction} from './api'
import {GOAL_ENDPOINT} from './endpoint'
import {GET_A_GOAL, GET_ACTIVITY_LOGS} from './types'

export function getAGoal(goalId) {
  return apiAction({
    url: `${GOAL_ENDPOINT}/${goalId}`,
    method: 'GET',
    label: GET_A_GOAL,
  })
}

export function getActivityLogs(goalId, queryFilters) {
  const keys = Object.keys(queryFilters)
  const query = keys
    .filter(key => queryFilters[key])
    .map(key => `${key}=${queryFilters[key]}`)
    .join('&')
  return apiAction({
    url: `${GOAL_ENDPOINT}/${goalId}/logs?${query}`,
    method: 'GET',
    label: GET_ACTIVITY_LOGS,
  })
}
