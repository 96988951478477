/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-syntax */
import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  KooperButton,
  KooperDatePicker,
  KooperDivider,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormSelect,
  KooperFormTextArea,
  KooperTooltip,
  KooperModal,
  KooperModalContent,
  KooperModalHeader,
  KooperModalActions,
} from 'kooper-library'
import {getAccountCurrency} from 'actions/setting_currency'
import {
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  GET_ACCOUNT_CURRENCY,
  GET_PRODUCT_DATA,
  GET_PRODUCTS_CATEGORY,
} from 'actions/types'
import {
  createProduct,
  deleteProductImage,
  getFieldByCategory,
  getProductCategory,
  getProductData,
  getProductlist,
  updateProduct,
} from 'actions/productLibrary'
import {removeDoubleQuotes, get} from 'utils/helper'
import {productsSchema} from 'validation/Sales/Products/product.schema'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

export const billingFreqOptions = [
  {key: 'One Time', value: 'One Time', text: 'One Time'},
  {key: 'Monthly', value: 'Monthly', text: 'Monthly'},
  {key: 'Quarterly', value: 'Quarterly', text: 'Quarterly'},
  {key: 'Half Annually', value: 'Half Annually', text: 'Half Annually'},
  {key: 'Annually', value: 'Annually', text: 'Annually'},
]

const InputField = ({control, errors, field}) => {
  const {name, type, required, values: optionValue} = field

  if (type === 'Textarea') {
    return (
      <Controller
        name={`fields[${name}]`}
        render={({value, onChange}) => (
          <KooperFormTextArea
            label={name}
            required={required}
            value={value}
            onChange={e => onChange(e.target.value)}
            error={
              errors?.fields?.[name] && {
                content: errors.fields[name].message,
              }
            }
          />
        )}
        control={control}
      />
    )
  }

  if (type === 'Dropdown' || type === 'Multiple Option') {
    const options = optionValue && optionValue.map(val => ({key: val, text: val, value: val}))

    return (
      <Controller
        name={`fields[${name}]`}
        control={control}
        render={({value, onChange}) => (
          <KooperFormSelect
            required={required}
            label={name}
            value={value}
            multiple={type === 'Multiple Option'}
            options={options}
            onChange={(e, {value}) => onChange(value)}
            error={
              errors?.fields?.[name] && {
                content: errors.fields[name].message,
              }
            }
          />
        )}
      />
    )
  }

  if (type === 'Date') {
    return (
      <Controller
        name={`fields[${name}]`}
        render={({value, onChange}) => (
          <KooperFormField required={required}>
            <label>{name}</label>
            <KooperDatePicker
              inputProps={{
                component: props => <input {...props} readOnly />,
              }}
              value={value && new Date(value)}
              onChange={val => onChange(val)}
              error={
                errors?.fields?.[name] && {
                  content: errors.fields[name].message,
                }
              }
              time={false}
            />
          </KooperFormField>
        )}
        control={control}
      />
    )
  }

  return (
    <Controller
      name={`fields[${name}]`}
      render={({value, onChange}) => (
        <KooperFormInput
          required={required}
          type={type}
          label={name}
          value={value}
          onChange={e => onChange(e.target.value)}
          error={
            errors?.fields?.[name] && {
              content: errors.fields.message,
            }
          }
        />
      )}
      control={control}
    />
  )
}

function checkRequiredLogic(fieldsList, watchFields) {
  const allFieldList = fieldsList
    .filter(f => f.required)
    .map(field => {
      return field.name
    })

  return allFieldList.every(value => {
    return (
      watchFields[value] !== null &&
      watchFields[value] !== undefined &&
      watchFields[value] !== '' &&
      watchFields[value] !== []
    )
  })
}

function ProductsDetails({toggle, open}) {
  const {id: editId} = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const defaultValues = {
    name: '',
    description: '',
    sku: '',
    categoryId: '',
    currency: 'USD',
    unitPrice: '0',
    unitCost: '0',
    billingFreq: 'One Time',
    tax: '',
    fields: {},
  }

  const {
    handleSubmit,
    errors,
    control,
    watch,
    reset,
    setValue,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(productsSchema),
    defaultValues,
  })
  const watchCategoryId = watch('categoryId')
  const watchFields = watch('fields')

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {fieldsByCategory} = useSelector(state => state.productLibrary)

  const [categoryList, setCategoryList] = useState([])
  const [fieldsList, setFieldsList] = useState([])
  const [preview, setPreview] = useState()
  const [selectedFile, setSelectedFile] = useState()
  const [errorMessage, setErrorMessage] = useState(false)
  const [currencyListData, setcurrencyListData] = useState([])
  const [productId, setProductId] = useState(null)
  const inputRef = useRef()

  useEffect(() => {
    dispatch(getFieldByCategory())

    return () => {
      dispatch({type: 'CLEAR_PRODUCT_DATA'})
    }
  }, [])

  const {data: productData = {}} = useApiResponse({
    action: getProductData,
    payload: editId,
    dependency: [editId],
    enabled: editId,
    label: GET_PRODUCT_DATA,
    storePath: 'productLibrary.productData',
    onSuccess: () => {
      setProductId(get(['id'], productData, null))
      const {
        name,
        description,
        sku,
        categoryId,
        image,
        unitCost,
        unitPrice,
        billingFreq,
        tax,
        termLength,
        fields,
        currency,
      } = productData
      reset({
        name,
        description,
        sku,
        categoryId,
        currency,
        unitPrice,
        unitCost,
        billingFreq,
        termLength,
        tax,
        fields,
      })
      setPreview(image)
    },
  })

  useEffect(() => {
    if (!editId) {
      window.history.pushState(null, '', window.location.href)
      window.onpopstate = () => toggle()

      return () => (window.onpopstate = () => {})
    }
  }, [])

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  useApiResponse({
    action: getProductCategory,
    enabled: true,
    label: GET_PRODUCTS_CATEGORY,
    storePath: 'productLibrary.categoryList',
    onSuccess: allCategories => {
      setCategoryList(
        allCategories.map(({id, category}) => ({
          key: id,
          value: id,
          text: category,
        }))
      )
    },
  })

  useEffect(() => {
    if (watchCategoryId) {
      const list = (fieldsByCategory || []).find(category => watchCategoryId === category.id)?.fields || []

      setFieldsList(list)
    }
  }, [watchCategoryId, fieldsByCategory])

  useEffect(() => {
    if (successLabels.includes(CREATE_PRODUCT)) {
      toggle()
      return dispatch(getProductlist())
    }

    if (successLabels.includes(UPDATE_PRODUCT)) {
      history.goBack()
      dispatch(getProductlist())
    }
  }, [successLabels])

  useApiResponse({
    label: GET_ACCOUNT_CURRENCY,
    enabled: true,
    storePath: 'settingCurrency.accountCurrencyList',
    action: getAccountCurrency,
    onSuccess: accountCurrencyList => {
      if (accountCurrencyList) {
        const currList = accountCurrencyList.map(list => ({
          key: list.base.id,
          value: list.currencyId,
          text: list.symbol,
        }))
        setcurrencyListData(currList)
      }
      if (accountCurrencyList) {
        const Bcur = accountCurrencyList.find(list => list.isBase && list)
        setValue('currencyId', Bcur?.currencyId)
      }
    },
  })

  const checkReqruiredFields = checkRequiredLogic(fieldsList, watchFields)
  useEffect(() => {
    setErrorMessage(checkReqruiredFields)
  }, [checkReqruiredFields])

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }

    setSelectedFile(e.target.files[0])
  }

  function submitForm(data) {
    const formData = new FormData()

    if (selectedFile) {
      formData.append('image', selectedFile)
    }

    for (const key in data) {
      if (key === 'fields') {
        formData.append(key, JSON.stringify(data[key]))
      } else {
        formData.append(key, data[key])
      }
    }

    if (productId) {
      if (!preview) dispatch(deleteProductImage(productId))
      return dispatch(updateProduct(productId, formData))
    }

    dispatch(createProduct(formData))
  }
  return (
    <KooperModal
      size="small"
      open={open}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={() => toggle(!open)}
    >
      <KooperModalHeader content={productId ? 'Update Product' : 'Add Product'} />
      <KooperModalContent scrolling>
        <KooperForm className="errorLabel">
          <KooperFormGroup widths="equal">
            <KooperFormField required>
              <label>Name</label>
              <Controller
                name="name"
                render={({value, onChange}) => (
                  <KooperFormInput
                    maxLength={20}
                    type="text"
                    name="Name"
                    id="Name"
                    placeholder="Product name.."
                    value={value}
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    error={
                      errors.name && {
                        content: removeDoubleQuotes(errors.name.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>

            <KooperFormField required>
              <div className="info-header">
                <label className="label-class">SKU</label>
                <KooperTooltip
                  content="Set unique display ID for the product"
                  size="mini"
                  position="top left"
                  trigger={<SvgIcon path="common/question" />}
                />
              </div>
              <Controller
                name="sku"
                render={({value, onChange}) => (
                  <KooperFormInput
                    maxLength={30}
                    placeholder=""
                    value={value}
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    error={
                      errors.sku && {
                        content: errors.sku.message,
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>
          </KooperFormGroup>

          {preview ? (
            <>
              <img
                onClick={() => inputRef.current.click()}
                accept=".png, .jpg, .jpeg"
                src={preview}
                alt="product"
                height="200"
                width="200"
              />
              <div>
                <KooperTooltip
                  content="Delete"
                  size="mini"
                  position="top left"
                  trigger={
                    <KooperButton
                      icon
                      onClick={() => {
                        setPreview(null)
                        setSelectedFile(null)
                      }}
                    >
                      <SvgIcon path="common/delete" />
                    </KooperButton>
                  }
                />
              </div>
            </>
          ) : (
            <KooperFormField>
              <div className="info-header">
                <label>Image</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Upload image of the product"
                  size="mini"
                  position="top left"
                />
              </div>
              <KooperButton basic size="small" onClick={() => inputRef.current.click()}>
                Upload Product Image
              </KooperButton>
            </KooperFormField>
          )}
          <KooperFormField>
            <label>Description</label>
            <Controller
              name="description"
              render={({value, onChange}) => (
                <KooperFormTextArea
                  maxLength={200}
                  placeholder="Description"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  error={
                    errors.description && {
                      content: removeDoubleQuotes(errors.description.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>

          <KooperFormField required>
            <div className="info-header">
              <label className="label-class">Select Category</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Select category under which the products come"
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              name="categoryId"
              render={({value, onChange}) => (
                <KooperFormSelect
                  search
                  fluid
                  options={categoryList}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.categoryId && {
                      content: removeDoubleQuotes(errors.categoryId.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>

          {fieldsList.map(field => {
            return <InputField errors={errors} control={control} field={field} id={field.id} />
          })}

          {!errorMessage && (
            <p style={{color: 'red'}}>Fields with * signs are required please fill them before submitting the form</p>
          )}

          <h4>Price</h4>
          <KooperDivider />

          <KooperFormGroup widths="equal">
            <KooperFormField>
              <div className="info-header">
                <label>Unit Price</label>
                <KooperTooltip
                  content="Specify the agreed upon price per unit for the product"
                  size="mini"
                  position="top left"
                  trigger={<SvgIcon path="common/question" />}
                />
              </div>
              <Controller
                name="unitPrice"
                render={({value, onChange}) => (
                  <KooperFormInput
                    value={value}
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    error={
                      errors.unitPrice && {
                        content: removeDoubleQuotes(errors.unitPrice.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>

            <KooperFormField>
              <div className="info-header">
                <label>Unit Cost</label>
                <KooperTooltip
                  content="Specify making cost per unit for the product"
                  size="mini"
                  position="top left"
                  trigger={<SvgIcon path="common/question" />}
                />
              </div>
              <Controller
                name="unitCost"
                render={({value, onChange}) => (
                  <KooperFormInput
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    error={
                      errors.unitCost && {
                        content: removeDoubleQuotes(errors.unitCost.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>
          </KooperFormGroup>

          <KooperFormGroup widths="equal">
            <KooperFormField required>
              <div className="info-header">
                <label>Currency</label>
                <KooperTooltip
                  content="Set a currency type for the product"
                  size="mini"
                  position="top left"
                  trigger={<SvgIcon path="common/question" />}
                />
              </div>
              <Controller
                name="currencyId"
                render={({value, onChange}) => (
                  <KooperFormSelect
                    search
                    fluid
                    options={currencyListData}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={
                      errors.currency && {
                        content: removeDoubleQuotes(errors.currency.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>

            <KooperFormField required>
              <div className="info-header">
                <label>Billing Frequency</label>
                <KooperTooltip
                  content="Set billing frequency for the product"
                  size="mini"
                  position="top left"
                  trigger={<SvgIcon path="common/question" />}
                />
              </div>
              <Controller
                name="billingFreq"
                render={({value, onChange}) => (
                  <KooperFormSelect
                    fluid
                    options={billingFreqOptions}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={
                      errors.billingFreq && {
                        content: errors.billingFreq.message,
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>
          </KooperFormGroup>
          <input hidden type="file" accept=".png, .jpg, .jpeg" onChange={onSelectFile} ref={inputRef} />
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => (productId ? history.goBack() : toggle())}>
          Cancel
        </KooperButton>
        {productId ? (
          <KooperButton
            primary
            onClick={handleSubmit(data => {
              submitForm(data, productId)
            })}
          >
            Update
          </KooperButton>
        ) : (
          <KooperButton primary onClick={handleSubmit(data => submitForm(data))}>
            Add
          </KooperButton>
        )}
      </KooperModalActions>
    </KooperModal>
  )
}

export default ProductsDetails
