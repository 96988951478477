import DocumentTitle from 'components/common/DocumentTitle'
import AllOneOnOneSList from 'components/pages/Teams/OneOnOneS/AllOneOnOneSList'
import PersonalOneOnOneSList from 'components/pages/Teams/OneOnOneS/PersonalOneOnOnesList'
import TeamOneOnOnesList from 'components/pages/Teams/OneOnOneS/TeamOneOnOnesList'
import {ONE_ON_ONE_S_PATH} from 'constants/path'
import OneOnOneSDetails from 'pages/authenticated/Teams/OneOnOneS/OneOnOneSDetails'

import {Redirect, Route, Switch} from 'react-router-dom'

export const oneOnOneSRoutes = [
  {path: `/${ONE_ON_ONE_S_PATH.PREFIX}/all`, component: AllOneOnOneSList, title: 'All 1-On-1s', exact: true},
  {
    path: `/${ONE_ON_ONE_S_PATH.PREFIX}/${ONE_ON_ONE_S_PATH.TEAM}`,
    component: TeamOneOnOnesList,
    title: 'Team',
    exact: false,
  },
  {
    path: `/${ONE_ON_ONE_S_PATH.PREFIX}/${ONE_ON_ONE_S_PATH.PERSONAL}`,
    component: PersonalOneOnOneSList,
    title: 'Personal',
    exact: false,
  },
  {
    path: `/${ONE_ON_ONE_S_PATH.PREFIX}/details`,
    component: OneOnOneSDetails,
    title: '1-on-1s Details',
    exact: false,
  },
  {
    path: `/${ONE_ON_ONE_S_PATH.PREFIX}/${ONE_ON_ONE_S_PATH.SETTINGS}`,
    component: AllOneOnOneSList,
    title: 'Settings',
    exact: false,
  },
]

const renderRoute = ({path, component: Component, title, exact}) => (
  <Route
    key={path}
    path={path}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} oneOnOneS />
        <Component {...props} />
      </>
    )}
  />
)

const OneOnOneSRoutes = () => (
  <Switch>
    {oneOnOneSRoutes.map(renderRoute)}
    <Route path={`/${ONE_ON_ONE_S_PATH.PREFIX}`}>
      <Redirect to={`/${ONE_ON_ONE_S_PATH.PREFIX}/all`} />
    </Route>
  </Switch>
)

export default OneOnOneSRoutes
