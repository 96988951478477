import {goalConstants} from 'constants/Teams/Goals/goals'
import Joi from 'joi'

export const addGoalSchema = Joi.object({
  name: Joi.string().required().min(3),
  description: Joi.string().allow('', null),
  type: Joi.string()
    .valid(...Object.values(goalConstants.TYPE))
    .required(),
  ownerId: Joi.number().integer().required(),
  parentId: Joi.number().integer().allow(null),
  teamId: Joi.number().integer().allow(null),
  subTeamIds: Joi.array().items(Joi.number().integer()).allow(null),
  startDate: Joi.date().required(),
  endDate: Joi.date().allow('', null),
  results: Joi.array()
    .items(
      Joi.object({
        title: Joi.string().required(),
        type: Joi.string()
          .valid(...Object.values(goalConstants.METRICS))
          .required(),
        startValue: Joi.when('metric', {
          is: ['numeric', 'percentage'],
          then: Joi.number().required(),
        }),
        targetValue: Joi.when('metric', {
          is: ['numeric', 'percentage'],
          then: Joi.number().required(),
        }),
      })
    )
    .max(20)
    .allow(null),
  goals: Joi.object({
    specific: Joi.string().allow('', null),
    measurable: Joi.string().allow('', null),
    relevant: Joi.string().allow('', null),
    attainable: Joi.array().items(
      Joi.object({
        idx: Joi.number().integer().max(3),
        ans: Joi.string().allow('', null),
      })
    ),
  }).allow(null),
  actionItems: Joi.array()
    .items(
      Joi.object({
        title: Joi.string().required(),
        order: Joi.number().integer(),
        dueDate: Joi.string(),
        isCompleted: Joi.boolean(),
      })
    )
    .max(20)
    .allow(null),
})
