import DocumentTitle from 'components/common/DocumentTitle'
import AllGoals from 'components/pages/Teams/Goals/GoalListPage/AllGoalsList'
import OrganizationGoals from 'components/pages/Teams/Goals/GoalListPage/OrganizationGoals'
import PersonalGoals from 'components/pages/Teams/Goals/GoalListPage/PersonalGoals'
import SettingsGoals from 'components/pages/Teams/Goals/SettingsGoals'
import TeamsGoals from 'components/pages/Teams/Goals/GoalListPage/TeamsGoals'
import {GOALS_PATH} from 'constants/path'
import GoalsDetails from 'pages/authenticated/Teams/Goals/GoalsDetails'

import {Redirect, Route, Switch} from 'react-router-dom'

const goalsRoutes = [
  {path: `/${GOALS_PATH.PREFIX}/all`, component: AllGoals, title: 'All Goals', exact: true},
  {
    path: `/${GOALS_PATH.PREFIX}/${GOALS_PATH.ORGANIZATION}`,
    component: OrganizationGoals,
    title: 'Organization',
    exact: false,
  },
  {path: `/${GOALS_PATH.PREFIX}/${GOALS_PATH.TEAM}`, component: TeamsGoals, title: 'Team', exact: false},
  {path: `/${GOALS_PATH.PREFIX}/${GOALS_PATH.PERSONAL}`, component: PersonalGoals, title: 'Personal', exact: false},
  {path: `/${GOALS_PATH.PREFIX}/details/:type/:goalId`, component: GoalsDetails, title: 'Goals Details', exact: false},
  {path: `/${GOALS_PATH.PREFIX}/${GOALS_PATH.SETTINGS}`, component: SettingsGoals, title: 'Settings', exact: false},
]

const renderRoute = ({path, component: Component, title, exact}) => (
  <Route
    key={path}
    path={path}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} goals />
        <Component {...props} />
      </>
    )}
  />
)

const GoalsRoutes = () => (
  <Switch>
    {goalsRoutes.map(renderRoute)}
    <Route path="/goals">
      <Redirect to="/goals/all" />
    </Route>
  </Switch>
)

export default GoalsRoutes
