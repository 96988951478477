import React, {useEffect} from 'react'
import {joiResolver} from '@hookform/resolvers'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormTextArea,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'
import {removeDoubleQuotes} from 'utils/helper'
import {addFormsSchema} from 'validation/Projects/projects/forms.schema'
import {CREATE_PROJECT_FORM} from 'actions/types'
import {createProjectForm, getAllProjectForms} from 'actions/projects'
import SvgIcon from 'components/common/SvgIcon'

function FormModal(props) {
  const {isAddFormOpen, setIsAddFormOpen, projectId} = props
  const dispatch = useDispatch()

  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)

  const {
    handleSubmit,
    errors,
    control,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange',
    resolver: joiResolver(addFormsSchema),
    defaultValues: {
      title: '',
      description: '',
    },
  })

  useEffect(() => {
    if (successLabels.includes(CREATE_PROJECT_FORM)) {
      reset({
        title: '',
        description: '',
      })
      dispatch(getAllProjectForms({projectId}))
      setIsAddFormOpen(false)
    }
  }, [successLabels, dispatch, projectId])

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={isAddFormOpen}
      onClose={() => setIsAddFormOpen(false)}
      size="small"
      centered={false}
    >
      <KooperModalHeader>Create Form</KooperModalHeader>
      <KooperModalContent scrolling>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <div className="info-header">
              <label>Title</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Specify title of form"
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              name="title"
              render={({value, onChange, onBlur}) => (
                <KooperFormInput
                  type="text"
                  placeholder="Specify a title of the form"
                  value={value}
                  maxLength={50}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  onBlur={onBlur}
                  error={
                    errors.title && {
                      content: removeDoubleQuotes(errors.title.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>
          <KooperFormField>
            <div className="info-header">
              <label>Description</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content=" Specify a brief description about the form"
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              name="description"
              render={({onChange, value}) => (
                <KooperFormTextArea
                  type="text"
                  value={value}
                  maxLength={250}
                  placeholder="Description"
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  error={
                    errors.description && {
                      content: removeDoubleQuotes(errors.description.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions style={{position: 'relative'}}>
        <KooperButton
          basic
          onClick={() => setIsAddFormOpen(false)}
          disabled={isLoadingData && type.includes(CREATE_PROJECT_FORM)}
        >
          Cancel
        </KooperButton>
        <KooperButton
          primary
          onClick={handleSubmit(data => dispatch(createProjectForm({data, projectId})))}
          loading={isLoadingData && type.includes(CREATE_PROJECT_FORM)}
          disabled={!isDirty}
        >
          Create Form
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default FormModal
