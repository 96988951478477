import React, {useEffect, useState} from 'react'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperDivider,
  KooperIcon,
  KooperNoData,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableFooter,
  KooperTableRow,
} from 'kooper-library'
import {useDispatch, useSelector} from 'react-redux'
import {MOVE_PROJECT_FINANCE_BILLING_ITEMS, UPDATE_PROJECT_FINANCE_BILLING_INVOICE} from 'actions/types'
import {
  completeProjectFinanceBillingInvoice,
  getProjectFinanceBillingInvoiceLogs,
  getProjectFinanceBillingOpenInvoicesList,
} from 'actions/projects'
import {PROJECTS_ENDPOINT} from 'actions/endpoint'
import {getFormattedDate} from 'utils/helper'
import ProjectBillingTimeTableList from './ProjectBillingTimeTableList'
import ProjectBillingExpenseTableList from './ProjectBillingExpenseTableList'
import ProjectBillingInvoiceModal from '../ProjectBillingInvoiceModal'

const ProjectBillingInvoice = ({projectId, budgetId, invoiceId}) => {
  const dispatch = useDispatch()

  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false)
  const [isEditTime, setIsEditTime] = useState(false)
  const [isEditExpense, setIsEditExpense] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {billingInvoiceLogs = {}} = useSelector(state => state.projects)
  const {expenseList = [], timeLogs = [], currency = ''} = billingInvoiceLogs

  useEffect(() => {
    if (projectId && budgetId && invoiceId) {
      dispatch(getProjectFinanceBillingInvoiceLogs({projectId, budgetId, invoiceId}))
    }
  }, [dispatch, projectId, budgetId, invoiceId])

  useEffect(() => {
    if (successLabels.includes(UPDATE_PROJECT_FINANCE_BILLING_INVOICE)) {
      setIsInvoiceModalOpen(false)
      dispatch(getProjectFinanceBillingOpenInvoicesList({projectId, budgetId}))
      dispatch(getProjectFinanceBillingInvoiceLogs({projectId, budgetId, invoiceId}))
    }
    if (successLabels.includes(MOVE_PROJECT_FINANCE_BILLING_ITEMS)) {
      dispatch(getProjectFinanceBillingInvoiceLogs({projectId, budgetId, invoiceId}))
    }
  }, [successLabels, dispatch, projectId, budgetId, invoiceId])

  const getHourMinuteFormatFromMinutes = minutes => {
    const hour = Math.floor(minutes / 60)
    const minute = minutes % 60
    return `${hour} hrs ${minute} mins`
  }

  const getTotalTime = () => {
    const totalMinutes = timeLogs.reduce((acc, item) => acc + item.loggedHrs * 60 + item.loggedMins, 0)
    return getHourMinuteFormatFromMinutes(totalMinutes)
  }

  const getSubTotalCost = list => {
    return list.reduce((acc, item) => acc + item.cost, 0).toFixed(2)
  }

  const renderTimeTableBody = () => {
    if (timeLogs.length === 0) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="6" className="emptyValue">
            <KooperNoData type="table" noDataText="No Data Available" />
          </KooperTableCell>
        </KooperTableRow>
      )
    }
    return timeLogs.map(time => (
      <KooperTableRow key={time.id} className="tableLink">
        <KooperTableCell>{getFormattedDate(time.date)}</KooperTableCell>
        <KooperTableCell>
          {time.createdByDetails?.firstName} {time.createdByDetails?.lastName}
        </KooperTableCell>
        <KooperTableCell>
          {time.tasklistDetails && (
            <p>
              {time.tasklistDetails?.title} - {time.taskDetails?.title}
            </p>
          )}
          <p>{time.description || 'No description'}</p>
        </KooperTableCell>
        <KooperTableCell>{getHourMinuteFormatFromMinutes(time.loggedHrs * 60 + time.loggedMins)}</KooperTableCell>
        <KooperTableCell>
          {currency} {time.cost.toFixed(2)}
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  const renderExpenseTableBody = () => {
    return expenseList.map(expense => (
      <KooperTableRow key={expense.id} className="tableLink">
        <KooperTableCell>{getFormattedDate(expense.expenseDate)}</KooperTableCell>
        <KooperTableCell>{expense.title}</KooperTableCell>
        <KooperTableCell>{expense.description}</KooperTableCell>
        <KooperTableCell>{expense.createdBy?.name}</KooperTableCell>
        <KooperTableCell>
          {currency} {expense.cost.toFixed(2)}
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  return (
    <>
      <KooperCard fluid>
        <KooperCardContent>
          <div className="d-flex">
            <div>
              <p>
                Invoice No./ID : {billingInvoiceLogs.invoiceNumber}{' '}
                <KooperIcon
                  name="edit"
                  onClick={() => setIsInvoiceModalOpen(true)}
                  className="project-billing-edit-icon"
                />
              </p>
              <p>
                Issue Date : {billingInvoiceLogs.invoiceDate ? getFormattedDate(billingInvoiceLogs.invoiceDate) : ''}
              </p>
            </div>
          </div>
          <KooperDivider />
          {timeLogs.length ? (
            <>
              <h3>
                Billable Time{' '}
                {isEditTime ? (
                  <KooperButton basic size="mini" onClick={() => setIsEditTime(false)}>
                    Cancel
                  </KooperButton>
                ) : (
                  <KooperIcon name="edit" onClick={() => setIsEditTime(true)} className="project-billing-edit-icon" />
                )}
              </h3>
              {isEditTime ? (
                <ProjectBillingTimeTableList
                  timeList={timeLogs}
                  currency="USD"
                  projectId={projectId}
                  budgetId={budgetId}
                  invoiceId={invoiceId}
                />
              ) : (
                <KooperTable basic>
                  <KooperTableBody>{renderTimeTableBody()}</KooperTableBody>
                  <KooperTableFooter>
                    <KooperTableRow>
                      <KooperTableCell colSpan="2" />
                      <KooperTableCell textAlign="right">Sub-Total:</KooperTableCell>
                      <KooperTableCell>{getTotalTime()}</KooperTableCell>
                      <KooperTableCell>
                        {currency} {getSubTotalCost(timeLogs)}
                      </KooperTableCell>
                    </KooperTableRow>
                  </KooperTableFooter>
                </KooperTable>
              )}
            </>
          ) : (
            <p>No time logged on this invoice</p>
          )}

          {expenseList.length ? (
            <>
              <h3>
                Expenses{' '}
                {isEditExpense ? (
                  <KooperButton basic size="mini" onClick={() => setIsEditExpense(false)}>
                    Cancel
                  </KooperButton>
                ) : (
                  <KooperIcon
                    name="edit"
                    onClick={() => setIsEditExpense(true)}
                    className="project-billing-edit-icon"
                  />
                )}
              </h3>
              {isEditExpense ? (
                <ProjectBillingExpenseTableList
                  expenseList={expenseList}
                  currency="USD"
                  projectId={projectId}
                  budgetId={budgetId}
                  invoiceId={invoiceId}
                />
              ) : (
                <KooperTable basic>
                  <KooperTableBody>{renderExpenseTableBody()}</KooperTableBody>
                  <KooperTableFooter>
                    <KooperTableRow>
                      <KooperTableCell colSpan="3" />
                      <KooperTableCell textAlign="right">Sub-Total:</KooperTableCell>
                      <KooperTableCell>
                        {currency} {getSubTotalCost(expenseList)}
                      </KooperTableCell>
                    </KooperTableRow>
                  </KooperTableFooter>
                </KooperTable>
              )}
            </>
          ) : (
            <p>No expenses logged on this invoice</p>
          )}

          <KooperDivider />

          <div className="d-flex">
            <div>
              <KooperButton
                basic
                primary
                icon="download"
                content="Export"
                onClick={() =>
                  window.open(`${PROJECTS_ENDPOINT}/budgets/invoice/download/${billingInvoiceLogs.link}`, '_blank')
                }
              />
              <KooperButton
                color="green"
                icon="check"
                content="Mark Complete"
                onClick={() => dispatch(completeProjectFinanceBillingInvoice({projectId, budgetId, invoiceId}))}
                className="ml-3"
              />
            </div>
            <div>
              <h2>
                Total: {currency} {billingInvoiceLogs.finalTotal?.toFixed(2)}
              </h2>
              {billingInvoiceLogs.footerText && <span>({billingInvoiceLogs.footerText})</span>}
            </div>
          </div>
        </KooperCardContent>
      </KooperCard>

      {isInvoiceModalOpen && (
        <ProjectBillingInvoiceModal
          open={isInvoiceModalOpen}
          toggle={setIsInvoiceModalOpen}
          projectId={projectId}
          budgetId={budgetId}
          invoiceId={invoiceId}
        />
      )}
    </>
  )
}

export default ProjectBillingInvoice
