/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {FaCrosshairs} from 'react-icons/fa'
import _ from 'lodash'
import {
  KooperButton,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperHeader,
  KooperIcon,
  KooperLabel,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
  KooperTooltip,
  KooperTablePlaceholder,
  KooperModalHeader,
  KooperModalContent,
  KooperModalActions,
  KooperModal,
  KooperNoData,
} from 'kooper-library'

import {DELETE_SEQUENCE, UPDATE_SEQUENCE_STATUS, GET_ALL_SEQUENCES} from 'actions/types'
import DeleteModal from 'components/common/DeleteModal'
import {deleteSequence, getAllSequences, updateSequenceStatus} from 'actions/sequence'
import create from 'assets/create.svg'
import CreateFirst from 'components/common/CreateFirst'
import {getFormattedDate, getPermissionTooltipContent, startCase} from 'utils/helper'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {SEQUENCES_PATH} from 'constants/path'

const sequenceType = [
  {key: 'all', value: 'all', text: 'All'},
  {key: 'live', value: 'live', text: 'Live'},
  {key: 'scheduled', value: 'scheduled', text: 'Scheduled'},
  {key: 'completed', value: 'completed', text: 'Completed'},
  {key: 'paused', value: 'paused', text: 'Paused'},
  {key: 'draft', value: 'draft', text: 'Draft'},
]

const Sequences = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false)
  const [sequenceToUpdate, setSequenceToUpdate] = useState(null)

  const [filter, setFilter] = useState({})
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [idToDelete, setIdToDelete] = useState('')
  const {successLabels = [], isLoadingData, type} = useSelector(state => state.apiReducer)

  const {isLoading} = useApiLoader({label: GET_ALL_SEQUENCES})
  const {salesAndCrmAccess, sequencesPermissions} = useUserPermissions()
  const salesSequencesAccess = salesAndCrmAccess?.salesSequences
  const manageSequencesPermissions = sequencesPermissions?.manage_sequences

  const {data: sequencesList = []} = useApiResponse({
    action: getAllSequences,
    dependency: [salesSequencesAccess, manageSequencesPermissions],
    enabled: salesSequencesAccess && manageSequencesPermissions,
    label: GET_ALL_SEQUENCES,
    storePath: 'sequences.sequencesList',
  })

  useEffect(() => {
    if (successLabels.includes(DELETE_SEQUENCE)) {
      dispatch(getAllSequences())
    }
    if (successLabels.includes(UPDATE_SEQUENCE_STATUS)) {
      dispatch(getAllSequences())
    }
  }, [successLabels, dispatch])

  const handlePublishConfirm = () => {
    if (sequenceToUpdate) {
      dispatch(updateSequenceStatus(sequenceToUpdate.id, {isActive: !sequenceToUpdate.isActive}))
      setIsPublishModalOpen(false)
      setSequenceToUpdate(null)
    }
  }

  const renderSequenceList = () => {
    if (sequencesList.length > 0) {
      return sequencesList.map((sequence, index) => (
        <KooperTableRow
          key={index}
          className="tableLink"
          onClick={() => history.push(`/${SEQUENCES_PATH.PREFIX}/${sequence.id}/${SEQUENCES_PATH.INSIGHTS}`)}
        >
          <KooperTableCell className="pl-4">{index + 1}</KooperTableCell>
          <KooperTableCell>
            <KooperHeader as="h5">{startCase(sequence.name)}</KooperHeader>
          </KooperTableCell>
          <KooperTableCell>{sequence.assignee}</KooperTableCell>
          <KooperTableCell>{getFormattedDate(sequence.startAt)}</KooperTableCell>
          <KooperTableCell>
            <KooperLabel className="kooper-label" color={sequence.isActive ? 'green' : 'red'}>
              {sequence.isActive ? 'True' : 'False'}
            </KooperLabel>
          </KooperTableCell>
          <KooperTableCell>
            <KooperLabel className="kooper-label">{startCase(sequence.status)}</KooperLabel>
          </KooperTableCell>
          <KooperTableCell>{startCase(sequence.inbox)}</KooperTableCell>
          <KooperTableCell>
            <KooperDropdown
              icon={<SvgIcon path="common/more-verticle" id="TooltipProductInbox" />}
              button
              className="icon"
              direction="left"
            >
              <KooperDropdownMenu>
                <LockPermissionTooltip isRoleAccessDenied={!manageSequencesPermissions}>
                  <KooperDropdownItem
                    onClick={() => {
                      if (manageSequencesPermissions) {
                        setIsPublishModalOpen(true) // Open the confirmation modal
                        setSequenceToUpdate(sequence)
                      }
                    }}
                  >
                    {sequence.isActive ? <KooperIcon name="eye slash" /> : <SvgIcon path="common/publish" />}
                    {sequence.isActive ? 'Unpublish' : 'Publish'}
                  </KooperDropdownItem>
                </LockPermissionTooltip>
                <LockPermissionTooltip isRoleAccessDenied={!manageSequencesPermissions}>
                  <KooperDropdownItem
                    onClick={() =>
                      manageSequencesPermissions && history.push(`/${SEQUENCES_PATH.PREFIX}/${sequence.id}`)
                    }
                  >
                    <SvgIcon path="common/edit2" /> Edit
                  </KooperDropdownItem>
                </LockPermissionTooltip>
                <LockPermissionTooltip isRoleAccessDenied={!manageSequencesPermissions}>
                  <KooperDropdownItem
                    onClick={e => {
                      e.stopPropagation()
                      e.preventDefault()
                      if (manageSequencesPermissions) {
                        setIsDeleteOpen(true)
                        setIdToDelete(sequence.id)
                      }
                    }}
                  >
                    <SvgIcon path="common/delete" /> Delete
                  </KooperDropdownItem>
                </LockPermissionTooltip>
              </KooperDropdownMenu>
            </KooperDropdown>
          </KooperTableCell>
        </KooperTableRow>
      ))
    }
  }

  useEffect(() => {
    if (salesSequencesAccess && manageSequencesPermissions) {
      if (filter) {
        dispatch(getAllSequences(filter))
      }
    }
  }, [salesSequencesAccess, manageSequencesPermissions, filter, dispatch])

  if (isLoading && salesSequencesAccess && manageSequencesPermissions) {
    return <PageLoader />
  }

  if (!salesSequencesAccess || !manageSequencesPermissions || (sequencesList.length < 1 && _.isEmpty(filter))) {
    return (
      <CreateFirst
        src={<FaCrosshairs />}
        Feature1="Personalized Sequence"
        Feature2="Execution time"
        Feature3="Sequence Delay"
        list1="Create contact specific information sequence dynamically"
        list2="Set the execution time to send it at optimal time"
        list3="Set delay between two sequence for more efficiency"
        buttonText="Create Sequence"
        addFirst={() =>
          salesSequencesAccess &&
          manageSequencesPermissions &&
          history.push(`/${SEQUENCES_PATH.PREFIX}/${SEQUENCES_PATH.CREATE}`)
        }
        tooltip={!salesSequencesAccess || !manageSequencesPermissions}
        tooltipContent={getPermissionTooltipContent({
          featureAccess: salesSequencesAccess,
          roleAccess: manageSequencesPermissions,
        })}
        img={create}
        header="Sequence"
        subHeader="Sequences are a series of scheduled touchpoints that help you connect with customers at a scale."
      />
    )
  }

  return (
    <div className="pageBodyWrap">
      <div className="pageHeader px-0">
        <div className="pageTitle">
          <div className="pageTitleIcon">
            <SvgIcon path="navigation/sequences" />
            <h5>Sequences ({sequencesList?.length})</h5>
          </div>
        </div>

        <div className="pageAction">
          <KooperTooltip
            content="Sequence Status"
            size="mini"
            position="top center"
            trigger={
              <KooperDropdown
                icon="chevron down"
                button
                className="dropdown-ellipsis"
                defaultValue="all"
                options={sequenceType}
                direction="left"
                onChange={(e, {value}) => {
                  setFilter({
                    ...filter,
                    status: value,
                  })
                }}
              />
            }
          />
          <LockPermissionTooltip isRoleAccessDenied={!manageSequencesPermissions}>
            <KooperButton
              content="Create Sequence"
              primary
              className={!manageSequencesPermissions ? 'disabled-button' : ''}
              onClick={() =>
                manageSequencesPermissions && history.push(`/${SEQUENCES_PATH.PREFIX}/${SEQUENCES_PATH.CREATE}`)
              }
            />
          </LockPermissionTooltip>
        </div>
      </div>
      <KooperTable selectable basic className="m-0">
        <KooperTableHeader>
          <KooperTableRow>
            <KooperTableHeaderCell className="pl-4">#</KooperTableHeaderCell>
            <KooperTableHeaderCell>Name</KooperTableHeaderCell>
            <KooperTableHeaderCell>Owner</KooperTableHeaderCell>
            <KooperTableHeaderCell>Start Date</KooperTableHeaderCell>
            <KooperTableHeaderCell>Active</KooperTableHeaderCell>
            <KooperTableHeaderCell>Status</KooperTableHeaderCell>
            <KooperTableHeaderCell>Mailbox</KooperTableHeaderCell>
            <KooperTableHeaderCell>Actions</KooperTableHeaderCell>
          </KooperTableRow>
        </KooperTableHeader>
        <KooperTableBody>
          {isLoadingData && type.includes(GET_ALL_SEQUENCES) ? (
            <KooperTablePlaceholder columns={8} />
          ) : sequencesList.length > 0 ? (
            renderSequenceList()
          ) : (
            <tr>
              <td colSpan="8" className="emptyValue">
                <KooperNoData type="table" noDataText="No Data Available" />
              </td>
            </tr>
          )}
        </KooperTableBody>
      </KooperTable>
      {isDeleteOpen && (
        <DeleteModal
          isModalOpen={isDeleteOpen}
          setIsModalOpen={setIsDeleteOpen}
          modalText="Are you sure you want to delete ?"
          deleteAction={deleteSequence}
          idTobeDeleted={idToDelete}
          type={DELETE_SEQUENCE}
        />
      )}
      {isPublishModalOpen && (
        <KooperModal
          closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
          size="tiny"
          centered={false}
          open={isPublishModalOpen}
          onClose={() => setIsPublishModalOpen(false)}
        >
          <KooperModalHeader>{sequenceToUpdate?.isActive ? 'Unpublish' : 'Publish'} Confirmation</KooperModalHeader>
          <KooperModalContent>
            Are you sure you want to {sequenceToUpdate?.isActive ? 'unpublish' : 'publish'} this sequence?
          </KooperModalContent>
          <KooperModalActions>
            <KooperButton basic onClick={() => setIsPublishModalOpen(false)}>
              Cancel
            </KooperButton>
            <KooperButton primary onClick={handlePublishConfirm}>
              Confirm
            </KooperButton>
          </KooperModalActions>
        </KooperModal>
      )}
    </div>
  )
}
export default Sequences
