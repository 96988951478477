import CardLoader from 'components/common/CardLoader'
import KooperNoDataTable from 'components/common/KooperNoDataTable'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SearchComponent from 'components/common/SearchComponent'
import SvgIcon from 'components/common/SvgIcon'
import {createGoalsDropdownData} from 'constants/Teams/Goals/goals'
import {
  KooperCard,
  KooperCardContent,
  KooperContainer,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperTooltip,
} from 'kooper-library'
import {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {GET_ALL_GOALS} from 'actions/types'
import {getGoalList} from 'actions/goalList'
import PersonalGoalSelectModal from '../PersonalGoalSelectModal'
import GoalsCardItem from './GoalsCardItem'

const PersonalGoals = () => {
  const history = useHistory()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const dispatch = useDispatch()

  const [currentListParams, setCurrentListParams] = useState({
    page: 1,
    status: '',
    type: 'personal',
    term: '',
    teamId: '',
    sortBy: '',
    orderBy: '',
    limit: '',
  })
  const {type = [], successLabels = []} = useSelector(state => state.apiReducer)
  const {goals = []} = useSelector(state => state.goalList)
  const isGoalsDataLoading = type.includes(GET_ALL_GOALS)

  useEffect(() => {
    dispatch(getGoalList(currentListParams))
  }, [dispatch, currentListParams])
  const types = [
    {value: 'all', key: 'all', text: 'All Types'},
    {value: 'organizational', key: 'organizational', text: 'Organizational'},
    {value: 'team', key: 'team', text: 'Team'},
    {value: 'individual', key: 'individual', text: 'Individual'},
  ]

  const teams = [
    {value: 'all', key: 'all', text: 'All Teams'},
    {value: 'team 1', key: 'team 1', text: 'Team 1'},
    {value: 'team 2', key: 'team 2', text: 'Team 2'},
  ]
  const statuses = [
    {value: 'active', key: 'active', text: 'All Active'},
    {value: 'onTrack', key: 'onTrack', text: 'On Track'},
    {value: 'completed', key: 'completed', text: 'Completed'},
    {value: 'delayed', key: 'delayed', text: 'Delayed'},
    {value: 'atRisk', key: 'atRisk', text: 'At Risk'},
    {value: 'abandoned', key: 'abandoned', text: 'Abandoned'},
    {value: 'archived', key: 'archived', text: 'Archived'},
  ]
  // if (goalsList.length === 0) {
  //   return (
  //     <SettingLayout>
  //       <CreateFirst
  //         header="Sales Orders"
  //         // addFirst={() => manageSalesOrdersPermissions && history.push(`/sales-orders/create`)}
  //         // tooltip={!manageSalesOrdersPermissions}
  //         // tooltipContent={getPermissionTooltipContent({
  //         //   roleAccess: manageSalesOrdersPermissions,
  //         // })}
  //         buttonText="Create Sales Order"
  //         imageSrc={create}
  //         subHeader="Manage the sale of products to a customer at an agreed-upon price"
  //         Feature1="Order Management"
  //         list1="Easily create and modify sales orders with customizable templates"
  //         Feature2="Multi-Currency Support"
  //         list2="Process orders in various currencies to conduct business globally"
  //         Feature3="Comprehensive Credits and Discounts"
  //         list3="Define total order credits based on tax and discount percentages"
  //       />
  //     </SettingLayout>
  //   )
  // }

  return (
    <div className="kooper-side-wrap">
      <KooperContainer>
        <div className="page-header">
          <div className="page-title">
            <div className="page-titleIcon">
              <SvgIcon path="navigation/sales-orders" />
              <h5>Goals ({goals ? goals.length : 0})</h5>
            </div>
          </div>
          <div className="page-action">
            <SearchComponent onSuccess={value => setCurrentListParams({text: value})} />
            <KooperTooltip
              position="top center"
              size="mini"
              content="Select Teams"
              trigger={
                <div className="mailActivity ml-3">
                  <KooperDropdown
                    icon="chevron down"
                    button
                    search
                    defaultValue="all"
                    options={teams}
                    onChange={(e, {value}) => {
                      setCurrentListParams({
                        ...currentListParams,
                        status: value,
                      })
                    }}
                  />
                </div>
              }
            />
            <KooperTooltip
              position="top center"
              size="mini"
              content="Select Status"
              trigger={
                <div className="mailActivity ml-3">
                  <KooperDropdown
                    icon="chevron down"
                    button
                    defaultValue="active"
                    options={statuses}
                    onChange={(e, {value}) => {
                      setCurrentListParams({
                        ...currentListParams,
                        status: value,
                      })
                    }}
                  />
                </div>
              }
            />
            <KooperTooltip
              position="top center"
              size="mini"
              content="Select Types"
              trigger={
                <div className="mailActivity ml-3">
                  <KooperDropdown
                    icon="chevron down"
                    button
                    search
                    defaultValue="all"
                    options={types}
                    onChange={(e, {value}) => {
                      setCurrentListParams({
                        ...currentListParams,
                        status: value,
                      })
                    }}
                  />
                </div>
              }
            />
            <LockPermissionTooltip // </div>isRoleAccessDenied={!manageSalesOrdersPermissions}
            >
              <KooperDropdown
                direction="left"
                button
                className="large primary goals-create-dropdown"
                text="Create Goals"
              >
                <KooperDropdownMenu className="goals-create-dropdown-menu">
                  {createGoalsDropdownData?.map(({value, Icon, header, subHeader}) => (
                    <KooperDropdownItem
                      key={value}
                      onClick={() =>
                        value === 'personal' ? setIsModalOpen(true) : history.push(`/goals/create/${value}`)
                      }
                    >
                      <div className="custom-dropdown-menu-icon">
                        <Icon />
                      </div>
                      <div className="goals-create-dropdown-content">
                        <h5>{header}</h5>
                        <p>{subHeader}</p>
                      </div>
                    </KooperDropdownItem>
                  ))}
                </KooperDropdownMenu>
              </KooperDropdown>
            </LockPermissionTooltip>
          </div>
        </div>

        {isGoalsDataLoading && <CardLoader length={3} />}
        {goals?.length === 0 && !isGoalsDataLoading && (
          <KooperCard fluid className="project-list-card">
            <KooperCardContent>
              <KooperNoDataTable />
            </KooperCardContent>
          </KooperCard>
        )}
        {goals?.length > 0 && !isGoalsDataLoading
          ? goals?.map(goal => (
              <GoalsCardItem
                // reportPermissions={reportPermissions?.banner}
                // managePermission={assistPermissions?.manage_banner}
                // setCustomDeleteBanner={setCustomDeleteBanner}
                key={goal.id}
                goal={goal}
                // setCustomPublish={setCustomPublish}
              />
            ))
          : null}
        {/* {deleteModal && (
          <DeleteModal
            isModalOpen={deleteModal}
            setIsModalOpen={setDeleteModal}
            modalText="Are you sure you want to delete ?"
            deleteAction={deleteSalesOrders}
            idTobeDeleted={editId}
            type={DELETE_SALES_ORDERS}
          />
        )} */}

        {isModalOpen && <PersonalGoalSelectModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />}
      </KooperContainer>
    </div>
  )
}

export default PersonalGoals
