import PersonalGoalsDetails from 'components/pages/Teams/Goals/GoalsDetails/PersonalGoalsDetails'
import TeamNOrganizationGoalsDetails from 'components/pages/Teams/Goals/GoalsDetails/TeamNOrganizationGoalsDetails'
import {KooperContainer} from 'kooper-library'
import {useParams} from 'react-router-dom'

export default function GoalsDetails() {
  const {type} = useParams()

  return (
    <div className="kooper-side-wrap">
      <KooperContainer>
        {type === 'individual' && <PersonalGoalsDetails />}

        {(type === 'team' || type === 'organizational') && <TeamNOrganizationGoalsDetails />}
      </KooperContainer>
    </div>
  )
}
