/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import {FiArchive} from 'react-icons/fi'
import _ from 'lodash'
import {
  KooperButton,
  KooperDivider,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperHeader,
  KooperIcon,
  KooperLabel,
  KooperNoData,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import {deleteProof, getAllProofs, getSingleProof, updateProofStatus} from 'actions/proofs'
import {getProjectsList} from 'actions/projects'
import {DELETE_PROOF, GET_ALL_PROOFS, UPDATE_PROOF_STATUS} from 'actions/types'
import DeleteModal from 'components/common/DeleteModal'
import SearchComponent from 'components/common/SearchComponent'
import {ProofApprovalStatus} from 'constants/projects'
import {get, getFormattedDate, getPermissionTooltipContent, startCase} from 'utils/helper'
import {AiOutlineCheck} from 'react-icons/ai'
import CreateFirst from 'components/common/CreateFirst'
import create from 'assets/create.svg'
import SvgIcon from 'components/common/SvgIcon'
import {PROOFS_PATH} from 'constants/path'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import AddProofs from './AddProofs'

function ProofsList(props) {
  const {projectId, rolePermissions} = props
  const dispatch = useDispatch()
  const history = useHistory()

  const [deleteModal, setDeleteModal] = useState(false)
  const [editId, setEditId] = useState([])
  const [isAddProofsOpen, setIsAddProofsOpen] = useState(false)
  const [currentListParams, setCurrentListParams] = useState({
    status: '',
    projectIds: '',
    approvalStatus: '',
    term: '',
  })

  const {getProofsListData = []} = useSelector(state => state.proofs)
  const {type, successLabels = []} = useSelector(state => state.apiReducer)
  const {projectsList = []} = useSelector(state => state.projects)

  useEffect(() => {
    if (projectId) {
      dispatch(
        getAllProofs({
          projectIds: projectId,
        })
      )
    } else {
      dispatch(getAllProofs())
      dispatch(getProjectsList())
    }
  }, [dispatch, projectId])

  useEffect(() => {
    if ((successLabels.includes(DELETE_PROOF) || successLabels.includes(UPDATE_PROOF_STATUS)) && !projectId) {
      dispatch(getAllProofs())
    }
    if ((successLabels.includes(DELETE_PROOF) || successLabels.includes(UPDATE_PROOF_STATUS)) && projectId) {
      dispatch(
        getAllProofs({
          projectIds: projectId,
        })
      )
    }
  }, [successLabels, dispatch, projectId])

  useEffect(() => {
    if (!projectId) {
      const newCurrentListParams = _.omitBy(currentListParams, value => {
        if (value === 'all' || value === '') {
          return true
        }
        return false
      })

      dispatch(getAllProofs(newCurrentListParams))
    }
  }, [currentListParams, dispatch, projectId])

  const renderTableBody = () => {
    return getProofsListData.map(
      ({id, name, approvalStatus, projectDetails, taskDetails, createdBy, dueDate, status, assigneeDetails}, index) => {
        return (
          <KooperTableRow
            key={id}
            className="tableLink"
            onClick={() => {
              history.push(`/${PROOFS_PATH.PREFIX}/${PROOFS_PATH.APPROVE}/${id}`)
            }}
          >
            <KooperTableCell>{index + 1}</KooperTableCell>
            <KooperTableCell>
              <KooperHeader as="h5">{startCase(name)}</KooperHeader>
            </KooperTableCell>
            <KooperTableCell>
              <KooperLabel className="kooper-label" color="red">
                {startCase(approvalStatus)}
              </KooperLabel>
            </KooperTableCell>
            <KooperTableCell>{projectDetails?.title}</KooperTableCell>
            <KooperTableCell>{taskDetails?.title}</KooperTableCell>
            <KooperTableCell>{`${get(['firstName'], createdBy)} ${get(['lastName'], createdBy)}`}</KooperTableCell>
            <KooperTableCell>{getFormattedDate(dueDate)}</KooperTableCell>
            <KooperTableCell>
              <div className="d-flex projects-listing-assign" style={{justifyContent: 'flex-start'}}>
                <div className="user-list">
                  {assigneeDetails?.map(({id, firstName, lastName}) => {
                    return (
                      <KooperTooltip
                        key={id}
                        content={`${firstName} ${lastName}`}
                        position="top center"
                        size="mini"
                        trigger={<span className="circle-user">{`${firstName[0]}${lastName[0]}`}</span>}
                      />
                    )
                  })}
                </div>
              </div>
            </KooperTableCell>
            <KooperTableCell>
              <KooperDropdown
                direction="left"
                icon={<SvgIcon path="common/more-verticle" id="TooltipProductInbox" />}
                button
                className="icon"
              >
                <KooperDropdownMenu>
                  <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
                    {approvalStatus !== 'approved' && (
                      <KooperDropdownItem
                        onClick={() => {
                          if (rolePermissions) {
                            dispatch(getSingleProof(id))
                            setIsAddProofsOpen(true)
                          }
                        }}
                      >
                        <KooperIcon name="edit" /> Edit
                      </KooperDropdownItem>
                    )}
                  </LockPermissionTooltip>
                  <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
                    <KooperDropdownItem
                      onClick={() =>
                        rolePermissions &&
                        dispatch(
                          updateProofStatus(id, {
                            status: status === 'active' ? 'archive' : 'active',
                          })
                        )
                      }
                    >
                      <span>{status === 'active' ? <FiArchive /> : <AiOutlineCheck />}</span>
                      {status === 'active' ? 'Archive' : 'Active'}
                    </KooperDropdownItem>
                  </LockPermissionTooltip>
                  {/* <KooperDropdownItem
                    onClick={e => {
                    }}
                  >
                    <Link className="ml-2" to={link} target="_blank">
                      <FiExternalLink />
                    </Link>
                    Open associated link
                  </KooperDropdownItem> */}
                  <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
                    <KooperDropdownItem
                      onClick={e => {
                        if (rolePermissions) {
                          setEditId(id)
                          setDeleteModal(true)
                        }
                        e.stopPropagation()
                      }}
                    >
                      <KooperIcon name="trash alternate" /> Delete
                    </KooperDropdownItem>
                  </LockPermissionTooltip>
                </KooperDropdownMenu>
              </KooperDropdown>
            </KooperTableCell>
          </KooperTableRow>
        )
      }
    )
  }

  return (
    <>
      {getProofsListData.length > 0 ||
      currentListParams.status ||
      currentListParams.projectIds ||
      currentListParams.approvalStatus ||
      currentListParams.term ? (
        <div className="pageBodyWrap">
          <div className="pageHeader px-0">
            <div className="pageTitle">
              <div className="pageTitleIcon">
                <SvgIcon path="navigation/proofs" />
                <h5>Proofs ({getProofsListData ? getProofsListData?.length : 0})</h5>
              </div>
            </div>
            <div className="pageAction">
              {!projectId && (
                <>
                  <SearchComponent onSuccess={value => setCurrentListParams({term: value})} />

                  <KooperTooltip
                    content="Project"
                    size="mini"
                    position="top center"
                    trigger={
                      <KooperDropdown
                        icon="chevron down"
                        button
                        className="dropdown-ellipsis"
                        defaultValue="all"
                        options={[
                          {key: 'all', value: 'all', text: 'All Projects'},
                          <KooperDivider />,
                          ...projectsList.map(({id, title}) => ({
                            key: id,
                            value: id,
                            text: title,
                          })),
                        ]}
                        onChange={(e, {value}) => {
                          setCurrentListParams({
                            ...currentListParams,
                            projectIds: value,
                          })
                        }}
                      />
                    }
                  />
                  <KooperTooltip
                    content="Approval Status"
                    size="mini"
                    position="top center"
                    trigger={
                      <KooperDropdown
                        icon="chevron down"
                        button
                        className="dropdown-ellipsis"
                        defaultValue="all"
                        options={ProofApprovalStatus}
                        onChange={(e, {value}) => {
                          setCurrentListParams({
                            ...currentListParams,
                            approvalStatus: value,
                          })
                        }}
                      />
                    }
                  />
                  <KooperTooltip
                    content="Status"
                    size="mini"
                    position="top center"
                    trigger={
                      <KooperDropdown
                        icon="chevron down"
                        button
                        className="dropdown-ellipsis"
                        defaultValue="all"
                        options={[
                          {key: 'all', value: 'all', text: 'All Projects'},
                          <KooperDivider />,
                          {key: 'active', value: 'active', text: 'Active'},
                          {key: 'archive', value: 'archive', text: 'Archive'},
                        ]}
                        onChange={(e, {value}) => {
                          setCurrentListParams({
                            ...currentListParams,
                            status: value,
                          })
                        }}
                      />
                    }
                  />
                </>
              )}
              <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
                <KooperButton
                  content="Create Proof"
                  primary
                  className={!rolePermissions ? 'disabled-button' : ''}
                  onClick={() => rolePermissions && setIsAddProofsOpen(!isAddProofsOpen)}
                />
              </LockPermissionTooltip>
            </div>
          </div>
          <KooperTable sortable basic selectable>
            <KooperTableHeader>
              <KooperTableRow>
                <KooperTableHeaderCell>#</KooperTableHeaderCell>
                <KooperTableHeaderCell>Proof Name</KooperTableHeaderCell>
                <KooperTableHeaderCell>Approval Status</KooperTableHeaderCell>
                <KooperTableHeaderCell>Project Name</KooperTableHeaderCell>
                <KooperTableHeaderCell>Linked Task</KooperTableHeaderCell>
                <KooperTableHeaderCell>Created By</KooperTableHeaderCell>
                <KooperTableHeaderCell>Due date</KooperTableHeaderCell>
                <KooperTableHeaderCell>Reviewers & Approvers</KooperTableHeaderCell>
                <KooperTableHeaderCell>Actions</KooperTableHeaderCell>
              </KooperTableRow>
            </KooperTableHeader>
            <KooperTableBody>
              {type.includes(GET_ALL_PROOFS) ? (
                <KooperTablePlaceholder columns={9} />
              ) : (getProofsListData || []).length > 0 ? (
                renderTableBody()
              ) : (
                <tr>
                  <td colSpan={9} className="emptyValue">
                    <KooperNoData type="table" noDataText="No Data Available" />
                  </td>
                </tr>
              )}
            </KooperTableBody>
          </KooperTable>
          {deleteModal && (
            <DeleteModal
              idTobeDeleted={editId}
              deleteAction={deleteProof}
              isModalOpen={deleteModal}
              setIsModalOpen={setDeleteModal}
              modalText="Are you sure you want to delete ?"
              type={DELETE_PROOF}
            />
          )}
          {isAddProofsOpen && (
            <AddProofs
              projectId={projectId}
              setIsAddProofsOpen={setIsAddProofsOpen}
              isAddProofsOpen={isAddProofsOpen}
            />
          )}
        </div>
      ) : (
        <>
          {isAddProofsOpen && (
            <AddProofs
              projectId={projectId}
              setIsAddProofsOpen={setIsAddProofsOpen}
              isAddProofsOpen={isAddProofsOpen}
            />
          )}
          <CreateFirst
            header="Proofs"
            subHeader="Proofs help to store and organize important documents or evidence related to project activities and outcomes"
            addFirst={() => rolePermissions && setIsAddProofsOpen(true)}
            buttonText="Create Proof"
            tooltip={!rolePermissions}
            tooltipContent={getPermissionTooltipContent({roleAccess: rolePermissions})}
            imageSrc={create}
            Feature1="Version control"
            list1="Maintain multiple versions of a single proof"
            Feature2="Set approvers"
            list2="Assign multiple approver for a proof"
            Feature3="Request change"
            list3="Apply for proof changes in one click"
          />
        </>
      )}
    </>
  )
}

export default ProofsList
