import DeleteModal from 'components/common/DeleteModal'
import SvgIcon from 'components/common/SvgIcon'
import OneOnOneSectionForm from 'components/pages/Teams/OneOnOneS/CreateOneOnOnes/OneOnOneSectionForm'
import OneOnOneTalkingPointForm from 'components/pages/Teams/OneOnOneS/CreateOneOnOnes/OneOnOneTalkingPointForm'
import OneOnOneDetailsCarousel from 'components/pages/Teams/OneOnOneS/OneOnOnesDetails/OneOnOneDetailsCarousel'
import ScheduleOneOnOneModal from 'components/pages/Teams/OneOnOneS/OneOnOnesDetails/ScheduleOneOnOneModal'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperContainer,
  KooperDivider,
  KooperDragDropContext,
  KooperDraggable,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperDroppable,
  KooperEditor,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormTextArea,
  KooperHeader,
  KooperIcon,
  KooperList,
  KooperListItem,
  KooperPopup,
  KooperSegment,
  KooperTooltip,
} from 'kooper-library'
import {useState} from 'react'

const editorOptions = {
  height: 200,
  paragraphStyles: [
    'spaced',
    {
      name: 'Custom',
      class: '__se__customClass',
    },
  ],
  buttonList: [['bold', 'italic', 'underline', 'list']],
}

const OneOnOneSDetails = () => {
  const [points, setPoints] = useState([
    {
      id: 'check-in',
      title: 'Check-in',
      duration: 10,
      type: 'section',
    },
    {
      id: 'tp1',
      content: 'How have you been since our last 1-on-1?',
      type: 'talkingPoint',
    },
    {
      id: 'whats-up',
      title: "What's up?",
      duration: 10,
      type: 'section',
    },
    {
      id: 'tp2',
      content: 'What went well since the last time we met?',
      type: 'talkingPoint',
    },
    {
      id: 'tp3',
      content:
        'When you think of your current goals, what actions are you currently doing or want to start doing to reach them?',
      type: 'talkingPoint',
    },
    {
      id: 'wrap-up',
      title: 'Wrap-up',
      duration: 5,
      type: 'section',
    },
    {
      id: 'tp4',
      content: "Is there anything we haven't covered that you'd like to discuss?",
      type: 'talkingPoint',
    },
    {
      id: 'tp5',
      content: 'What are the main points you got out of our conversation?',
      type: 'talkingPoint',
    },
  ])

  const onDragEnd = result => {
    const {source, destination, type} = result

    // If no destination, do nothing
    if (!destination) return

    // Handle reordering sections
    if (type === 'section') {
      const reorderedSections = Array.from(points)
      const [movedItem] = reorderedSections.splice(source.index, 1)
      reorderedSections.splice(destination.index, 0, movedItem)
      setPoints(reorderedSections)
      return
    }

    // Handle reordering talking points within or across sections
    if (type === 'talkingPoint') {
      const sourceSectionIndex = points.findIndex(point => point.id === source.droppableId)
      const destinationSectionIndex = points.findIndex(point => point.id === destination.droppableId)

      if (sourceSectionIndex === -1 || destinationSectionIndex === -1) return

      const sourceSection = points[sourceSectionIndex]
      const destinationSection = points[destinationSectionIndex]

      // Ensure both source and destination sections are of the same type
      if (sourceSection.type !== 'section' || destinationSection.type !== 'section') return

      const sourceTalkingPoints = sourceSection.talkingPoints || []
      const destinationTalkingPoints = destinationSection.talkingPoints || []

      const [movedTalkingPoint] = sourceTalkingPoints.splice(source.index, 1)

      if (sourceSectionIndex === destinationSectionIndex) {
        // Reorder within the same section
        sourceTalkingPoints.splice(destination.index, 0, movedTalkingPoint)
      } else {
        // Move to another section
        destinationTalkingPoints.splice(destination.index, 0, movedTalkingPoint)
      }

      const updatedSections = points.map((section, index) => {
        if (index === sourceSectionIndex) {
          return {...section, talkingPoints: sourceTalkingPoints}
        }
        if (index === destinationSectionIndex) {
          return {...section, talkingPoints: destinationTalkingPoints}
        }
        return section
      })

      setPoints(updatedSections)
    }
  }

  const addTalkingPoint = () => {
    const updatedSections = [...points]
    updatedSections.push({
      id: `tp${Date.now()}`,
      content: '',
      type: 'talkingPoint',
      new: true,
    })
    setPoints(updatedSections)
  }

  const addSection = () => {
    const updatedSections = [...points]
    updatedSections.push({
      type: 'section',
      new: true,

      id: `section${Date.now()}`,
      title: '',
    })
    setPoints(updatedSections)
  }

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isEditingTitle, setIsEditingTitle] = useState(false)
  const [isEditingDescription, setIsEditingDescription] = useState(false)
  const [title, setTitle] = useState('ujhigh')
  const [description, setDescription] = useState("Add a description of what you'll talk about.")

  const [items, setItems] = useState(['gdh', 'hdfhfh'])
  const [editingIndex, setEditingIndex] = useState(null)
  const [editValue, setEditValue] = useState('')
  const [newItem, setNewItem] = useState('')

  const handleEdit = (index: number) => {
    setEditingIndex(index)
    setEditValue(items[index])
  }

  const handleSave = (index: number) => {
    if (editValue.trim()) {
      const newItems = [...items]
      newItems[index] = editValue.trim()
      setItems(newItems)
    }
    setEditingIndex(null)
    setEditValue('')
  }

  const addAction = () => {
    setItems([...items, newItem.trim()])
    setNewItem('')
    setEditingIndex(items?.length)
  }
  const [openScheduleModal, setOpenScheduleModal] = useState(false)

  const teams = [
    {value: 'all', key: 'all', text: 'agenda 1 - (12 November, 2024)'},
    {value: 'team 1', key: 'team 1', text: 'agenda 2 - (Unscheduled)'},
    {value: 'team 2', key: 'team 2', text: 'agenda 3 - (Unscheduled)'},
  ]
  return (
    <div className="kooper-side-wrap">
      <KooperContainer>
        <div className="d-flex mb-4 ">
          <KooperHeader as="h2" className="mb-0">
            1-on-1s with test user &nbsp;
            <KooperPopup
              content={
                <KooperSegment>
                  <strong>Collaborate with test-survey-02 on this agenda</strong>
                  <br />
                  You can both add talking points and private notes, view past 1-on-1s, and more, for truly useful
                  conversations.
                  <br />
                  <span style={{color: 'blue'}}>Tell me more</span>
                </KooperSegment>
              }
              trigger={<SvgIcon path="settings/users" />}
              size="mini"
              position="right center"
            />
          </KooperHeader>
          <div className="d-flex mailActivity">
            <KooperDropdown
              icon="chevron down"
              button
              search
              placeholder="select your agenda"
              // defaultValue="all"
              options={teams}
            />
            <KooperButton onClick={() => setOpenScheduleModal(true)} basic primary>
              <KooperIcon name="calendar minus outline" />
              Schedule 1-on-1
            </KooperButton>
          </div>
        </div>

        {/* <OneOnOneDetailsCarousel setUnscheduled={setUnscheduled} unscheduled={unscheduled} /> */}
        <KooperForm className="errorLabel">
          <KooperCard fluid>
            <KooperCardContent>
              <div className="d-flex jcsb mb-4">
                <span style={{color: '#666'}}>Unscheduled</span>
                <div className="d-flex">
                  <KooperButton primary className="mr-4">
                    Wrap up
                  </KooperButton>
                  <KooperTooltip
                    content="More"
                    size="mini"
                    position="top center"
                    trigger={
                      <KooperDropdown
                        button
                        icon={<SvgIcon path="common/more-verticle" />}
                        className="icon mr-0 button"
                      >
                        <KooperDropdownMenu direction="left">
                          <KooperDropdownItem>
                            <KooperIcon name="sticky note outline" size="sm" /> Save to my template library
                          </KooperDropdownItem>

                          <KooperDropdownItem onClick={() => setIsDeleteModalOpen(true)}>
                            <SvgIcon path="common/delete" /> Delete this section
                          </KooperDropdownItem>
                        </KooperDropdownMenu>
                      </KooperDropdown>
                    }
                  />
                </div>
              </div>

              <KooperFormGroup>
                <KooperFormField width={8}>
                  {isEditingTitle ? (
                    <KooperFormInput
                      fluid
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                      onBlur={() => setIsEditingTitle(false)}
                      style={{
                        fontSize: '1.2rem',
                      }}
                    />
                  ) : (
                    <button
                      type="button"
                      onClick={() => setIsEditingTitle(true)}
                      style={{
                        fontSize: '24px',
                        cursor: 'pointer',
                        background: 'none',
                        border: 'none',
                      }}
                    >
                      {title}
                    </button>
                  )}
                </KooperFormField>
              </KooperFormGroup>
              <KooperFormGroup>
                <KooperFormField width={8}>
                  {isEditingDescription ? (
                    <KooperFormTextArea
                      value={description}
                      onChange={e => setDescription(e.target.value)}
                      onBlur={() => setIsEditingDescription(false)}
                    />
                  ) : (
                    <button
                      type="button"
                      onClick={() => setIsEditingDescription(true)}
                      style={{
                        color: '#666',
                        cursor: 'pointer',
                        background: 'none',
                        display: 'block',
                        border: 'none',
                      }}
                    >
                      {description}
                    </button>
                  )}
                </KooperFormField>
              </KooperFormGroup>

              <KooperDivider />
              {points?.length === 0 && (
                <KooperFormField width={8}>
                  <div className="info-header">
                    <label>What do you want to talk about?</label>
                  </div>
                </KooperFormField>
              )}
              <KooperDragDropContext onDragEnd={onDragEnd}>
                <KooperDroppable droppableId="sections" type="section">
                  {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {points.map((point, index) => (
                        <KooperDraggable key={point.id} draggableId={point.id} index={index}>
                          {provided => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              {point.type === 'section' && <OneOnOneSectionForm section={point} />}
                              {point.type === 'talkingPoint' && (
                                <OneOnOneTalkingPointForm isDetails talkingPoint={point} />
                              )}
                            </div>
                          )}
                        </KooperDraggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </KooperDroppable>
              </KooperDragDropContext>

              <div className="d-flex jcfs">
                <KooperButton basic color="blue" className="mr-4" onClick={addTalkingPoint}>
                  <KooperIcon name="plus" />
                  Add talking point
                </KooperButton>
                <KooperButton basic color="blue" onClick={addSection}>
                  <KooperIcon name="plus" />
                  Add section
                </KooperButton>
              </div>
            </KooperCardContent>
          </KooperCard>

          <KooperCard fluid>
            <KooperCardContent>
              <KooperHeader
                as="h2"
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  marginBottom: '12px',
                }}
              >
                Summary
              </KooperHeader>
              <KooperContainer
                style={{
                  marginBottom: '20px',
                  color: 'rgba(0,0,0,0.7)',
                  fontSize: '14px',
                  lineHeight: '1.4',
                }}
              >
                You and your team member can add notes about this 1-on-1. They will be shared with both of you after the
                meeting.
              </KooperContainer>
              <KooperEditor
                width="100%"
                placeholder="What did you talk about and how did it go? Are there action items? What was resolved or needs to be revisited?"
                setOptions={editorOptions}
                setDefaultStyle="font-size: 15px; line-height: 1.5;"
                //   setContents={value}
                //   onChange={content => onChange(content)}
                //   disable={!isEditable}
                // onBlur={() => setOpenNote(false)}
              />
              {/* <KooperFormTextArea
                placeholder="What did you talk about and how did it go? Are there action items? What was resolved or needs to be revisited?"
                style={{
                  width: '100%',
                  minHeight: '100px',
                  padding: '16px',
                  border: '1px solid rgba(0,0,0,0.1)',
                  borderRadius: '4px',
                  fontSize: '14px',
                  resize: 'vertical',
                }}
              /> */}
            </KooperCardContent>
          </KooperCard>
          <KooperCard
            fluid
            style={{
              border: '1px solid rgba(0,0,0,0.1)',
              backgroundColor: 'rgb(255, 250, 210)',
            }}
          >
            <KooperCardContent>
              <KooperHeader
                as="h2"
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  marginBottom: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                Private notes
                <KooperIcon
                  name="lock"
                  size="small"
                  style={{
                    margin: 0,
                    opacity: 0.6,
                  }}
                />
              </KooperHeader>

              <div
                style={{
                  marginBottom: '16px',
                  color: 'rgba(0,0,0,0.7)',
                  fontSize: '14px',
                  lineHeight: '1.4',
                }}
              >
                What you write in this section is visible only to you
              </div>

              <div style={{position: 'relative'}}>
                <KooperFormTextArea
                  placeholder="This section can be as messy as your teenage bedroom. Just write whatever you're thinking. Is there anything you need to keep an eye on? Offer guidance about? Plan a reward for?"
                  style={{
                    width: '100%',
                    minHeight: '100px',
                    padding: '16px',
                    border: '1px solid rgba(0,0,0,0.1)',
                    borderRadius: '4px',
                    fontSize: '14px',
                    resize: 'vertical',
                    backgroundColor: 'white',
                  }}
                />
                <KooperButton
                  primary
                  size="small"
                  style={{
                    backgroundColor: '#4F46E5',
                    color: 'white',
                    position: 'absolute',
                    bottom: 10,
                    right: 10,
                  }}
                >
                  Save
                </KooperButton>
              </div>
            </KooperCardContent>
          </KooperCard>

          <KooperCard fluid>
            <KooperCardContent>
              <KooperHeader
                as="h2"
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  marginBottom: '8px',
                }}
              >
                Action items
              </KooperHeader>

              <div
                style={{
                  marginBottom: '16px',
                  color: 'rgba(0,0,0,0.7)',
                  fontSize: '14px',
                  lineHeight: '1.4',
                }}
              >
                What are the next steps?
              </div>

              <KooperList style={{marginBottom: '16px'}}>
                {items.map((item, index) => (
                  <KooperListItem
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '8px 12px',
                      gap: '8px',
                      borderRadius: '4px',
                    }}
                  >
                    {editingIndex === index ? (
                      <KooperFormGroup className="d-flex jcfs" style={{width: '100%', position: 'relative'}}>
                        <span
                          style={{
                            fontSize: '20px',
                            lineHeight: '1',
                            color: '#6b7280',
                          }}
                        >
                          •
                        </span>
                        <KooperFormField style={{flex: 1}} width={16}>
                          <KooperFormInput
                            width={16}
                            fluid
                            value={editValue}
                            onChange={e => setEditValue(e.target.value)}
                            autoFocus
                          />
                        </KooperFormField>
                        <KooperButton
                          style={{position: 'absolute', right: 40, padding: '6px 8px'}}
                          primary
                          onClick={() => handleSave(index)}
                        >
                          Save
                        </KooperButton>
                      </KooperFormGroup>
                    ) : (
                      <KooperFormGroup className="d-flex jcfs" style={{width: '100%', position: 'relative'}}>
                        <span
                          style={{
                            fontSize: '20px',
                            lineHeight: '1',
                            color: '#6b7280',
                            marginRight: '4px',
                          }}
                        >
                          •
                        </span>
                        <span style={{flex: 1}} onClick={() => handleEdit(index)}>
                          {item}
                        </span>
                        <div style={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                          <KooperIcon
                            name="user"
                            className="mr-3"
                            style={{
                              color: '#9ca3af',
                            }}
                          />
                          <KooperTooltip
                            content="More"
                            size="mini"
                            position="top center"
                            trigger={
                              <KooperDropdown
                                button
                                icon={<SvgIcon path="common/more-verticle" />}
                                className="icon mr-0 button"
                              >
                                <KooperDropdownMenu direction="left">
                                  <KooperDropdownItem>
                                    <KooperIcon name="user cancel" size="sm" /> Unassign
                                  </KooperDropdownItem>
                                  <KooperDropdownItem>
                                    <KooperIcon name="sticky note outline" size="sm" /> Add a due date
                                  </KooperDropdownItem>

                                  <KooperDropdownItem onClick={() => setIsDeleteModalOpen(true)}>
                                    <SvgIcon path="common/delete" /> Delete
                                  </KooperDropdownItem>
                                </KooperDropdownMenu>
                              </KooperDropdown>
                            }
                          />
                        </div>
                      </KooperFormGroup>
                    )}
                  </KooperListItem>
                ))}
              </KooperList>

              <KooperButton basic color="blue" onClick={addAction}>
                <KooperIcon name="plus" />
                Add action item
              </KooperButton>
            </KooperCardContent>
          </KooperCard>
        </KooperForm>
      </KooperContainer>
      {openScheduleModal && (
        <ScheduleOneOnOneModal isModalOpen={openScheduleModal} setIsModalOpen={setOpenScheduleModal} />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          // idTobeDeleted={deleteId}
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          //   deleteAction={deleteProjectsCategory}
          //   type={DELETE_PROJECTS_CATEGORY}
        />
      )}
    </div>
  )
}

export default OneOnOneSDetails
