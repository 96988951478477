import {deleteProjectsTimesheetTimeLogs, getProjectsTimeTrackingList} from 'actions/projects'
import {timeStatusList} from 'constants/projects'
import _ from 'lodash'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {FiChevronRight} from 'react-icons/fi'
import {useDispatch, useSelector} from 'react-redux'
import {get, getFormattedDate, startCase} from 'utils/helper'
import {
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperIcon,
  KooperNoData,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import DeleteModal from 'components/common/DeleteModal'
import {DELETE_PROJECTS_TIMESHEET_TIME_LOGS, UPDATE_PROJECTS_TIMESHEET_TIME_LOG} from 'actions/types'
import SvgIcon from 'components/common/SvgIcon'
import TimeModal from '../../../Time/TimeModal'

const ProjectBudgetTimeLog = ({projectId}) => {
  const dispatch = useDispatch()

  const [activeAccordion, setActiveAccordion] = useState(-1)
  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false)
  const [timesheetId, setTimesheetId] = useState(null)
  const [timeId, setTimeId] = useState(null)
  const [isTimeDeleteModalOpen, setIsTimeDeleteModalOpen] = useState(false)

  const [currentListParams, setCurrentListParams] = useState({
    projectId,
    status: 'all',
    createdBy: 'all',
    groupBy: 'date',
    orderBy: 'asc',
  })

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {timeTrackingList = {}, projectAssignees = [], timesheets = []} = useSelector(state => state.projects)

  useEffect(() => {
    const newList = _.omitBy(currentListParams, value => value === 'all')
    dispatch(getProjectsTimeTrackingList(newList))
  }, [dispatch, currentListParams])

  useEffect(() => {
    if (
      successLabels.includes(UPDATE_PROJECTS_TIMESHEET_TIME_LOG) ||
      successLabels.includes(DELETE_PROJECTS_TIMESHEET_TIME_LOGS)
    ) {
      const newList = _.omitBy(currentListParams, value => value === 'all')
      dispatch(getProjectsTimeTrackingList(newList))
    }
  }, [successLabels, currentListParams, dispatch])

  const getValue = key => {
    if (currentListParams.groupBy === 'date') {
      return moment(key).format('MMM DD')
    }
    if (currentListParams.groupBy === 'month') {
      return moment(key).format('MMM, YYYY')
    }
    if (currentListParams.groupBy === 'person') {
      const assignee = projectAssignees.find(assignee => assignee.id === +key)
      if (assignee) {
        return `${get(['firstName'], assignee, '')} ${get(['lastName'], assignee, '')}`
      }
      return null
    }
    if (currentListParams.groupBy === 'timesheet') {
      const timesheet = timesheets.find(timesheet => timesheet.id === +key)
      if (timesheet) {
        return timesheet.title
      }
      return null
    }
    return null
  }

  const renderTableBody = () => {
    if (Object.values(timeTrackingList).every(val => val.length === 0)) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="8" className="emptyValue">
            <KooperNoData type="table" noDataText="No Data Available" />
          </KooperTableCell>
        </KooperTableRow>
      )
    }
    return Object.entries(timeTrackingList).map(([key, data], index) => {
      return (
        <>
          <KooperTableRow onClick={() => setActiveAccordion(activeAccordion === index ? '-1' : index)}>
            <KooperTableCell>
              {activeAccordion === index ? (
                <SvgIcon path="common/down" style={{marginRight: 8}} />
              ) : (
                <FiChevronRight style={{marginRight: 8}} />
              )}{' '}
              {getValue(key)}
            </KooperTableCell>
            <KooperTableCell colSpan={8} />
          </KooperTableRow>
          {data.map(timeLog => (
            <KooperTableRow
              key={timeLog.id}
              className="tableLink"
              style={{
                display: activeAccordion === index ? '' : 'none',
              }}
            >
              <KooperTableCell>
                {get(['createdByDetails', 'firstName'], timeLog, '')}{' '}
                {get(['createdByDetails', 'lastName'], timeLog, '')}
              </KooperTableCell>
              <KooperTableCell>{`${get(['loggedHrs'], timeLog, 0)}h ${get(
                ['loggedMins'],
                timeLog,
                0
              )}m`}</KooperTableCell>
              <KooperTableCell>{getFormattedDate(timeLog.date)}</KooperTableCell>
              <KooperTableCell>{timeLog.description || 'No Description'}</KooperTableCell>
              <KooperTableCell>{timeLog.tasklistDetails?.title || '-'}</KooperTableCell>
              <KooperTableCell>{timeLog.taskDetails?.title || '-'}</KooperTableCell>
              <KooperTableCell>{startCase(get(['status'], timeLog, ''))}</KooperTableCell>
              <KooperTableCell>
                <KooperDropdown direction="left" icon={<SvgIcon path="common/more-verticle" />} className="icon">
                  <KooperDropdownMenu>
                    <KooperDropdownItem
                      onClick={() => {
                        setTimeId(timeLog.id)
                        setTimesheetId(timeLog.timesheetId)
                        setIsTimeModalOpen(true)
                      }}
                    >
                      <KooperIcon name="edit" /> Edit
                    </KooperDropdownItem>
                    <KooperDropdownItem
                      onClick={() => {
                        setTimeId(timeLog.id)
                        setTimesheetId(timeLog.timesheetId)
                        setIsTimeDeleteModalOpen(true)
                      }}
                    >
                      <KooperIcon name="trash alternate" /> Delete
                    </KooperDropdownItem>
                  </KooperDropdownMenu>
                </KooperDropdown>
              </KooperTableCell>
            </KooperTableRow>
          ))}
        </>
      )
    })
  }

  return (
    <>
      <div className="project-finance-budget-expense-action">
        <KooperTooltip
          position="top center"
          content="Status"
          size="mini"
          trigger={
            <KooperDropdown
              icon="chevron down"
              button
              className="dropdown-ellipsis"
              options={[{key: 'all', value: 'all', text: 'All'}, ...timeStatusList]}
              value={currentListParams.status}
              onChange={(e, {value}) =>
                setCurrentListParams({
                  ...currentListParams,
                  status: value,
                })
              }
            />
          }
        />
        <KooperTooltip
          position="top center"
          content="Logged By"
          size="mini"
          trigger={
            <KooperDropdown
              icon="chevron down"
              button
              className="dropdown-ellipsis"
              options={[
                {key: 'all', value: 'all', text: 'All'},
                ...projectAssignees.map(({id, firstName, lastName}) => ({
                  key: id,
                  value: id,
                  text: `${firstName} ${lastName}`,
                })),
              ]}
              value={currentListParams.createdBy}
              onChange={(e, {value}) =>
                setCurrentListParams({
                  ...currentListParams,
                  createdBy: value,
                })
              }
            />
          }
        />
        <KooperTooltip
          position="top center"
          content="Group By"
          size="mini"
          trigger={
            <KooperDropdown
              icon="chevron down"
              button
              className="dropdown-ellipsis"
              options={[
                {key: 'date', value: 'date', text: 'Date'},
                {key: 'month', value: 'month', text: 'Month'},
                {key: 'person', value: 'person', text: 'Person'},
                {key: 'timesheet', value: 'timesheet', text: 'Timesheet'},
              ]}
              value={currentListParams.groupBy}
              onChange={(e, {value}) =>
                setCurrentListParams({
                  ...currentListParams,
                  groupBy: value,
                })
              }
            />
          }
        />
        <KooperTooltip
          position="top center"
          content="Order By"
          size="mini"
          trigger={
            <KooperDropdown
              icon="chevron down"
              button
              className="dropdown-ellipsis"
              options={[
                {key: 'asc', value: 'asc', text: 'Asc'},
                {key: 'desc', value: 'desc', text: 'Desc'},
              ]}
              value={currentListParams.orderBy}
              onChange={(e, {value}) =>
                setCurrentListParams({
                  ...currentListParams,
                  orderBy: value,
                })
              }
            />
          }
        />
      </div>

      <KooperTable basic>
        <KooperTableHeader>
          <KooperTableRow>
            <KooperTableHeaderCell>Logged By</KooperTableHeaderCell>
            <KooperTableHeaderCell>Time Logged</KooperTableHeaderCell>
            <KooperTableHeaderCell>Date</KooperTableHeaderCell>
            <KooperTableHeaderCell>Description</KooperTableHeaderCell>
            <KooperTableHeaderCell>Tasklist</KooperTableHeaderCell>
            <KooperTableHeaderCell>Task</KooperTableHeaderCell>
            <KooperTableHeaderCell>Status</KooperTableHeaderCell>
            <KooperTableHeaderCell>Actions</KooperTableHeaderCell>
          </KooperTableRow>
        </KooperTableHeader>
        <KooperTableBody>{renderTableBody()}</KooperTableBody>
      </KooperTable>

      {isTimeModalOpen && (
        <TimeModal
          open={isTimeModalOpen}
          toggle={setIsTimeModalOpen}
          projectId={projectId}
          timesheetId={timesheetId}
          timeId={timeId}
        />
      )}

      {isTimeDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isTimeDeleteModalOpen}
          setIsModalOpen={setIsTimeDeleteModalOpen}
          onDelete={() => dispatch(deleteProjectsTimesheetTimeLogs({projectId, timesheetId, timeIds: [timeId]}))}
          type={DELETE_PROJECTS_TIMESHEET_TIME_LOGS}
        />
      )}
    </>
  )
}

export default ProjectBudgetTimeLog
