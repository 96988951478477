/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {matchPath, useHistory} from 'react-router'
import Axios from 'axios'
import _ from 'lodash'
import {
  ADD_MESSAGE_TO_CONVERSATION,
  CREATE_TASK,
  DELETE_CONVERSATION,
  GET_CONVERSATION,
  GET_CONVERSATION_DATA,
  GET_TIME_TRACKING_ENTRY_STATUS,
  MERGE_CONVERSATION,
  MOVE_CONVERSATION_TO,
  SEND_NOTE,
  SNOOZE_CONVERSATION,
  SPLIT_CONVERSATION,
  SUBMIT_TIME_TRACKING_AUTOMATIC,
  SUBMIT_TIME_TRACKING_MANUAL,
  UPDATE_CONVERSATION_AND_REFRESH,
} from 'actions/types'
import {getAllMailInbox, getConversation} from 'actions/inbox'
import {get, onIdealUser} from 'utils/helper'
import {EmailBodyContentPlaceholder, EmptyInboxPlaceholder} from 'components/common/InboxPlaceholders'
import nodata from 'assets/no-data.svg'
import AttachmentPreview from 'components/common/AttachmentPreview'
import {getUserInfo} from 'utils/local-storage'
import {InboxInLineComposeMail} from 'components/pages/EmailInbox/InboxInLineComposeMail'
import useEmailBody from 'hooks/pure/useEmailBody'
import {getEnteriesByCoversation, submitTimeTrackingAutomatic, submitTimeTrackingManual} from 'actions/time_tracking'
import {INBOX_TYPES} from 'constants/enums'
import {EmailBodyContent} from './EmailBodyContent'
import EmailBodyHeader from './EmailBodyHeader'
import EmailBodyFooter from './EmailBodyFooter'

const {CancelToken} = Axios
const requestToken = CancelToken.source()

export const EmailBody = props => {
  const {refreshInboxData, customerProfile, setCustomerProfile, typingConversationId, selectedInbox} = props

  const dispatch = useDispatch()
  const history = useHistory()

  const {editorOpenFor, setEditorOpenFor, editorData, setEditorData} = useEmailBody()

  const emailBodyContentRef = useRef()
  const emailBodyRef = React.createRef()
  const replyEditorRef = React.createRef()

  const [uploadedFilesNote, setUploadedFilesNote] = useState([])
  const [noteToMessage, setNoteToMessage] = useState('')
  const [teammate, setTeammate] = useState([])
  const [teamList, setTeamList] = useState([])
  const [assigneeOptions, setAssigneeOptions] = useState({})
  const [filesNote, setFilesNote] = useState([])
  const [timeTrackingTimer, setTimeTrackingTimer] = useState(0)
  const [timeTrackingCounterStatus, setTimeTrackingCounterStatus] = useState('completed')
  const [timeTrackingStartTime, setTimeTrackingStartTime] = useState(new Date())

  const {isLoadingData, type = [], successLabels = []} = useSelector(state => state.apiReducer)
  const {inbox: mailboxes} = useSelector(state => state.mailInbox)
  const {conversation, conversations, getConversationMessage} = useSelector(state => state.emailInbox, _.isEqual)
  const {timeTrackingData = {}, timeTrackingEntryStatusData = {}} = useSelector(state => state.timeTracking)

  const {
    messages: conversationMessages = [],
    type: conversationType,
    isTrashed: isTrashedConversation,
    labels: currentLabelsOfConversation,
    ticketNo,
    snoozeTime: isSnoozed,
    subject: conversationSubject,
    inboxId,
    id: ConversationId,
    isSpam: CurrentSpamStatusOfConversation,
    isRead: CurrentReadStatusOfConversation,
    priority: CurrentPriorityOfConversation,
    status: CurrentStatusOfConversation = {},
    assignee: CurrentAssigneeOfConversation = null,
    team: currentTeamOfConversation,
    contact,
  } = conversation || {}
  const [{id: MessagesId = 0} = {}] = conversationMessages || []

  const getParams =
    matchPath(history.location.pathname, {
      path: '/inbox/:id',
    }) ||
    matchPath(history.location.pathname, {
      path: '/conversation/:id',
    })

  const convertNote = noteToMessage => {
    let note = noteToMessage

    if (noteToMessage !== '') {
      note = note.split('@@@').join(`<span class='mentionsName'>`)
      note = note.split('@@^^^').join('</span>')
      return note
    }
  }

  const getTeammatesExcludingMe = () => {
    const userId = get(['id'], getUserInfo())
    if (teammate) {
      return teammate
        .map(teammate => {
          const name = `${teammate.firstName} ${teammate.lastName}`
          return {
            id: teammate.id,
            display: name,
            avatar: teammate.avatar,
          }
        })
        .filter(teammate => teammate.id !== Number(userId))
    }
  }

  const getTeammatesForSuggesions = () => {
    const teamMatesForMention = getTeammatesExcludingMe()
    if (teamMatesForMention) {
      teamMatesForMention.push({id: -1, display: 'all'})
      return teamMatesForMention
    }
  }

  // ************** Ps: Dont remove code below this line **************
  //  Automatic scroll at bottom of conversation
  const scrollToBottom = () => {
    if (emailBodyRef.current)
      emailBodyRef.current.scrollTo({
        // behavior: "smooth",
        // block: 'end',
        top: emailBodyRef.current.scrollHeight,
        behavior: 'smooth',
      })
  }
  // ************** Ps: Dont remove code above this line **************

  useEffect(() => {
    let id
    if (timeTrackingCounterStatus === 'running') {
      id = setInterval(() => {
        setTimeTrackingTimer(timeTrackingTimer + 1000)
      }, 1000)
    }

    return () => clearInterval(id)
  })

  useEffect(() => {
    if (timeTrackingCounterStatus === 'running') {
      if (timeTrackingData.pauseTimerIdle) {
        onIdealUser({
          onIdeal: () => {
            setTimeTrackingCounterStatus('paused')
            if (!timeTrackingData.isAutoForConversation)
              dispatch(
                submitTimeTrackingManual({
                  conversationId: ConversationId,
                  status: 'paused',
                })
              )
          },
        })
      }
      if (!timeTrackingData.pauseTimerIdle && timeTrackingData.timerPauseLimit > 0) {
        onIdealUser({
          timeOut: timeTrackingData.timerPauseLimit * 1000,
          onIdeal: () => {
            setTimeTrackingCounterStatus('paused')
            if (!timeTrackingData.isAutoForConversation)
              dispatch(
                submitTimeTrackingManual({
                  conversationId: ConversationId,
                  status: 'paused',
                })
              )
          },
        })
      }
    }
  }, [timeTrackingCounterStatus])

  useEffect(() => {
    if (ConversationId && timeTrackingData.isTimeTracking && timeTrackingData.isAutoForConversation) {
      setTimeTrackingStartTime(new Date())
      setTimeTrackingCounterStatus('running')
      setTimeTrackingTimer(0)
    }
  }, [ConversationId, timeTrackingData.isAutoForConversation, timeTrackingData.isTimeTracking])

  useEffect(() => {
    if (
      successLabels.includes(MOVE_CONVERSATION_TO) ||
      successLabels.includes(SNOOZE_CONVERSATION) ||
      successLabels.includes(UPDATE_CONVERSATION_AND_REFRESH)
    ) {
      refreshInboxData(true)
    }
    if ((conversations || []).length > 0 && successLabels.includes(DELETE_CONVERSATION)) {
      const indexofSelected = (conversations || []).findIndex(index => index.id === conversation.id)
      ;(conversations || []).splice(indexofSelected, 1)

      dispatch({
        type: 'UPDATE_CONVERSATIONS_LIST',
        payload: conversations || [],
      })

      refreshInboxData(true)
    }
    if (successLabels.includes(MERGE_CONVERSATION) || successLabels.includes(SPLIT_CONVERSATION)) {
      refreshInboxData(false)
    }
    if (successLabels.includes(CREATE_TASK)) {
      if (ConversationId) {
        dispatch(getConversation(ConversationId))
      }
    }
  }, [successLabels])

  useEffect(() => {
    if (get(['params', 'id'], getParams)) {
      dispatch(getConversation(get(['params', 'id'], getParams)))
    }
    dispatch(getAllMailInbox())
  }, [JSON.stringify(getParams)])

  useEffect(() => {
    if (
      successLabels.includes(GET_CONVERSATION) ||
      successLabels.includes(ADD_MESSAGE_TO_CONVERSATION) ||
      successLabels.includes(SEND_NOTE)
    ) {
      scrollToBottom()
    }
    if (editorOpenFor) {
      scrollToBottom()
    }
    if (successLabels.includes(ADD_MESSAGE_TO_CONVERSATION)) {
      setEditorOpenFor('')

      // automatic time tracking schedule
      if (
        ConversationId &&
        timeTrackingData.isTimeTracking &&
        timeTrackingData.isAutoForConversation &&
        timeTrackingData.onDraftAndReply
      ) {
        dispatch(
          submitTimeTrackingAutomatic({
            startTime: timeTrackingStartTime,
            duration: timeTrackingTimer / 1000,
            conversationId: ConversationId,
            description: `Reply/ Draft to ticket no ${ticketNo}`,
          })
        )
        setTimeTrackingStartTime(new Date())
        setTimeTrackingCounterStatus('running')
        setTimeTrackingTimer(0)
      }
      // manula tracking entry
      if (
        ConversationId &&
        timeTrackingData.isTimeTracking &&
        !timeTrackingData.isAutoForConversation &&
        timeTrackingData.onDraftAndReply
      ) {
        dispatch(
          submitTimeTrackingManual({
            status: 'completed',
            conversationId: ConversationId,
            description: `Reply/ Draft to ticket no ${ticketNo}`,
          })
        )
        setTimeTrackingStartTime(new Date())
        setTimeTrackingCounterStatus('completed')
        setTimeTrackingTimer(0)
      }
    }
  }, [successLabels, editorOpenFor])

  useEffect(() => {
    if (successLabels.includes(GET_TIME_TRACKING_ENTRY_STATUS)) {
      setTimeTrackingTimer(timeTrackingEntryStatusData.duration * 1000)
      setTimeTrackingCounterStatus(timeTrackingEntryStatusData.status)
    }
    if (successLabels.includes(SUBMIT_TIME_TRACKING_AUTOMATIC) || successLabels.includes(SUBMIT_TIME_TRACKING_MANUAL)) {
      dispatch(getEnteriesByCoversation(ConversationId))
    }
  }, [successLabels, dispatch])

  useEffect(() => {
    if (conversation) {
      setEditorOpenFor('')
      setEditorData({
        from: '',
        to: [],
        cc: [],
        bcc: [],
        editorValue: '',
        subject: '',
      })
    }
  }, [conversation])

  useEffect(() => {
    if (successLabels.includes(SEND_NOTE)) {
      setNoteToMessage('')
      setFilesNote([])
      setUploadedFilesNote([])
    }
  }, [successLabels])

  useEffect(() => {
    if (mailboxes) {
      const matchedMailBox = mailboxes.find(mailBox => {
        return inboxId === mailBox.id
      })
      if (matchedMailBox) {
        setTeammate(matchedMailBox?.assignees || [])
        setTeamList(matchedMailBox?.teams || [])
      }
    }
  }, [inboxId, mailboxes])

  useEffect(() => {
    const options = {
      assignees: [
        {
          value: 'unassign',
          text: 'Unassign',
          key: 'unassign',
        },
        ...(teammate || []).map(teammate => {
          return {
            value: teammate.id,
            text: teammate.firstName,
            firstName: teammate.firstName,
            lastName: teammate.lastName,
            key: teammate.id,
            avatar: teammate.avatar,
          }
        }),
      ],
      teams:
        teamList.length > 0
          ? teamList.map(team => ({
              key: team.id,
              value: {value: team.id, isTeam: true},
              text: team.name,
            }))
          : [
              {
                key: 'Teams',
                value: 'teams',
                text: 'Teams',
              },
            ],
    }
    setAssigneeOptions(options)
  }, [teammate, teamList])

  return (
    <>
      {!(isLoadingData && (type.includes(GET_CONVERSATION) || type.includes(GET_CONVERSATION_DATA))) &&
        (conversations || []).length !== 0 && (
          <EmailBodyHeader
            ticketNo={ticketNo}
            conversations={conversations}
            conversation={conversation}
            ConversationId={ConversationId}
            CurrentStatusOfConversation={CurrentStatusOfConversation}
            isSnoozed={isSnoozed}
            mailboxes={mailboxes}
            inboxId={inboxId}
            CurrentReadStatusOfConversation={CurrentReadStatusOfConversation}
            isTrashedConversation={isTrashedConversation}
            emailBodyContentRef={emailBodyContentRef}
            CurrentSpamStatusOfConversation={CurrentSpamStatusOfConversation}
            CurrentPriorityOfConversation={CurrentPriorityOfConversation}
            CurrentAssigneeOfConversation={CurrentAssigneeOfConversation}
            currentTeamOfConversation={currentTeamOfConversation}
            assigneeOptions={assigneeOptions}
            conversationSubject={conversationSubject}
            currentLabelsOfConversation={currentLabelsOfConversation}
            customerProfile={customerProfile}
            setCustomerProfile={setCustomerProfile}
            conversationType={conversationType}
            contact={contact}
            timeTrackingData={timeTrackingData}
            timeTrackingCounterStatus={timeTrackingCounterStatus}
            setTimeTrackingCounterStatus={setTimeTrackingCounterStatus}
            timeTrackingTimer={timeTrackingTimer}
            setTimeTrackingTimer={setTimeTrackingTimer}
          />
        )}
      <div
        className="view-box"
        style={{
          height: ![INBOX_TYPES.EMAIL, INBOX_TYPES.HELPDESK, INBOX_TYPES.SUPPORT_FORM].includes(conversationType)
            ? 'calc(100% - 90px)' // 90px = (.headerBar + .SubjectBar)
            : `calc(100% - ${editorOpenFor ? 98 : 158}px)`, // 99px = (.headerBar + .SubjectBar + 8px) , 158px = (.headerBar + .SubjectBar + .addNote + 8px), 8px is bottom padding
        }}
      >
        {!isLoadingData && (!conversation || conversations?.length === 0) && (
          <EmptyInboxPlaceholder
            header="Great Job, Relax !"
            subHeader="No new conversation as of now..."
            imageSrc={nodata}
          />
        )}
        {isLoadingData && (type.includes(GET_CONVERSATION) || type.includes(GET_CONVERSATION_DATA)) ? (
          <EmailBodyContentPlaceholder />
        ) : (
          conversationType !== undefined && (
            <div ref={emailBodyRef}>
              <EmailBodyContent
                conversationType={conversationType}
                getConversationMessage={getConversationMessage}
                printReference={emailBodyContentRef}
                replyEditorRef={replyEditorRef}
                FromMailId={inboxId}
                renderRecipient={() => 'To Me'}
                conversationMessages={conversationMessages || []}
                noteToMessage={convertNote(noteToMessage)}
                mailbox={mailboxes || []}
                conversationId={ConversationId}
                CurrentStatusOfConversation={CurrentStatusOfConversation}
                getTeammatesForSuggesions={getTeammatesForSuggesions}
                getTeammatesExcludingMe={getTeammatesExcludingMe}
                MessagesId={MessagesId}
                typingConversationId={typingConversationId}
                conversationSubject={conversationSubject}
              />
              <AttachmentPreview
                files={filesNote}
                uploadedFiles={uploadedFilesNote}
                setUploadedFiles={setUploadedFilesNote}
                success="noteAttachment"
                cancelToken={requestToken}
              />
              {['reply', 'replyAll', 'forward'].includes(editorOpenFor) && (
                <InboxInLineComposeMail
                  defaultTo={editorData.to}
                  defaultFrom={editorData.from}
                  defaultEditorValue={editorData.editorValue}
                  ConversationId={ConversationId}
                  fromInboxId={inboxId}
                  defaultSubject={editorData.subject}
                  conversationType={conversation?.type}
                />
              )}
            </div>
          )
        )}
        {!editorOpenFor &&
          !(isLoadingData && (type.includes(GET_CONVERSATION) || type.includes(GET_CONVERSATION_DATA))) &&
          (conversations || []).length !== 0 &&
          [INBOX_TYPES.EMAIL, INBOX_TYPES.HELPDESK, INBOX_TYPES.SUPPORT_FORM].includes(conversationType) &&
          selectedInbox.name !== 'draft' && (
            <EmailBodyFooter
              noteToMessage={noteToMessage}
              setNoteToMessage={setNoteToMessage}
              teammate={teammate}
              filesNote={filesNote}
              setFilesNote={setFilesNote}
              requestToken={requestToken}
              MessagesId={MessagesId}
              convertNote={convertNote}
              uploadedFilesNote={uploadedFilesNote}
            />
          )}
      </div>
    </>
  )
}
