import SvgIcon from 'components/common/SvgIcon'
import {ONE_ON_ONE_S_PATH} from 'constants/path'
import {KooperAccordion, KooperAccordionContent, KooperAccordionTitle, KooperMenu, KooperMenuItem} from 'kooper-library'
import {useState} from 'react'
import {FiChevronRight, FiLayers} from 'react-icons/fi'
import {useHistory} from 'react-router-dom'
import {isActiveNavlink} from 'utils/helper'

const oneOnOneSMenu = [
  {path: `/${ONE_ON_ONE_S_PATH.PREFIX}/all`, title: 'All', exact: true},
  {path: `/${ONE_ON_ONE_S_PATH.PREFIX}/${ONE_ON_ONE_S_PATH.TEAM}`, title: 'Team', exact: false},
  {path: `/${ONE_ON_ONE_S_PATH.PREFIX}/${ONE_ON_ONE_S_PATH.PERSONAL}`, title: 'Personal', exact: false},
]
const OneOnOneSMenu = () => {
  const history = useHistory()

  const [expand, setExpand] = useState([])

  const handleMenuExpand = key => {
    const menus = [...expand]
    if (menus.includes(key)) {
      menus.splice(menus.indexOf(key), 1)
      setExpand(menus)
    } else {
      setExpand([...menus, key])
    }
  }

  const isActive = key => !expand.includes(key)
  return (
    <KooperMenu vertical secondary className="kooper-menu">
      {oneOnOneSMenu.map(({path, title}) => (
        <KooperMenuItem key={path} active={isActiveNavlink(path)} onClick={() => history.push(path)}>
          <FiLayers /> {title}
        </KooperMenuItem>
      ))}

      <KooperAccordion>
        <KooperAccordionTitle onClick={() => handleMenuExpand(ONE_ON_ONE_S_PATH.SETTINGS)}>
          Settings
          {isActive(ONE_ON_ONE_S_PATH.SETTINGS) ? <SvgIcon path="common/down" /> : <FiChevronRight />}
        </KooperAccordionTitle>
        <KooperAccordionContent active={isActive(ONE_ON_ONE_S_PATH.SETTINGS)}>
          <KooperMenuItem
            active={isActiveNavlink(`/${ONE_ON_ONE_S_PATH.PREFIX}/${ONE_ON_ONE_S_PATH.SETTINGS}`)}
            onClick={() => history.push(`/${ONE_ON_ONE_S_PATH.PREFIX}/${ONE_ON_ONE_S_PATH.SETTINGS}`)}
          >
            <SvgIcon path="finance/prefix" /> Settings
          </KooperMenuItem>
        </KooperAccordionContent>
      </KooperAccordion>
    </KooperMenu>
  )
}

export default OneOnOneSMenu
