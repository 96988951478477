import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCheckbox,
  KooperDatePicker,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormSelect,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperRadio,
  KooperTooltip,
} from 'kooper-library'
import {
  CONTACT_TYPE_LIST,
  CREATE_SCORING_RULES,
  GET_ALL_PIPELINES,
  GET_ALL_TAG_LABEL,
  GET_USERS,
  GET_CONTACT_STATUSES,
  GET_SINGLE_SCORING_RULES,
  GET_SOURCES,
  GET_STAGE_DEALS,
  UPDATE_SCORING_RULES,
} from 'actions/types'
import {getTagLabel} from 'actions/multiple_fields'
import {getContactStatus, getSources} from 'actions/custom_options'
import {getContactType} from 'actions/contacts'

import {getPipelines, getStageDeals} from 'actions/deals'
import {
  createScoringRules,
  getAllScoringRules,
  getSingleScoringRules,
  updateScoringRules,
} from 'actions/settings_scoringrules'
import {
  companiesConditionFieldOptions,
  contactsConditionFieldOptions,
  dealsConditionFieldOptions,
  operatorOptionsEq,
  operatorOptionsEqC,
  operatorsOptions,
} from 'constants/ScoringConstants'
import {removeDoubleQuotes, startCase, KooperCountryList} from 'utils/helper'
import {priorityList} from 'constants/variables'
import {ScoringSchema} from 'validation/Settings/sales/Scoring.schema'
import SvgIcon from 'components/common/SvgIcon'
import {getUsers} from 'actions/settings'
import useApiResponse from 'hooks/impure/useApiResponse'

const ScoringDefaultValues = {
  entity: '',
  name: '',
  conditionsJson: {
    conditionsArray: [{field: '', operator: '', value: ''}],
    conditionsOperator: 'and',
  },
  isAddition: true,
  points: null,
}

const ScoringModal = ({open, toggle, editId, entity, setEditId}) => {
  const dispatch = useDispatch()

  const [fieldOptions, setFieldOptions] = useState([])
  const [dropdownData, setDropdownData] = useState({
    assigneeList: [],
    tagList: [],
    statusList: [],
    sourceList: [],
    contactTypeList: [],
    stageList: [],
  })

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {getSingleScoringRulesData = {}} = useSelector(state => state.settingsScoringRules)

  const {handleSubmit, errors, clearErrors, control, watch, setValue, getValues, reset} = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange',
    defaultValues: ScoringDefaultValues,
    resolver: joiResolver(ScoringSchema),
  })

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'conditionsJson.conditionsArray',
  })

  const currentIfCondition = getValues('conditionsJson.conditionsArray')

  const {data: getAllPipelineData = []} = useApiResponse({
    action: getPipelines,
    enabled: true,
    label: GET_ALL_PIPELINES,
    storePath: 'deals.getAllPipelineData',
  })

  useEffect(() => {
    if (editId) {
      dispatch(getSingleScoringRules(editId))
    }
  }, [editId, dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_SCORING_RULES)) {
      const data = _.pick(getSingleScoringRulesData, ['entity', 'name', 'conditionsJson', 'isAddition', 'points'])
      reset(data)
    }
  }, [successLabels, reset, getSingleScoringRulesData])

  useEffect(() => {
    if (successLabels.includes(GET_ALL_PIPELINES)) {
      dispatch(getStageDeals(getAllPipelineData[0].id))
    }
  }, [successLabels, dispatch, getAllPipelineData])

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList.users.map(({id, firstName, lastName}) => ({
        key: id,
        value: id,
        text: `${firstName} ${lastName}`,
      }))
      setDropdownData(dropdownData => ({
        ...dropdownData,
        assigneeList: list,
      }))
    },
  })
  useApiResponse({
    action: getTagLabel,
    enabled: true,
    label: GET_ALL_TAG_LABEL,
    storePath: 'multipleFields.getTagList',
    onSuccess: getTagList => {
      const list = getTagList.map(({id, label}) => ({
        key: id,
        value: id,
        text: label,
      }))
      setDropdownData(dropdownData => ({
        ...dropdownData,
        tagList: list,
      }))
    },
  })
  useApiResponse({
    action: getContactStatus,
    enabled: true,
    label: GET_CONTACT_STATUSES,
    storePath: 'custom_option.contactStatusData',
    onSuccess: contactStatusData => {
      const list = contactStatusData.map(({id, status}) => ({
        key: id,
        value: id,
        text: status,
      }))
      setDropdownData(dropdownData => ({
        ...dropdownData,
        statusList: list,
      }))
    },
  })
  useApiResponse({
    action: getSources,
    enabled: true,
    label: GET_SOURCES,
    storePath: 'custom_option.sourcesData',
    onSuccess: sourcesData => {
      const list = sourcesData.map(({id, source}) => ({
        key: id,
        value: id,
        text: source,
      }))
      setDropdownData(dropdownData => ({
        ...dropdownData,
        sourceList: list,
      }))
    },
  })
  useApiResponse({
    action: getContactType,
    enabled: true,
    label: CONTACT_TYPE_LIST,
    storePath: 'contacts.getTypeListData',
    onSuccess: getTypeListData => {
      const list = getTypeListData.map(({id, type}) => ({
        key: id,
        value: id,
        text: type,
      }))
      setDropdownData(dropdownData => ({
        ...dropdownData,
        contactTypeList: list,
      }))
    },
  })
  useApiResponse({
    label: GET_STAGE_DEALS,
    storePath: 'deals.getStageDeals',
    onSuccess: getStageDealsList => {
      const list = getStageDealsList.map(({stage}) => ({
        key: stage,
        value: stage,
        text: startCase(stage),
      }))
      setDropdownData(dropdownData => ({
        ...dropdownData,
        stageList: list,
      }))
    },
  })

  useEffect(() => {
    if (entity) {
      setValue('entity', entity)
    } else {
      setValue('entity', '')
    }
  }, [entity, setValue])

  useEffect(() => {
    if (entity === 'contact') {
      setFieldOptions(contactsConditionFieldOptions)
    } else if (entity === 'company') {
      setFieldOptions(companiesConditionFieldOptions)
    } else if (entity === 'deal') {
      setFieldOptions(dealsConditionFieldOptions)
    } else {
      setFieldOptions([])
    }
  }, [entity])

  useEffect(() => {
    if (successLabels.includes(CREATE_SCORING_RULES)) {
      toggle()
      dispatch(getAllScoringRules())
    }
    if (successLabels.includes(UPDATE_SCORING_RULES)) {
      toggle()
      setEditId(null)
      dispatch(getAllScoringRules())
    }
  }, [dispatch, successLabels])

  const getOperatorList = conditionField => {
    if (
      conditionField === 'monetaryValue' ||
      conditionField === 'win' ||
      conditionField === 'interactions' ||
      conditionField === 'closedAt'
    ) {
      return operatorsOptions.filter(op => op.value !== '!=' && op.value !== '<>')
    }
    if (
      conditionField === 'firstName' ||
      conditionField === 'lastName' ||
      conditionField === 'title' ||
      conditionField === 'company' ||
      conditionField === 'website' ||
      conditionField === 'description' ||
      conditionField === 'locality' ||
      conditionField === 'state' ||
      conditionField === 'postalCode' ||
      conditionField === 'socialLinks' ||
      conditionField === 'name' ||
      conditionField === 'domain'
    ) {
      return operatorOptionsEqC
    }
    return operatorOptionsEq
  }

  const renderAppropriateField = (condition, index, defaultVal) => {
    if (
      condition === 'assignee' ||
      condition === 'country' ||
      condition === 'tags' ||
      condition === 'statusId' ||
      condition === 'sourcesId' ||
      condition === 'contactType' ||
      condition === 'priority' ||
      condition === 'stage'
    ) {
      let optionsList = []
      let isSearchable = false
      switch (condition) {
        case 'assignee':
          optionsList = dropdownData.assigneeList
          break
        case 'country':
          optionsList = KooperCountryList
          isSearchable = true
          break
        case 'tags':
          optionsList = dropdownData.tagList
          break
        case 'statusId':
          optionsList = dropdownData.statusList
          break
        case 'sourcesId':
          optionsList = dropdownData.sourceList
          break
        case 'contactType':
          optionsList = dropdownData.contactTypeList
          break
        case 'priority':
          optionsList = priorityList
          break
        case 'stage':
          optionsList = dropdownData.stageList
          break
        default:
          break
      }
      return (
        <Controller
          name={`conditionsJson.conditionsArray[${index}].value`}
          control={control}
          defaultValue={defaultVal}
          render={({value, onChange}) => (
            <KooperFormSelect
              fluid
              value={value}
              search={isSearchable}
              options={optionsList}
              onChange={(e, {value}) => {
                onChange(value)
                setValue(`conditionsJson.conditionsArray[${index}].value`, value, {
                  shouldValidate: true,
                })
              }}
              onClick={() => clearErrors(`conditionsJson.conditionsArray[${index}].value`)}
              error={
                errors.conditionsJson?.conditionsArray?.[index]?.value && {
                  content: removeDoubleQuotes(errors.conditionsJson.conditionsArray[index].value.message),
                }
              }
            />
          )}
        />
      )
    }
    if (
      condition === 'firstName' ||
      condition === 'lastName' ||
      condition === 'email' ||
      condition === 'phone' ||
      condition === 'title' ||
      condition === 'company' ||
      condition === 'website' ||
      condition === 'description' ||
      condition === 'locality' ||
      condition === 'state' ||
      condition === 'postalCode' ||
      condition === 'socialLinks' ||
      condition === 'name' ||
      condition === 'domain' ||
      condition === 'monetaryValue' ||
      condition === 'win' ||
      condition === 'interactions'
    ) {
      return (
        <Controller
          name={`conditionsJson.conditionsArray[${index}].value`}
          control={control}
          defaultValue={defaultVal}
          render={({value, onChange}) => (
            <KooperFormInput
              value={value}
              onChange={(e, {value}) => onChange(value)}
              error={
                errors?.conditionsJson?.conditionsArray?.[index]?.value && {
                  content: removeDoubleQuotes(errors.conditionsJson.conditionsArray[index].value.message),
                }
              }
            />
          )}
        />
      )
    }

    if (condition === 'closedAt') {
      return (
        <>
          <Controller
            name={`conditionsJson.conditionsArray[${index}].value`}
            control={control}
            render={({value, onChange}) => (
              <KooperDatePicker
                dropUp
                min={new Date()}
                step={60}
                defaultValue={value.length ? new Date(value) : null}
                onChange={value => onChange(value)}
                inputProps={{
                  component: props => <input {...props} readOnly />,
                }}
                error={
                  errors?.conditionsJson?.conditionsArray?.[index]?.value && {
                    content: removeDoubleQuotes(errors.conditionsJson.conditionsArray[index].value.message),
                  }
                }
              />
            )}
          />
          {errors?.conditionsJson?.conditionsArray?.[index]?.value && (
            <p className="errorLabelEl pl-3">
              {removeDoubleQuotes(errors.conditionsJson.conditionsArray[index].value.message)}
            </p>
          )}
        </>
      )
    }
    return <>something went wrong</>
  }

  const submitData = data => {
    if (editId) {
      dispatch(updateScoringRules(editId, data))
    } else {
      dispatch(createScoringRules(data))
    }
  }

  return (
    <KooperModal
      size="small"
      open={open}
      onClose={toggle}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
    >
      <KooperModalHeader content={editId ? 'Edit Scoring' : 'Create Scoring'} />
      <KooperModalContent scrolling>
        <KooperForm className="errorLabel">
          <KooperFormField required width={8}>
            <label>Name your Scoring Action</label>
            <Controller
              name="name"
              control={control}
              render={({value, onChange}) => (
                <KooperFormInput
                  maxLength={20}
                  fluid
                  placeholder="Enter Name of Scoring"
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.name && {
                      content: removeDoubleQuotes(errors.name.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>

          <KooperCard fluid>
            <KooperCardContent>
              {fields.map(({id, field, operator, value}, index) => {
                const conditionField = watch(`conditionsJson.conditionsArray[${index}].field`)
                return (
                  <React.Fragment key={id}>
                    <KooperFormField required>
                      <label>Specify your Trigger (Entity and Trigger)</label>
                      <KooperFormGroup>
                        <KooperFormField width={5}>
                          <Controller
                            name={`conditionsJson.conditionsArray[${index}].field`}
                            defaultValue={field}
                            control={control}
                            render={({value, onChange}) => (
                              <KooperFormSelect
                                placeholder="Select Condition"
                                fluid
                                options={fieldOptions}
                                value={value}
                                onChange={(e, {value}) => {
                                  onChange(value)
                                  setValue(`conditionsJson.conditionsArray[${index}].operator`, '')
                                  setValue(`conditionsJson.conditionsArray[${index}].value`, '')
                                  clearErrors([
                                    `conditionsJson.conditionsArray[${index}].operator`,
                                    `conditionsJson.conditionsArray[${index}].value`,
                                  ])
                                  setFieldOptions(dropdownList =>
                                    dropdownList.map(dropdownItem => {
                                      return dropdownItem.value === value
                                        ? {
                                            ...dropdownItem,
                                            disabled: true,
                                          }
                                        : {
                                            ...dropdownItem,
                                            disabled: !!currentIfCondition
                                              .map(({field}) => field)
                                              .includes(dropdownItem.value),
                                          }
                                    })
                                  )
                                }}
                                onClick={() => clearErrors(`conditionsJson.conditionsArray[${index}].field`)}
                                error={
                                  errors?.conditionsJson?.conditionsArray?.[index]?.field && {
                                    content: removeDoubleQuotes(
                                      errors.conditionsJson.conditionsArray[index].field.message
                                    ),
                                  }
                                }
                              />
                            )}
                          />
                        </KooperFormField>
                        <KooperFormField width={5}>
                          <Controller
                            name={`conditionsJson.conditionsArray[${index}].operator`}
                            control={control}
                            defaultValue={operator}
                            render={({value, onChange}) => (
                              <KooperFormSelect
                                placeholder="Select operator"
                                fluid
                                options={getOperatorList(conditionField)}
                                value={value}
                                onChange={(e, {value}) => {
                                  onChange(value)
                                }}
                                onClick={() => clearErrors(`conditionsJson.conditionsArray[${index}].operator`)}
                                error={
                                  errors?.conditionsJson?.conditionsArray?.[index]?.operator && {
                                    content: removeDoubleQuotes(
                                      errors.conditionsJson.conditionsArray[index].operator.message
                                    ),
                                  }
                                }
                              />
                            )}
                          />
                        </KooperFormField>
                        <KooperFormField width={5}>
                          {conditionField && renderAppropriateField(conditionField, index, value)}
                        </KooperFormField>
                        {fields.length > 1 && (
                          <KooperButton icon basic className="mt-2" onClick={() => remove(index)}>
                            <SvgIcon path="common/delete" />
                          </KooperButton>
                        )}
                      </KooperFormGroup>
                    </KooperFormField>
                    {index !== fields.length - 1 && (
                      <div className="scoringSlider">
                        <p>OR</p>
                        <Controller
                          name="conditionsJson.conditionsOperator"
                          control={control}
                          render={({value, onChange}) => (
                            <KooperTooltip
                              content="Add multiple scoring trigger"
                              size="mini"
                              position="top left"
                              trigger={
                                <KooperCheckbox
                                  toggle
                                  className="mx-3"
                                  checked={value === 'and'}
                                  onClick={(e, {checked}) => (checked ? onChange('and') : onChange('or'))}
                                  error={
                                    errors?.conditionsJson?.conditionsOperator && {
                                      content: removeDoubleQuotes(errors.conditionsJson.conditionsOperator.message),
                                    }
                                  }
                                />
                              }
                            />
                          )}
                        />
                        <p>AND</p>
                      </div>
                    )}
                  </React.Fragment>
                )
              })}
              <KooperTooltip
                content="Add New Condition"
                position="right center"
                trigger={
                  <KooperButton className="m-0" icon onClick={() => append({field: '', operator: '', value: ''})}>
                    <SvgIcon path="common/plus" />
                  </KooperButton>
                }
              />
              <KooperFormField className="mt-3">
                <label>Filter Conditions By</label>
                <KooperFormGroup inline>
                  <KooperFormField>
                    <Controller
                      name="isAddition"
                      control={control}
                      render={({value, onChange}) => (
                        <>
                          <KooperRadio label="Add" value checked={value === true} onChange={() => onChange(true)} />
                          <KooperRadio
                            className="ml-2"
                            label="Subtract"
                            value={false}
                            checked={value === false}
                            onChange={() => onChange(false)}
                          />
                        </>
                      )}
                    />
                  </KooperFormField>
                  <Controller
                    name="points"
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormInput
                        style={{width: '50px', marginRight: '10px'}}
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        error={
                          errors?.points && {
                            content: removeDoubleQuotes(errors.points.message),
                          }
                        }
                      />
                    )}
                  />
                  <span style={{marginTop: '3px'}}>Point(s)</span>
                </KooperFormGroup>
              </KooperFormField>
            </KooperCardContent>
          </KooperCard>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={toggle}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitData)}>
          {editId ? 'Update' : 'Create'}
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default ScoringModal
