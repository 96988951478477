/* eslint-disable no-nested-ternary */
import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import moment from 'moment'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperImage,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperNoData,
  KooperRadio,
  KooperTablePlaceholder,
  KooperTooltip,
} from 'kooper-library'
import {DELETE_PROJECT, GET_PROJECTS_LIST} from 'actions/types'
import {cloneProject, deleteProject, updateProjectStatus} from 'actions/projects'
import DeleteModal from 'components/common/DeleteModal'
import {get, getPermissionTooltipContent, startCase} from 'utils/helper'
import KooperCustomPagination from 'components/common/KooperCustomPagination'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {PROJECTS_PATH} from 'constants/path'
import EditProjectModal from './EditProjectModal'

const ProjectList = ({filter, setFilter, rolePermissions = true}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [idToEdit, setIdToEdit] = useState(null)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [idToDelete, setIdToDelete] = useState(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false)
  const [projectToUpdate, setProjectToUpdate] = useState(null)

  const {projectsList = [], users = []} = useSelector(state => state.projects)
  const {type} = useSelector(state => state.apiReducer)

  const {totalPages, currentPage} = useApiResponse({storePath: 'projects.projectsPage'})

  const getDateMonthYear = date => {
    return `${moment(date).date()}-${moment(date).month()}-${moment(date).year()}`
  }

  const getFullName = id => {
    const user = users.find(u => u.id === id)
    if (user) {
      return `${get(['firstName'], user, '')} ${get(['lastName'], user, '')}`
    }
    return null
  }

  const getSortName = id => {
    const user = users.find(u => u.id === id)
    if (user) {
      return `${get(['firstName'], user, '')[0]}${get(['lastName'], user, '')[0]}`
    }
    return null
  }

  const getStatus = status => {
    if (status === 'active') return 'Active'
    if (status === 'inactive') return 'In-Active'
    if (status === 'archived') return 'Archived'
    return null
  }

  const handleStatusConfirm = () => {
    if (projectToUpdate) {
      const newStatus = projectToUpdate.status === 'active' ? 'closed' : 'active'

      const data = {
        status: newStatus,
      }

      dispatch(updateProjectStatus(projectToUpdate.id, data))
      setIsStatusModalOpen(false)
      setProjectToUpdate(null)
    }
  }

  const renderProjectList = () => {
    if (type.includes(GET_PROJECTS_LIST)) {
      return <KooperTablePlaceholder columns={8} />
    }
    if (!type.includes(GET_PROJECTS_LIST) && !projectsList.length) {
      return (
        <KooperCard fluid className="project-list-card">
          <KooperCardContent>
            <KooperNoData type="table" noDataText="No Data Available" />
          </KooperCardContent>
        </KooperCard>
      )
    }
    return projectsList.map(project => {
      return (
        <KooperCard fluid className="project-list-card" key={project.id}>
          <KooperCardContent>
            <div className="d-flex">
              <KooperCardHeader
                className="d-flex"
                onClick={() => history.push(`/${PROJECTS_PATH.PREFIX}/${project.id}/${PROJECTS_PATH.OVERVIEW}`)}
                style={{marginBottom: 'var(--margin-medium)'}}
              >
                {startCase(project.title)}
              </KooperCardHeader>
              <div className="d-flex" style={{columnGap: 'var(--gap-medium)'}}>
                <KooperTooltip
                  content={
                    rolePermissions
                      ? getStatus(project.status)
                      : getPermissionTooltipContent({roleAccess: rolePermissions})
                  }
                  position="top center"
                  size="mini"
                  trigger={
                    <KooperRadio
                      toggle
                      checked={project.status === 'active'}
                      onClick={() => {
                        if (rolePermissions) {
                          setIsStatusModalOpen(true)
                          setProjectToUpdate(project)
                        }
                      }}
                    />
                  }
                />
                <KooperTooltip
                  content="Analytics"
                  position="top center"
                  size="mini"
                  trigger={
                    <KooperButton icon>
                      <SvgIcon path="settings/activities" />
                    </KooperButton>
                  }
                />
                <KooperTooltip
                  content={rolePermissions ? 'Clone' : getPermissionTooltipContent({roleAccess: rolePermissions})}
                  position="top center"
                  size="mini"
                  trigger={
                    <KooperButton icon onClick={() => rolePermissions && dispatch(cloneProject(project.id))}>
                      <SvgIcon path="common/clone" />
                    </KooperButton>
                  }
                />
                <KooperTooltip
                  content={rolePermissions ? 'Edit' : getPermissionTooltipContent({roleAccess: rolePermissions})}
                  position="top center"
                  size="mini"
                  trigger={
                    <KooperButton
                      icon
                      onClick={() => {
                        if (rolePermissions) {
                          setIdToEdit(project.id)
                          setIsEditModalOpen(true)
                        }
                      }}
                    >
                      <SvgIcon path="common/edit2" />
                    </KooperButton>
                  }
                />
                <KooperTooltip
                  content={rolePermissions ? 'Delete' : getPermissionTooltipContent({roleAccess: rolePermissions})}
                  position="top center"
                  size="mini"
                  trigger={
                    <KooperButton
                      icon
                      onClick={() => {
                        if (rolePermissions) {
                          setIdToDelete(project.id)
                          setIsDeleteModalOpen(true)
                        }
                      }}
                    >
                      <SvgIcon path="common/delete" />
                    </KooperButton>
                  }
                />
              </div>
            </div>

            <div className="d-flex projects-listing-assign" style={{justifyContent: 'flex-start'}}>
              <div style={{gap: '6px'}}>
                <div className="user-position">Manager</div>
                <div className="user-list">
                  <KooperTooltip
                    content={getFullName(get(['managerId'], project, null))}
                    position="top center"
                    size="mini"
                    trigger={<span className="circle-user">{getSortName(get(['managerId'], project, null))}</span>}
                  />
                </div>
              </div>
              <div style={{gap: '6px'}}>
                <div className="user-position">Assignee</div>
                <div className="user-list">
                  {get(['assignedTo'], project, []).map(assignedId => {
                    return (
                      <KooperTooltip
                        key={assignedId}
                        content={getFullName(assignedId)}
                        position="top center"
                        size="mini"
                        trigger={<span className="circle-user">{getSortName(assignedId)}</span>}
                      />
                    )
                  })}
                </div>
              </div>
            </div>

            <div className="d-flex projects-listing-dates" style={{justifyContent: 'flex-start', marginTop: '10px'}}>
              <div style={{gap: '6px'}}>
                <span>Created By</span>
                <KooperTooltip
                  content={getDateMonthYear(project.createdAt)}
                  size="mini"
                  position="top center"
                  trigger={<span>{getFullName(project.createdBy)}</span>}
                />
              </div>
              {project.updatedAt && (
                <div>
                  <span>Modified By</span>
                  <KooperTooltip
                    content={getDateMonthYear(project.updatedAt)}
                    size="mini"
                    position="top center"
                    trigger={<span>{getFullName(project.updatedBy)}</span>}
                  />
                </div>
              )}
            </div>
          </KooperCardContent>
        </KooperCard>
      )
    })
  }

  return (
    <>
      {renderProjectList()}

      {totalPages > 1 && (
        <KooperCustomPagination
          totalPages={totalPages}
          activePage={currentPage}
          onPageChange={page => {
            setFilter(sort => ({...sort, page}))
          }}
        />
      )}
      {isEditModalOpen && <EditProjectModal open={isEditModalOpen} toggle={setIsEditModalOpen} id={idToEdit} />}
      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          onDelete={() => dispatch(deleteProject({id: [idToDelete]}))}
          type={DELETE_PROJECT}
        />
      )}
      {isStatusModalOpen && (
        <KooperModal
          closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
          size="tiny"
          centered={false}
          open={isStatusModalOpen}
          onClose={() => setIsStatusModalOpen(false)}
        >
          <KooperModalHeader>Status Change</KooperModalHeader>
          <KooperModalContent>Are you sure you want to change status of this project?</KooperModalContent>
          <KooperModalActions>
            <KooperButton basic onClick={() => setIsStatusModalOpen(false)}>
              Cancel
            </KooperButton>
            <KooperButton primary onClick={handleStatusConfirm}>
              Confirm
            </KooperButton>
          </KooperModalActions>
        </KooperModal>
      )}
    </>
  )
}

export default ProjectList
