import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {EditorState} from 'draft-js'
import {getFormattedDate, getFormattedTime, getTimeDifferenceFromNow} from 'utils/helper'
import {ADD_MESSAGE_TO_CONVERSATION} from 'actions/types'
import {INBOX_TYPES} from 'constants/enums'
import {ChatMessageFooter} from './ChatMessageFooter'
import {ChatMessageList} from './ChatMessageList'
import {ConversationMsgBox} from './ConversationMsgBox'
import {Note} from './Note'

export const EmailBodyContent = props => {
  const {
    conversationMessages,
    replyEditorRef,
    MessagesId,
    noteToMessage,
    FromMailId,
    mailbox,
    conversationType,
    printReference,
    conversationId,
    CurrentStatusOfConversation,
    getTeammatesForSuggesions,
    getTeammatesExcludingMe,
    typingConversationId,
    conversationSubject,
  } = props

  const conversationMessagesReverse = [...conversationMessages]
  conversationMessagesReverse.reverse()

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [notes, setNotes] = useState('')
  const [activeMsgTab, setActiveMsgTab] = useState(0)
  const [editMsgId, setEditMsgId] = useState(null)
  const [isReplyingQuote, setIsReplyingQuote] = useState(false)
  const [replyingQuoteMsg, setReplyingQuoteMsg] = useState(null)

  useEffect(() => {
    if (successLabels.includes(ADD_MESSAGE_TO_CONVERSATION)) {
      setIsReplyingQuote(false)
      setReplyingQuoteMsg(null)
    }
  }, [successLabels])

  if (
    ![INBOX_TYPES.EMAIL, INBOX_TYPES.HELPDESK, INBOX_TYPES.INTERNAL_TICKET, INBOX_TYPES.SUPPORT_FORM].includes(
      conversationType
    )
  ) {
    return (
      <>
        <ChatMessageList
          conversationId={conversationId}
          chatMessagesReverse={conversationMessagesReverse}
          typingConversationId={typingConversationId}
          setEditorState={setEditorState}
          setEditMsgId={setEditMsgId}
          setActiveMsgTab={setActiveMsgTab}
          setIsReplyingQuote={setIsReplyingQuote}
          setReplyingQuoteMsg={setReplyingQuoteMsg}
          style={{height: `calc(100vh - 310px - ${isReplyingQuote ? '128px' : '0px'})`}}
        />
        <ChatMessageFooter
          conversationId={conversationId}
          CurrentStatusOfConversation={CurrentStatusOfConversation}
          editMsgId={editMsgId}
          editorState={editorState}
          notes={notes}
          setEditorState={setEditorState}
          setNotes={setNotes}
          setEditMsgId={setEditMsgId}
          setActiveMsgTab={setActiveMsgTab}
          activeMsgTab={activeMsgTab}
          getTeammatesForSuggesions={getTeammatesForSuggesions}
          getTeammatesExcludingMe={getTeammatesExcludingMe}
          messageId={MessagesId}
          isReplyingQuote={isReplyingQuote}
          setIsReplyingQuote={setIsReplyingQuote}
          replyingQuoteMsg={replyingQuoteMsg}
          setReplyingQuoteMsg={setReplyingQuoteMsg}
        />
      </>
    )
  }
  return (
    <div ref={printReference} style={{padding: '12px 8px 0'}}>
      {conversationMessagesReverse.map((msg, i) => {
        return (
          <>
            <ConversationMsgBox
              conversationMessagesReverse={conversationMessagesReverse}
              index={i}
              conversationType={conversationType}
              FromMailId={FromMailId}
              messageId={msg.id}
              replyEditorRef={replyEditorRef}
              to={msg.to}
              isInbound={msg.isInbound}
              date={getTimeDifferenceFromNow(msg.updatedAt || msg.createdAt)}
              dateAndTime={`${getFormattedDate(msg.updatedAt || msg.createdAt)} ${getFormattedTime(
                msg.updatedAt || msg.createdAt
              )}`}
              textHtml={msg.messageHtml}
              mailbox={mailbox}
              fromObj={Array.isArray(msg.from) ? msg.from : []}
              isDraft={msg.isDraft}
              attachments={msg.attachments ? msg.attachments : []}
              conversationId={conversationId}
              singleMsgData={msg}
              conversationSubject={conversationSubject}
            />
            <Note msg={msg} actionId={msg.id} MessagesId={MessagesId} noteToMessage={noteToMessage} />
          </>
        )
      })}
    </div>
  )
}
