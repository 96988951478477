import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getProjectsTimesheetTimelogsByProjectList, getProjectsTimesheetTimelogsChart} from 'actions/analytics_projects'
import {
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperGrid,
  KooperGridColumn,
  KooperNoData,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import {getGridYValuesMultipleBar, maxOfGraphMultipleBar} from 'utils/graph'
import {ResponsiveBar} from '@nivo/bar'
import CustomLoader from 'components/common/CustomLoader'
import {GET_PROJECTS_TIMESHEET_TIMELOGS_BY_PROJECT_LIST, GET_PROJECTS_TIMESHEET_TIMELOGS_CHART} from 'actions/types'
import Moment from 'moment'
import {get, startCase} from 'utils/helper'
import NoDataChart from 'components/common/NoDataChart'
import {ResponsivePie} from '@nivo/pie'
import SvgIcon from 'components/common/SvgIcon'
import {ProjectDropdown} from '../../AnalyticsUtils'
import DateRangeGroup from '../../DateRangeGroup'

const TimesheetByProjects = () => {
  const dispatch = useDispatch()

  const {type = []} = useSelector(state => state.apiReducer)
  const {
    analyticsSelectedProject,
    projectsTimesheetTimelogsChartData = {},
    projectsTimesheetTimelogsByProjectList = [],
  } = useSelector(state => state.analyticsProjects)
  const {formattedData = [], piechartData = [], summaryData = {}} = projectsTimesheetTimelogsChartData

  const actionsToDispatch = useCallback(
    data => {
      dispatch(getProjectsTimesheetTimelogsChart(data))
      dispatch(getProjectsTimesheetTimelogsByProjectList(data))
    },
    [dispatch]
  )

  const getFormattedTime = minutes => {
    const duration = Moment.duration(minutes, 'minutes')
    const hour = Math.floor(duration.asHours())
    const minute = duration.minutes()

    const formattedDuration = `${hour}h ${minute}m`
    return formattedDuration
  }

  const renderTableBody = () => {
    if (type.includes(GET_PROJECTS_TIMESHEET_TIMELOGS_BY_PROJECT_LIST)) {
      return <KooperTablePlaceholder columns={8} />
    }
    if (
      !type.includes(GET_PROJECTS_TIMESHEET_TIMELOGS_BY_PROJECT_LIST) &&
      !projectsTimesheetTimelogsByProjectList.length
    ) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="8" className="emptyValue">
            <KooperNoData type="table" noDataText="No Data Available" />
          </KooperTableCell>
        </KooperTableRow>
      )
    }
    return projectsTimesheetTimelogsByProjectList.map(project => (
      <KooperTableRow key={project.id} className="tableLink">
        <KooperTableCell>{startCase(project.title)}</KooperTableCell>
        <KooperTableCell>{project.managerName}</KooperTableCell>
        <KooperTableCell>{get(['timeData', 'loggedHours'], project, '-')}</KooperTableCell>
        <KooperTableCell>{get(['timeData', 'billableHours'], project, '-')}</KooperTableCell>
        <KooperTableCell>{get(['timeData', 'billedHours'], project, '-')}</KooperTableCell>
        <KooperTableCell>{get(['timeData', 'nonbillableHours'], project, '-')}</KooperTableCell>
        <KooperTableCell>
          <div style={{width: '130px'}}>
            <p className="d-flex mb-1">
              <span>{get(['timeData', 'billablePercentage'], project, 0)}%</span>
              <span>{get(['timeData', 'nonBillablePercentage'], project, 0)}%</span>
            </p>
            <div className="budget-progress">
              <div
                className="budget-progress-completed"
                style={{width: `${get(['timeData', 'billablePercentage'], project, 0).toFixed(2)}%`}}
              />
            </div>
          </div>
        </KooperTableCell>
        <KooperTableCell>
          {project.isBudgetCreated ? (
            <div style={{width: '130px'}}>
              <p className="mb-0">{get(['budgetData', 'usedPercentage'], project, 0).toFixed(2)}%</p>
              <div className="budget-progress">
                <div
                  className="budget-progress-completed"
                  style={{width: `${get(['budgetData', 'usedPercentage'], project, 0).toFixed(2)}%`}}
                />
              </div>
              <p>
                ${get(['budgetData', 'usedAmount'], project, 0)} 0f ${get(['budgetData', 'totalAmount'], project, 0)}
              </p>
            </div>
          ) : (
            '-'
          )}
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/time-sheet-projects" />
            <h5>Timesheet By Projects</h5>
          </div>
        </div>
        <div className="page-action">
          <ProjectDropdown />
          <DateRangeGroup
            actionsToDispatch={actionsToDispatch}
            projectId={
              analyticsSelectedProject && analyticsSelectedProject !== 'all' ? Number(analyticsSelectedProject) : null
            }
          />
        </div>
      </div>

      <KooperGrid>
        <KooperGridColumn width={10}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox pb-0">
              <KooperCardHeader>Timesheets</KooperCardHeader>
              <KooperTooltip
                content="Graphical representation of timesheet"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 367}}>
              {type.includes(GET_PROJECTS_TIMESHEET_TIMELOGS_CHART) && <CustomLoader />}
              {!type.includes(GET_PROJECTS_TIMESHEET_TIMELOGS_CHART) &&
              formattedData.every(({added, deleted}) => added === 0 && deleted === 0) ? (
                <KooperNoData type="chart" noDataText="No Data Available" />
              ) : (
                !type.includes(GET_PROJECTS_TIMESHEET_TIMELOGS_CHART) && (
                  <ResponsiveBar
                    data={formattedData}
                    keys={['Billable', 'Non Billable', 'Billed']}
                    indexBy="time"
                    margin={{top: 37, right: 27, bottom: 70, left: 70}}
                    padding={0.3}
                    minValue={0}
                    maxValue={maxOfGraphMultipleBar(formattedData, ['Billable', 'Non Billable', 'Billed'])}
                    gridYValues={getGridYValuesMultipleBar(formattedData, ['Billable', 'Non Billable', 'Billed'])}
                    groupMode="grouped"
                    valueScale={{type: 'linear'}}
                    indexScale={{type: 'band', round: true}}
                    colors={d => d.data[`${d.id}Color`]}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 1.6]],
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: -45,
                      legend: 'Time',
                      legendPosition: 'middle',
                      legendOffset: 60,
                    }}
                    axisLeft={{
                      format: value => getFormattedTime(value),
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      tickValues: getGridYValuesMultipleBar(formattedData, ['Billable', 'Non Billable', 'Billed']),
                      legend: 'Time',
                      legendPosition: 'middle',
                      legendOffset: -65,
                    }}
                    enableLabel={false}
                    labelSkipHeight={1}
                    labelTextColor={{
                      from: 'color',
                      modifiers: [['darker', 1.6]],
                    }}
                    legends={[
                      {
                        dataFrom: 'keys',
                        anchor: 'top',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: -40,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 10,
                        symbolShape: 'circle',
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                    tooltip={e => {
                      const {label, value} = e
                      return (
                        <div style={{backgroundColor: 'white', padding: '3px 6px 3px 6px', borderRadius: '2px'}}>
                          {label} : <stong>{getFormattedTime(value)}</stong>
                        </div>
                      )
                    }}
                  />
                )
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>

        <KooperGridColumn width={6}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox pb-0">
              <KooperCardHeader>Pie Data</KooperCardHeader>
              <KooperTooltip
                content="Breakdown of time sheet over a time frame"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0 pb-0" style={{height: 300}}>
              {type.includes(GET_PROJECTS_TIMESHEET_TIMELOGS_CHART) && <CustomLoader />}
              {!type.includes(GET_PROJECTS_TIMESHEET_TIMELOGS_CHART) &&
                (!piechartData.every(({value}) => value === 0) ? (
                  <ResponsivePie
                    data={piechartData}
                    margin={{top: 20, right: 80, bottom: 20, left: 80}}
                    startAngle={-90}
                    endAngle={90}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={0}
                    borderWidth={1}
                    colors={d => d.data.color}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.2]],
                    }}
                    arcLinkLabel={e => `${e.id} (${e.value})`}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{from: 'color'}}
                    arcLabelsSkipAngle={10}
                    enableArcLabels={false}
                    arcLabelsTextColor={{
                      from: 'color',
                      modifiers: [['darker', 2]],
                    }}
                    tooltip={function (e) {
                      const {id, value} = e.datum
                      return (
                        <strong style={{backgroundColor: 'white', padding: '3px 6px 3px 6px', borderRadius: '2px'}}>
                          {id} : {value}%
                        </strong>
                      )
                    }}
                  />
                ) : (
                  <KooperNoData type="chart" noDataText="No Data Available" />
                ))}
            </KooperCardContent>
            <KooperCardContent className="pt-0">
              <div className="d-flex text-center">
                <div>
                  <p>Billable</p>
                  <p>{summaryData.billableTimeLog}</p>
                </div>
                <div>
                  <p>Total</p>
                  <p>{summaryData.totalTimeLog}</p>
                </div>
                <div>
                  <p>Non Billable</p>
                  <p>{summaryData.nonBilableTimeLOg}</p>
                </div>
              </div>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>

      <KooperGrid>
        <KooperGridColumn>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Timesheet By Projects</KooperCardHeader>
              <KooperTooltip
                content="List of timesheets based on projects"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0">
              <KooperTable basic>
                <KooperTableHeader>
                  <KooperTableRow>
                    <KooperTableHeaderCell>Project Name</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Owner</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Logged Time</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Billable Time</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Billed Time</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Non Billable Time</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Billable vs Non Billable</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Budget</KooperTableHeaderCell>
                  </KooperTableRow>
                </KooperTableHeader>
                <KooperTableBody>{renderTableBody()}</KooperTableBody>
              </KooperTable>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
    </div>
  )
}

export default TimesheetByProjects
