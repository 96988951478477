import DeleteModal from 'components/common/DeleteModal'
import SvgIcon from 'components/common/SvgIcon'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCheckbox,
  KooperContainer,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormSelect,
  KooperRadio,
  KooperTooltip,
} from 'kooper-library'
import {useState} from 'react'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {FiPlus, FiTrash2} from 'react-icons/fi'
import ReorderModal from './ReorderModal'

const Questions = () => {
  const {handleSubmit, errors, clearErrors, control, watch, setValue, reset} = useForm({
    mode: 'onTouched',
    shouldUnregister: false,
    shouldFocusError: true,
    reValidateMode: 'onChange',
    defaultValues: {
      questionType: 'text',
      questions: {
        options: [
          {
            choice: '',
            order: 1,
          },
          {
            choice: '',
            order: 2,
          },
        ],
      },
    },
    // resolver: joiResolver(),
  })

  const onSubmit = data => {
    console.log(data)
  }

  const [isReorderModalOpen, setIsReorderModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [askFollowupQuestion, setAskFollowupQuestion] = useState(false)
  const questionTypeWatch = watch('questionType')
  const renderFollowupQuestion = label => (
    <KooperFormField>
      <label>{label || 'Would you like more detail?'}</label>
      <div className="d-flex">
        <div className="info-header">
          <label>Ask follow-up question</label>
          <KooperTooltip
            trigger={<SvgIcon path="common/question" />}
            content="This allows your team to type in their thoughts and reasoning behind their answer"
            size="mini"
            psoition="top center"
          />
        </div>
        <KooperRadio
          checked={askFollowupQuestion}
          onClick={() => {
            setAskFollowupQuestion(!askFollowupQuestion)
          }}
          toggle
        />
      </div>
      {askFollowupQuestion && (
        <Controller
          name="followupquestion"
          render={({onChange, value}) => (
            <KooperFormGroup>
              <KooperFormInput
                type="text"
                required
                width={10}
                className="mt-3"
                fluid
                value={value}
                placeholder="E.g. What would you suggest for our next presentation?"
                onChange={e => {
                  onChange(e.target.value)
                }}
                error={
                  errors.ownerEmail && {
                    content: errors.ownerEmail.message,
                  }
                }
              />
            </KooperFormGroup>
          )}
          control={control}
        />
      )}
    </KooperFormField>
  )
  const [questions, setQuestions] = useState(1)
  const {
    fields: optionFields,
    append: appendOption,
    remove: removeOption,
  } = useFieldArray({
    control,
    name: `${`questions.options`}`,
  })
  return (
    <KooperContainer>
      {Array.from({length: questions}, (_, i) => i + 1)?.map(index => (
        <KooperCard fluid key={index}>
          <KooperCardContent>
            <div className="d-flex">
              <div>
                <h3 className="mb-0">Question {index}</h3>
                <p className="mt-0 mb-4 card-description">Select question type and provide details.</p>
              </div>
              {questions > 1 && (
                <div>
                  <KooperButton onClick={() => setIsReorderModalOpen(true)} primary>
                    Reorder
                  </KooperButton>
                  <KooperButton onClick={() => setIsDeleteModalOpen(true)} color="red">
                    Delete
                  </KooperButton>
                </div>
              )}
            </div>
            <KooperForm onSubmit={handleSubmit(onSubmit)}>
              <KooperFormGroup>
                <KooperFormField width={8}>
                  <div className="info-header">
                    <label>Select your type of question</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Set type of question"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="questionType"
                    control={control}
                    render={({onChange, value}) => (
                      <KooperFormSelect
                        fluid
                        options={[
                          {key: 'text', value: 'text', text: 'Text'},
                          {key: 'opinion', value: 'opinion', text: 'Opinion Scale'},
                          {key: 'multiple', value: 'multiple', text: 'Multiple Choice'},
                          {key: 'likert', value: 'likert', text: 'Likert Scale'},
                        ]}
                        selection
                        placeholder="Select question type"
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                        value={value}
                      />
                    )}
                  />
                </KooperFormField>
              </KooperFormGroup>

              {questionTypeWatch !== 'likert' && (
                <KooperFormGroup>
                  <KooperFormField required width={8}>
                    <label>What would you like to ask?</label>
                    <Controller
                      name="question"
                      control={control}
                      render={({onChange, value}) => (
                        <KooperFormInput
                          placeholder="Enter your question"
                          value={value}
                          maxLength={150}
                          onChange={(e, {value}) => onChange(value)}
                          error={errors?.question && {content: errors.question.message}}
                        />
                      )}
                    />
                  </KooperFormField>
                </KooperFormGroup>
              )}

              {questionTypeWatch === 'likert' && (
                <KooperFormGroup>
                  <KooperFormField width={8}>
                    <label>Statement</label>
                    <Controller
                      name="statement"
                      control={control}
                      render={({onChange, value}) => (
                        <KooperFormInput
                          placeholder="E.g. The way my organization makes decisions reflects our values."
                          value={value}
                          maxLength={150}
                          onChange={(e, {value}) => onChange(value)}
                          error={errors?.question && {content: errors.question.message}}
                        />
                      )}
                    />
                  </KooperFormField>
                </KooperFormGroup>
              )}

              {/* Conditionally render fields based on question type */}
              {questionTypeWatch === 'opinion' && (
                <>
                  <KooperFormField>
                    <label>10-point scale</label>
                  </KooperFormField>
                  <KooperFormGroup>
                    <KooperFormField required width={8}>
                      <label>"0" label</label>
                      <Controller
                        name="opinion0"
                        control={control}
                        render={({onChange, value}) => (
                          <KooperFormInput
                            type="number"
                            placeholder="It was bad"
                            value={value}
                            onChange={(e, {value}) => onChange(value)}
                            error={errors?.opinion0 && {content: errors.opinion0.message}}
                          />
                        )}
                      />
                    </KooperFormField>
                    <KooperFormField required width={8}>
                      <label>"10" label</label>
                      <Controller
                        name="opinion10"
                        control={control}
                        render={({onChange, value}) => (
                          <KooperFormInput
                            type="number"
                            value={value}
                            onChange={(e, {value}) => onChange(value)}
                            placeholder="It was perfect!"
                            error={errors?.opinion10 && {content: errors.opinion10.message}}
                          />
                        )}
                      />
                    </KooperFormField>
                  </KooperFormGroup>
                  {renderFollowupQuestion()}
                </>
              )}

              {questionTypeWatch === 'multiple' && (
                <>
                  <KooperFormField className="mb-0">
                    <label>Multiple choice</label>
                  </KooperFormField>
                  {optionFields?.map((option, index) => (
                    <KooperFormGroup key={option?.id}>
                      <KooperFormField width={8}>
                        <Controller
                          name={`questions.options[${index}].choice`}
                          control={control}
                          render={({onChange, value}) => (
                            <KooperFormInput
                              value={value}
                              maxLength={100}
                              onChange={(e, {value}) => onChange(value)}
                              placeholder={`Enter choice ${index + 1}`}
                              error={errors?.choice2 && {content: errors.choice2.message}}
                            />
                          )}
                        />
                      </KooperFormField>
                      <KooperTooltip
                        content="Remove"
                        size="mini"
                        position="left center"
                        trigger={
                          <KooperButton
                            className="ml-3 mr-0 bg-btn-color"
                            disabled={optionFields.length <= 2}
                            icon
                            onClick={e => {
                              e.preventDefault()
                              removeOption(index)
                            }}
                          >
                            <FiTrash2 />
                          </KooperButton>
                        }
                      />
                    </KooperFormGroup>
                  ))}
                  {watch('ownanser') && (
                    <KooperFormGroup>
                      <KooperFormField width={8}>
                        <Controller
                          name="other"
                          control={control}
                          render={({onChange, value}) => (
                            <KooperFormInput
                              placeholder="Other"
                              value={value}
                              disabled
                              onChange={(e, {value}) => onChange(value)}
                              error={errors?.question && {content: errors.question.message}}
                            />
                          )}
                        />
                      </KooperFormField>
                    </KooperFormGroup>
                  )}
                  {optionFields?.length < 10 && (
                    <KooperFormGroup>
                      <KooperFormField>
                        <KooperTooltip
                          content="Add a choice"
                          size="mini"
                          position="top center"
                          trigger={
                            <KooperButton
                              icon
                              className="bg-btn-color"
                              onClick={e => {
                                e.preventDefault()
                                appendOption({
                                  choice: '',
                                  order: optionFields.length + 1,
                                })
                              }}
                            >
                              <FiPlus />
                            </KooperButton>
                          }
                        />
                      </KooperFormField>
                    </KooperFormGroup>
                  )}
                  <KooperFormField>
                    <div className="d-flex">
                      <div>
                        <label>Allow own answer</label>
                        <label>Allow respondents to add their own answer </label>
                      </div>
                      <Controller
                        name="ownanser"
                        control={control}
                        render={({value, onChange}) => (
                          <KooperRadio
                            checked={value}
                            toggle
                            value
                            onClick={() => {
                              onChange(!value)
                            }}
                          />
                        )}
                      />
                    </div>
                  </KooperFormField>

                  <KooperFormField>
                    <div className="d-flex">
                      <div>
                        <label>Allow multiple answers</label>
                        <label>Allow respondents to select multiple answers</label>
                      </div>
                      <Controller
                        name="multianswer"
                        control={control}
                        render={({value, onChange}) => (
                          <KooperRadio
                            checked={value}
                            toggle
                            value
                            onClick={() => {
                              onChange(!value)
                            }}
                          />
                        )}
                      />
                    </div>
                  </KooperFormField>
                  {renderFollowupQuestion()}
                </>
              )}

              {questionTypeWatch === 'likert' && (
                <>
                  <KooperFormGroup>
                    <KooperFormField width={8}>
                      <label>5-point scale</label>
                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <label>Strongly Agree</label>
                        <label>Strongly Disagree</label>
                      </div>
                    </KooperFormField>
                  </KooperFormGroup>
                  {renderFollowupQuestion('Would you like to ask a follow-up question?')}
                </>
              )}
            </KooperForm>
          </KooperCardContent>
        </KooperCard>
      ))}
      <KooperCard
        style={{
          backgroundColor: '#FAFAFA',
          padding: '20px',
          textAlign: 'center',
          width: '100%',
        }}
      >
        <KooperCardContent>
          <KooperButton onClick={() => setQuestions(questions + 1)} primary>
            + Add another question
          </KooperButton>
        </KooperCardContent>
      </KooperCard>
      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          modalText="Are you sure you want to delete ?"
          // deleteAction={deleteCompany}
          // idTobeDeleted={checked.length > 0 ? [...checked].join(',') : singleIdForDelete}
          // type={DELETE_COMPANY}
        />
      )}
      {isReorderModalOpen && <ReorderModal isModalOpen={isReorderModalOpen} setIsModalOpen={setIsReorderModalOpen} />}
    </KooperContainer>
  )
}

export default Questions
