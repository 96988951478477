import KooperNoDataTable from 'components/common/KooperNoDataTable'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperContainer,
  KooperDivider,
  KooperFormField,
  KooperFormGroup,
  KooperFormSelect,
  KooperGrid,
  KooperGridColumn,
  KooperHeader,
  KooperIcon,
  KooperMessage,
  KooperMessageContent,
  KooperTab,
  KooperTabPane,
  KooperTooltip,
} from 'kooper-library'
import {useState} from 'react'
import {buildStyles, CircularProgressbarWithChildren} from 'react-circular-progressbar'
import {useHistory, useParams} from 'react-router-dom'

const SurveyDetails = () => {
  const {id} = useParams()

  const history = useHistory()
  const [activeTab, setActiveTab] = useState(0)

  const panes = [
    {
      menuItem: {
        content: 'Report',
        key: 'report',
        style: {
          color: activeTab === 0 ? '#1a1f36' : '#666',
          borderBottom: activeTab === 0 ? '2px solid #0052cc' : 'none',
          fontWeight: activeTab === 0 ? '500' : 'normal',
          backgroundColor: 'transparent',
          padding: '12px 16px',
        },
      },
      render: () => (
        <KooperTabPane attached={false} style={{padding: '24px 0'}}>
          <KooperGrid centered>
            <KooperGridColumn width={5}>
              <KooperGrid centered>
                <KooperGridColumn width={9}>
                  <CircularProgressbarWithChildren
                    styles={buildStyles({
                      textColor: 'rgba(0, 0, 0, 0.6)',
                      trailColor: '#f2f4f7',
                      textSize: '7px',
                    })}
                    strokeWidth={10}
                    value={0}
                  >
                    <div className="d-flex" style={{flexDirection: 'column'}}>
                      <h1 className="mb-0">0%</h1>
                      <span>Completion</span>
                    </div>
                  </CircularProgressbarWithChildren>
                </KooperGridColumn>
              </KooperGrid>
            </KooperGridColumn>
            <KooperGridColumn width={5}>
              <div className="pr-5">
                <div style={{marginBottom: '24px'}}>
                  <div className="d-flex mb-3">
                    <div style={{color: '#666', fontSize: '14px'}}>Target</div>
                    <div style={{color: '#1a1f36', fontSize: '14px'}}>0 teams</div>
                  </div>
                  <div className="d-flex mb-3">
                    <div style={{color: '#666', fontSize: '14px'}}>Audience</div>
                    <div style={{color: '#1a1f36', fontSize: '14px'}}>2 members</div>
                  </div>
                </div>

                <div>
                  <div style={{display: 'flex', alignItems: 'center', marginBottom: '8px'}}>
                    <div
                      style={{
                        width: '12px',
                        height: '12px',
                        backgroundColor: '#36B37E',
                        marginRight: '8px',
                        borderRadius: '2px',
                      }}
                    />
                    <span
                      style={{
                        color: '#1a1f36',
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                      }}
                    >
                      <span style={{fontWeight: '500'}}>0%</span>
                      <span>Answered</span>
                    </span>
                  </div>

                  <div style={{display: 'flex', alignItems: 'center', marginBottom: '8px'}}>
                    <div
                      style={{
                        width: '12px',
                        height: '12px',
                        backgroundColor: '#FFAB00',
                        marginRight: '8px',
                        borderRadius: '2px',
                      }}
                    />
                    <span
                      style={{
                        color: '#1a1f36',
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                      }}
                    >
                      <span style={{fontWeight: '500'}}>0%</span>
                      <span>Skipped</span>
                    </span>
                  </div>

                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div
                      style={{
                        width: '12px',
                        height: '12px',
                        backgroundColor: '#DFE1E6',
                        marginRight: '8px',
                        borderRadius: '2px',
                      }}
                    />
                    <span
                      style={{
                        color: '#1a1f36',
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                      }}
                    >
                      <span style={{fontWeight: '500'}}>100%</span>
                      <span>Unanswered</span>
                    </span>
                  </div>
                </div>
              </div>
            </KooperGridColumn>

            <KooperGridColumn width={5}>
              <div className="d-flex mb-3">
                <div style={{color: '#666', fontSize: '14px', marginBottom: '4px'}}>Status</div>
                <div style={{color: '#1a1f36', fontSize: '14px'}}>Active</div>
              </div>
              <div className="d-flex mb-3">
                <div style={{color: '#666', fontSize: '14px', marginBottom: '4px'}}>Published on</div>
                <div style={{color: '#1a1f36', fontSize: '14px'}}>November 19, 2024</div>
              </div>
              <div className="d-flex mb-3">
                <div style={{color: '#666', fontSize: '14px', marginBottom: '4px'}}>Created by</div>
                <div style={{color: '#1a1f36', fontSize: '14px'}}>Jordan Banks</div>
              </div>
              <div className="d-flex mb-3">
                <div style={{color: '#666', fontSize: '14px', marginBottom: '4px'}}>Content</div>
                <div style={{color: '#1a1f36', fontSize: '14px'}}>1 question</div>
              </div>
            </KooperGridColumn>
          </KooperGrid>
        </KooperTabPane>
      ),
    },
    {
      menuItem: {
        content: 'Text answers',
        key: 'text-answers',
        style: {
          color: activeTab === 1 ? '#1a1f36' : '#666',
          borderBottom: activeTab === 1 ? '2px solid #0052cc' : 'none',
          fontWeight: activeTab === 1 ? '500' : 'normal',
          backgroundColor: 'transparent',
          padding: '12px 16px',
        },
      },
      render: () => (
        <KooperTabPane attached={false} style={{padding: '24px 0'}}>
          <KooperNoDataTable />
        </KooperTabPane>
      ),
    },
  ]
  return (
    <div className="kooper-full-page">
      <div className="page-header p-0">
        <div className="campaignNavbar">
          <div>
            <KooperButton primary onClick={() => history.goBack()}>
              Exit
            </KooperButton>
          </div>
          <h3 className="m-0">Custom Survey results</h3>
          <div>
            <KooperButton primary className="mr-3">
              Export
            </KooperButton>
            <KooperButton primary>Share</KooperButton>
          </div>
        </div>
      </div>
      <KooperContainer>
        <div style={{marginBottom: '24px'}}>
          <div className="d-flex jcfs mb-2">
            <KooperHeader
              as="h1"
              style={{
                fontSize: '24px',
                color: '#1a1f36',
                fontWeight: '600',
                marginBottom: 0,
              }}
            >
              New survey
            </KooperHeader>
            <KooperTooltip
              content="Access lebel: Team manager and executives"
              size="mini"
              psoition="top center"
              trigger={<KooperIcon name="lock" size="small" style={{marginLeft: '8px', fontSize: 10, opacity: 0.5}} />}
            />
          </div>
          <p style={{color: '#666', fontSize: '14px', margin: 0}}>Report generated on November 26, 2024</p>
        </div>
        <KooperCard fluid>
          <KooperCardContent>
            <KooperFormGroup>
              <KooperFormField style={{width: '50%'}}>
                <KooperFormSelect
                  fluid
                  value="all"
                  options={[
                    {
                      key: 'all',
                      value: 'all',
                      text: 'All company',
                    },
                    {
                      key: 'Marketting',
                      value: 'marketting',
                      text: 'Marketting',
                    },
                    {
                      key: 'Marketting 2',
                      value: 'marketting 2',
                      text: 'Marketting 2',
                    },
                  ]}
                />
              </KooperFormField>
            </KooperFormGroup>
          </KooperCardContent>
        </KooperCard>
        <KooperCard fluid>
          <KooperCardContent style={{padding: 0}}>
            <KooperTab
              panes={panes}
              onTabChange={(_, data) => setActiveTab(data.activeIndex)}
              menu={{
                secondary: true,
                pointing: true,
                color: 'blue',
              }}
              activeIndex={activeTab}
              style={{margin: 0}}
            />
          </KooperCardContent>
        </KooperCard>

        <div
          style={{
            marginTop: '48px',
            backgroundColor: '#fff',
            border: '1px solid #e6e8ec',
            borderRadius: '8px',
            padding: '24px',
          }}
        >
          <KooperHeader
            as="h3"
            style={{
              fontSize: '16px',
              fontWeight: '600',
              color: '#1a1f36',
              marginBottom: '16px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            1. new question
            <span style={{fontSize: '14px', color: '#666'}}>0 Answered 0 Skipped</span>
          </KooperHeader>

          {id === 5 ? (
            <div
              style={{
                padding: '16px',
                backgroundColor: '#f8f9fa',
                borderRadius: '6px',
                marginBottom: '16px',
              }}
            >
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                  <img src="/placeholder.svg?height=24&width=24" style={{borderRadius: '4px'}} alt="Survey" />
                  <span style={{color: '#1a1f36', fontSize: '14px'}}>this is from test-survey-02</span>
                </div>
                <span style={{color: '#666', fontSize: '14px'}}>Nov 26, 2024</span>
              </div>
            </div>
          ) : (
            <KooperNoDataTable />
          )}
        </div>

        {id !== '2' && (
          <KooperButton
            basic
            fluid
            style={{
              marginTop: '24px',
              color: '#666',
              borderColor: '#e6e8ec',
            }}
          >
            View all text answers
            <KooperIcon name="chevron down" style={{marginLeft: '8px'}} />
          </KooperButton>
        )}
      </KooperContainer>
    </div>
  )
}

export default SurveyDetails
