import {
  KooperCard,
  KooperCardContent,
  KooperContainer,
  KooperDivider,
  KooperHeaderContent,
  KooperList,
  KooperListItem,
} from 'kooper-library'
import React from 'react'

const ReviewAndLaunch = () => {
  return (
    <KooperContainer>
      <KooperCard
        fluid
        style={{
          padding: '24px',
          boxShadow: 'none',
          border: '1px solid #e6e8ec',
          borderRadius: '8px',
          maxWidth: '800px',
          margin: '0 auto',
        }}
      >
        <KooperCardContent>
          <KooperHeaderContent
            variant="header"
            style={{
              fontSize: '16px',
              fontWeight: '600',
              color: '#1a1f36',
              marginBottom: '24px',
            }}
          >
            Review your Custom Survey details
          </KooperHeaderContent>
          <KooperDivider />
          <KooperList relaxed style={{margin: 0}}>
            {[
              {label: 'Title:', value: 'ghdfh'},
              {label: 'Audience:', value: 'Entire organization'},
              {label: 'Recipients:', value: '2 members in total'},
              {label: 'Question(s):', value: '1 per member'},
              {label: 'Access level:', value: 'Closed'},
              {label: 'Sender:', value: 'Jordan'},
              {
                label: 'Delivery:',
                value: 'Included with the Pulse Survey following November 27, 2024',
              },
              {label: 'Frequency:', value: 'Monthly'},
              {label: 'Closing:', value: 'On December 22, 2024'},
            ].map((item, index) => (
              <KooperListItem
                key={index}
                style={{
                  marginBottom: '16px',
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '16px',
                }}
              >
                <KooperHeaderContent
                  variant="body"
                  style={{
                    color: '#666',
                    width: '120px',
                    display: 'inline-block',
                    fontSize: '14px',
                  }}
                >
                  {item.label}
                </KooperHeaderContent>
                <KooperHeaderContent variant="body" style={{color: '#1a1f36', fontSize: '14px'}}>
                  {item.value}
                </KooperHeaderContent>
              </KooperListItem>
            ))}
          </KooperList>
        </KooperCardContent>
      </KooperCard>
    </KooperContainer>
  )
}

export default ReviewAndLaunch
