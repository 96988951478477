import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ResponsiveBar} from '@nivo/bar'
import {ResponsivePie} from '@nivo/pie'
import {
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperGrid,
  KooperGridColumn,
  KooperNoData,
  KooperTooltip,
} from 'kooper-library'

import {
  WON_DEALS_BY_PRODUCTS,
  TOTAL_QUANTITY_SOLD_BY_PRODUCTS,
  CONTACT_BY_PRODUCTS,
  TOTAL_REVENUE_BY_PRODUCTS,
  CURRENT_DEAL_BY_PRODUCTS,
} from 'actions/types'
import {
  currentDealByProducts,
  totalRevenueByProducts,
  newLeadsOrContactByProduct,
  totalQuantitySoldByProduct,
  wonDealsByProducts,
} from 'actions/reports_products'
import {getGridYValuesBar, maxOfGraphBar} from 'utils/graph'
import CustomLoader from 'components/common/CustomLoader'
import {UserDropdown} from 'components/pages/Analytics/AnalyticsUtils'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'

const ProductsAnalytics = () => {
  const dispatch = useDispatch()
  const {type = []} = useSelector(state => state.apiReducer)
  const {
    wonDealsByProductsData = [],
    totalRevenueByProductsData = [],
    totalQuantitySoldByProductData = [],
    currentDealByProductsData = [],
    newLeadsOrContactByProductData = [],
  } = useSelector(state => state.reportsProducts)
  const {analyticsSelectedAssignee} = useSelector(state => state.analyticsInbox)
  const {analyticsAccess, reportsPermissions} = useUserPermissions()
  const productsAccess = analyticsAccess?.products
  const revenueReportsPermissions = reportsPermissions?.revenue_reports

  const actionsToDispatch = useCallback(
    data => {
      dispatch(wonDealsByProducts(data))
      dispatch(totalRevenueByProducts(data))
      dispatch(totalQuantitySoldByProduct(data))
      dispatch(currentDealByProducts(data))
      dispatch(newLeadsOrContactByProduct(data))
    },
    [dispatch]
  )

  const {totalProduct = []} = totalRevenueByProductsData

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/products" />
            <h5>Products Analytics</h5>
          </div>
        </div>
        {productsAccess && revenueReportsPermissions && (
          <div className="page-action">
            <UserDropdown />
            <DateRangeGroup
              actionsToDispatch={actionsToDispatch}
              userId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'assignee'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
            />
          </div>
        )}
      </div>

      <KooperGrid>
        <KooperGridColumn width={8}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Won Deals By Products</KooperCardHeader>
              <KooperTooltip
                content="Breakdown of all won deals based on products over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>

            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(WON_DEALS_BY_PRODUCTS) && <CustomLoader />}
              {!type.includes(WON_DEALS_BY_PRODUCTS) &&
              !wonDealsByProductsData.every(({value}) => Number(value) === 0) ? (
                <ResponsivePie
                  data={wonDealsByProductsData}
                  margin={{top: 20, right: 150, bottom: 30, left: 0}}
                  startAngle={-45}
                  innerRadius={0.5}
                  padAngle={2}
                  cornerRadius={0}
                  borderWidth={1}
                  enableArcLinkLabels={false}
                  sliceLabel={({id}) => `${id}`}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  radialLabelsSkipAngle={10}
                  radialLabelsTextXOffset={6}
                  radialLabelsTextColor="#333333"
                  radialLabelsLinkOffset={0}
                  radialLabelsLinkDiagonalLength={7}
                  radialLabelsLinkHorizontalLength={13}
                  radialLabelsLinkStrokeWidth={1}
                  radialLabelsLinkColor={{from: 'color'}}
                  slicesLabelsSkipAngle={10}
                  slicesLabelsTextColor="#333333"
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                  tooltip={({
                    datum: {
                      data: {id, value, percentage},
                    },
                  }) => (
                    <>
                      <strong>
                        {id}, Value: {value}, {percentage}%
                      </strong>
                    </>
                  )}
                  legends={[
                    {
                      anchor: 'right',
                      direction: 'column',
                      justify: false,
                      translateX: 110,
                      translateY: 0,
                      itemsSpacing: 20,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                !type.includes(WON_DEALS_BY_PRODUCTS) && <KooperNoData type="chart" noDataText="No Data Available" />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
        <KooperGridColumn width={8}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Total Quantity Sold By Product</KooperCardHeader>
              <KooperTooltip
                content="Breakdown of total quantity sold based on products"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>

            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(TOTAL_QUANTITY_SOLD_BY_PRODUCTS) && <CustomLoader />}
              {!type.includes(TOTAL_QUANTITY_SOLD_BY_PRODUCTS) &&
              !totalQuantitySoldByProductData.every(({value}) => value === 0) ? (
                <ResponsivePie
                  data={totalQuantitySoldByProductData}
                  colors={{datum: 'data.color'}}
                  margin={{top: 20, right: 150, bottom: 30, left: 0}}
                  startAngle={-45}
                  innerRadius={0.5}
                  padAngle={2}
                  cornerRadius={0}
                  borderWidth={1}
                  enableArcLinkLabels={false}
                  sliceLabel={({id}) => `${id}`}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  radialLabelsSkipAngle={10}
                  radialLabelsTextXOffset={6}
                  radialLabelsTextColor="#333333"
                  radialLabelsLinkOffset={0}
                  radialLabelsLinkDiagonalLength={7}
                  radialLabelsLinkHorizontalLength={13}
                  radialLabelsLinkStrokeWidth={1}
                  radialLabelsLinkColor={{from: 'color'}}
                  slicesLabelsSkipAngle={10}
                  slicesLabelsTextColor="#333333"
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                  tooltip={({
                    datum: {
                      data: {id, value, percentage},
                    },
                  }) => (
                    <>
                      <strong>
                        {id}, Value: {value}, {percentage}%
                      </strong>
                    </>
                  )}
                  legends={[
                    {
                      anchor: 'right',
                      direction: 'column',
                      justify: false,
                      translateX: 110,
                      translateY: 0,
                      itemsSpacing: 20,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                !type.includes(TOTAL_QUANTITY_SOLD_BY_PRODUCTS) && (
                  <KooperNoData type="chart" noDataText="No Data Available" />
                )
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
      <KooperGrid>
        <KooperGridColumn width={8}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Contact By Product</KooperCardHeader>
              <KooperTooltip
                content="Graphical representation of all the contacts based on products"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(CONTACT_BY_PRODUCTS) && <CustomLoader />}
              {!type.includes(CONTACT_BY_PRODUCTS) && newLeadsOrContactByProductData.every(({count}) => count === 0) ? (
                <KooperNoData type="chart" noDataText="No Data Available" />
              ) : (
                <ResponsiveBar
                  data={newLeadsOrContactByProductData}
                  keys={['count']}
                  indexBy="name"
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  padding={0.3}
                  colors={{scheme: 'nivo'}}
                  enableLabel
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  minValue={0}
                  maxValue={maxOfGraphBar(newLeadsOrContactByProductData, 'count')}
                  gridYValues={getGridYValuesBar(newLeadsOrContactByProductData, 'count')}
                  axisTop={null}
                  axisRight={null}
                  tooltip={({data: {count}}) => (
                    <div className="customTooltip">
                      <span />
                      <strong className="pl-2">count : {count}</strong>
                    </div>
                  )}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'name',
                    legendPosition: 'middle',
                    legendOffset: 40,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesBar(newLeadsOrContactByProductData, 'count'),
                    legend: 'Count',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
        <KooperGridColumn width={8}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Total Revenue By Products</KooperCardHeader>
              <KooperTooltip
                content="Graphical representation of total revenue collected by selling products"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(TOTAL_REVENUE_BY_PRODUCTS) && <CustomLoader />}
              {!type.includes(TOTAL_REVENUE_BY_PRODUCTS) && totalProduct.every(({value}) => value === 0) ? (
                <KooperNoData type="chart" noDataText="No Data Available" />
              ) : (
                <ResponsiveBar
                  data={totalProduct}
                  // keys={'value'}
                  indexBy="name"
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  padding={0.3}
                  colors={{scheme: 'nivo'}}
                  enableLabel
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  minValue={0}
                  maxValue={maxOfGraphBar(totalProduct, 'value')}
                  gridYValues={getGridYValuesBar(totalProduct, 'value')}
                  axisTop={null}
                  axisRight={null}
                  tooltip={({value, data: {count}}) => (
                    <div className="customTooltip">
                      <span />
                      <strong className="pl-2">
                        value : ${value} , count : {count}
                      </strong>
                    </div>
                  )}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'name',
                    legendPosition: 'middle',
                    legendOffset: 40,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesBar(totalProduct, 'value'),
                    legend: 'Value',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
      <KooperGrid>
        <KooperGridColumn width={8}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Current Deal By Products</KooperCardHeader>
              <KooperTooltip
                content="Graphical representation of all ongoing deals with respect to the products"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(CURRENT_DEAL_BY_PRODUCTS) && <CustomLoader />}
              {!type.includes(CURRENT_DEAL_BY_PRODUCTS) && currentDealByProductsData.every(({count}) => count === 0) ? (
                <KooperNoData type="chart" noDataText="No Data Available" />
              ) : (
                <ResponsiveBar
                  data={currentDealByProductsData}
                  keys={['Count']}
                  indexBy="name"
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  padding={0.3}
                  colors={{scheme: 'nivo'}}
                  enableLabel
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  minValue={0}
                  maxValue={maxOfGraphBar(currentDealByProductsData, 'count')}
                  gridYValues={getGridYValuesBar(currentDealByProductsData, 'count')}
                  axisTop={null}
                  axisRight={null}
                  tooltip={({value, data: {count}}) => (
                    <div className="customTooltip">
                      <span />
                      <strong className="pl-2">
                        value : ${value} , count : {count}
                      </strong>
                    </div>
                  )}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'name',
                    legendPosition: 'middle',
                    legendOffset: 40,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesBar(currentDealByProductsData, 'count'),
                    legend: 'Count',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
    </div>
  )
}
export default ProductsAnalytics
