/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {getProjectsBudtesList} from 'actions/budgeting'
import {getUsers} from 'actions/settings'
import {GET_PROJECTS_BUDGETS_LIST} from 'actions/types'
import SearchComponent from 'components/common/SearchComponent'
import SvgIcon from 'components/common/SvgIcon'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min'
import {get, getFormattedDate, startCase} from 'utils/helper'
import {
  KooperDropdown,
  KooperHeader,
  KooperNoData,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import {PROJECTS_PATH} from 'constants/path'

const BudgetsList = () => {
  const dispatch = useDispatch()

  const history = useHistory()

  const [currentListParams, setCurrentListParams] = useState({
    text: '',
    managerId: 'all',
  })

  const {type = []} = useSelector(state => state.apiReducer)
  const {projectsBudgetsList = []} = useSelector(state => state.budgeting)
  const {getUsersList: {users = []} = {}} = useSelector(state => state.settings)

  useEffect(() => {
    const newList = _.omitBy(currentListParams, value => {
      if (value === 'none' || value === '' || value === 'all') {
        return true
      }
      return false
    })
    dispatch(getProjectsBudtesList(newList))
  }, [dispatch, currentListParams])

  useEffect(() => {
    dispatch(getUsers())
  }, [dispatch])

  const renderTableBody = () => {
    if (type.includes(GET_PROJECTS_BUDGETS_LIST)) {
      return <KooperTablePlaceholder />
    }
    if (!type.includes(GET_PROJECTS_BUDGETS_LIST) && !projectsBudgetsList.length) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="5" className="emptyValue">
            <KooperNoData type="table" noDataText="No Data Available" />
          </KooperTableCell>
        </KooperTableRow>
      )
    }
    return projectsBudgetsList.map(budget => (
      <KooperTableRow className="tableLink">
        <KooperTableCell>
          <KooperHeader
            as="h5"
            onClick={() =>
              history.push(
                `/${PROJECTS_PATH.PREFIX}/${budget.id}/${PROJECTS_PATH.FINANCE}/${PROJECTS_PATH.FINANCE_SUMMARY}`
              )
            }
          >
            {startCase(budget.title)}
          </KooperHeader>
        </KooperTableCell>
        <KooperTableCell>{budget.managerName}</KooperTableCell>
        <KooperTableCell>
          {budget.isBudgetCreated ? (
            <div style={{width: '130px'}}>
              <h5 className="mb-1">{get(['budget', 'usedPercentage'], budget, 0).toFixed(2)}%</h5>
              <div className="budget-progress">
                <div
                  className="budget-progress-completed"
                  style={{width: `${get(['budget', 'usedPercentage'], budget, 0).toFixed(2)}%`}}
                />
              </div>
              <p className="mt-1">
                ${get(['budget', 'usedAmount'], budget, 0)} 0f ${get(['budget', 'budgetAmount'], budget, 0)}
              </p>
            </div>
          ) : (
            '-'
          )}
        </KooperTableCell>
        <KooperTableCell>
          {getFormattedDate(budget.budget?.periodStartDate)} - {getFormattedDate(budget.budget?.periodEndDate)}
        </KooperTableCell>
        <KooperTableCell>
          {budget.isBudgetCreated ? (
            <a
              onClick={() =>
                history.push({
                  pathname: `${PROJECTS_PATH.PREFIX}/${budget.id}/${PROJECTS_PATH.FINANCE}/${PROJECTS_PATH.FINANCE_RATES}`,
                  state: {
                    budgetId: budget.budget?.budgetId,
                  },
                })
              }
            >
              Set Rates
            </a>
          ) : (
            '-'
          )}
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  return (
    <div className="pageBodyWrap">
      <div className="pageHeader px-0">
        <div className="pageTitle">
          <div className="pageTitleIcon">
            <SvgIcon path="navigation/budgeting" />
            <h5>Budgets</h5>
          </div>
        </div>
        <div className="pageAction">
          <SearchComponent
            onSuccess={text => setCurrentListParams(currentListParams => ({...currentListParams, text}))}
          />
          <div className="mailActivity ml-3">
            <KooperTooltip
              content="Owner"
              size="mini"
              position="top center"
              trigger={
                <KooperDropdown
                  icon="chevron down"
                  button
                  direction="left"
                  defaultValue={currentListParams.managerId}
                  options={[
                    {key: 'all', value: 'all', text: 'All'},
                    ...users.map(user => ({key: user.id, value: user.id, text: `${user.firstName} ${user.lastName}`})),
                  ]}
                  onChange={(e, {value}) => setCurrentListParams({...currentListParams, managerId: value})}
                />
              }
            />
          </div>
        </div>
      </div>
      <KooperTable basic>
        <KooperTableHeader>
          <KooperTableRow>
            <KooperTableHeaderCell>Name</KooperTableHeaderCell>
            <KooperTableHeaderCell>Owner</KooperTableHeaderCell>
            <KooperTableHeaderCell>Budget</KooperTableHeaderCell>
            <KooperTableHeaderCell>Budget Dates</KooperTableHeaderCell>
            <KooperTableHeaderCell>Rates</KooperTableHeaderCell>
          </KooperTableRow>
        </KooperTableHeader>
        <KooperTableBody>{renderTableBody()}</KooperTableBody>
      </KooperTable>
    </div>
  )
}

export default BudgetsList
