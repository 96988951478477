import DocumentTitle from 'components/common/DocumentTitle'
import AllSurveys from 'components/pages/Teams/Surveys/AllSurveys'
import PulseSurveys from 'components/pages/Teams/Surveys/PulseSurveys'
import {SURVEYS_PATH} from 'constants/path'
import {Redirect, Route, Switch} from 'react-router-dom'

const surveysRoutes = [
  {path: `/${SURVEYS_PATH.PREFIX}/${SURVEYS_PATH.ALL}`, component: AllSurveys, title: 'All Surveys', exact: true},
  {
    path: `/${SURVEYS_PATH.PREFIX}/${SURVEYS_PATH.PULSE_SURVEY}`,
    component: PulseSurveys,
    title: 'Pulse survey',
    exact: false,
  },
]

const renderRoute = ({path, component: Component, title, exact}) => (
  <Route
    key={path}
    path={path}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} surveys />
        <Component {...props} />
      </>
    )}
  />
)

const SurveysRoutes = () => (
  <Switch>
    {surveysRoutes.map(renderRoute)}
    <Route path={`/${SURVEYS_PATH.PREFIX}`}>
      <Redirect to={`/${SURVEYS_PATH.PREFIX}/${SURVEYS_PATH.ALL}`} />
    </Route>
  </Switch>
)

export default SurveysRoutes
